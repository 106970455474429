import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import "bootstrap/dist/css/bootstrap.min.css";

class SortBy extends Component {
  handleChange = (e) => {
    this.props.handleChange(e);
  };
  render() {
    return (
      <select
        className=""
        onChange={(e) => {
          this.handleChange(e.target.value);
        }}
      >
        <option value="product_asc">Product Name A-Z</option>
        <option value="product_desc">Product Name Z-A</option>
        <option value="price_asc">Price Low to High</option>
        <option value="price_desc">Price High to Low</option>
      </select>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.LoaderReducer.loading,
});

export default connect(mapStateToProps)(SortBy);
