import axios from "axios";
import {
  USER_SERVICE_ERROR,
  USER_SERVICE_PENDING,
  USER_SERVICE_SUCCESS,
  EDIT_USER_SERVICE_ERROR,
  EDIT_USER_SERVICE_SUCCESS,
  EDIT_USER_SERVICE_PENDING,
} from "./actionTypes";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { loaderStatus } from "../vendor/loaderAction";

export const getUserService = (props) => (dispatch) => {
  const body = {
    username: props.username,
    sessionid: props.sessionid,
    failstatus: 0,
    request: {},
  };

 

  if ("orderitemid" in props) {
    body.request = {
      orderitemid: props.orderitemid,
    };
  }

  if (props.filter) {
    body.request.filter = props.filter;
  }


  console.log('GET USERS REQUEST',JSON.stringify(body));
  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + "getusers",
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(serviceActionSuccess(response.data.data));
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
      } else {
        dispatch(serviceActionError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(loaderStatus(false));
      dispatch(serviceActionError(error.message));
    });
};

export const serviceActionPending = () => ({
  type: USER_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: USER_SERVICE_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: USER_SERVICE_SUCCESS,
  payload: data,
});

export const editUserService = (data, history) => (dispatch) => {
  let body = {
    username: data.username,
    sessionid: data.sessionid,
    failstatus: 0,
    request: {
      ...data.user,
    },
  };

  dispatch(editUserServicePending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + (data.edit === false ? "adduser" : "edituser"),
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(editUserServiceSuccess(response.data.statusMessage));
      } else if (response.data.status > 0) {
        dispatch(editUserServiceError(response.data.statusMessage));
      } else {
        dispatch(editUserServiceError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(loaderStatus(false));
      dispatch(editUserServiceError(error.message));
    });
};

export const editUserServicePending = () => (dispatch) => {
  dispatch({ type: EDIT_USER_SERVICE_PENDING });
};

export const editUserServiceSuccess = (value) => (dispatch) => {
  dispatch({
    type: EDIT_USER_SERVICE_SUCCESS,
    payload: value,
  });
};

export const editUserServiceError = (value) => (dispatch) => {
  dispatch({
    type: EDIT_USER_SERVICE_ERROR,
    payload: value,
  });
};
