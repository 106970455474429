import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./navbar";
import Footer from "./footer";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "../../styles/commontStyles";
 import DialogModal from "../common/dialogModal";
import Loader from "../common/loader";
import { orderSubmit } from "../../actions/vendor/orderProcessAction";

 
class OrderSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: null,
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      shouldBlockNavigation: true,
    };
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  cancelPaymentProcess = () => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Alert!",
      dialogBody: "Are you want to cancel this order?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={this._cancelCheckOutOrder}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        </>
      ),
    });
  };

  _cancelCheckOutOrder = () => {
    let orderErrorType = this.props.orderErrorType;
 
    if (orderErrorType === "PNP_HELD_PAYMENT_FAIL") {
      this.props.history.push("/orders", {
        actiontype: "CHECKEDINORDERS",
      });
    } else {
      let resubmitData = this.props.location.state.resubmitData;
      let data = {
        username: resubmitData.username,
        sessionid: resubmitData.sessionid,
        ordereditems: resubmitData.ordereditems,
        processaction: "CANCELCHECKOUTORDER",
        orderid: resubmitData.orderid,
      };
      this.props.orderSubmit(data, this.props.history);
    }
  };

  makeCheckOutPayment = () => {
    this.props.history.push("/order/detail", { actiontype: "CHECKEDINORDERS" });
  };

  resubmitOrder = () => {
    let resubmitData = this.props.location.state.resubmitData;
    this.props.orderSubmit(resubmitData, this.props.history);
  };

  render() {
    let errorMessage = this.props.location.state.errorMessage;
     return (
      <>
        <NavBar />
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <div
          className="container-fluid"
          style={{ paddingBottom: 50, paddingTop: 50 }}
        >
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <FontAwesomeIcon
                icon="times-circle"
                color="#FF0000"
                style={{ fontSize: 140 }}
              />
              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                Payment Failed
              </div>

              <div style={{ marginTop: 30, fontSize: 15, fontWeight: "bold" }}>
                {errorMessage}!
              </div>

              <div
                style={{
                  marginTop: 30,
                  fontSize: 30,
                  fontWeight: "bold",
                  justifyContent: "space-between",
                }}
              >
                <button
                  onClick={this.makeCheckOutPayment}
                  className="btn "
                  style={{
                    ...styles.confirmButtonStyle,
                    ...{ marginRight: 15 },
                  }}
                >
                  MAKE PAYMENT
                </button>

                <button
                  onClick={this.resubmitOrder}
                  className="btn "
                  style={{
                    ...styles.confirmButtonStyle,
                    ...{},
                  }}
                >
                  RESUBMIT
                </button>

                <button
                  onClick={this.cancelPaymentProcess}
                  className="btn "
                  style={{
                    ...styles.confirmButtonStyle,
                    ...{ marginLeft: 15 },
                  }}
                >
                  CANCEL CHECKOUT PROCESS
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

//  export default Success;
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
  orderErrorType: state.OrderProcessReducer.orderErrorType,
});

export default connect(mapStateToProps, { orderSubmit })(OrderSuccess);
