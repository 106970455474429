import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import { confirmCart, addSignature } from "../../actions/public/paymentAction";
import * as valid from "card-validator";
import Loader from "../common/loader";
import Footer from "../common/footer";
import Modal from "react-bootstrap/Modal";
import SignatureCanvas from "react-signature-canvas";
import { updateCardDetails } from "../../actions/vendor/orderProcessAction";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";
import { loaderStatus } from "../../actions/vendor/loaderAction";
import Fac from "../../plugins/fac";
import { processTransaction } from "../../actions/common/OrderAction";
import { processCard } from "../../actions/common/ProcessCardAction";
import DialogModal from "../common/dialogModal";
import { SIMULATE_TEST_CARD } from "../../Config/CustomConfig";

class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      checked: false,
      toggle: false,
      paymentMethod: "card",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      calculatedValue: "",
      isOpen: true,
      errorMessage: {
        cardHolderName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      },
      currency: "USD",
      exchangerate: 1,
      currencycode: "USD",
      isOpenSignature: false,
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.cartItems.length === 0) {
      this.props.history.push("/");
    }

    this.toggle();

    this.setState({
      cardHolderName: this.props.cardedetails.cardHolderName
        ? this.props.cardedetails.cardHolderName
        : "",
      cardNumber: this.props.cardedetails.cardNumber
        ? this.props.cardedetails.cardNumber
        : "",
      expiryDate: this.props.cardedetails.expiryDate
        ? this.props.cardedetails.expiryDate
        : "",
      cvv: this.props.cardedetails.cvv ? this.props.cardedetails.cvv : "",
    });
  };
  showModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  hideModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  toggle = (event) => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };
  paymentOption = (e) => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
    this.setState({
      paymentMethod: e.target.value,
    });

    let calculatedValue = (
      (this.props.cartTotal + this.props.cartTaxTotal) *
      this.state.exchangerate
    ).toFixed(2);
    this.setState({
      calculatedValue: calculatedValue,
    });
    this.setState({
      currency: "USD",
    });
  };

  cardHolderNameChange = (e) => {
    this.setState({
      cardHolderName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
    });
  };
  cardnumberChange = (e) => {
    this.setState({
      cardNumber: e.target.value.replace(/[^0-9]/g, ""),
    });
  };
  expirydateChange = (e) => {
    this.setState({
      expiryDate: e.target.value.replace(/[^0-9]/g, ""),
    });
  };
  cvvChange = (e) => {
    this.setState({
      cvv: e.target.value.replace(/[^0-9]/g, ""),
    });
  };
  firstNameChange = (e) => {
    this.setState({
      firstName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
    });
  };
  lastNameChange = (e) => {
    this.setState({
      lastName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
    });
  };
  emailChange = (e) => {
    this.setState({
      email: e.target.value.trim(),
    });
  };
  phoneNumberChange = (e) => {
    this.setState({
      phoneNumber: e.target.value.replace(/[^0-9]/g, ""),
    });
  };
  setCurrency = (e) => {
    this.setState({
      exchangerate: e.target[e.target.selectedIndex].getAttribute(
        "data-exchangerate"
      ),
      currencycode: e.target[e.target.selectedIndex].getAttribute(
        "data-currencycode"
      ),
      currency: e.target.value,
    });
    if (!e.target.value) {
      this.setState({
        calculatedValue: "",
        currency: "",
        exchangerate: "",
      });
    } else {
      let calc_result = (
        (this.props.cartTotal + this.props.cartTaxTotal) *
        e.target[e.target.selectedIndex].getAttribute("data-exchangerate")
      ).toFixed(2);
      this.setState({ calculatedValue: calc_result });
    }
  };

  resetError = () => {
    let errorMessage = {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    };
    this.setState({ errorMessage: errorMessage });
  };

  handleCashDetailSubmit = (e) => {
    e.preventDefault();
    if (this.props.signature === null || this.props.signature === undefined) {
      this.setState((prevState) => ({
        isOpenSignature: !prevState.isOpenSignature,
      }));
    } else {
      this.saveOrder();
    }
  };

  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  saveOrder = async () => {
    let canvasSignature;
    const logindetails = JSON.parse(localStorage.getItem("loginDetail"));
    if (this.props.signature === null || this.props.signature === undefined) {
      canvasSignature = this.sigPad.getCanvas().toDataURL("image/png");
      this.setState({ signatureDataURL: canvasSignature });
      this.props.addSignature(canvasSignature);

      this.setState((prevState) => ({
        isOpenSignature: !prevState.isOpenSignature,
      }));
    } else {
      canvasSignature = this.props.signature;
    }

    if (!canvasSignature) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Alert!",
        dialogBody: "Signature is required!",
        dialogFooter: (
          <>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={() => {
                this.setState({ displayDialog: false });
              }}
            >
              Cancel
            </button>
          </>
        ),
      });
      return false;
    }

    let parameter = {
      user: {
        username: this.props.userDetail.userdefault.username,
        firstname: this.props.guestInfo.firstname,
        lastname: this.props.guestInfo.lastname,
        email: this.props.guestInfo.email,
        phone: this.props.guestInfo.phone,
      },
      sessionid: this.props.userDetail.sessionid,
      cart: this.props.cartItems,
      cartTotal: this.props.cartTotal + this.props.cartTaxTotal,
      scheduleId: this.props.formData.scheduleId,
      direction: this.props.formData.direction,
      walkin: "Y",
      signature: canvasSignature,
      exchangerate: this.state.exchangerate,
      currencycode: this.state.currencycode,
    };

    if (this.state.paymentMethod === "cash") {
      parameter = { ...parameter, paymentMethod: "cash" };
    } else {
      this.props.updateCardDetails({
        cardHolderName: this.state.cardHolderName,
        cardNumber: this.state.cardNumber,
        expiryDate: this.state.expiryDate,
        cvv: this.state.cvv,
      });

      let cardNumber = this.state.cardNumber;
      const cardnumberData = valid.number(cardNumber);
      parameter = {
        ...parameter,
        paymentMethod: "creditcard",
        cardInfo: {
          cardtype: cardnumberData.card.type,
          cardholder: this.state.cardHolderName,
          cvv: this.state.cvv,
          cardnumber: this.state.cardNumber,
          expirydate: this.state.expiryDate,
        },
        logindata: {
          username: logindetails.username,
          password: logindetails.password,
          internal: logindetails.internal,
        },
      };
    }
    const PaymentGatewayDetail = JSON.parse(
      localStorage.getItem("PaymentGatewayDetail")
    );
    if (
      PaymentGatewayDetail.paymentgateway === "FAC" &&
      parameter.paymentMethod === "creditcard"
    ) {
      const FAC_CONFIG = {
        merchantid: PaymentGatewayDetail.merchantid,
        password: PaymentGatewayDetail.password,
        endpoint: PaymentGatewayDetail.endpointpath,
        acquireid: PaymentGatewayDetail.acquirerid,
        currency_code: PaymentGatewayDetail.currencycode,
        transacton_code: PaymentGatewayDetail.transactioncode,
        redirecturl: PaymentGatewayDetail.redirecturl,
        endpointpath: PaymentGatewayDetail.endpointpath,
      };

      this.props.loaderStatus(true);

      const fac = new Fac(FAC_CONFIG);

      //Request body for processtransaction api
      let processTransactionRequestBody = {
        transactiontype: "RESERVECART",
        source: "AIR",
        reservation: {
          direction: parameter.direction,
          scheduleid: parameter.scheduleId,
          cart: parameter.cart.map((item, index) => {
            return {
              vendorid: item.vendorid,
              productid: item.productid,
              unitcost: item.unitcost,
              quantity: item.quantityincart,
              taxrate: item.taxrate,
              total: item.total,
            };
          }),
          walkin: parameter.walkin,
        },
        amount: parameter.cartTotal,
        reset: "N"
      };

      let processTransactionResponse = await processTransaction(
        processTransactionRequestBody
      );

      if (!processTransactionResponse.success) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Alert!",
          dialogBody: processTransactionResponse.statusMessage,
          dialogFooter: (
            <>
              <button
                className="btn"
                style={styles.dialogConfirmButtonStyle}
                onClick={() => {
                  this.closeDialog();
                }}
              >
                Ok
              </button>
            </>
          ),
        });
      } else {


        const transactionStatusCheck = await processCard({
          orderid: processTransactionResponse.data.orderid,
          actiontype: "TRANSACTIONSTATUS",
        });

        if (transactionStatusCheck.data.reasoncode !== "1") {

          processTransactionRequestBody.reset = "Y";

          processTransactionResponse = await processTransaction(
            processTransactionRequestBody
          );

          if (!processTransactionResponse.success) {
            this.setState({
              displayDialog: true,
              dialogTitle: "Alert!",
              dialogBody: processTransactionResponse.statusMessage,
              dialogFooter: (
                <>
                  <button
                    className="btn"
                    style={styles.dialogConfirmButtonStyle}
                    onClick={() => {
                      this.closeDialog();
                    }}
                  >
                    Ok
                  </button>
                </>
              ),
            });
            return;
          }


          let processCardRequestBody = {
            orderid: processTransactionResponse.data.orderid,
            actiontype: "CHARGECARD",
            transactioncode: 8,
            creditcard: {
              cardtype: "VISA",
              cardnumber: parameter.cardInfo.cardnumber,
              cardholder: parameter.cardInfo.cardholder,
              expirydate: parameter.cardInfo.expirydate,
              cvv: parameter.cardInfo.cvv,
              amount: parameter.cartTotal.toFixed(2),
            },
          };

          const processCardResponse = await processCard(processCardRequestBody);
          if (processCardResponse.success) {
            console.log("processCardResponse", processCardResponse);
            const response = await fac.process(
              processCardResponse.data.html,
              processTransactionResponse.data.orderid
            );

            console.clear();
            console.log("FACresponse", JSON.stringify(response.data));

            this.props.loaderStatus(false);
            if (response.data.reasoncode == 1) {
              parameter = {
                ...parameter,
                transactionCode: 8,
                paymentResponse: response.data,
              };
              this.props.confirmCart(parameter, this.props.history);
            } else {
              //FAC Failed so we call processtransaction with 'transactiontype = CANCELCART'

              await processTransaction({
                transactiontype: "CANCELCART",
              });

              let errorMessage = response.data.reasondescription
                ? response.data.reasondescription
                : response.statusMessage;

              // alert(errorMessage);
              if (errorMessage != "Window is closed") {
                this.props.history.push("/order/payment/failed", {
                  walkin: "Y",
                  amount: parameter.cartTotal.toFixed(2),
                });

                // this.setState({
                //   displayDialog: true,
                //   dialogTitle: "Alert!",
                //   dialogBody: errorMessage,
                //   dialogFooter: (
                //     <>
                //       <button
                //         className="btn"
                //         style={styles.dialogConfirmButtonStyle}
                //         onClick={() => {
                //           this.closeDialog();
                //         }}
                //       >
                //         Ok
                //       </button>
                //     </>
                //   ),
                // });
              }
            }
          } else {
            this.props.loaderStatus(false);
            //FAC Failed so we call processtransaction with 'transactiontype = CANCELCART'

            if (!processCardResponse.success) {
              this.setState({
                displayDialog: true,
                dialogTitle: "Alert!",
                dialogBody: processCardResponse.statusMessage,
                dialogFooter: (
                  <>
                    <button
                      className="btn"
                      style={styles.dialogConfirmButtonStyle}
                      onClick={() => {
                        this.closeDialog();
                      }}
                    >
                      Ok
                    </button>
                  </>
                ),
              });
            }

            let cancelCartResponse = await processTransaction({
              transactiontype: "CANCELCART",
            });
            console.log("Cancel Cart Response", cancelCartResponse);
          }
        }
        else {
          if (transactionStatusCheck.data.reasoncode == 1) {

            this.props.loaderStatus(false);
            if (processTransactionResponse.data.amount === parameter.cartTotal) {
              // let request = this.props.location.state.request;
              // request = {
              //   ...request,
              //   transactionCode: 0,
              //   paymentResponse: transactionStatusCheck.data,
              // };

              parameter = {
                ...parameter,
                transactionCode: 0,
                paymentResponse: transactionStatusCheck.data,
              };
              console.log("Request payment 1", parameter);
              this.props.confirmCart(parameter, this.props.history);
            }
            else {
              this.setState({
                displayDialog: true,
                dialogTitle: "Alert!",
                dialogBody: "The amount authorized does not match with the amount, please contact support.",
                dialogFooter: (
                  <>
                    <button
                      className="btn"
                      style={styles.dialogConfirmButtonStyle}
                      onClick={() => {
                        this.closeDialog();
                      }}
                    >
                      Ok
                    </button>
                  </>
                ),
              });

            }
          }
          // else {
          //   this.props.loaderStatus(false);
          //   //FAC Failed so we call processtransaction with 'transactiontype = CANCELCART'
          //   let cancelCartResponse = await processTransaction({
          //     transactiontype: "CANCELCART",
          //   });

          //   let errorMessage = transactionStatusCheck.data.reasondescription
          //     ? transactionStatusCheck.data.reasondescription
          //     : transactionStatusCheck.statusMessage;

          //   if (errorMessage !== "Window is closed") {
          //     this.props.history.push("/order/payment/failed", {
          //       walkin: "N",
          //       amount: this.props.cartTotal.toFixed(2),
          //     });

          //     this.setState({
          //       displayDialog: true,
          //       dialogTitle: "Alert!",
          //       dialogBody: errorMessage,
          //       dialogFooter: (
          //         <>
          //           <button
          //             className="btn"
          //             style={styles.dialogConfirmButtonStyle}
          //             onClick={() => {
          //               this.closeDialog();
          //             }}
          //           >
          //             Ok
          //           </button>
          //         </>
          //       ),
          //     });
          //   }

          //   if (!cancelCartResponse.success) {
          //     this.setState({
          //       displayDialog: true,
          //       dialogTitle: "Alert!",
          //       dialogBody: cancelCartResponse.statusMessage,
          //       dialogFooter: (
          //         <>
          //           <button
          //             className="btn"
          //             style={styles.dialogConfirmButtonStyle}
          //             onClick={() => {
          //               this.closeDialog();
          //             }}
          //           >
          //             Ok
          //           </button>
          //         </>
          //       ),
          //     });
          //   }
          // }
        }
      }
    } else {
      this.props.confirmCart(parameter, this.props.history);
      this.props.loaderStatus(false);
    }
  };

  isValidEmail = (email) => {
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return expr.test(email);
  };

  handleCardDetailSubmit = (event) => {
    event.preventDefault();
    const cardHolderName = this.state.cardHolderName;
    let cardNumber = this.state.cardNumber;
    const expiryDate = this.state.expiryDate;
    const cvv = this.state.cvv;
    const cardnumberData = valid.number(cardNumber);

    let errorMessage = {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
    };

    if (!cardHolderName.trim()) {
      errorMessage.cardHolderName = "Card Holder Name is Required.";
    }
    if (!cardNumber) {
      errorMessage.cardNumber = "Card Number is Required.";
    } else if (cardnumberData.card) {
      if (
        cardnumberData.card.type === "visa" ||
        cardnumberData.card.type === "mastercard"
      ) {
        if (!SIMULATE_TEST_CARD && !cardnumberData.isValid) {
          errorMessage.cardNumber = "Please enter a valid Card Number.";
        }
      } else {
        errorMessage.cardNumber = "Please enter a valid Card Number.";
      }
    } else {
      errorMessage.cardNumber = "Please enter a valid Card Number.";
    }

    if (expiryDate === "") {
      errorMessage.expiryDate = "Expiry Date is Required.";
    } else if (!valid.expirationDate(expiryDate).isValid) {
      errorMessage.expiryDate = "Please enter a valid Expiry Date.";
    }

    if (cvv === "") {
      errorMessage.cvv = "CVV is Required.";
    } else if (!valid.cvv(cvv, 3).isValid) {
      errorMessage.cvv = "Please Enter a valid CVV.";
    }

    this.setState({ errorMessage: errorMessage });

    // return false;
    if (cardHolderName.indexOf(" ") !== 0 && cardNumber && expiryDate && cvv) {
      if (

        valid.expirationDate(expiryDate).isValid &&
        valid.cvv(cvv, 3).isValid
      ) {

        if (!SIMULATE_TEST_CARD && !cardnumberData.isValid) {
          return false;
        }

        if (
          cardnumberData.card.type === "visa" ||
          cardnumberData.card.type === "mastercard"
        ) {
          if (
            this.props.signature === null ||
            this.props.signature === undefined
          ) {
            this.setState((prevState) => ({
              isOpenSignature: !prevState.isOpenSignature,
            }));
          } else {
            this.saveOrder();
          }
        }
      }
    }
  };

  hideSignatureModal = () => {
    this.setState((prevState) => ({
      isOpenSignature: !prevState.isOpenSignature,
    }));
  };

  clear = () => {
    this.sigPad.clear();
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  render() {
    if (this.props.cartItems.length === 0) {
      this.props.history.push("/");
    }

    return (
      <>
        <NavBar />
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        {/* ******** Signature Modal */}
        <Modal
          show={this.state.isOpenSignature}
          centered
          onHide={this.hideSignatureModal}
        >
          <Modal.Header closeButton style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Customer Signature
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              penColor="black"
              canvasProps={{ width: 450, height: 300, className: "sigCanvas" }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              disabled={this.sigPad}
              className="btn "
              style={styles.dialogConfirmButtonStyle}
              onClick={this.saveOrder}
            >
              Save & Proceed
            </button>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.clear}
            >
              Reset
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Signature Modal End*/}

        <Modal show={this.state.isOpen} centered onHide={this.hideModal}>
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Select Payment option
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="paymentOption"
                  value="card"
                  onChange={this.paymentOption}
                  checked={this.state.paymentMethod === "card"}
                />{" "}
                Card
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="paymentOption"
                  value="cash"
                  onChange={this.paymentOption}
                  checked={this.state.paymentMethod === "cash"}
                />{" "}
                Cash
              </label>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn "
              style={styles.dialogConfirmButtonStyle}
              onClick={this.hideModal}
            >
              Continue
            </button>
          </Modal.Footer>
        </Modal>

        <Container className="back-bar-container" fluid>
          <Row className={"back-bar categoryBar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          {this.state.paymentMethod === "card" ? (
            <>
              <form onSubmit={this.handleCardDetailSubmit}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div
                        style={{
                          // padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className={"card-head "}>
                          <h3 className="summary-title">
                            Payment Details{" "}
                            <small style={{ fontSize: 11 }}>
                              Credit Card Detail
                            </small>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                style={styles.inputLabelStyle}
                                htmlFor="cardholder"
                              >
                                Card Holder Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                autoFocus={true}
                                placeholder="Enter Card Holder Name"
                                value={this.state.cardHolderName}
                                onChange={this.cardHolderNameChange}
                                onFocus={this.resetError}
                              />
                              {this.state.errorMessage.cardHolderName && (
                                <small className="text-danger">
                                  {this.state.errorMessage.cardHolderName}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="cardnumber"
                                style={styles.inputLabelStyle}
                              >
                                Card Number
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="16"
                                placeholder="Enter Card Number"
                                onChange={this.cardnumberChange}
                                onFocus={this.resetError}
                              />
                              {this.state.errorMessage.cardNumber && (
                                <small className="text-danger">
                                  {this.state.errorMessage.cardNumber}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="expirydate"
                                style={styles.inputLabelStyle}
                              >
                                Expiry Date
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength={4}
                                placeholder="Enter Expiry Date in MMYY"
                                value={this.state.expiryDate}
                                onChange={this.expirydateChange}
                                onFocus={this.resetError}
                              />
                              {this.state.errorMessage.expiryDate && (
                                <small className="text-danger">
                                  {this.state.errorMessage.expiryDate}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label
                                htmlFor="cvv"
                                style={styles.inputLabelStyle}
                              >
                                CVV:
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                maxLength="3"
                                placeholder="Enter CVV"
                                value={this.state.cvv}
                                onChange={this.cvvChange}
                                onFocus={this.resetError}
                              />
                              {this.state.errorMessage.cvv && (
                                <small className="text-danger">
                                  {this.state.errorMessage.cvv}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div
                        style={{
                          padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "#555",
                          }}
                        >
                          <h3 className="summary-title">Order Summary</h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div
                          className="d-flex justify-content-between"
                          style={{ marginBottom: 10 }}
                        >
                          <div className="FS14 FWB">Sub Total</div>
                          <div className="FS14">
                            ${this.props.cartTotal.toFixed(2)}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">Tax</div>
                          <div className="FS14">
                            ${this.props.cartTaxTotal.toFixed(2)}
                          </div>
                        </div>
                        <div className={"seperator"}></div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS16 FWB">Grand Total</div>
                          <div className="FS16 FWB">
                            $
                            {(
                              this.props.cartTotal + this.props.cartTaxTotal
                            ).toFixed(2)}
                          </div>
                        </div>
                        <button
                          type="button"
                          onClick={this.showModal}
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Change Payment Method
                        </button>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : null}
          {this.state.paymentMethod === "cash" ? (
            <>
              <form onSubmit={this.handleCashDetailSubmit}>
                <div className="row">
                  <div className="col-md-8">
                    <div className="card">
                      <div
                        style={{
                          // padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div className={"card-head "}>
                          <h3 className="summary-title">
                            Cash Payment Details{" "}
                            <small style={{ fontSize: 11 }}></small>
                          </h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">Amount:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={(
                                  this.props.cartTotal + this.props.cartTaxTotal
                                ).toFixed(2)}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">From:</label>
                              <input
                                type="text"
                                className="form-control"
                                value="US Dollar (USD)"
                                disabled
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">To:</label>
                              <select
                                className="form-control"
                                onChange={this.setCurrency}
                                value={this.state.currency}
                              >
                                <option value="">--Select Currency--</option>
                                {this.props.userDetail.currencies.map(
                                  (item) => (
                                    <option
                                      key={item.description}
                                      data-currencycode={item.currencycode}
                                      data-exchangerate={item.exchangerate}
                                      value={item.currencycode}
                                    >
                                      {item.description} ({item.currencycode})
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="amount">Amount to Charge:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={this.state.calculatedValue}
                                disabled
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="card">
                      <div
                        style={{
                          padding: 15,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: 18,
                            color: "#555",
                          }}
                        >
                          <h3 className="summary-title">Order Summary</h3>
                        </div>
                      </div>
                      <div className="card-body">
                        <div
                          className="d-flex justify-content-between"
                          style={{ marginBottom: 10 }}
                        >
                          <div className="FS14 FWB">Sub Total</div>
                          <div className="FS14">
                            ${this.props.cartTotal.toFixed(2)}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS14 FWB">Tax</div>
                          <div className="FS14">
                            ${this.props.cartTaxTotal.toFixed(2)}
                          </div>
                        </div>
                        <div className={"seperator"}></div>
                        <div className="d-flex justify-content-between MB10">
                          <div className="FS16 FWB">Grand Total</div>
                          <div className="FS16 FWB">
                            $
                            {(
                              this.props.cartTotal + this.props.cartTaxTotal
                            ).toFixed(2)}
                          </div>
                        </div>

                        <button
                          type="button"
                          onClick={this.showModal}
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Change Payment Method
                        </button>
                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                          }}
                          className="btn btn-block btn-lg"
                        >
                          Pay
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </>
          ) : null}
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

//  export default Payment;

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,

  cartTotal: state.CartReducer.cartTotal,
  userDetail: state.SignInReducer.logindetails,
  formData: state.FlightInfoReducer.formData,
  guestInfo: state.PaymentReducer.guestInfo,
  signature: state.PaymentReducer.signature,
  cardedetails: state.OrderProcessReducer.cardedetails,
  cartTaxTotal: state.CartReducer.cartTaxTotal,
});

export default connect(mapStateToProps, {
  confirmCart,
  addSignature,
  updateCardDetails,
  loaderStatus,
})(Payment);
