import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  EMPTY_CART,
  CHANGE_PRODUCT_QUANTITY,
  IS_CART_ITEM_EDIT,
  UPDATE_CART_ITEM,
} from "./actionTypes";

export const addToCart = (item) => (dispatch) => {
   dispatch({
    type: ADD_TO_CART,
    payload: item,
  });
};

export const removeItem = (item) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: { item },
  });
};

export const emptyCart = () => (dispatch) => {
  dispatch({
    type: EMPTY_CART,
  });
};
export const changeProductQuantity = (productId, quantity, maxquantity) => (
  dispatch
) => {
  dispatch({
    type: CHANGE_PRODUCT_QUANTITY,
    payload: {
      productId: productId,
      quantityincart: quantity,
      maxquantity: maxquantity,
    },
  });
};

export const setIsEdit = (isedit) => (dispatch) => {
  dispatch({
    type: IS_CART_ITEM_EDIT,
    payload: isedit,
  });
};

export const updateCartItem = (item, index, vendorid) => (dispatch) => {
  dispatch({
    type: UPDATE_CART_ITEM,
    payload: { item: item, index: index, vendorid: vendorid },
  });
};
