import {
  FLIGHT_SERVICE_SUCCESS,
  FLIGHT_SERVICE_PENDING,
  FLIGHT_SERVICE_ERROR,
  UPDATE_FLIGHT_FORM_INFO,
  EMPTY_FLIGHT_FORM_DATA,
} from "../../actions/public/actionTypes";

const initialState = {
  status: null,
  error: null,
  data: [],

  formData: {
    airportid: null,
    direction: null,
    scheduleId: null,
    flighttime: null,
    airline: null,
    flightId: null,
     targetDate: null,
    traveldate: null,
    departureDate: null,
  },
};

const FlightInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FLIGHT_FORM_INFO:
      const updateFormData = { ...state.formData };
      action.payload.map((item) => (updateFormData[item.key] = item.value));

      return {
        ...state,
        formData: updateFormData,
      };
    case FLIGHT_SERVICE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: "success",
        error: null,
      };
    case FLIGHT_SERVICE_PENDING:
      return {
        ...state,
        error: null,
        status: "pending",
      };
    case FLIGHT_SERVICE_ERROR:
      return {
        ...state,
        error: action.payload,
        status: "failed",
      };
    case EMPTY_FLIGHT_FORM_DATA:
      return {
        ...state,
        formData: {
          // airportid: null,
          // direction: null,
          ...state.formData,
          scheduleId: null,
          flighttime: null,
          airline: null,
          flightId: null,
           targetDate: null,
          traveldate: null,
          departureDate: null,
        },
      };
    default:
      return state;
  }
};

export default FlightInfoReducer;
