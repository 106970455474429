import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./navbar";
import Footer from "./footer";
import { Toast, Image, Text, Container, Col, Row } from "react-bootstrap";
import {
  addToCart,
  setIsEdit,
  updateCartItem,
  changeProductQuantity,
} from "../../actions/public/cartAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "../../styles/commontStyles";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import Modal from "react-bootstrap/Modal";
import DialogModal from "./dialogModal";
import Select from "react-select";
import ReactImageMagnify from "react-image-magnify";
import Magnifier from "react-magnifier";

const formatOptionLabel = ({ value, label, unitcost }) => (
  <div style={{ display: "flex", borderRadius: 0, padding: 0, fontSize: 12 }}>
    <div style={{ width: "70%", fontWeight: "bold" }}>{label}</div>
    <div
      style={{
        width: "30%",
        marginLeft: "10px",
        fontWeight: "bold",
        textAlign: "right",
      }}
    >
      ${unitcost}
    </div>
  </div>
);

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      products: [],
      displayToast: false,
      toastMessage: "",
      flag: 0,
      productQuantity: 1,
      product: this.props.location.state.product,
      rootproduct: this.props.location.state.product,
      selectedProductId: this.props.location.state.product.productid,
      selectedProduct: this.props.location.state.product,
      isProductModalShow: false,
      childs: [],
      cartquantity: 1,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.location.state) {
      const product = this.props.location.state.product;
      this.props.cart.map((item) => {
        if (item.productid === product.productid) {
          this.setState({ flag: 1 });
        }
        return false;
      });
    } else {
      this.props.history.push("/");
    }

    if (this.props.location.state.rootproductid) {
      this.returnProductFromStores(
        this.props.location.state.product.productid,
        this.props.location.state.storeid,
        this.props.location.state.rootproductid
      );
    }

    if (this.props.isEdit === false) {
      if (
        this.state.rootproduct.siblings &&
        this.state.rootproduct.siblings.length > 1
      ) {
        let productid = this.state.rootproduct.siblings[0].productid;

        this.setState({
          selectedProduct: this.returnProduct(productid),
          selectedProductId: productid,
        });
      }
    }

    let childs = [];
    if (
      this.state.rootproduct.siblings &&
      this.state.rootproduct.siblings.length > 1
    ) {
      this.state.rootproduct.siblings.map((sibling) => {
        childs.push({
          value: sibling.productid,
          label: sibling.productname,
          unitcost: sibling.unitcost.toFixed(2),
        });
      });
      this.setState({
        childs: childs,
      });
    }
  };

  componentWillUnmount() {
    this.props.setIsEdit(false);
  }

  returnProductFromStores = (productid, storeid, rootproductid) => {
    this.props.stores.forEach((store) => {
      if (store.storeid === storeid) {
        store.categories.forEach((category) => {
          category.products.forEach((product) => {
            /////SELECT ROOT PRODUCT

            if (product.productid === rootproductid) {
              this.setState({
                rootproduct: product,
                product: product,
              });

              let childs = [];
              if (product.siblings && product.siblings.length > 1) {
                product.siblings.map((sibling) => {
                  childs.push({
                    value: sibling.productid,
                    label: sibling.productname,
                    unitcost: sibling.unitcost.toFixed(2),
                  });
                });
              }

              this.setState({
                childs: childs,
              });
            }

            ///SET SELECTED PRODUCT
            if (product.siblings) {
              product.siblings.forEach((sibling) => {
                if (sibling.productid === productid) {
                  this.setState({
                    selectedProduct: sibling,
                    selectedProductId: sibling.productid,
                  });
                }
              });
            } else {
              if (product.productid === productid) {
                this.setState({
                  selectedProduct: product,
                  selectedProductId: product.productid,
                });
              }
            }
          });
        });
      }
    });
  };

  _addToCart = (product) => {
    this.props.setIsEdit(false);
    let findProductDetails = {};

    this.props.stores.forEach((store, index) => {
      store.categories.forEach((category, index) => {
        category.products.forEach((p, index) => {
          if (p.productid === this.state.rootproduct.productid) {
            findProductDetails = {
              store: store,
              product: p,
              category: category,
            };
            return false;
          }
        });
      });
    });

    let storeid = findProductDetails.store.storeid;
    let storename = findProductDetails.store.name;
    let category = findProductDetails.category;
    // let product = findProductDetails.product;

    this.props.addToCart({
      ...product,
      vendorid: storeid,
      quantityincart: this.state.cartquantity,
      vendorname: storename,
      rootproductid: this.state.product.productid,
    });
    this.setState({
      displayToast: true,
      toastMessage: `${product.productname} added to your cart.`,
    });
    this.setState({ flag: 1 });
  };

  _buyNow = (product) => {
    this.props.setIsEdit(false);
    let findProductDetails = {};

    this.props.stores.forEach((store, index) => {
      store.categories.forEach((category, index) => {
        category.products.forEach((p, index) => {
          if (p.productid === this.state.rootproduct.productid) {
            findProductDetails = {
              store: store,
              product: p,
              category: category,
            };
            return false;
          }
        });
      });
    });

    let storeid = findProductDetails.store.storeid;
    let storename = findProductDetails.store.name;
    let category = findProductDetails.category;
    this.props.addToCart({
      ...product,
      vendorid: storeid,
      vendorname: storename,
      quantityincart: this.state.cartquantity,

      rootproductid: this.state.product.productid,
    });
    this.setState({
      displayToast: true,
      toastMessage: `${product.productname} added to your cart.`,
    });
    this.setState({ flag: 1 });
    this.props.history.push("/cart");
  };

  returnProduct = (productid) => {
    return this.state.product.siblings.find(
      (item) => item.productid === productid
    );
  };

  onProductVariationChanged = (target) => {
    let productid = target.value;
    this.setState({
      selectedProduct: this.returnProduct(productid),
      selectedProductId: productid,
      cartquantity: 1,
    });
  };

  goToCartCheck = (productid) => {
    const cart = this.props.cart.filter(
      (product) => product.productid === productid
    ).length;
    return cart;
  };

  itemExistInCart = () => {
    const product = this.state.selectedProduct; //this.props.navigation.getParam('product').siblings ? this.state.selectedProduct : this.props.navigation.getParam('product');
    const isExist = this.props.cart.find(
      (item) => item.productid === product.productid
    );

    if (isExist) {
      return false;
    }
    return true;
  };

  updateProduct = (product) => {
    if (this.itemExistInCart() === false) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: "Item already added in cart",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.setState({ displayDialog: false });
            }}
          >
            Close
          </button>
        ),
      });
    } else {
      this.setState({
        displayToast: true,
        toastMessage: `${product.productname} updated to your cart.`,
      });

      let findProductDetails = {};

      this.props.stores.forEach((store, index) => {
        store.categories.forEach((category, index) => {
          category.products.forEach((p, index) => {
            if (p.productid === this.state.rootproduct.productid) {
              findProductDetails = {
                store: store,
                product: p,
                category: category,
              };
              return false;
            }
          });
        });
      });

      let storeid = findProductDetails.store.storeid;
      let storename = findProductDetails.store.name;
      let category = findProductDetails.category;
      // let product = findProductDetails.product;

      product.quantityincart = this.quantityincart();
      product.vendorname = storename;

      this.props.updateCartItem(
        product,
        this.props.location.state.cartindex,
        this.props.location.state.storeid
      );
      this.props.setIsEdit(false);
    }
  };

  itemExistInCart = () => {
    const product = this.state.selectedProduct; //this.props.navigation.getParam('product').siblings ? this.state.selectedProduct : this.props.navigation.getParam('product');
    const isExist = this.props.cart.find(
      (item) => item.productid === product.productid
    );

    if (isExist) {
      return false;
    }
    return true;
  };

  showModal = () => {
    this.setState({
      isProductModalShow: true,
    });
  };
  hideModal = () => {
    // setIsOpen(true);
    this.setState({
      isProductModalShow: false,
    });
  };

  quantityincart = () => {
    let productid = this.state.selectedProduct.productid;
    const isExist = this.props.cart.find(
      (item) => item.productid === productid
    );
    if (isExist) {
      return isExist.quantityincart;
    } else {
      return this.state.cartquantity;
    }
  };

  isExist = () => {
    let productid = this.state.selectedProduct.productid;
    const isExist = this.props.cart.find(
      (item) => item.productid === productid
    );
    if (isExist) {
      return true;
    } else {
      return false;
    }
  };

  _changeProductQuantity = (productid, quantity, maxquantity) => {
    const isExist = this.props.cart.find(
      (item) => item.productid === productid
    );

    if (isExist) {
      if (quantity > maxquantity) {
        // alert(`Only ${maxquantity} item left in stock`);

        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: `Only ${maxquantity} item left in stock`,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={() => {
                this.setState({ displayDialog: false });
              }}
            >
              OK
            </button>
          ),
        });
      } else {
        if (quantity >= 1) {
          this.props.changeProductQuantity(productid, quantity, maxquantity);
        }
      }
    } else {
      if (quantity > maxquantity) {
        // alert(`Only ${maxquantity} item left in stock`);
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: `Only ${maxquantity} item left in stock`,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={() => {
                this.setState({ displayDialog: false });
              }}
            >
              OK
            </button>
          ),
        });
      } else {
        if (quantity >= 1) {
          this.setState({ cartquantity: quantity });
        }
      }
    }
  };

  render() {
    if (!this.state.product) return null;

    let product = this.state.selectedProduct; //navigation.getParam('product');
    const rootproduct = this.state.rootproduct;

    return (
      <>
        <NavBar />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar categoryBar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => {
                  this.props.userDetail.userdefault.subscriber === "Y"
                    ? this.props.history.push("/store/products/", {
                        category: this.props.location.state.category,
                        storeid: this.props.location.state.storeid,
                        storename: this.props.location.state.storename,
                      })
                    : this.props.history.push("/category/product/", {
                        category: this.props.location.state.category,
                        storeid: this.props.location.state.storeid,
                        storename: this.props.location.state.storename,
                      });
                }}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div
          className="container-fluid"
          style={{ paddingTop: 30, paddingBottom: 100 }}
        >
          <div className="row">
          <div className="col-md-4 col-xs-12  col-sm-4 col-lg-4">
            <div className="product-detail-image">
              {product.stickerid != "" ? (
                <img alt=""
                  src={APPLICATION_CONST.stickerImages + product.stickerid}
                  className={"product-detail-sticker"}
                />
              ) : null}
              <Magnifier
                mgShape={"square"}
                src={
                  APPLICATION_CONST.productImageUrl +
                  product.productreferenceid +
                  ".png" +
                  "?" +
                  new Date()
                }
                width={300}
              />
            </div>
            </div>
            <div className="col-md-8 col-xs-12 col-sm-8 col-lg-8">

            <div style={{ float: "left", padding: 25,maxWidth:"75%" }}>
              {/* <div
                id="portal"
                className="portal"
                style={{
                  position: "absolute",
                  zIndex: 15,
                  backgroundColor: "#FFF",
                }}
              /> */}
              <div style={{ fontWeight: "bold", fontSize: 22 }}>
                {product.productname}
              </div>
              {/* <div style={{ fontSize: 18 }}>
                ${parseFloat(product.unitcost).toFixed(2)}
              </div> */}
              {product.msrp > product.unitcost ? (
                <>
                  <div style={{ fontSize: 18 }}>
                    <strike>USD $ {product.msrp}</strike>
                  </div>
                  <div style={{ fontSize: 18 }}>USD $ {product.unitcost}</div>
                </>
              ) : (
                <div style={{ fontSize: 18 }}>USD ${product.unitcost}</div>
              )}
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div
                  style={{ fontWeight: "bold", fontSize: 15, color: "#777" }}
                >
                  Product Description
                </div>
                <div style={{ fontSize: 12 }}>{product.description}</div>
              </div>
              {}
              {rootproduct.siblings && rootproduct.siblings.length > 1 ? (
                <>
                  {
                    <>
                      <div style={{ width: "100%" }}>
                        <Select
                          name="form-field-name"
                          formatOptionLabel={formatOptionLabel}
                          options={this.state.childs}
                          onChange={this.onProductVariationChanged}
                          placeholder={""}
                          value={this.state.childs.filter(
                            (option) =>
                              option.value === this.state.selectedProductId
                          )}
                        />
                      </div>
                    </>
                  }
                </>
              ) : null}

              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <div
                  style={{ fontWeight: "bold", fontSize: 15, color: "#777" }}
                >
                  <span
                    style={{
                      display: "inline-block",

                      fontSize: 13,
                      fontFamily: "Poppins-Light",
                      fontWeight: "bold",
                    }}
                  >
                    <button
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        border: "1px solid #BBB",
                        paddingTop: 9,
                        paddingBottom: 7,
                        paddingRight: 15,
                        paddingLeft: 15,
                      }}
                      disabled={this.quantityincart() === 1}
                      onClick={() =>
                        this._changeProductQuantity(
                          this.state.selectedProduct.productid,
                          this.quantityincart() - 1,
                          this.state.selectedProduct.quantity
                        )
                      }
                      className="btn btn-sm"
                    >
                      -
                    </button>
                    <span style={{ marginLeft: 5, marginRight: 5 }}>
                      {" "}
                      {this.quantityincart()}{" "}
                    </span>
                    <button
                      style={{
                        color: "green",
                        fontWeight: "bold",
                        border: "1px solid #BBB",
                        paddingTop: 9,
                        paddingBottom: 7,
                        paddingRight: 15,
                        paddingLeft: 15,
                      }}
                      disabled={
                        this.state.selectedProduct.quantityincart ===
                          this.state.selectedProduct.quantity ||
                        this.quantityincart() <= 0
                      }
                      onClick={() =>
                        this._changeProductQuantity(
                          this.state.selectedProduct.productid,
                          this.quantityincart() + 1,
                          this.state.selectedProduct.quantity
                        )
                      }
                      className="btn btn-sm"
                    >
                      +
                    </button>
                  </span>
                  {this.state.selectedProduct.quantity -
                    this.quantityincart() <=
                  this.state.selectedProduct.noticequantity ? (
                    <span
                      className="btn"
                      style={{
                        cursor: "default",
                        display: "inline-block",
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "red",
                        marginTop: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "red",
                        marginLeft: 10,
                      }}
                    >
                      {this.state.selectedProduct.quantity} Left
                    </span>
                  ) : (
                    <span
                      className="btn"
                      style={{
                        cursor: "default",
                        display: "inline-block",
                        backgroundColor: "transparent",
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "green",
                        marginTop: 0,
                        borderRadius: 0,
                        borderWidth: 2,
                        borderColor: "green",
                        marginLeft: 10,
                      }}
                    >
                      In Stock
                    </span>
                  )}
                </div>
              </div>

              {this.goToCartCheck(product.productid) === 0 &&
              this.props.isEdit === false ? (
                <>
                  <button
                    onClick={() => this._addToCart(product)}
                    className="btn"
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#FFF",
                      marginTop: 10,
                      borderRadius: 0,
                      backgroundColor: "#555",
                      borderWidth: 2,
                      borderColor: "#555",
                    }}
                  >
                    Add to Cart
                  </button>

                  <button
                    onClick={() => this._buyNow(product)}
                    className="btn"
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "#555",
                      marginTop: 10,
                      marginLeft: 10,
                      borderRadius: 0,
                      backgroundColor: "transparent",
                      borderWidth: 2,
                      borderColor: "#555",
                    }}
                  >
                    Buy Now
                  </button>
                </>
              ) : (
                <button
                  onClick={() => this.props.history.push("/cart")}
                  className="btn"
                  style={{
                    fontSize: 14,
                    fontWeight: "bold",
                    color: "#FFF",
                    marginTop: 10,
                    borderRadius: 0,
                    backgroundColor: "#555",
                    borderWidth: 2,
                    borderColor: "#555",
                  }}
                >
                  Go to Cart
                </button>
              )}

              {
                // navigation.getParam('edit') ?
                this.props.isEdit === true ? (
                  rootproduct.siblings && rootproduct.siblings.length > 1 ? (
                    <button
                      onClick={() => this.updateProduct(product)}
                      className="btn"
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "#555",
                        marginTop: 10,
                        marginLeft: 10,
                        borderRadius: 0,
                        backgroundColor: "transparent",
                        borderWidth: 2,
                        borderColor: "#555",
                      }}
                    >
                      Update Cart
                    </button>
                  ) : null
                ) : null
              }

              <hr />
              <div>
                {/* {this.props.userDetail.userdefault.subscriber === "Y" ? (
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#FF5252",
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#FFF",
                      marginRight: 20,
                    }}
                    onClick={() => this.props.history.push("/stores")}
                  >
                    Shop from another store
                  </button>
                ) : null} */}

                {/* {this.props.userDetail.userdefault.subscriber === "Y" ? (
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#009688",
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#FFF",
                    }}
                    onClick={() =>
                      this.props.history.push("/store/categories", {
                        storeid: this.props.location.state.storeid,
                      })
                    }
                  >
                    All categories
                  </button>
                ) : (
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#009688",
                      fontSize: 12,
                      fontWeight: "bold",
                      color: "#FFF",
                    }}
                    onClick={() =>
                      this.props.history.push("/categories", {
                        storeid: this.props.location.state.storeid,
                      })
                    }
                  >
                    All categories
                  </button>
                )} */}
              </div>
            </div>
            </div>
          </div>

          <Toast
            onClose={() => this.setState({ displayToast: false })}
            show={this.state.displayToast}
            delay={2000}
            autohide
            style={{
              backgroundColor: "#20232a",
              position: "fixed",
              bottom: 10,
              right: 10,
              width: 500,
              maxWidth: 500,
              marginLeft: -250,
              zIndex: 999,
            }}
          >
            <Toast.Body>
              <span style={{ color: "#FFF" }}> {this.state.toastMessage}</span>
            </Toast.Body>
          </Toast>

          <Modal
            show={this.state.isProductModalShow}
            centered
            onHide={this.hideModal}
            size="xs"
            dialogClassName={styles.modal_90w}
          >
            <Modal.Header style={{ backgroundColor: "#F2F2F2" }} closeButton>
              <Modal.Title style={styles.dialogHeaderStyle}>
                Select Products
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                "max-height": "calc(100vh - 210px)",
                "overflow-y": "auto",
                padding: 0,
              }}
            >
              {rootproduct.siblings && rootproduct.siblings.length > 1
                ? rootproduct.siblings.map((sibling) => (
                    <div
                      key={sibling.productid}
                      value={sibling.productid}
                      className={"sibling-items"}
                    >
                      <span className="pull-left">{sibling.productname}</span>

                      <span className="pull-right">
                        {" "}
                        ${sibling.unitcost.toFixed(2)}
                      </span>
                    </div>
                  ))
                : null}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.hideModal}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stores: state.StoreReducer.stores,
  cart: state.CartReducer.cart,
  userDetail: state.SignInReducer.logindetails,
  flightData: state.FlightInfoReducer.formData,
  isEdit: state.CartReducer.isEdit,
});
export default connect(mapStateToProps, {
  changeProductQuantity,
  addToCart,
  setIsEdit,
  updateCartItem,
})(ProductDetail);
