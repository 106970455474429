import axios from 'axios';
import {
    ORDER_ERROR,
    ORDER_PENDING,
    ORDER_SUCCESS,
    ORDER_EDIT,
    ORDER_QTY_UPDATE,
    ORDER_ASSIGN_TO_AGENT
} from './actionTypes';
import { loaderStatus } from '../vendor/loaderAction';
import { APPLICATION_CONST } from '../../Config/CustomConfig';

export const orderService = (data) => dispatch => {
    const body = {
        username: data.username,
        sessionid: data.sessionid,
        failstatus: 0,
        request: {
            actiontype: data.actiontype
        }
    }
    dispatch(serviceActionPending());
    dispatch(loaderStatus(true))
    axios({
        method: 'post',
        url: APPLICATION_CONST.url + "getorders",
        data: body
    }).then(response => {
        dispatch(loaderStatus(false))
        if (response.data.status === 0) {
            dispatch(serviceActionSuccess({
                orders: response.data.data.orders,
                actiontype: data.actiontype,
            }));
        }
        else if (response.data.status > 0) {
            dispatch(serviceActionError(response.data.statusMessage));
        }
        else {
            dispatch(serviceActionError(APPLICATION_CONST.message));
        }

    })
        .catch(error => {
            dispatch(loaderStatus(false))
            dispatch(serviceActionError(error.message))
        });
}


export const serviceActionPending = () => ({
    type: ORDER_PENDING
})

export const serviceActionError = (error) => ({
    type: ORDER_ERROR,
    payload: error
})

export const serviceActionSuccess = (data) => ({
    type: ORDER_SUCCESS,
    payload: data
})

// export const orderEdit = (data) => dispatch => {
//     dispatch({
//         type: ORDER_EDIT,
//         payload: data,
//     })
// }
// export const orderItemQtyUpdate = (data) => dispatch => {
//     dispatch({
//         type: ORDER_QTY_UPDATE,
//         payload: data,
//     })
// }

export const orderAssingToAgent = (data) => dispatch => {
    dispatch({
        type: ORDER_ASSIGN_TO_AGENT,
        payload: data,
    })
}