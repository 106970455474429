import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { Image } from "react-bootstrap";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class CancelRequest extends Component {
  getOrders = (actiontype) => {
     this.props.history.push("/orders", {
      actiontype: actiontype,
      cancelrequest: true,
    });
  };
  render() {
    let userdetails = this.props.logindetails;
    return (
      <>
        <NavBar />
        {/* <div
          style={{
            backgroundColor: "#F1F1F1",
            borderTop: "1px solid #DDD",
            borderBottom: "1px solid #DDD",
          }}
        >
          <div style={{ padding: 20, textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#777",
                marginLeft: 20,
                marginTop: 20,
                float: "left",
                display: "inline-block",
              }}
              onClick={() => this.props.history.push('/')}
            >
              <FontAwesomeIcon
                icon="chevron-left"
                color="#777"
                className={'back-icon'}

              />
              Back
            </button>
            <div style={styles.pageHeadingStyle}>Cancel Request</div>
          </div>
        </div> */}
        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Cancel Request</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid stores-container">
          <div className="row">
            {userdetails.userdefault.express === "Y" &&
            userdetails.userdefault.fulfillmentallowed === "Y" ? (
              <div className="col-md-3">
                <div style={styles.vendorCardStyle}>
                  <Image
                    style={{
                      height: 100,
                      width: "auto",
                      margin: 3,
                      alignSelf: "center",
                    }}
                    resizemode="contain"
                    src={
                      APPLICATION_CONST.vendorButtonImageUrl +
                      "cancelfulfillorder.png"
                    }
                  />
                  <div className="card-body">
                    <button
                      onClick={() => this.getOrders("CANCELCONFIRMED")}
                      className="btn btn-block"
                      style={styles.vendorCardButtonStyle}
                    >
                      Cancel Fulfillment
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {userdetails.userdefault.fulfillmentallowed === "Y" ? (
                  <div className="col-md-3">
                    <div style={styles.vendorCardStyle}>
                      <Image
                        style={{
                          height: 100,
                          width: "auto",
                          margin: 3,
                          alignSelf: "center",
                        }}
                        resizemode="contain"
                        src={
                          APPLICATION_CONST.vendorButtonImageUrl +
                          "cancelfulfillment.png"
                        }
                      />
                      <div className="card-body">
                        <button
                          onClick={() => this.getOrders("CANCELFULFILLED")}
                          className="btn btn-block"
                          style={styles.vendorCardButtonStyle}
                        >
                          Cancel Fulfillment
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}

                {userdetails.userdefault.assignmentallowed === "Y" ? (
                  <div className="col-md-3">
                    <div style={styles.vendorCardStyle}>
                      <Image
                        style={{
                          height: 100,
                          width: "auto",
                          margin: 3,
                          alignSelf: "center",
                        }}
                        resizemode="contain"
                        src={
                          APPLICATION_CONST.vendorButtonImageUrl +
                          "cancelassignment.png"
                        }
                      />
                      <div className="card-body">
                        <button
                          onClick={() => this.getOrders("CANCELASSIGNED")}
                          className="btn btn-block"
                          style={styles.vendorCardButtonStyle}
                        >
                          Cancel Assignment
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="col-md-3">
                  <div style={styles.vendorCardStyle}>
                    <Image
                      style={{
                        height: 100,
                        width: "auto",
                        margin: 3,
                        alignSelf: "center",
                      }}
                      resizemode="contain"
                      src={
                        APPLICATION_CONST.vendorButtonImageUrl +
                        "cancelconfirmation.png"
                      }
                    />
                    <div className="card-body">
                      <button
                        onClick={() => this.getOrders("CANCELCONFIRMED")}
                        className="btn btn-block"
                        style={styles.vendorCardButtonStyle}
                      >
                        Cancel Confirmation
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="spacer"> </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
});
export default connect(mapStateToProps, {})(CancelRequest);
