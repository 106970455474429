import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import DialogModal from "../common/dialogModal";
import { updateFlightForm } from "../../actions/public/flightInfoAction";
import { emptyCart } from "../../actions/public/cartAction";
import { Container, Image, Card, Col, Row } from "react-bootstrap";
import Loader from "../common/loader";

import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { Link } from "react-router-dom";

import MainBannerComponent from "../common/MainBannerComponent";
import AirportsComponent from "../common/AirportsComponent";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      instance: this,
    };
  }

  onBtnClick = (storeid) => {
    this.setState({
      storeid: storeid,
    });
  };

  static getDerivedStateFromProps(props, state) {
    //  walkin page redirect after store success

    if (
      props.storeStatus !== state.instance.props.storeStatus &&
      state.instance.props.storeStatus !== null
    ) {
      if (props.storeStatus === "success") {
        const airportId = props.flightData.airportid;
        const direction = props.flightData.direction;
        const stores = props.stores.filter(
          (store) =>
            store.direction === direction && store.airportid === airportId
        );

        if (stores.length === 1) {
          const storeId = stores[0].storeid;

          state.instance.props.history.push("/store/categories", {
            storeid: storeId,
          });
        } else {
          state.instance.props.history.push("/stores");
        }

        console.log("stores", stores);
      }
    }

    // walkin page redirect after store success

    if (
      props.storeError !== state.instance.props.storeError &&
      state.instance.props.storeError !== null
    ) {
      return {
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: props.storeError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={state.instance.closeDialog}
          >
            Close
          </button>
        ),
      };
    }
  }

  render() {
    const styles = {
      card_links: {
        color: "#000",
        textDecoration: "none",
        cursor: "pointer",
      },
    };
    return (
      <div className="wrapper">
        {/*****************NavBar Component */}
        <NavBar />

        <Loader message="Processing..." />

        {/*****************NavBar Component */}

        {/*****************Main Banner Component */}
        <MainBannerComponent history={this.props.history} />

        {/*****************Main Banner Component */}

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        {/*****************Main Banner Component */}
        <AirportsComponent history={this.props.history} />
        {/*****************Main Banner Component */}

        <Container fluid className="advertisment-banner-container">
          <Image
            className="advertisment-banner"
            src={
              APPLICATION_CONST.applicationImages + "airportality-banner1.jpg"
            }
            fluid
          />
        </Container>

        <Container fluid className={"instruction-container"}>
          <Row md={2} lg={2} sm={2}>
            <Col className={"instruction-card"}>
              <Card>
                <Card.Img
                  variant="top"
                  src={APPLICATION_CONST.applicationImages + "bannertwo1.jpg"}
                />
                <Card.Body>
                  <Card.Title className={"instruction-title"}>
                    <Link to="/Servicework" style={styles.card_links}>
                      How does the service work?
                    </Link>
                  </Card.Title>
                  <Card.Text className={"instruction-description"}>
                    Jamaica Experiences Retail caters to passengers travelling
                    through Jamaica’s International Airports.
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Col>
            <Col className={"instruction-card"}>
              <Card>
                <Card.Img
                  variant="top"
                  src={APPLICATION_CONST.applicationImages + "bannertwo2.jpg"}
                />
                <Card.Body>
                  <Card.Title className={"instruction-title"}>
                    <Link to="/Pickuplocations" style={styles.card_links}>
                      Our Stores at the Airports
                    </Link>
                  </Card.Title>
                  <Card.Text className={"instruction-description"}>
                    Duty-free purchases will be made online and collected on
                    departure or on arrivals.
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Col>
            <Col className={"instruction-card"}>
              <Card>
                <Card.Img
                  variant="top"
                  src={APPLICATION_CONST.applicationImages + "bannertwo3.jpg"}
                />
                <Card.Body>
                  <Card.Title className={"instruction-title"}>
                    <Link to="/Pickuplocations" style={styles.card_links}>
                      Pick Up Points
                    </Link>
                  </Card.Title>
                  <Card.Text className={"instruction-description"}>
                    Club Mobay or Club Kingston lounges
                  </Card.Text>
                  {/* <Button variant="primary">Go somewhere</Button> */}
                </Card.Body>
              </Card>
            </Col>

            <Col className={"instruction-card"}>
              <Card>
                <Card.Img
                  variant="top"
                  src={APPLICATION_CONST.applicationImages + "bannertwo4.jpg"}
                />
                <Card.Body>
                  <Card.Title className={"instruction-title"}>
                    <Link to="/CustomsAllowances" style={styles.card_links}>
                      Custom Allowances
                    </Link>
                  </Card.Title>
                  <Card.Text className={"instruction-description"}>
                    Tax free allowances are in line with local customs
                    authorities of your destination.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid className="advertisment-banner-container">
          <Image
            className="advertisment-banner"
            src={
              APPLICATION_CONST.applicationImages + "airportality-banner.jpg"
            }
            fluid
          />
        </Container>
        <div className="spacer"></div>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  airportInfo: state.FlightInfoReducer.formData,
  stores: state.StoreReducer.stores,
  logindetails: state.SignInReducer.logindetails,
  storeError: state.StoreReducer.error,
  storeStatus: state.StoreReducer.status,
  products: state.VendorProductReducer.products,
  mainBanners: state.SignInReducer.logindetails.banners.main,
  flightData: state.FlightInfoReducer.formData,
});
export default connect(mapStateToProps, { updateFlightForm, emptyCart })(Home);
