import {
    RECEIPT_SUCCESS,
    RECEIPT_ERROR,
    RECEIPT_PENDING
} from '../../actions/vendor/actionTypes';

const initialState = {
    status: null,
    errorMessage: null,
    documentPath: null,
    reportFileName: null
}

const ReceiptReducer = (state = initialState, action) => {
    switch (action.type) {
        /*******************get products reducer case *********************/
        case RECEIPT_PENDING:
            return {
                ...state,
                status: 'pending',
                errorMessage: null,
                documentPath: null,
                reportFileName: null
            }
        case RECEIPT_SUCCESS:
            return {
                ...state,
                status: 'success',
                errorMessage: null,
                documentPath: null,
                reportFileName: action.payload
            }
        case RECEIPT_ERROR:
            return {
                ...state,
                status: 'error',
                errorMessage: action.payload,
                documentPath: null,
                reportFileName: null 
            }
        default:
            return state;
    }
}

export default ReceiptReducer;
