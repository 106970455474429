import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import {
  getCategorys,
  callUpdateCategory,
} from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { Image, Container, Col, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";

class Categorys extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      loaderMessage: "Retrieving Categorys...",
      dataTable: {
        columns: [
          {
            label: "#",
            field: "index",
            width: 10,
          },
          {
            label: "Image",
            field: "image",
            sort: "asc",
            width: 150,
          },
          {
            label: "Category Name",
            field: "description",
            sort: "asc",
            width: 270,
          },

          {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: [
          ...this.props.categorylist.map((category, index) => ({
            index: index + 1,
            image: (
              <Image
                style={{
                  height: 50,
                  width: 50,
                  margin: 3,
                  alignSelf: "center",
                }}
                resizemode="contain"
                src={
                  APPLICATION_CONST.categoryImageUrl +
                  category.categoryid +
                  ".png" +
                  "?" +
                  new Date()
                }
              />
            ),
            description: category.description,

            action: (
              <span>
                <button
                  onClick={() => this.uploadProductImage(category)}
                  className="btn btn-sm btn-info btn-primary"
                >
                  <i className="fa fa-upload"></i>
                </button>
                <button
                  onClick={() => this.onEditBtnClick(category)}
                  className="btn btn-sm btn-info btn-warning"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <i className="fa fa-pencil"></i>
                </button>

                <button
                  className="btn btn-sm btn-info btn-danger"
                  onClick={() => this.showDeleteCategoryDialog(category)}
                >
                  <FontAwesomeIcon
                    icon="trash"
                    color="#FFF"
                    style={{ widht: 15, height: 15 }}
                  />
                </button>
              </span>
            ),
          })),
        ],
      },
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    this.props.history.index = 0;
    this.props.getCategorys();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryerror !== this.props.categoryerror) {
      if (this.props.categoryerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: this.props.categoryerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  onEditBtnClick = (category) => {
    this.props.history.push("/categorys/edit", {
      category: category,
    });
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  showDeleteCategoryDialog = (item) => {
    this.setState({
      loaderMessage: "Processing...",
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this Category?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.deleteCategory(item);
            }}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  deleteCategory = (item) => {
    this.closeDialog();
    this.props.callUpdateCategory(
      {
        actiontype: "DELETE",
        categoryid: item.categoryid,
        description: item.description,
      },
      this.props.history
    );
  };

  uploadProductImage = (item) => {
    this.props.history.push("imageupload", {
      item: item,
      assettype: "CATEGORY",
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.categorylist !== this.props.categorylist) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.categorylist.map((category, index) => ({
              index: index + 1,
              image: (
                <Image
                  style={{
                    height: 50,
                    width: 50,
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={
                    APPLICATION_CONST.categoryImageUrl +
                    category.categoryid +
                    ".png" +
                    "?" +
                    new Date()
                  }
                />
              ),
              description: category.description,
              action: (
                <span>
                  <button
                    onClick={() => this.uploadProductImage(category)}
                    className="btn btn-sm btn-info btn-primary"
                  >
                    <i className="fa fa-upload"></i>
                  </button>
                  <button
                    onClick={() => this.onEditBtnClick(category)}
                    className="btn btn-sm btn-info btn-warning"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>

                  <button
                    className="btn btn-sm btn-info btn-danger"
                    onClick={() => this.showDeleteCategoryDialog(category)}
                  >
                    <FontAwesomeIcon
                      icon="trash"
                      color="#FFF"
                      style={{ widht: 15, height: 15 }}
                    />
                  </button>
                </span>
              ),
            })),
          ],
        },
      });
    }
  }

  render() {
    return (
      <>
        <NavBar />
        <Loader message={this.state.loaderMessage} />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Categories</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="stores-container" fluid>
          <div style={{ padding: 15 }} className="custom_datatable ">
            <MDBDataTable
              striped
              bordered
              hover
              small
              data={this.state.dataTable}
            />

            <button
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                position: "fixed",
                backgroundColor: "#4CAF50",
                bottom: 0,
                right: 0,
                zIndex: 100,
                margin: 30,
              }}
              className="btn"
              onClick={() => this.props.history.push("/categorys/add")}
            >
              <FontAwesomeIcon icon="plus" color="#FFF" />
            </button>
          </div>
          <div className="spacer"></div>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  categorylist: state.VendorProductReducer.categorylist,
  categoryerror: state.VendorProductReducer.categoryerror,
  categoryerrormessage: state.VendorProductReducer.categoryerrormessage,
});
export default connect(mapStateToProps, {
  getCategorys,
  callUpdateCategory,
})(Categorys);
