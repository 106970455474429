import { SIGNUP_SERVICE_PENDING, SIGNUP_SERVICE_SUCCESS, SIGNUP_SERVICE_ERROR } from './actionTypes';
import { APPLICATION_CONST } from '../../Config/CustomConfig';
import axios from 'axios';
import { loaderStatus } from '../vendor/loaderAction';

export const signUpServicePending = () => ({
    type: SIGNUP_SERVICE_PENDING
})

export const signUpServiceSuccess = (data) => ({
    type: SIGNUP_SERVICE_SUCCESS
})

export const signUpServiceError = (error) => ({
    type: SIGNUP_SERVICE_ERROR,
    payload: error,
})

export const signupService = (data,history) => dispatch => {
    let body = {
        username: APPLICATION_CONST.username,
        sessionid: APPLICATION_CONST.sessionid,
        failstatus: 0,
        request: {
            ...data,
            adminuser: 'N',
            subscriber: 'Y',
            reportagent: 'N',
        },
    };

    dispatch(signUpServicePending());
    dispatch(loaderStatus(true))
    axios({
        method: "post",
        url: APPLICATION_CONST.url + "adduser",
        data: body
    }).then(response => {
        dispatch(loaderStatus(false))
        if (response.data.status === 0) {
            dispatch(signUpServiceSuccess(response.data.statusMessage));
        }
        else if (response.data.status > 0) {
            dispatch(signUpServiceError(response.data.statusMessage));
        }
        else {
            dispatch(signUpServiceError(APPLICATION_CONST.message));
        }

    }).catch(error => {
        dispatch(loaderStatus(false))
        dispatch(signUpServiceError(error.message));
    })
}



