import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class VendorSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      cancelrequest: false,
    };
  }

  componentWillMount() {
    let type = this.props.location.state.type;
    let actionType = this.props.location.state.actiontype;
    if (type === "vendor" && actionType === "CREATE") {
      this.setState({
        message: "Vendor Added",
      });
    } else if (type === "vendor" && actionType === "UPDATE") {
      this.setState({
        message: "Vendor Updated",
      });
    } else if (type === "vendor" && actionType === "DELETE") {
      this.setState({
        message: "Vendor Deleted",
      });
    }
  }

  backToHome = () => {
    const actionType = this.props.location.state.actionType;
    const type = this.props.location.state.type;

    if (type === "vendor") {
      this.props.history.push("/vendormaster");
    }
  };

  render() {
    return (
      <>
        <NavBar />
        <div
          className="container-fluid"
          style={{ paddingBottom: 50, paddingTop: 50 }}
        >
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <FontAwesomeIcon
                icon="check-circle"
                color="#4CAF50"
                style={{ fontSize: 140 }}
              />

              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                {this.state.message}
              </div>
              {/* <div style={{ fontSize: 12 }}>
                Order has been placed successfully
              </div>
              <div style={{ fontSize: 12 }}>
                You may view your orders anytime by choosing My Orders from the
                menu.
              </div> */}

              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                <button
                  className="btn btn-success btn-primary"
                  style={{ fontSize: 13, fontWeight: "bold" }}
                  onClick={() => this.backToHome()}
                >
                  <FontAwesomeIcon
                    icon="reply"
                    color="#FFF"
                    style={{ fontSize: 15, marginRight: 5 }}
                  />
                  Back To Home
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

//  export default Success;
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps)(VendorSuccess);
