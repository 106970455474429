import React from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";

import { styles } from "../../styles/commontStyles";
import { Image } from "react-bootstrap";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { callStoresWebService } from "../../actions/public/storesAction";

const _onClickHandler = (props) => {
  if (props.type === "orders") {
    props.instance.getOrders(props.action);
  } else if (props.type === "reports") {
    props.instance.props.history.push("/reports");
  } else if (props.type === "cancelrequest") {
    props.instance.props.history.push("/CancelRequest");
  } else if (props.type === "admin") {
    props.instance.props.history.push("/admin");
  } else if (props.type === "walkin") {
    props.instance.props.callStoresWebService();
    //props.instance.props.history.push("/categories");
  }
};

const DashboardButton = (props) => {
  return (
    <div className="col-md-3">
      <div style={styles.vendorCardStyle}>
        <Image
          style={{
            height: 100,
            width: "auto",
            margin: 3,
            alignSelf: "center",
          }}
          resizemode="contain"
          src={APPLICATION_CONST.vendorButtonImageUrl + props.avtar}
        />
        <div className="card-body">
          <button
            onClick={() => _onClickHandler(props)}
            className="btn btn-block"
            style={styles.vendorCardButtonStyle}
          >
            {props.text}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { callStoresWebService })(DashboardButton);
