import axios from "axios";
import {
  STORES_ERROR,
  STORES_PENDING,
  STORES_SUCCESS,
  CLEAR_STORES_ERROR,
  RESET_CREDIT_CARD_INFO,
  EMPTY_FLIGHT_FORM_DATA,
} from "../public/actionTypes";

import { RESET_CARD_DETAILS } from "../../actions/vendor/actionTypes";

import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { loaderStatus } from "../vendor/loaderAction";

export const callStoresWebService = () => (dispatch) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  var body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: {},
  };
  dispatch({ type: RESET_CREDIT_CARD_INFO });
  dispatch({ type: EMPTY_FLIGHT_FORM_DATA });
  dispatch({ type: RESET_CARD_DETAILS });
  dispatch(loaderStatus(true));
  dispatch(serviceActionPending());
  return axios({
    method: "post",
    url: APPLICATION_CONST.url + "getstores",
    timeout: 10000,
    data: JSON.stringify(body),
  })
    .then((response) => {
      if (response.data.status === 0) {
        dispatch(serviceActionSuccess(response.data.data.stores));
        dispatch(loaderStatus(false));
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
        dispatch(loaderStatus(false));
      } else {
        dispatch(serviceActionError(APPLICATION_CONST.message));
        dispatch(loaderStatus(false));
      }
    })
    .catch((error) => {
      dispatch(serviceActionError(error.message));

    })
    .finally(() => {
      dispatch(loaderStatus(false));
    });
};

export const serviceActionPending = () => (dispatch) => {
  dispatch({
    type: STORES_PENDING,
  });
};

export const serviceActionError = (error) => (dispatch) => {
  dispatch({ type: STORES_ERROR, payload: error });
};

export const serviceActionSuccess = (data) => (dispatch) => {
  dispatch({
    type: STORES_SUCCESS,
    payload: data,
  });
};

export const clearStoresError = (data) => (dispatch) => {
  dispatch({
    type: CLEAR_STORES_ERROR,
    payload: data,
  });
};
