import axios from "axios";
import {
  ORDER_PROCESS_ERROR,
  ORDER_PROCESS_PENDING,
  ORDER_PROCESS_SUCCESS,
  ADD_TO_ORDER,
  UPDATE_EXTRA_ORDER_QUANTITY,
  REMOVE_FROM_ORDER,
  EMPTY_EXTRA_ORDER,
  ORDER_EDIT,
  ORDER_QTY_UPDATE,
  ADD_SIGNATURE,
  REMOVE_SIGNATURE,
  ORDER_UPDATE,
  UPDATE_CARD_DETAILS,
} from "./actionTypes";
import {
  APPLICATION_CONST,
  PNP_PAYMENT_FAIL,
  PNP_HELD_PAYMENT_FAIL,
  STOCK_UNAVAILABLE,
} from "../../Config/CustomConfig";
import { loaderStatus } from "../vendor/loaderAction";
import { callLoginService } from "../authentication/signInAction";
export const addToOrder = (item) => (dispatch) => {
  dispatch({
    type: ADD_TO_ORDER,
    payload: item,
  });
};

export const removeFromOrder = (productid) => (dispatch) => {
  dispatch({
    type: REMOVE_FROM_ORDER,
    payload: productid,
  });
};

export const emptyExtraOrder = () => (dispatch) => {
  dispatch({
    type: EMPTY_EXTRA_ORDER,
  });
};

export const updateExtraOrderQuantity = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_EXTRA_ORDER_QUANTITY,
    payload: data,
  });
};

export const orderSubmit = (data, history) => (dispatch) => {
  const body = {
    username: data.username,
    sessionid: data.sessionid,
    failstatus: 0,
    request: {
      ordereditems: data.ordereditems,
      actiontype: data.processaction,
    },
  };

  var totalappliedamount = 0;
  const items = data.ordereditems;
  items.forEach((item) => {
    totalappliedamount += item.processedquantity;
  });

  /*****************************Request*************************************/

  /************************* if order assign to agent **********************/
  if ("assignto" in data === true) {
    body.request.assignto = data.assignto;
  }
  if ("signature" in data === true) {
    if (data.signature || data.signature === "") {
      body.request["signature"] = data["signature"];
    } else {
      return (dispatch) => {
        dispatch(
          serviceActionError({
            error: "Please add guest signature",
            errorType: null,
          })
        );
      };
    }
  }
  if ("extraOrder" in data) {
    body.request.cart = data.extraOrder.map((order) => {
      return {
        vendorid: order.vendorid,
        taxrate: order.taxrate,
        productid: order.productid,
        quantity: order.quantity,
        unitcost: order.unitcost,
        total: order.total,
      };
    });
  }

  if ("creditcard" in data) {
    // let paymenttypes = data.paymentMethod.toLowerCase();
    if (data.processaction === "REFUNDORDER") {
      body.request.payment = {
        paymenttype: "CREDITCARD",
        creditcard: {
          email: data.creditcard.email,
          amount: data.creditcard.amount,
          cardtype: data.creditcard.cardtype.toUpperCase(),
          cardholder: data.creditcard.cardholder,
          cardnumber: data.creditcard.cardnumber,
          authorizationnumber: data.creditcard.authorizationnumber,
        },
      };
    } else {
      body.request.payment = {
        paymenttype: "CREDITCARD",
        creditcard: {
          email: data.email,
          amount: data.amount,
          cardtype: data.creditcard.cardtype.toUpperCase(),
          cardholder: data.creditcard.cardholder,
          cvv: data.creditcard.cvv,
          cardnumber: data.creditcard.cardnumber,
          expirydate: data.creditcard.expirydate,
        },
      };
    }
  }

  if (data.paymentMethod === "cash") {
    body.request.payment = {
      paymenttype: "CASH",
      cash: {
        currencycode: data.currencycode,
        exchangerate: data.exchangerate,
        amount: data.amount * data.exchangerate,
      },
    };
  }

  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + "processorder",
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      console.log("RESPONSE:", response);

      if (response.data.status === 0) {
        const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

        dispatch(
          callLoginService({
            username: loginDetail.username,
            password: loginDetail.password,
            silentLogin: true,
            internal: loginDetail.internal,
          })
        );

        if (data.processaction === "CANCELCHECKOUTORDER") {
          history.push("/orders", {
            actiontype: "CHECKEDINORDERS",
          });
        } else if (
          (data.processaction === "CHECKOUTORDER" && totalappliedamount > 0) ||
          (data.processaction === "FORCECHECKOUTORDER" &&
            totalappliedamount > 0)
        ) {
          history.push("/order/receipt", {
            reportFilename: response.data.data.filename,
            actionype: "CHECKOUTORDER",
            orderid: data.orderid,
            email: data.email,
          });
        } else if (data.processaction === "REFUNDORDER") {
          history.push("/order/success", { actiontype: data.processaction });
          // history.push("/order/receipt", {
          //   reportFilename: "AirportalityReceipt_42263.pdf",
          //   actionype: "REFUNDORDER",
          //   orderid: data.orderid,
          //   email: data.email,
          // });
        } else {
          history.push("/order/success", { actiontype: data.processaction });
          dispatch(serviceActionSuccess({ response: response.data }));
        }
      } else if (response.data.status > 0) {
        if (response.data.status in PNP_PAYMENT_FAIL) {
          if (data.processaction === "CHECKOUTORDER") {
            dispatch(
              serviceActionError({
                error: response.data.statusMessage,
                errorType: "PNP_PAYMENT_FAIL",
              })
            );

            history.push("PaymentCancel", {
              errorMessage: response.data.statusMessage,
              errotype: "PNP_PAYMENT_FAIL",
              resubmitData: data,
            });

            // history.push("PaymentFail", {
            //   errorMessage: response.data.statusMessage,
            //   errotype: "PNPFAIL",
            // });
          }
        } else if (response.data.status in PNP_HELD_PAYMENT_FAIL) {
          dispatch(
            serviceActionError({
              error: response.data.statusMessage,
              errorType: "PNP_HELD_PAYMENT_FAIL",
            })
          );

          history.push("PaymentCancel", {
            errorMessage: response.data.statusMessage,
            errotype: "PNP_HELD_PAYMENT_FAIL",
            resubmitData: data,
          });
        } else if (response.data.status in STOCK_UNAVAILABLE) {
          dispatch(
            serviceActionError({ error: "", erroType: "STOCK_UNAVAILABLE" })
          );
          // history.push("PaymentFail", {
          //   errorMessage: response.data.statusMessage,
          //   errotype: "INVENTORY",
          // });
        } else {
          dispatch(
            serviceActionError({
              error: response.data.statusMessage,
              errorType: null,
            })
          );
          dispatch(loaderStatus(false));
          history.push("/order/failed", {
            actiontype: data.processaction,
            errorMessage: response.data.statusMessage,
          });
        }
      } else {
        dispatch(
          serviceActionError({
            error: APPLICATION_CONST.message,
            errorType: null,
          })
        );
        history.push("/order/failed", {
          actiontype: data.processaction,
          errorMessage: APPLICATION_CONST.message,
        });
      }
    })
    .catch((error) => {
      dispatch(loaderStatus(false));
      dispatch(serviceActionError({ error: error.message, errorType: null }));
      history.push("/order/failed", {
        actiontype: data.processaction,
        errorMessage: error.message,
      });
    });
};

export const serviceActionPending = () => ({
  type: ORDER_PROCESS_PENDING,
});

export const serviceActionError = (error) => ({
  type: ORDER_PROCESS_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: ORDER_PROCESS_SUCCESS,
  payload: data,
});

export const orderEdit = (data) => (dispatch) => {
  dispatch({
    type: ORDER_EDIT,
    payload: data,
  });
};

export const orderItemQtyUpdate = (data) => (dispatch) => {
  dispatch({
    type: ORDER_QTY_UPDATE,
    payload: data,
  });
};

export const orderUpdate = (data) => (dispatch) => {
  dispatch({
    type: ORDER_UPDATE,
    payload: data,
  });
};

export const addSignature = (data) => (dispatch) => {
  dispatch({
    type: ADD_SIGNATURE,
    payload: data,
  });
};

export const removeSignature = () => (dispatch) => {
  dispatch({
    type: REMOVE_SIGNATURE,
  });
};

export const updateCardDetails = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CARD_DETAILS,
    payload: data,
  });
};

export const emptyCardDetails = () => (dispatch) => {
  const cardedetails = {
    cardHolderName: null,
    cardNumber: null,
    expiryDate: null,
    cvv: null,
  };
  dispatch(updateCardDetails(cardedetails));
};
