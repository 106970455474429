import {
    LOADER_STATUS
} from '../../actions/vendor/actionTypes';

const initialState = {
   loading: false
}

const LoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADER_STATUS:
            return {
                ...state,
                loading: action.payload,
            }
        default:
            return state;
    }
}

export default LoaderReducer;