import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { callUpdateCategory } from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        categoryid: "",
        description: "",
      },
      categoryError: {
        description: "",
      },
      actiontype: "CREATE",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.location.state) {
      const category = this.props.location.state.category;
      if (category) {
        this.setState({
          category: category,
          actiontype: "UPDATE",
        });
      }
    } else if (this.props.location.pathname === "/categorys/edit") {
      this.props.history.push("/");
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.categoryerror !== this.props.categoryerror) {
      if (this.props.categoryerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: this.props.categoryerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  resetError = () => {
    let categoryError = {
      description: "",
    };
    this.setState({ categoryError: categoryError });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const history = this.props.history;
    const description = this.state.category.description;

    let categoryError = {
      description: "",
    };

    if (!description.trim()) {
      categoryError.description = "Category required.";
    }

    this.setState({ categoryError: categoryError });

    if (description.trim()) {
      const actiontype = this.state.actiontype;

      if (actiontype === "UPDATE") {
        this.props.callUpdateCategory(
          {
            actiontype: "UPDATE",
            categoryid: this.state.category.categoryid,
            description: this.state.category.description,
          },
          this.props.history
        );
      } else {
        this.props.callUpdateCategory(
          {
            actiontype: "CREATE",
            classid: this.state.category.classid,
            description: this.state.category.description,
          },
          this.props.history
        );
      }
    }
  };

  classChange = (e) => {
    this.setState({
      classification: {
        ...this.state.classification,
        classtypeid: e.target.value,
      },
    });
  };
  render() {
    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/categorys")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className={"card-header custom-card-header"}>
                    <h4>
                      {this.state.actiontype === "CREATE"
                        ? "Add Category"
                        : "Edit Category"}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <div className="control-label col-md-2">
                            <label
                              htmlFor="description"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Category
                            </label>
                          </div>
                          <div className="col-md-10">
                            <input
                            id="description"
                            placeholder={"Category"}
                              type="text"
                              autoFocus={true}
                              onChange={(e) =>
                                this.setState({
                                  category: {
                                    ...this.state.category,
                                    description: e.target.value,
                                  },
                                })
                              }
                              value={this.state.category.description}
                              className="form-control"
                            />{" "}
                            {this.state.categoryError.description && (
                              <small className="text-danger">
                                {this.state.categoryError.description}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"card-footer"}>
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <button
                        type="submit"
                        className="btn pull-right submit-btn"
                        style={styles.confirmButtonStyle}
                      >
                        {this.state.actiontype === "CREATE" ? "Save" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  categoryerror: state.VendorProductReducer.categoryerror,
  categoryerrormessage: state.VendorProductReducer.categoryerrormessage,
});
export default connect(mapStateToProps, {
  callUpdateCategory,
})(AddCategory);
