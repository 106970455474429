import * as Actions from '../../actions/authentication/actionTypes';

const initialState = {
    error: null,
    success: null,
}

const ResetPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.RESET_SERVICE_PENDING:
            return {
                ...state,
                error: null,
                success: null
            }
        case Actions.RESET_SERVICE_ERROR:
            return {
                ...state,
                error: action.payload,
                success: null
            }
        case Actions.RESET_SERVICE_SUCCESS:
            return {
                ...state,
                error: null,
                success: action.payload
            }
        default:
            return state;
    }
}

export default ResetPasswordReducer;
