import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../styles/commontStyles";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { resetPasswordSubmit } from "../../actions/authentication/resetPasswordAction";
import { callChangePasswordService } from "../../actions/authentication/changePasswordAction";

import "../../styles/style.css";
import { Container, Image } from "react-bootstrap";
import { APPLICATION_CONST, PASSWORD_CONST } from "../../Config/CustomConfig";
import validator from "validator";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: { value: "", error: "" },
      old_password: { value: "", error: "" },
      new_password: { value: "", error: [] },
      confirm_password: { value: "", error: "" },
    

      robots: { value: false, error: "" },
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.error,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (nextProps.success === 'success' && nextProps.success !== this.props.success) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: "Password reset completed",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
  }
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  resetError = () => {
    this.setState({
      username: { ...this.state.username, error: "" },
      robots: { ...this.state.robots, error: "" },
    });
  };
  handelSubmit = (e) => {
    e.preventDefault();
    let username = this.state.username;
    let old_password = this.state.old_password;
    let new_password = this.state.new_password;
    let confirm_password = this.state.confirm_password;

    let robots = this.state.robots;
    const history = this.props.history;
    const regex = new RegExp('(?=.*[!@#$%^&*()])');

    if (validator.isStrongPassword(new_password.value, {
      minLength: 8, minLowercase: 3,
      minUppercase: 2, minNumbers: 2, minSymbols: 1
    })) {
      if (regex.test(new_password.value))
        this.setState({
          new_password: { ...new_password, error: [PASSWORD_CONST.pass_valid] },
        });
      else {
        this.setState({
          new_password: { ...new_password, error: [PASSWORD_CONST.pass_sym_error] },
        });
      }
    } else {
      this.setState({
        new_password: { ...new_password, error: ["Password must be: ", PASSWORD_CONST.pass_length_error, 
          PASSWORD_CONST.pass_sym_error, 
          PASSWORD_CONST.pass_lowercase_error, 
          PASSWORD_CONST.pass_uppercase_error,
          PASSWORD_CONST.pass_digit_error
          ] },
      });
    }

    if (old_password.value.trim() === "") {
      this.setState({
        old_password: { ...old_password, error: PASSWORD_CONST.old_pass_required },
      });
    }

    if (new_password.value.trim() === "") {
      this.setState({
        new_password: { ...new_password, error: [PASSWORD_CONST.new_pass_required] },
      });
    }

    if (confirm_password.value.trim() !== new_password.value.trim()) {
      this.setState({
        confirm_password: {
          ...confirm_password,
          error: PASSWORD_CONST.conform_pass_notMatch,
        },
      });
    }

    console.log(confirm_password.value.trim() === new_password.value.trim());

    if (
      old_password.value !== "" &&
      new_password.value !== "" &&
      confirm_password.value.trim() === new_password.value.trim() && new_password.error[0] === PASSWORD_CONST.pass_valid 
    ) {
      // if (username.value !== '') {
      const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

      const data = {
        username: loginDetail.username,
        sessionid: loginDetail.sessionid,
        oldPassword: old_password.value.trim(),
        newPassword: new_password.value.trim(),
      };

      this.props.callChangePasswordService(data, history);
    }
  };
  render() {
    return (
      <>
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"auth-container"} fluid>
          <div className="auth-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <a href={"/"}>
                <Image
                  type="button"
                  style={{
                    height: 60,
                    width: "auto",
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={APPLICATION_CONST.applicationImages + "logo.png"}
                />
              </a>
            </div>

            <div>
              <h5 style={{ textAlign: "center", fontFamily: "Poppins-Light" }}>
                Change Password
              </h5>

              <form className="form-signin" onSubmit={this.handelSubmit}>
                <div className="row">
                  <div className="col-md-12 myInput-continer">
                    <div className="form-group">
                      <input
                        disabled={true}
                        value={this.props.logindetails.userdefault.username}
                        type="text"
                        autoFocus={true}
                        className="form-control myInput"
                        placeholder="Username"
                      />

                      {this.state.username.error && (
                        <small className="text-danger">
                          {this.state.username.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 myInput-continer">
                    <div className="form-group">
                      <input
                        value={this.state.old_password.value}
                        type="password"
                        autoFocus={true}
                        className="form-control myInput"
                        placeholder="Old Password"
                        id="old_password"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            old_password: {
                              ...this.state.old_password,
                              value: e.target.value.replace(" ", ""),
                            },
                          })
                        }
                      />

                      {this.state.old_password.error && (
                        <small className="text-danger">
                          {this.state.old_password.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 myInput-continer">
                    <div className="form-group">
                      <input
                        value={this.state.new_password.value}
                        type="password"
                        className="form-control myInput"
                        placeholder="New Password"
                        id="new_password"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            new_password: {
                              ...this.state.new_password,
                              value: e.target.value.replace(" ", ""),
                            },
                          })
                        }
                      />

                      {this.state.new_password.error[0] !== PASSWORD_CONST.pass_valid && (
                        <small className="text-danger" style={{textTransform:'none'}}>
                          {this.state.new_password.error.map((item,index) => <div key={index}>{item}</div>)}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 myInput-continer">
                    <div className="form-group">
                      <input
                        value={this.state.confirm_password.value}
                        type="password"
                        className="form-control myInput"
                        placeholder="Confirm Password"
                        id="confirm_password"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            confirm_password: {
                              ...this.state.confirm_password,
                              value: e.target.value.replace(" ", ""),
                            },
                          })
                        }
                      />

                      {this.state.confirm_password.error && (
                        <small className="text-danger">
                          {this.state.confirm_password.error}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-block"
                        type="submit"
                        style={styles.confirmButtonStyle}
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>


          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  success: state.ResetPasswordReducer.changePasswordStatus,
  error: state.ChangePasswordReducer.changePasswordError,
  logindetails: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps, { callChangePasswordService })(
  ChangePassword
);
