import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "./navbar";
import Footer from "./footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/fontawesome-free-solid";

import { APPLICATION_CONST } from "../../Config/CustomConfig";
class NetworkError extends Component {

  constructor(props) {
    super(props)
    console.log("Props from  502", props);
  }

  render() {
    return (
      <>
        <NavBar />
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ marginTop: 50 }} className="text-center">
              <img
                src={APPLICATION_CONST.applicationImages + "404-error.png"}
                alt="502"
                style={{ width: 150 }}
              />
              <h2 style={{ marginTop: 30 }}>Oops! Got a problem. </h2>
            </div>


          </div>
          <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold", textAlign: "center" }}>
            <button
              className="btn btn-success btn-primary"
              style={{ fontSize: 13, fontWeight: "bold" }}
              onClick={() => {
                this.props.history.push("/");
              }}
            >
              <FontAwesomeIcon
                icon={faReply}
                color="#FFF"
                style={{ fontSize: 15, marginRight: 5 }}
              />
              Back To Home
            </button>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps)(NetworkError);
