import React, { Component } from "react";
import { connect } from "react-redux";
import { QRCode } from "react-qr-svg";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import Footer from "../common/footer";
import DialogModal from "../common/dialogModal";
import Modal from "react-bootstrap/Modal";
import { getUserService } from "../../actions/vendor/userAction";
import { Toast } from "react-bootstrap";
import { processCard } from "../../actions/common/ProcessCardAction";
import { dateFormat } from "../../actions/common/dateTime";
import { getReportService } from "../../actions/vendor/reportAction";

import {
  addToOrder,
  updateExtraOrderQuantity,
  orderSubmit,
  emptyExtraOrder,
  removeFromOrder,
} from "../../actions/vendor/orderProcessAction";
import SignatureCanvas from "react-signature-canvas";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import {
  orderItemQtyUpdate,
  addSignature,
  orderUpdate,
} from "../../actions/vendor/orderProcessAction";
import { callReceiptWebService } from "../../actions/vendor/receiptAction";
import { callStoresWebService } from "../../actions/public/storesAction";
import QrReader from "react-qr-reader";
import Select from "react-select";

import ProcessButton from "../vendor/processButton";
import moment from "moment";

import { Col, Row, Container } from "react-bootstrap";
import { loaderStatus } from "../../actions/vendor/loaderAction";
import Fac from "../../plugins/fac";
//import { processTransaction } from "../../actions/common/TransactionAction";
import { processTransaction } from "../../actions/common/OrderAction";

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      isOpen: false,
      isProductOpen: false,
      isOpenPayment: false,
      isOpenRefundPayment: false,
      isOpenSignature: false,
      minamount: 0,
      orderAmount: 0,
      appliedamount: 0,
      appliedtaxamount: 0,
      extraAmount: 0,
      extrataxAmount: 0,

      finalAmount: 0,
      signatureDataURL: null,
      sigPad: {},
      assignto: null,
      assigntoError: null,
      categories: [],
      successMessage: null,
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      products: [],
      result: null,
      isProductDetailOpen: false,
      productDetail: [],
      rootproduct: [],
      displayToast: false,
      toastMessage: "",
    };

 
    if (Object.entries(this.props.order).length === 0)
      return this.props.history.push("/");
  }
  hideProductModal = () => {
    this.setState((prevState) => ({
      isProductOpen: !prevState.isProductOpen,
    }));
  };

  hideProductDetailModal = () => {
    this.setState((prevState) => ({
      isProductDetailOpen: !prevState.isProductDetailOpen,
    }));
  };

  showProductDetailModal = () => {
    this.setState((prevState) => ({
      isProductDetailOpen: !prevState.isProductDetailOpen,
    }));
  };

  moveCategory = () => {
    this.setState((prevState) => ({
      isProductOpen: !prevState.isProductOpen,
    }));
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  chargeableAmount = () => {
    let charge = 0;
    if (this.props.orderErrorType === "PNP_HELD_PAYMENT_FAIL") {
      charge =
        this.state.appliedamount +
        this.state.appliedtaxamount +
        this.state.extraAmount +
        this.state.extrataxAmount;
    } else {
      charge = this.state.finalAmount - this.state.orderAmount;
    }

    return charge.toFixed(2);
  };

  refundableAmount = () => {
    let charge = 0;

    charge = this.state.finalAmount;

    return charge.toFixed(2);
  };

  onBackButtonEvent = (e) => {
    e.preventDefault();
    this.props.history.go(1);
    // this.goBack();
  };

  _backButtonHandler = () => {
    this.props.history.goBack();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (Object.entries(this.props.order).length === 0)
      return this.props.history.push("/");

    const processAction = this.props.location.state.actiontype;

    if (processAction === "UNPROCESSED") {
      this.setState({ successMessage: "Fulfill Order Successfull" });
    }
    if (processAction === "FULFILLED") {
      this.setState({ successMessage: "Assign Order Successfull" });
      const data = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        filter: "Y",
      };
      this.props.getUserService(data);
    }

    if (processAction === "CHECKEDOUTORDERS") {
      this.setState({ successMessage: "Return Successfull" });
      const data = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        orderitemid: this.props.order.orderitems[0].orderitemid,
        filter: "Y",
      };
      this.props.getUserService(data);
    }
  };

  confirmRemoveOrderItem = (item) => {
    this.setState({
      displayToast: true,
      toastMessage: `${item.productname} deleted from cart.`,
    });
    this.props.removeFromOrder(item.productid);
  };

  removeItemFromCart = (item) => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this item?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.setState({ displayDialog: false });
              this.confirmRemoveOrderItem(item);
            }}
          >
            OK
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.setState({ displayDialog: false });
            }}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.result !== this.state.result) {
      if (this.state.result !== null) {
        const loungeProducts = this.state.products;
        let productId = this.state.result.trim();
        let filterProduct = loungeProducts.filter(
          (d) => d.productid === productId
        );
        if (filterProduct.length > 0) {
          let storeid = this.props.logindetails.userdefault.vendorid;

          this.onBtnClick(filterProduct[0], storeid);
        } else {
          this.setState({
            displayDialog: true,
            dialogTitle: "Oops!",
            dialogBody: "Invalid QR Code Scan.",
            dialogFooter: (
              <>
                <button
                  className="btn"
                  style={styles.dialogCancelButtonStyle}
                  onClick={() => {
                    this.setState({ displayDialog: false });
                  }}
                >
                  OK
                </button>
              </>
            ),
          });
        }
      }
    }
  }
  componentWillUnmount = () => {};

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.orderProcessError !== this.props.orderProcessError &&
      nextProps.orderProcessError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.orderProcessError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.orderProcessSuccess &&
      nextProps.orderProcessSuccess !== this.props.orderProcessSuccess
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: this.state.successMessage,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.closeDialog();

              this.props.history.push(
                this.props.location.state.cancelrequest ? "/CancelRequest" : "/"
              );
            }}
          >
            OK
          </button>
        ),
      });
    }

    // walkin page redirect after store success
    if (
      nextProps.storeStatus !== this.props.storeStatus &&
      nextProps.storeStatus !== null
    ) {
      if (nextProps.storeStatus === "success") {
        this.setState((prevState) => ({
          isOpen: !prevState.isOpen,
        }));
      }
    }
    // walkin page redirect after store success

    //Fetch Store Error Modal Show
    if (
      nextProps.storeError !== this.props.storeError &&
      nextProps.storeError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.storeError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
    //Fetch Store Error Modal Show
    const processAction = this.props.location.state.actiontype;

    let users = [];

    if (processAction === "CHECKEDOUTORDERS") {
      nextProps.users.map((user, index) => {
        users.push({
          value: user.username,
          label: user.firstname + " " + user.lastname,
        });
      });
    } else {
      nextProps.users.map((user, index) => {
        users.push({
          value: user.username,
          label: user.firstname + " " + user.lastname,
        });
      });
    }

    this.setState({
      users: users,
    });
  }

  finalPrice = () => {
    let charge = 0;
    this.props.order.orderitems.forEach((item) => {
      charge += item.unitcost * item.appliedamount;
    });
    return charge.toFixed(2);
  };

  addProductButton = () => {
    const orderPrice = this.finalPrice();
    if (orderPrice === 0) {
      return (
        // <button
        //   disabled={true}
        //   onClick={this.showModal}
        //   className="btn btn-info btn-primary"
        //   style={{ marginLeft: 10 }}
        // >
        //   Add Product
        // </button>
        <></>
      );
    } else {
      return (
        // <button
        //   onClick={() => {
        //     this.props.callStoresWebService();
        //     this.showModal();
        //   }}
        //   className="btn btn-info btn-primary"
        //   style={{ marginLeft: 10 }}
        // >
        //   Add Product
        // </button>
        <></>
      );
    }
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  orderPrice = () => {
    let charge = 0;
    this.props.order.orderitems.forEach((item) => {
      let charge = item.unitcost * item.orderedamount;
      let taxTotal = parseFloat(charge) * parseFloat(item.taxrate);
      charge += item.unitcost * item.orderedamount;
    });
    return charge.toFixed(2);
  };

  orderSalesPrice = () => {
    let charge = 0;
    let taxTotal = 0;
    this.props.order.orderitems.forEach((item) => {
      charge = item.unitcost * item.appliedamount;
      taxTotal += parseFloat(charge) * parseFloat(item.taxrate);
    });
    return taxTotal.toFixed(2);
  };

  extraOrderPrice = () => {
    let charge = 0;
    this.props.extraOrders.forEach((item) => {
      charge += item.unitcost * item.quantity;
    });
    return charge.toFixed(2);
  };

  extraOrderSalesTax = () => {
    let charge = 0;
    let taxTotal = 0;
    this.props.extraOrders.forEach((item) => {
      charge = item.unitcost * item.quantity;
      taxTotal += parseFloat(charge) * parseFloat(item.taxrate);
    });
    return taxTotal.toFixed(2);
  };
  I;

  _showFinalPriceModal = () => {
    let totalAmt =
      parseFloat(this.finalPrice()) +
      parseFloat(
        this.extraOrderPrice() + parseFloat(this.extraOrderSalesTax())
      );

    if (totalAmt === 0) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Alert!",
        dialogBody: "The order is being cancelled (or returned)",
        dialogFooter: (
          <>
            <button
              className="btn"
              style={styles.dialogConfirmButtonStyle}
              onClick={() => {
                this.setState({ displayDialog: false });
                this._submitOrder("CHECKOUTORDER", { signature: "" });
              }}
            >
              Ok
            </button>

            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={() => {
                this.setState({ displayDialog: false });
              }}
            >
              Cancel
            </button>
          </>
        ),
      });
    } else {
      this.showPaymentModal();
    }
  };

  _submitOrder = async (processAction, extraParam = null) => {
    if (
      processAction === "ASSIGNFULFILLMENT" ||
      processAction === "ASSIGNRETURN"
    ) {
      if (!this.state.assignto) {
        this.setState({
          assigntoError: "Please select an agent.",
        });
        return false;
      }
    }

    let totalappliedamount = 0;
    this.props.order.orderitems.forEach((item) => {
      totalappliedamount += item.appliedamount;
    });

    // if (totalappliedamount === 0) {
    //   this.setState({
    //     displayDialog: true,
    //     dialogTitle: "Alert!",
    //     dialogBody: "Please Select Valid Quantity",
    //     dialogFooter: (
    //       <>
    //         <button
    //           className="btn"
    //           style={styles.dialogCancelButtonStyle}
    //           onClick={() => {
    //             this.setState({ displayDialog: false });
    //           }}
    //         >
    //           Cancel
    //         </button>
    //       </>
    //     ),
    //   });
    // } else {

    let result = [];
    this.props.order.orderitems.forEach((item) => {
      result = [
        ...result,
        {
          orderitemid: item.orderitemid,
          orderedquantity: item.orderedamount,
          processedquantity: item.appliedamount,
        },
      ];
    });
    const data = {
      username: this.props.logindetails.username,
      sessionid: this.props.logindetails.sessionid,
      processaction: processAction,
      ordereditems: result,
      orderid: this.props.order.orderid,
      email: this.props.order.guest.email,
      ...extraParam,
    };

    if (processAction === "CHECKOUTORDER") {
      const PaymentGatewayDetail = JSON.parse(
        localStorage.getItem("PaymentGatewayDetail")
      );
      if (PaymentGatewayDetail.paymentgateway === "FAC") {
        let deposit = this.props.order.deposit;

        const FAC_CONFIG = {
          merchantid: PaymentGatewayDetail.merchantid,
          password: PaymentGatewayDetail.password,
          endpoint: PaymentGatewayDetail.endpointpath,
          acquireid: PaymentGatewayDetail.acquirerid,
          currency_code: PaymentGatewayDetail.currencycode,
          transacton_code: PaymentGatewayDetail.transactoncode,
          redirecturl: PaymentGatewayDetail.redirecturl,
          endpointpath: PaymentGatewayDetail.endpointpath,
        };

        this.props.loaderStatus(true);
        const fac = new Fac(FAC_CONFIG);

        //let totalAmt =
        /// parseFloat(this.finalPrice()) +
        // parseFloat(
        //    this.extraOrderPrice() + parseFloat(this.extraOrderSalesTax())
        //  );

        let totalAmt = (
          this.state.appliedamount + this.state.appliedtaxamount
        ).toFixed(2);

        let processCardRequestBody = {};

        console.log("Deposit Amount: ", deposit);
        console.log("applied amount : ", this.state.appliedamount);
        console.log("applied tax amount: ", this.state.appliedtaxamount);

        if (totalAmt === 0) {
          processCardRequestBody = {
            amount: deposit.amount,
            modificationtype: 3,
            actiontype: "MODIFYTRANSACTION",
          };
        } else if (totalAmt < deposit.amount) {
          processCardRequestBody = {
            amount: parseFloat(deposit.amount) - totalAmt,
            modificationtype: 3,
            actiontype: "MODIFYTRANSACTION",
          };
        } else {
          processCardRequestBody = {
            amount: totalAmt,
            modificationtype: 1,
            actiontype: "MODIFYTRANSACTION",
          };
        }

        processCardRequestBody = {
          ...processCardRequestBody,
          orderid: deposit.orderid,
        };

        console.log("processCardRequestBody", processCardRequestBody);

        let processTransactionResponse = await processTransaction({
          orderid: data.orderid,
          transactiontype: "STARTTRANSACTION",
          actiontype: processAction,
        });

        if (processTransactionResponse.success) {
          const response = await processCard(processCardRequestBody);
          console.log("responses", response);

          this.props.loaderStatus(false);

          console.log("FAC Reponse Return", JSON.stringify(response));

          if (response.success) {
            let request = this.props.location.state.request;
            request = {
              ...request,
              transactionCode: 0,
              // paymentResponse: response.data,
            };
            this.props.orderSubmit(data, this.props.history);
          } else {
            await processTransaction({
              orderid: processCardRequestBody.orderid,
              transactiontype: "ENDTRANSACTION",
              actiontype: processAction,
            });
            this.setState({
              displayDialog: true,
              dialogTitle: "Alert!",
              dialogBody: response.statusMessage,
              dialogFooter: (
                <>
                  <button
                    className="btn"
                    style={styles.dialogConfirmButtonStyle}
                    onClick={() => {
                      this.closeDialog();
                    }}
                  >
                    Ok
                  </button>
                </>
              ),
            });
          }
        } else {
          this.props.loaderStatus(false);

          this.setState({
            displayDialog: true,
            dialogTitle: "Alert!",
            dialogBody: processTransactionResponse.statusMessage,
            dialogFooter: (
              <>
                <button
                  className="btn"
                  style={styles.dialogConfirmButtonStyle}
                  onClick={() => {
                    this.closeDialog();
                  }}
                >
                  Ok
                </button>
              </>
            ),
          });
        }
      } else {
        this.props.orderSubmit(data, this.props.history);
      }
    } else {
      this.props.orderSubmit(data, this.props.history);
    }
  };

  confirmSubmit = (processAction, extraParam = null) => {
    let result = [];
    this.props.order.orderitems.forEach((item) => {
      result = [
        ...result,
        {
          orderitemid: item.orderitemid,
          orderedquantity: item.orderedamount,
          processedquantity: item.appliedamount,
        },
      ];
    });
    const data = {
      username: this.props.logindetails.username,
      sessionid: this.props.logindetails.sessionid,
      processaction: processAction,
      ordereditems: result,
      orderid: this.props.order.orderid,

      ...extraParam,
    };

    this.props.orderSubmit(data, this.props.history);
  };

  handleRefundSubmit = () => {
    let totalAmt = parseFloat(this.finalPrice());

    if (totalAmt === 0) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Alert!",
        dialogBody: "Please Choose Valid Quantity For Refund.",
        dialogFooter: (
          <>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={() => {
                this.setState({ displayDialog: false });
              }}
            >
              Cancel
            </button>
          </>
        ),
      });
    } else {
      this.showRefundPaymentModal();
    }
  };

  saveOrder = () => {
    let canvasSignature = "";
    if (this.props.signature === null) {
      canvasSignature = this.sigPad.getCanvas().toDataURL("image/png");
      this.setState({ signatureDataURL: canvasSignature });
      this.setState((prevState) => ({
        isOpenSignature: !prevState.isOpenSignature,
      }));

      this.props.addSignature(canvasSignature);
    } else {
      canvasSignature = this.props.signature;
    }

    if (!canvasSignature) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Alert!",
        dialogBody: "Signature is required!",
        dialogFooter: (
          <>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={() => {
                this.setState({ displayDialog: false });
              }}
            >
              Cancel
            </button>
          </>
        ),
      });
      return false;
    }

    this._submitOrder("CHECKOUTORDER", {
      signature: canvasSignature,
      amount: this.state.finalAmount,
    });
  };
  showModal = () => {
    // setIsOpen(true);
    const result = this.props.stores.find(
      (stores) =>
        stores.storeid === this.props.logindetails.userdefault.vendorid
    );
    if (result !== undefined) {
      if ("categories" in result) {
        this.setState({
          categories: result.categories,
        });
        // this.setState((prevState) => ({
        //   isOpen: !prevState.isOpen,
        // }));
      } else {
        alert("No Products Available");
      }
    } else {
      alert("No Products Available");
    }
  };
  hideModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  hideSignatureModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpenSignature: !prevState.isOpenSignature,
    }));
  };
  showSignatureModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpenSignature: !prevState.isOpenSignature,
    }));
    this.setState((prevState) => ({
      isOpenPayment: !prevState.isOpenPayment,
    }));
  };
  hidePaymentModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpenPayment: !prevState.isOpenPayment,
    }));
  };

  hideRefundPaymentModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpenRefundPayment: !prevState.isOpenRefundPayment,
    }));
  };
  clear = () => {
    this.sigPad.clear();
  };
  showPaymentModal = () => {
    this.setState((prevState) => ({
      isOpenPayment: !prevState.isOpenPayment,
    }));
    let orderAmount_new = 0;
    let appliedamount_new = 0;
    let extraAmount = 0;
    let extrataxAmount = 0;
    let appliedtaxamount = 0;
    const tempOrderitems = [...this.props.order.orderitems];
    tempOrderitems.forEach((orderitem) => {
      /****************************Count Applied Tax AMount**********************/
      let applied_total = orderitem.unitcost * orderitem.appliedamount;
      appliedtaxamount +=
        parseFloat(applied_total) * parseFloat(orderitem.taxrate);
      /****************************Count Applied Tax AMount**********************/

      appliedamount_new += orderitem.unitcost * orderitem.appliedamount;

      let orderAmount_chagre = orderitem.unitcost * orderitem.orderedamount;
      let orderAmount_tax =
        parseFloat(orderAmount_chagre) * parseFloat(orderitem.taxrate);
      let charge = orderitem.unitcost * orderitem.orderedamount;
      orderAmount_new += parseFloat(orderAmount_tax) + parseFloat(charge);
    });
    this.setState({
      orderAmount: orderAmount_new,
    });
    const tempextraOrders = this.props.extraOrders;
    tempextraOrders.forEach((orderitem) => {
      // /****************************Count Applied Tax AMount**********************/
      extrataxAmount +=
        parseFloat(orderitem.total) * parseFloat(orderitem.taxrate);
      // /****************************Count Applied Tax AMount**********************/
      extraAmount += orderitem.total;
    });
    this.setState({
      appliedamount: appliedamount_new,
      appliedtaxamount: appliedtaxamount,
    });
    this.setState({
      extraAmount: extraAmount,
      extrataxAmount: extrataxAmount,
    });
    let finalAmount_new = appliedamount_new + extraAmount;
    let finalTaxTotalAmt = appliedtaxamount + extrataxAmount;
    this.setState({
      finalAmount: finalAmount_new + finalTaxTotalAmt,
    });
  };

  showRefundPaymentModal = () => {
    this.setState((prevState) => ({
      isOpenRefundPayment: !prevState.isOpenRefundPayment,
    }));
    let orderAmount_new = 0;
    let appliedamount_new = 0;
    let extraAmount = 0;
    let extrataxAmount = 0;
    let appliedtaxamount = 0;
    const tempOrderitems = [...this.props.order.orderitems];
    tempOrderitems.forEach((orderitem) => {
      /****************************Count Applied Tax AMount**********************/
      let applied_total = orderitem.unitcost * orderitem.appliedamount;
      appliedtaxamount +=
        parseFloat(applied_total) * parseFloat(orderitem.taxrate);
      /****************************Count Applied Tax AMount**********************/

      appliedamount_new += orderitem.unitcost * orderitem.appliedamount;

      let orderAmount_chagre = orderitem.unitcost * orderitem.orderedamount;
      let orderAmount_tax =
        parseFloat(orderAmount_chagre) * parseFloat(orderitem.taxrate);
      let charge = orderitem.unitcost * orderitem.orderedamount;
      orderAmount_new += parseFloat(orderAmount_tax) + parseFloat(charge);
    });
    this.setState({
      orderAmount: orderAmount_new,
    });
    const tempextraOrders = this.props.extraOrders;
    tempextraOrders.forEach((orderitem) => {
      // /****************************Count Applied Tax AMount**********************/
      extrataxAmount +=
        parseFloat(orderitem.total) * parseFloat(orderitem.taxrate);
      // /****************************Count Applied Tax AMount**********************/
      extraAmount += orderitem.total;
    });
    this.setState({
      appliedamount: appliedamount_new,
      appliedtaxamount: appliedtaxamount,
    });
    this.setState({
      extraAmount: extraAmount,
      extrataxAmount: extrataxAmount,
    });
    let finalAmount_new = appliedamount_new + extraAmount;
    let finalTaxTotalAmt = appliedtaxamount + extrataxAmount;
    this.setState({
      finalAmount: finalAmount_new + finalTaxTotalAmt,
    });
  };

  processAction = () => {
    const actiontype = this.props.location.state.actiontype;
    const subaction = this.props.location.state.subaction;

    const cancelrequest = this.props.location.state.cancelrequest;
    let userdetails = this.props.logindetails;

    if (actiontype === "UNPROCESSED") {
      return (
        <ProcessButton
          onClick={() => this._submitOrder("FULFILLORDER")}
          title={"Fulfill Order"}
        />
      );
    } else if (
      actiontype === "FULFILLED" &&
      !cancelrequest &&
      this.props.order.status !== "CANCELLED"
    ) {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("ASSIGNFULFILLMENT", {
              assignto: this.state.assignto,
            })
          }
          title={"Assign Order"}
        />
      );
    } else if (
      actiontype === "MYASSIGNEDFULFILLMENT" &&
      this.props.order.status !== "CANCELLED"
    ) {
      return (
        <ProcessButton
          onClick={() => this._submitOrder("CONFIRMFULFILLMENT")}
          title={"Confirm Order Assignment"}
        />
      );
    } else if (actiontype === "ASSIGNEDRETURNS") {
      return (
        <ProcessButton
          onClick={() => this._submitOrder("CONFIRMRETURN")}
          title={
            this.props.logindetails.userdefault.express === "Y"
              ? "Check In Return"
              : "Confirm Return"
          }
        />
      );
    } else if (actiontype === "CONFIRMEDRETURNS") {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("CHECKINRETURN", {
              assignto: this.props.order.assignedto,
            })
          }
          title={"Check In Return"}
        />
      );
    } else if (actiontype === "CHECKIN" && !cancelrequest) {
      if (
        this.props.order.status !== "CANCELLED" &&
        this.props.order.status !== "CHECKED OUT" &&
        this.props.order.status !== "REFUNDED"
      ) {
        return (
          <ProcessButton
            onClick={() => {
              this._submitOrder("CHECKINORDER", {
                assignto: this.props.order.assignedto,
              });
            }}
            title={"Check In"}
          />
        );
      }
    } else if (
      actiontype === "CHECKEDINORDERS" &&
      this.props.order.status !== "CANCELLED"
    ) {
      return (
        <ProcessButton
          onClick={this._showFinalPriceModal}
          title={"Check Out"}
        />
      );
    } else if (actiontype === "CHECKEDOUTORDERS") {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("ASSIGNRETURN", { assignto: this.state.assignto })
          }
          title={"Return"}
        />
      );
    } else if (
      actiontype === "CANCELFULFILLED" &&
      subaction === "cancelfulfillment"
      //  &&
      // userdetails.userdefault.express === "Y" &&
      // userdetails.userdefault.fulfillmentallowed === "Y"
    ) {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("CANCELFULFILLED", {
              assignto: this.props.order.assignedto,
            })
          }
          title={"Cancel Fulfillment"}
        />
      );
    } else if (
      actiontype === "CANCELFULFILLED" &&
      cancelrequest &&
      userdetails.userdefault.fulfillmentallowed === "Y"
    ) {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("CANCELFULFILLED", {
              assignto: this.props.order.assignedto,
            })
          }
          title={"Cancel Fulfillment"}
        />
      );
    } else if (
      actiontype === "CANCELASSIGNED" &&
      cancelrequest &&
      userdetails.userdefault.assignmentallowed === "Y"
    ) {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("CANCELASSIGNEDFULFILLMENT", {
              assignto: this.props.order.assignedto,
            })
          }
          title={"Cancel Assignment"}
        />
      );
    } else if (actiontype === "CANCELCONFIRMED" && cancelrequest) {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("CANCELCONFIRMEDFULFILLMENT", {
              assignto: this.props.order.assignedto,
            })
          }
          title={
            userdetails.userdefault.express === "Y"
              ? "Cancel Fulfillment"
              : "Cancel Confirmation"
          }
        />
      );
    } else if (actiontype === "REPRINTABLERECEIPTS") {
      return (
        <ProcessButton
          onClick={() =>
            this.props.callReceiptWebService(
              {
                orderid: this.props.order.orderid,
                reprint: "Y",
              },
              this.props.history,
              this.props.order.guest.email
            )
          }
          title={"PRINT RECEIPT"}
        />
      );
    } else if (actiontype === "REFUNDABLEORDERS") {
      return (
        <ProcessButton
          onClick={() => this.handleRefundSubmit()}
          title={"Refund"}
        />
      );
    } else if (actiontype === "CANCELRETURNS") {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("CANCELASSIGNEDRETURN", {
              assignto: this.props.order.assignedto,
            })
          }
          title={"Cancel Return"}
        />
      );
    } else if (actiontype === "CANCELCHECKIN") {
      return (
        <ProcessButton
          onClick={() =>
            this._submitOrder("CANCELCHECKIN", {
              assignto: this.props.order.assignedto,
            })
          }
          title={"Cancel Check In"}
        />
      );
    }
  };

  increaseAppliedQuantity = (maxquantity, quantity, orderitemid) => {
    if (quantity < maxquantity) {
      this.props.orderItemQtyUpdate({
        orderitemid: orderitemid,
        qty: quantity + 1,
      });
    }
  };

  checkOrderTotalQuatnity = () => {
    const orderitems = this.props.order.orderitems;
    let totalqty = 0;
    orderitems.forEach((product) => {
      totalqty += product.appliedamount;
    });
    return totalqty;
  };

  decreaseAppliedQuantity = (
    minquantity,
    quantity,
    orderitemid,
    extraCartCheck = true
  ) => {
    const totalQty = this.checkOrderTotalQuatnity() - 1;

    if (totalQty === 0 && extraCartCheck === true) {
      if (this.props.extraOrders.length > 0) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Alert!",
          dialogBody:
            "This will remove the extra products from cart. Do you want to Continue ?",
          dialogFooter: (
            <>
              <button
                className="btn"
                style={styles.dialogConfirmButtonStyle}
                onClick={() => {
                  this.props.emptyExtraOrder();
                  this.setState({ displayDialog: false });
                  this.decreaseAppliedQuantity(
                    minquantity,
                    quantity,
                    orderitemid,
                    false
                  );
                }}
              >
                Ok
              </button>

              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={() => {
                  this.setState({ displayDialog: false });
                }}
              >
                Cancel
              </button>
            </>
          ),
        });
      } else {
        if (quantity > minquantity) {
          this.props.orderItemQtyUpdate({
            orderitemid: orderitemid,
            qty: quantity - 1,
          });
        }
      }
    } else {
      if (quantity > minquantity) {
        this.props.orderItemQtyUpdate({
          orderitemid: orderitemid,
          qty: quantity - 1,
        });
      }
    }
  };

  updateQuantityPlus = (product) => {
    let new_qty = product.quantity + 1;

    if (product.quantity <= product.maxqty) {
      const data = {
        productid: product.productid,
        productname: product.productname,
        description: product.description,
        unitcost: product.unitcost,
        qty: new_qty,
        maxqty: product.maxqty,
      };
      this.props.updateExtraOrderQuantity(data);
    }
  };
  updateQuantityMinus = (product) => {
    if (product.quantity <= product.maxqty && product.quantity > 0) {
      const data = {
        productid: product.productid,
        productname: product.productname,
        description: product.description,
        unitcost: product.unitcost,
        qty: product.quantity - 1,
        maxqty: product.maxqty,
      };
      this.props.updateExtraOrderQuantity(data);
    }
  };
  onCategoryBtnClick = (category, storeid) => {
    this.setState((prevState) => ({
      isProductOpen: true,
      isCategoryOpen: false,
    }));
    if (category) {
      if ("products" in category) {
        this.setState({
          products: category.products,
        });
      }
    }
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  onBtnClick = (productDetail, storeid) => {
    if (productDetail.siblings) {
      this.setState((prevState) => ({
        productDetail: productDetail,
        rootproduct: productDetail,
        isProductOpen: !prevState.isProductOpen,
        isProductDetailOpen: true,
      }));
    } else {
      this._addToCart(productDetail);
    }
  };

  onChangeAssignTo = (e) => {
    this.setState({
      assignto: e.value,
    });
  };

  scanQRCode = () => {
    this.setState((prevState) => ({
      QRReaderOpen: !prevState.QRReaderOpen,
    }));
  };

  hideQRModal = () => {
    this.setState((prevState) => ({
      QRReaderOpen: !prevState.QRReaderOpen,
    }));
  };

  handleScan = (data) => {
    if (data) {
      this.setState({
        result: data,
        QRReaderOpen: false,
      });
    }
  };

  _addToCart = (productDetail) => {
    const data = {
      productid: productDetail.productid,
      productname: productDetail.productname,
      description: productDetail.description,
      unitcost: productDetail.unitcost,
      quantity: productDetail.quantity,
      vendorid: this.props.logindetails.userdefault.vendorid,
      taxrate: productDetail.taxrate,
    };
    this.props.addToOrder(data);
    this.setState((prevState) => ({
      productDetail: [],
      rootproduct: [],
      isProductOpen: false,
      isProductDetailOpen: false,
    }));

    this.setState({
      displayToast: true,
      toastMessage: `${productDetail.productname} added to your cart.`,
    });
  };

  returnProduct = (productid) => {
    return this.state.rootproduct.siblings.find(
      (item) => item.productid === productid
    );
  };

  goToCartCheck = (productid) => {
    const cart = this.props.extraOrders.filter(
      (product) => product.productid === productid
    ).length;
    return cart;
  };

  onProductVariationChanged = (event, productDetail) => {
    let productid = event.target.value;
    this.setState({
      productDetail: this.returnProduct(productid),
      selectedProductId: productid,
    });
  };

  _printReceipt = () => {
    let orderid = this.props.order.orderid;

    const data = {
      username: this.props.logindetails.username,
      sessionid: this.props.logindetails.sessionid,
      headerTitle: "ORDER NUMBER "+orderid,
      request: {
        reportid: 19,

         startorderid: orderid,
        endorderid: orderid,
      },
    };

    console.clear();
    console.log('data',data);
    this.props.getReportService(data, this.props.history);
   }
  render() {
    console.log("SS", this.props.order);
     if (Object.entries(this.props.order).length === 0) return null;
    return (
      <>
        <NavBar />

        <Toast
          onClose={() => this.setState({ displayToast: false })}
          show={this.state.displayToast}
          delay={2000}
          autohide
          style={{
            backgroundColor: "#20232a",
            position: "fixed",
            bottom: 10,
            right: 10,
            width: 500,
            maxWidth: 500,
            marginLeft: -250,
            zIndex: 999,
          }}
        >
          <Toast.Body>
            <span style={{ color: "#FFF" }}> {this.state.toastMessage}</span>
          </Toast.Body>
        </Toast>
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        {/* <div
          style={{
            backgroundColor: "#F1F1F1",
            borderTop: "1px solid #DDD",
            borderBottom: "1px solid #DDD",
          }}
        >
          <div style={{ padding: 20, textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#777",
                marginLeft: 20,
                marginTop: 20,
                float: "left",
                display: "inline-block",
              }}
              onClick={() => {
                this._backButtonHandler();
              }}
            >
              <FontAwesomeIcon
                icon="chevron-left"
                color="#777"
                className={'back-icon'}

              />
              Back
            </button>
            <div style={styles.pageHeadingStyle}>Order Details</div>
          </div>
        </div> */}
        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Order {this.props.location.state.order.orderid}</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid stores-container">
          {Object.entries(this.props.order).length !== 0 ? (
            <div>
              <div className="row">
                <div className="col-md-5">
                  <div
                    className="card"
                    style={{ marginBottom: 20, borderRadius: 0 }}
                  >
                    <div className="card-header custom-card-header">
                      <h5 style={{ fontFamily: "Poppins-Light" }}>
                        Order Detail
                      </h5>
                    </div>

                    <div className="card-body">
                      <div>
                        <div>
                          <span style={styles.orderDetailsCaption}>
                            Order #:{" "}
                          </span>{" "}
                          {this.props.order.orderid}
                        </div>
                        <div>
                          <span style={styles.orderDetailsCaption}>
                            Status:{" "}
                          </span>{" "}
                          {this.props.order.status}
                        </div>
                        {this.props.location.state.actiontype ===
                          "CONFIRMEDRETURNS" ||
                        this.props.location.state.actiontype ===
                          "ASSIGNEDFULFILLMENT" ||
                        this.props.location.state.actiontype ===
                          "CANCELCHECKIN" ? (
                          <div>
                            <span style={styles.orderDetailsCaption}>
                              Assigned:{" "}
                            </span>{" "}
                            {this.props.order.assignedname}
                          </div>
                        ) : null}
                      </div>

                      {this.props.location.state.actiontype === "CHECKIN" ? (
                        <>
                          <div>
                            <span style={styles.orderDetailsCaption}>
                              Vendor:{" "}
                            </span>{" "}
                            {this.props.order.vendorname}
                          </div>
                          <div>
                            <span style={styles.orderDetailsCaption}>
                              Assigned:{" "}
                            </span>{" "}
                            {this.props.order.assignedname}
                          </div>
                        </>
                      ) : null}

                      {this.props.location.state.actiontype ===
                      "CHECKEDOUTORDERS" ? (
                        <div>
                          <span style={styles.orderDetailsCaption}>
                            Vendor :{" "}
                          </span>{" "}
                          {this.props.order.vendorname}
                        </div>
                      ) : null}
                      {this.props.location.state.actiontype ===
                      "MYCONFIRMEDFULFILLMENT" ? (
                        <div
                          style={{
                            flex: 1,
                            flexDirection: "row",
                            justifyContent: "center",
                            paddingVertical: 20,
                          }}
                        >
                          <QRCode
                            bgColor="#FFFFFF"
                            fgColor="#000000"
                            level="Q"
                            style={{ width: 180 }}
                            value={
                              this.props.order.orderid +
                              "|" +
                              this.props.order.assignedto
                            }
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="card" style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        fontFamily: "Poppins-Light",
                        fontSize: 14,
                        padding: 10,
                        borderBottom: "2px dashed #DDD",
                      }}
                    >
                      Customer Detail
                    </div>
                    <div
                      className="card-body"
                      style={{ fontSize: 12, fontFamily: "Poppins-Light" }}
                    >
                      <div>
                        <span style={styles.orderDetailsCaption}>Name: </span>{" "}
                        {this.props.order.guest.firstname}{" "}
                        {this.props.order.guest.lastname}
                      </div>
                      <div>
                        <span style={styles.orderDetailsCaption}>Email: </span>{" "}
                        {this.props.order.guest.email}
                      </div>
                      <div>
                        <span style={styles.orderDetailsCaption}>Phone: </span>{" "}
                        {this.props.order.guest.phone}
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div
                      style={{
                        fontFamily: "Poppins-Light",
                        fontSize: 14,
                        padding: 10,
                        borderBottom: "2px dashed #DDD",
                      }}
                    >
                      Flight Detail
                    </div>
                    <div
                      className="card-body"
                      style={{ fontSize: 12, fontFamily: "Poppins-Light" }}
                    >
                      <div>
                        <span style={styles.orderDetailsCaption}>
                          Airport:{" "}
                        </span>{" "}
                        {this.props.order.flight.airportid === "SIA"
                          ? "Sangster International"
                          : "Norman Manley International"}
                      </div>
                      <div>
                        <span style={styles.orderDetailsCaption}>
                          Airline:{" "}
                        </span>{" "}
                        {this.props.location.state.order.flight.airlineid}
                      </div>
                      <div>
                        <span style={styles.orderDetailsCaption}>Flight: </span>{" "}
                        {this.props.order.flight.flightid}
                      </div>
                      <div>
                        <span style={styles.orderDetailsCaption}>
                          Travel Date :{" "}
                        </span>{" "}
                        {moment(this.props.order.flight.flighttime).format(
                          dateFormat(
                            "normaldate",
                            this.props.logindetails.userdefault
                          )
                        )}
                      </div>
                      <div>
                        <span style={styles.orderDetailsCaption}>
                          Flight Time:{" "}
                        </span>{" "}
                        {moment(this.props.order.flight.flighttime).format(
                          dateFormat(
                            "normaltime",
                            this.props.logindetails.userdefault
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <div className="card" style={{ marginBottom: 20 }}>
                    <div
                      style={{
                        padding: 15,
                        backgroundColor: "#EEE",
                        fontFamily: "Poppins-Light",
                        fontSize: 14,
                      }}
                    >
                      Ordered Items
                    </div>
                    {this.props.location.state.actiontype ===
                    "REFUNDABLEORDERS" ? (
                      <div
                        className="card-body"
                        style={{ marginRight: 13, marginLeft: 13 }}
                      >
                        <div>
                          <div className="row">
                            <table width="100%">
                              <tr>
                                <th
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "left",
                                  }}
                                >
                                  Products
                                </th>
                                <th
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "center",
                                  }}
                                >
                                  Delivered
                                </th>
                                <th
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "center",
                                  }}
                                >
                                  Refunded
                                </th>
                                <th
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "right",
                                    paddingRight: 32,
                                  }}
                                >
                                  Refund
                                </th>
                              </tr>
                              {this.props.order.orderitems.map((item) => (
                                <tr>
                                  <td
                                    style={{
                                      fontSize: 12,
                                      fontFamily: "Poppins-Light",
                                      textAlign: "left",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {item.productname}
                                  </td>

                                  <td
                                    style={{
                                      fontSize: 12,
                                      fontFamily: "Poppins-Light",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {item.orderedamount}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 12,
                                      fontFamily: "Poppins-Light",
                                      textAlign: "center",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {parseInt(item.orderedamount) -
                                      parseInt(item.maxamount)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: 12,
                                      fontFamily: "Poppins-Light",
                                      textAlign: "right",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    {(item.minamount === item.appliedamount &&
                                      item.maxamount === item.appliedamount) ||
                                    this.props.order.status === "CANCELLED" ? (
                                      <span style={{ paddingRight: 50 }}>
                                        {" "}
                                        {item.appliedamount}{" "}
                                      </span>
                                    ) : (
                                      <>
                                        <button
                                          onClick={() =>
                                            this.decreaseAppliedQuantity(
                                              item.minamount,
                                              item.appliedamount,
                                              item.orderitemid
                                            )
                                          }
                                          disabled={
                                            item.minamount === item.appliedamount
                                          }
                                          className="btn btn-sm"
                                          style={{
                                            border: "1px solid #DDD",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            paddingRight: 15,
                                            paddingLeft: 15,
                                            fontSize: 16,
                                            fontFamily: "Poppins-Light",
                                            color: "red",
                                          }}
                                        >
                                          -
                                        </button>
                                        <span
                                          style={{
                                            marginRight: 5,
                                            marginLeft: 5,
                                          }}
                                        >
                                          {" "}
                                          {item.appliedamount}{" "}
                                        </span>
                                        <button
                                          onClick={() =>
                                            this.increaseAppliedQuantity(
                                              item.maxamount,
                                              item.appliedamount,
                                              item.orderitemid
                                            )
                                          }
                                          disabled={
                                            item.maxamount === item.appliedamount
                                          }
                                          className="btn btn-sm"
                                          style={{
                                            border: "1px solid #DDD",
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            paddingRight: 15,
                                            paddingLeft: 15,
                                            fontSize: 16,
                                            fontFamily: "Poppins-Light",
                                            color: "green",
                                          }}
                                        >
                                          +
                                        </button>
                                      </>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="card-body">
                        <div>
                          <div className="row">
                            <div
                              className="col-md-3"
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                              }}
                            >
                              ITEM
                            </div>
                            <div
                              className="col-md-2"
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                              }}
                            >
                              COST
                            </div>
                            <div
                              className="col-md-2"
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                                textAlign: "center",
                              }}
                            >
                              QUANTITY
                            </div>
                            {this.props.location.state.actiontype ===
                            "UNPROCESSED" ? (
                              <div
                                className="col-md-2"
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Poppins-Light",
                                  textAlign: "center",
                                }}
                              >
                                APPLIED
                              </div>
                            ) : null}

                            <div
                              className={
                                this.props.location.state.actiontype ===
                                "UNPROCESSED"
                                  ? "col-md-3"
                                  : "col-md-4"
                              }
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                                textAlign: "center",
                              }}
                            >
                              {this.props.location.state.actiontype ===
                              "UNPROCESSED"
                                ? "APPLY"
                                :  (this.props.location.state.actiontype === 'CANCELFULFILLED' || this.props.location.state.actiontype === 'CANCELCONFIRMED' || this.props.location.state.actiontype === 'CANCELASSIGNED' || this.props.location.state.actiontype === 'MYCONFIRMEDFULFILLMENT') ? "APPLIED" : "APPLY"}
                            </div>
                          </div>
                        </div>

                        {this.props.order.orderitems.map((item) => (
                          <div
                            key={item.orderitemid}
                            style={{ marginTop: 5, marginBottom: 5 }}
                          >
                            <div
                              className="row"
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                              }}
                            >
                              <div className="col-md-3">{item.productname}</div>
                              <div className="col-md-2">
                                $ {parseFloat(item.unitcost).toFixed(2)}
                              </div>
                              <div
                                className="col-md-2"
                                style={{ textAlign: "center" }}
                              >
                                {item.orderedamount}
                              </div>
                              {this.props.location.state.actiontype ===
                              "UNPROCESSED" ? (
                                <div
                                  className="col-md-2"
                                  style={{ textAlign: "center" }}
                                >
                                  {item.display_appliedamount}
                                </div>
                              ) : null}
                              <div
                                className={
                                  this.props.location.state.actiontype ===
                                  "UNPROCESSED"
                                    ? "col-md-3"
                                    : "col-md-4"
                                }
                                style={{ textAlign: "center" }}
                              >
                                {item.minamount === item.appliedamount &&
                                item.maxamount === item.appliedamount ? (
                                  <span> {item.appliedamount} </span>
                                ) : (
                                  <>
                                    <button
                                      onClick={() =>
                                        this.decreaseAppliedQuantity(
                                          item.minamount,
                                          item.appliedamount,
                                          item.orderitemid
                                        )
                                      }
                                      disabled={
                                        item.minamount === item.appliedamount
                                      }
                                      className="btn btn-sm"
                                      style={{
                                        border: "1px solid #DDD",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingRight: 15,
                                        paddingLeft: 15,
                                        fontSize: 16,
                                        fontFamily: "Poppins-Light",
                                        color: "red",
                                      }}
                                    >
                                      -
                                    </button>
                                    <span
                                      style={{ marginRight: 5, marginLeft: 5 }}
                                    >
                                      {" "}
                                      {item.appliedamount}{" "}
                                    </span>
                                    <button
                                      onClick={() =>
                                        this.increaseAppliedQuantity(
                                          item.maxamount,
                                          item.appliedamount,
                                          item.orderitemid
                                        )
                                      }
                                      disabled={
                                        item.maxamount === item.appliedamount
                                      }
                                      className="btn btn-sm"
                                      style={{
                                        border: "1px solid #DDD",
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        paddingRight: 15,
                                        paddingLeft: 15,
                                        fontSize: 16,
                                        fontFamily: "Poppins-Light",
                                        color: "green",
                                      }}
                                    >
                                      +
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}

                     
                      </div>
                    )}
                  </div>

                  {this.props.extraOrders.length !== 0 ? (
                    <div className="card" style={{ marginBottom: 20 }}>
                      <div
                        className="card-header"
                        style={{
                          padding: 15,
                          backgroundColor: "#EEE",
                          fontFamily: "Poppins-Light",
                          fontSize: 14,
                        }}
                      >
                        Extra Items
                      </div>
                      <div className="card-body">
                        <table className="table " width="100%">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                width="35%"
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Poppins-Light",
                                  borderBottom: 0,
                                  textTransform: "uppercase",
                                }}
                              >
                                Item
                              </th>
                              <th
                                scope="col"
                                width="45%"
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Poppins-Light",
                                  borderBottom: 0,
                                  textTransform: "uppercase",
                                }}
                              >
                                Cost
                              </th>
                              <th
                                scope="col"
                                width="20%"
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Poppins-Light",
                                  borderBottom: 0,
                                  textTransform: "uppercase",
                                  alignItems: "right",
                                  textAlign: "center",
                                }}
                              >
                                Applied
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.extraOrders.map((item) => (
                              <tr
                                key={item.productid}
                                style={{
                                  fontSize: 12,
                                  fontFamily: "Poppins-Light",
                                }}
                              >
                                <td>{item.productname}</td>
                                <td>${parseFloat(item.unitcost).toFixed(2)}</td>
                                <td style={{ textAlign: "center" }}>
                                  <button
                                    onClick={() =>
                                      this.updateQuantityMinus(item)
                                    }
                                    className="btn btn-sm"
                                    style={{
                                      border: "1px solid #DDD",
                                      paddingTop: 5,
                                      paddingBottom: 5,
                                      paddingRight: 15,
                                      paddingLeft: 15,
                                      fontSize: 16,
                                      fontFamily: "Poppins-Light",
                                      color: "red",
                                    }}
                                  >
                                    -
                                  </button>
                                  <span
                                    style={{ marginRight: 5, marginLeft: 5 }}
                                  >
                                    {" "}
                                    {item.quantity}{" "}
                                  </span>
                                  <button
                                    onClick={() =>
                                      this.updateQuantityPlus(item)
                                    }
                                    className="btn btn-sm"
                                    style={{
                                      border: "1px solid #DDD",
                                      paddingTop: 5,
                                      paddingBottom: 5,
                                      paddingRight: 15,
                                      paddingLeft: 15,
                                      fontSize: 16,
                                      fontFamily: "Poppins-Light",
                                      color: "green",
                                    }}
                                  >
                                    +
                                  </button>
                                </td>
                                <td>
                                  <button
                                    onClick={() =>
                                      this.removeItemFromCart(item)
                                    }
                                    className="btn btn-sm btn-danger"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                  {(this.props.location.state.actiontype === "FULFILLED" &&
                    !this.props.location.state.cancelrequest) ||
                  this.props.location.state.actiontype ===
                    "CHECKEDOUTORDERS" ? (
                    <div className="form-group">
                      <label
                        htmlFor="sel1"
                        style={{ fontSize: 14, fontFamily: "Poppins-Light" }}
                      >
                        Assign To:
                      </label>
                      <Select
                        name="form-field-name"
                        options={this.state.users}
                        onChange={this.onChangeAssignTo}
                        placeholder={"Search Agent"}
                        isDisabled={
                          this.props.order.status === "CANCELLED" &&
                          this.props.location.state.actiontype !==
                            "CHECKEDOUTORDERS"
                            ? true
                            : false
                        }
                        onFocus={() => this.setState({ assigntoError: null })}
                      />
                      {this.state.assigntoError && (
                        <small className="text-danger">
                          {this.state.assigntoError}
                        </small>
                      )}
                    </div>
                  ) : null}

                  {this.processAction()}

                  {this.props.location.state.actiontype === "CHECKEDINORDERS"
                    ? this.addProductButton()
                    : null}

{this.props.location.state.actiontype ===
                        "MYCONFIRMEDFULFILLMENT" && this.props.order.status === "WIP" ? (
                          <button
                          type="button"
                            className="btn"
                            style={styles.dialogConfirmButtonStyle}
                            onClick={() => this._printReceipt()}
                          >
                            Print
                          </button>
                        ) : null}
                </div>
              </div>
            </div>
          ) : null}
          <div className="spacer"></div>
        </div>
        {/* ******** Categories Modal */}
        <Modal
          show={this.state.isOpen}
          centered
          onHide={this.hideModal}
          size="lg"
          dialogClassName={styles.modal_90w}
        >
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }} closeButton>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Categories
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <div className="row" style={styles.dialogBodyTextStyle}>
              <div className="col-md-12">
                {this.props.stores
                  ? this.props.stores.map((store, index) =>
                      store.storeid ===
                      this.props.logindetails.userdefault.vendorid ? (
                        <div key={index} className="row">
                          {store.categories.map((category, indexcat) => (
                            <div
                              key={category.categoryid + index + indexcat}
                              className="col-md-4"
                              style={{ marginTop: 20, marginBottom: 10 }}
                            >
                              <div
                                className="card"
                                style={styles.productCardStyle}
                              >
                                <img
                                  src={
                                    APPLICATION_CONST.categoryImageUrl +
                                    category.categoryid +
                                    ".png"
                                  }
                                  alt="Category"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    alignSelf: "center",
                                    padding: 10,
                                  }}
                                />
                                <div className="card-body">
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                      marginBottom: 15,
                                      textAlign: "center",
                                    }}
                                  >
                                    {category.description}
                                  </div>
                                  <button
                                    onClick={() =>
                                      this.onCategoryBtnClick(
                                        category,
                                        store.storeid
                                      )
                                    }
                                    style={{
                                      fontSize: 12,
                                      fontWeight: "bold",
                                      backgroundColor: "#39304A",

                                      border: "1px solid #EEE",
                                      padding: 10,
                                      color: "#FFF",
                                      width: "100%",
                                    }}
                                  >
                                    View Products
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.hideModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Categories Modal End*/}
        {/* ******** Payment Modal */}
        <Modal
          show={this.state.isOpenPayment}
          centered
          onHide={this.hidePaymentModal}
        >
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Payment Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={styles.dialogBodyTextStyle}>
              <table className="table table-bordered">
                <thead></thead>
                <tbody>
                  <tr>
                    <th scope="col">
                      Order Amount: $
                      {parseFloat(this.state.orderAmount).toFixed(2)}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">
                      Applied Amount: $
                      {parseFloat(this.state.appliedamount).toFixed(2)}
                    </th>
                  </tr>
                  {/* <tr>
                    <th scope="col">
                      Extra Order Amount: $
                      {parseFloat(this.state.extraAmount).toFixed(2)}
                    </th>
                  </tr> */}
                  <tr>
                    <th scope="col">
                      Sales Tax: $
                      {parseFloat(
                        this.state.appliedtaxamount + this.state.extrataxAmount
                      ).toFixed(2)}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">
                      Final Amount: $
                      {parseFloat(this.state.finalAmount).toFixed(2)}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.finalAmount > this.state.orderAmount ||
            this.props.orderErrorType === "PNP_HELD_PAYMENT_FAIL" ? (
              <>
                <button
                  style={styles.dialogConfirmButtonStyle}
                  className="btn  btn-info btn-primary"
                  onClick={() =>
                    this.props.history.push("/order/payment", {
                      order: this.props.order,
                      chargeableAmount: this.chargeableAmount(),
                      paymenttype: "cash",
                    })
                  }
                >
                  Pay Cash
                </button>

                <button
                  style={styles.dialogConfirmButtonStyle}
                  className="btn  btn-info btn-primary"
                  onClick={() =>
                    this.props.history.push("/order/payment", {
                      order: this.props.order,
                      chargeableAmount: this.chargeableAmount(),
                      paymenttype: "card",
                    })
                  }
                >
                  Pay with credit card
                </button>
              </>
            ) : (
              <button
                style={styles.dialogConfirmButtonStyle}
                className="btn  btn-info btn-primary"
                onClick={() => {
                  this.props.signature === null
                    ? this.showSignatureModal()
                    : this.saveOrder();
                  this.hidePaymentModal();
                }}
              >
                Ok
              </button>
            )}
            <button
              style={styles.dialogCancelButtonStyle}
              className="btn  btn-info btn-primary"
              onClick={this.hidePaymentModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Payment Modal End*/}

        {/******Refund Payment Modal Start */}

        <Modal
          show={this.state.isOpenRefundPayment}
          centered
          onHide={this.hideRefundPaymentModal}
        >
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Refund Detail
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={styles.dialogBodyTextStyle}>
              <table className="table table-bordered">
                <thead></thead>
                <tbody>
                  <tr>
                    <th scope="col">
                      Delivered Amount: $
                      {parseFloat(this.state.orderAmount).toFixed(2)}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">
                      Refunded Amount: $
                      {parseFloat(this.state.appliedamount).toFixed(2)}
                    </th>
                  </tr>
                  {/* <tr>
                    <th scope="col">
                      Extra Order Amount: $
                      {parseFloat(this.state.extraAmount).toFixed(2)}
                    </th>
                  </tr> */}
                  <tr>
                    <th scope="col">
                      Sales Tax: $
                      {parseFloat(this.state.appliedtaxamount).toFixed(2)}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">
                      Final Amount: $
                      {parseFloat(this.state.finalAmount).toFixed(2)}
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <>
              <button
                style={styles.dialogConfirmButtonStyle}
                className="btn"
                onClick={() =>
                  this.props.history.push("/order/payment", {
                    order: this.props.order,
                    chargeableAmount: this.refundableAmount(),
                    paymenttype: "cash",
                    actiontype: this.props.location.state.actiontype,
                  })
                }
              >
                Refund By Cash
              </button>

              <button
                style={styles.dialogConfirmButtonStyle}
                className="btn "
                onClick={() =>
                  this.props.history.push("/order/payment", {
                    order: this.props.order,
                    chargeableAmount: this.refundableAmount(),
                    paymenttype: "card",
                    actiontype: this.props.location.state.actiontype,
                  })
                }
              >
                Refund with credit card
              </button>
            </>

            <button
              style={styles.dialogCancelButtonStyle}
              className="btn"
              onClick={this.hideRefundPaymentModal}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        {/******Refund Payment Modal End */}

        {/* ******** Signature Modal */}
        <Modal
          show={this.state.isOpenSignature}
          onHide={this.hideSignatureModal}
          centered
        >
          <Modal.Header closeButton style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Customer Signature
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 450,
                height: 300,
                className: "sigCanvas",
              }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              disabled={this.sigPad}
              style={styles.dialogConfirmButtonStyle}
              onClick={this.saveOrder}
            >
              Save & Proceed
            </button>

            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.clear}
            >
              Reset
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Signature Modal End*/}
        {/* ******** Products Modal */}
        <Modal
          show={this.state.isProductOpen}
          onHide={this.hideProductModal}
          size="lg"
          centered
        >
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }} closeButton>
            <Modal.Title style={styles.dialogHeaderStyle}>Products</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <div className="row" style={styles.dialogBodyTextStyle}>
              <div className="col-md-12">
                <button
                  className="btn btn-success btn-sm  float-right"
                  onClick={() => this.scanQRCode()}
                >
                  <FontAwesomeIcon
                    icon="barcode"
                    color="#FFF"
                    style={{ marginRight: 5 }}
                  />
                  Scan Product
                </button>
              </div>
              <div className="col-md-12">
                {this.props.stores
                  ? this.props.stores.map((store, index) =>
                      store.storeid ===
                      this.props.logindetails.userdefault.vendorid ? (
                        <div key={index} className="row">
                          {this.state.products.map((product, indexcat) => (
                            <div
                              key={product.productid + index + indexcat}
                              className="col-md-4"
                              style={{ marginTop: 20, marginBottom: 10 }}
                            >
                              <div
                                key={product.productid + index + indexcat}
                                className="card"
                                style={styles.productCardStyle}
                              >
                                <img
                                  src={
                                    APPLICATION_CONST.productImageUrl +
                                    product.productreferenceid +
                                    ".png" +
                                    "?" +
                                    new Date()
                                  }
                                  alt="Category"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    alignSelf: "center",
                                    padding: 10,
                                  }}
                                />
                                <div
                                  className="card-body"
                                  style={{ height: 100 }}
                                >
                                  <div
                                    style={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                      marginBottom: 15,
                                      textAlign: "center",
                                    }}
                                  >
                                    {product.productname}
                                  </div>
                                  {this.goToCartCheck(product.productid) > 0 ? (
                                    <button
                                      onClick={() =>
                                        this.setState({
                                          productDetail: [],
                                          rootproduct: [],
                                          isProductOpen: false,
                                          isProductDetailOpen: false,
                                        })
                                      }
                                      style={{
                                        backgroundColor: "#FED23D",
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        border: "1px solid #EEE",
                                        padding: 10,
                                        color: "#4f4b4a",
                                        width: "100%",
                                      }}
                                    >
                                      Go to Cart
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() =>
                                        this.onBtnClick(product, store.storeid)
                                      }
                                      style={{
                                        fontSize: 12,
                                        fontWeight: "bold",
                                        backgroundColor: "#4f4b4a",
                                        border: "1px solid #EEE",
                                        padding: 10,
                                        color: "#fff",
                                        width: "100%",
                                      }}
                                    >
                                      Add to Cart&nbsp;&nbsp;
                                      <FontAwesomeIcon
                                        icon="shopping-cart"
                                        color="#fff"
                                        size="lg"
                                      />
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : null
                    )
                  : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              style={styles.dialogConfirmButtonStyle}
              onClick={this.moveCategory}
            >
              Back
            </button>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.hideProductModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Products Modal End*/}

        {/* ******** Products Modal */}
        <Modal
          show={this.state.isProductDetailOpen}
          onHide={this.hideProductDetailModal}
          size="lg"
          centered
        >
          <Modal.Header style={{ backgroundColor: "#F2F2F2" }} closeButton>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Product Detail
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <div className="row" style={styles.dialogBodyTextStyle}>
              <div className="col-md-12">
                {this.state.productDetail ? (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          alt=""
                          style={{
                            height: 350,
                            width: "auto",
                            margin: 3,
                            alignSelf: "center",
                          }}
                          resizemode="contain"
                          src={
                            APPLICATION_CONST.productImageUrl +
                            this.state.productDetail.productreferenceid +
                            ".png" +
                            "?" +
                            new Date()
                          }
                        />
                      </div>
                      <div className="col-md-8">
                        <div style={{ fontWeight: "bold", fontSize: 22 }}>
                          {this.state.productDetail.productname}
                        </div>
                        {/* <div style={{ fontSize: 18 }}>
                ${parseFloat(product.unitcost).toFixed(2)}
              </div> */}
                        {this.state.productDetail.msrp >
                        this.state.productDetail.unitcost ? (
                          <>
                            <div style={{ fontSize: 18 }}>
                              <strike>
                                USD $ {this.state.productDetail.msrp}
                              </strike>
                            </div>
                            <div style={{ fontSize: 18 }}>
                              USD $ {this.state.productDetail.unitcost}
                            </div>
                          </>
                        ) : (
                          <div style={{ fontSize: 18 }}>
                            USD ${this.state.productDetail.unitcost}
                          </div>
                        )}
                        <div style={{ marginTop: 20, marginBottom: 20 }}>
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 15,
                              color: "#777",
                            }}
                          >
                            Product Description
                          </div>
                          <div style={{ fontSize: 12 }}>
                            {this.state.productDetail.description}
                          </div>
                        </div>
                        {}
                        {this.state.rootproduct.siblings &&
                        this.state.rootproduct.siblings.length > 1 ? (
                          <>
                            <select
                              className="form-control"
                              onChange={this.onProductVariationChanged}
                              onFocus={this.resetError}
                              value={this.state.selectedProductId}
                            >
                              {this.state.rootproduct.siblings.map(
                                (sibling) => (
                                  <option
                                    key={sibling.productid}
                                    value={sibling.productid}
                                  >
                                    {sibling.productname}&nbsp;&nbsp;&nbsp;$
                                    {sibling.unitcost.toFixed(2)}
                                  </option>
                                )
                              )}
                            </select>
                          </>
                        ) : null}

                        <>
                          {this.goToCartCheck(
                            this.state.productDetail.productid
                          ) === 0 ? (
                            <button
                              onClick={() =>
                                this._addToCart(this.state.productDetail)
                              }
                              className="btn"
                              style={{
                                backgroundColor: "#FED23D",
                                fontSize: 14,
                                fontWeight: "bold",
                                color: "#555",
                                marginTop: 10,
                              }}
                            >
                              Add to Cart
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                this.setState({
                                  productDetail: [],
                                  rootproduct: [],
                                  isProductDetailOpen: false,
                                })
                              }
                              className="btn"
                              style={{
                                backgroundColor: "#FED23D",
                                fontSize: 14,
                                fontWeight: "bold",
                                color: "#333",
                                marginTop: 10,
                              }}
                            >
                              Go to Cart
                            </button>
                          )}
                        </>

                        <hr />
                        <div>
                          <button
                            className="btn"
                            style={{
                              backgroundColor: "#009688",
                              fontSize: 12,
                              fontWeight: "bold",
                              color: "#FFF",
                            }}
                            onClick={() =>
                              this.props.history.push("/store/categories", {
                                storeid: this.props.location.state.storeid,
                              })
                            }
                          >
                            All categories
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-info btn-primary  btn-sm"
              style={styles.dialogConfirmButtonStyle}
              onClick={this.moveCategory}
            >
              Back
            </button>
            <button
              className="btn btn-info btn-primary btn-sm"
              style={styles.dialogCancelButtonStyle}
              onClick={this.hideProductDetailModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Products Modal End*/}

        {/* ******** Product Search QR Scanner Modal */}
        <Modal
          centered
          show={this.state.QRReaderOpen}
          onHide={this.hideQRModal}
          size="xs"
        >
          <Modal.Header closeButton style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Scan Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <QrReader
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: "100%" }}
                />
                <p>{this.state.result}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.hideQRModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Product Search QR Scanner Modal End*/}
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.VendorOrderReducer.orders,
  logindetails: state.SignInReducer.logindetails,
  users: state.UserReducer.users,
  stores: state.StoreReducer.stores,
  extraOrders: state.OrderProcessReducer.extraOrder,
  orderProcessError: state.OrderProcessReducer.orderProcessError,
  orderProcessSuccess: state.OrderProcessReducer.orderProcessSuccess,
  order: state.OrderProcessReducer.order,
  signature: state.OrderProcessReducer.signature,
  orderErrorType: state.OrderProcessReducer.orderErrorType,
  storeError: state.StoreReducer.error,
  storeStatus: state.StoreReducer.status,
});

export default connect(mapStateToProps, {
  getUserService,
  addToOrder,
  updateExtraOrderQuantity,
  orderSubmit,
  emptyExtraOrder,
  orderItemQtyUpdate,
  addSignature,
  orderUpdate,
  callReceiptWebService,
  callStoresWebService,
  removeFromOrder,
  loaderStatus,
  getReportService
})(OrderDetail);
