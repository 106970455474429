import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { getProductService } from "../../actions/vendor/productAction";
import {
  updateProductService,
  getClassification,
  callUpdateClassification,
} from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";

class Classification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      loaderMessage: "Retrieving Classifications...",
      dataTable: {
        columns: [
          {
            label: "#",
            field: "index",
            width: 10,
          },
          {
            label: "Class Type",
            field: "classtype",
            sort: "asc",
            width: 150,
          },
          {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 270,
          },

          {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: [
          ...this.props.classificationlist.map((classification, index) => ({
            index: index + 1,
            classtype: classification.classtype,
            description: classification.description,

            action: (
              <span>
                <button
                  onClick={() => this.onEditBtnClick(classification)}
                  className="btn btn-sm btn-info btn-warning"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <i className="fa fa-pencil"></i>
                </button>

                <button
                  className="btn btn-sm btn-info btn-danger"
                  onClick={() =>
                    this.showDeleteClassificationDialog(classification)
                  }
                >
                  <FontAwesomeIcon
                    icon="trash"
                    color="#FFF"
                    style={{ widht: 15, height: 15 }}
                  />
                </button>
              </span>
            ),
          })),
        ],
      },
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    this.props.history.index = 0;
    this.props.getClassification();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.classificationerror !== this.props.classificationerror) {
      if (this.props.classificationerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: this.props.classificationerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  onEditBtnClick = (classification) => {
    this.props.history.push("/classifications/edit", {
      classification: classification,
    });
  };
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  showDeleteClassificationDialog = (item) => {
    this.setState({
      loaderMessage: "Processing...",
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this Classification?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.deleteClassification(item);
            }}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  deleteClassification = (item) => {
    this.closeDialog();
    this.props.callUpdateClassification(
      {
        actiontype: "DELETE",
        classid: item.classid,
        classificationdescription: item.description,
        classtypeid: item.classtypeid,
      },
      this.props.history
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.classificationlist !== this.props.classificationlist) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.classificationlist.map((classification, index) => ({
              index: index + 1,
              classtype: classification.classtype,
              description: classification.description,

              action: (
                <span>
                  <button
                    onClick={() => this.onEditBtnClick(classification)}
                    className="btn btn-sm btn-info btn-warning"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>

                  <button
                    className="btn btn-sm btn-info btn-danger"
                    onClick={() =>
                      this.showDeleteClassificationDialog(classification)
                    }
                  >
                    <FontAwesomeIcon
                      icon="trash"
                      color="#FFF"
                      style={{ widht: 15, height: 15 }}
                    />
                  </button>
                </span>
              ),
            })),
          ],
        },
      });
    }
  }

  render() {
    return (
      <>
        <NavBar />
        <Loader message={this.state.loaderMessage} />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Classifications</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="stores-container" fluid>
          <div style={{ padding: 15 }} className="custom_datatable ">
            <MDBDataTable
              striped
              bordered
              hover
              small
              data={this.state.dataTable}
            />

            <button
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                position: "fixed",
                backgroundColor: "#4CAF50",
                bottom: 0,
                right: 0,
                zIndex: 100,
                margin: 30,
              }}
              className="btn"
              onClick={() => this.props.history.push("/classifications/add")}
            >
              <FontAwesomeIcon icon="plus" color="#FFF" />
            </button>
          </div>
          <div className="spacer"></div>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  classificationlist: state.VendorProductReducer.classificationlist,
  classificationerror: state.VendorProductReducer.classificationerror,
  classificationerrormessage:
    state.VendorProductReducer.classificationerrormessage,
});
export default connect(mapStateToProps, {
  getProductService,
  updateProductService,
  getClassification,
  callUpdateClassification,
})(Classification);
