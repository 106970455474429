import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { getClassType } from "../../actions/vendor/productAction";
import {
  updateProductService,
  getClassification,
  callUpdateClassification,
  callUpdateClassType,
} from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      loaderMessage: "Retrieving Classifications...",
      dataTable: {
        columns: [
          {
            label: "#",
            field: "index",
            sort: "disabled",
          },
          {
            label: "CLASS TYPES",
            field: "description",
            sort: "asc",
          },

          {
            label: "Action",
            field: "action",
            sort: "disabled",
          },
        ],
        rows: [
          ...this.props.classtypeslist.map((classtype, index) => ({
            index: index + 1,
            description: classtype.description,
            action: (
              <span>
                <button
                  onClick={() => this.onEditBtnClick(classtype)}
                  className="btn btn-sm btn-info btn-warning"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  className="btn btn-sm btn-info btn-danger"
                  onClick={() => this.showDeleteClassificationDialog(classtype)}
                >
                  <FontAwesomeIcon
                    icon="trash"
                    color="#FFF"
                    style={{ widht: 15, height: 15 }}
                  />
                </button>
              </span>
            ),
          })),
        ],
      },
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    this.props.history.index = 0;
    this.props.getClassType();
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.classtypeerror !== this.props.classtypeerror) {
      if (nextProps.classtypeerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.classtypeerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }

    if (nextProps.classtypeslist !== this.props.classtypeslist) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.classtypeslist.map((classtype, index) => ({
              index: index + 1,
              description: classtype.description,
              action: (
                <span>
                  <button
                    onClick={() => this.onEditBtnClick(classtype)}
                    className="btn btn-sm btn-info btn-warning"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-info btn-danger"
                    onClick={() =>
                      this.showDeleteClassificationDialog(classtype)
                    }
                  >
                    <FontAwesomeIcon
                      icon="trash"
                      color="#FFF"
                      style={{ widht: 15, height: 15 }}
                    />
                  </button>
                </span>
              ),
            })),
          ],
        },
      });
    }
  };

  onEditBtnClick = (classtype) => {
    this.props.history.push("/classtypes/edit", {
      classtype: classtype,
    });
  };
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  showDeleteClassificationDialog = (item) => {
    this.setState({
      loaderMessage: "Processing...",
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this class?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.deleteClass(item);
            }}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  deleteClass = (item) => {
    this.closeDialog();
    this.props.callUpdateClassType(
      {
        actiontype: "DELETE",
        classtypeid: item.classtypeid,
        classtypedescription: item.classtypedescription,
      },
      this.props.history
    );
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.classtypeslist !== this.props.classtypeslist) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.classtypeslist.map((classtype, index) => ({
              index: index + 1,
              description: classtype.description,
              action: (
                <span>
                  <button
                    onClick={() => this.onEditBtnClick(classtype)}
                    className="btn btn-sm btn-info btn-warning"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-info btn-danger"
                    onClick={() =>
                      this.showDeleteClassificationDialog(classtype)
                    }
                  >
                    <FontAwesomeIcon
                      icon="trash"
                      color="#FFF"
                      style={{ widht: 15, height: 15 }}
                    />
                  </button>
                </span>
              ),
            })),
          ],
        },
      });
    }
  }

  render() {
    return (
      <>
        <NavBar />
        <Loader message={this.state.loaderMessage} />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Class Types</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="stores-container" fluid>
          <div style={{ padding: 15 }} className="custom_datatable ">
            <MDBDataTable
              striped
              bordered
              hover
              small
              data={this.state.dataTable}
            />
            <button
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                position: "fixed",
                backgroundColor: "#4CAF50",
                bottom: 0,
                right: 0,
                zIndex: 100,
                margin: 30,
              }}
              className="btn"
              onClick={() => this.props.history.push("/classtypes/add")}
            >
              <FontAwesomeIcon icon="plus" color="#FFF" />
            </button>
          </div>
          <div className="spacer"></div>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  classtypeslist: state.VendorProductReducer.classtypeslist,
  classtypeerror: state.VendorProductReducer.classtypeerror,
  classtypeerrormessage: state.VendorProductReducer.classtypeerrormessage,
});
export default connect(mapStateToProps, {
  updateProductService,
  getClassification,
  callUpdateClassification,
  getClassType,
  callUpdateClassType,
})(Product);
