import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "./navbar";
import Footer from "./footer";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
class NoMatch extends Component {
  render() {
    return (
      <>
        <NavBar />
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ marginTop: 50 }} className="text-center">
              <img
                src={APPLICATION_CONST.applicationImages + "404-error.png"}
                alt="404"
                style={{ width: 150 }}
              />
              <h2 style={{ marginTop: 30 }}>Oops! Page not found</h2>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps)(NoMatch);
