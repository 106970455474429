import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import {
  updateProductService,
  callProductMasterWebServiceForUpdate,
  productEditWebService,
  setPreviousQuantity
} from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class AddProduct extends Component {
  constructor(props) {
    super(props);

    if(this.props.logindetails.userdefault.supervisor === 'N'){
      this.props.history.push('/');
    }
    
    this.state = {
      product: {
        productname: "",
        description: "",
        categoryid: "",
        unitcost: "",
        quantity: "",
        status: "I",
      },
      productErr: {
        productname: "",
        categoryid: "",
        unitcost: "",
        quantity: "",
        description: "",
      },
      previousquantity: 0,
      actiontype: "CREATE",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.location.state) {
      const result = this.props.products.find(
        (product) => product.productid === this.props.location.state.productid
      );


      if (result) {
        this.setState({
          product: result,
          actiontype: "UPDATE",
          previousquantity: result.quantity
        }, () => {

          this.props.setPreviousQuantity(result.quantity);
        });

      }


    } else if (this.props.location.pathname === "/products/edit") {
      this.props.history.push("/");
    }

    this.props.callProductMasterWebServiceForUpdate({});
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.updateProductError !== this.props.updateProductError &&
      nextProps.updateProductError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.updateProductError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.updateProductSuccess &&
      nextProps.updateProductSuccess !== this.props.updateProductSuccess
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: `Product ${this.state.actiontype === "CREATE" ? "added" : "updated"
          } successfully!`,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.closeDialog();
              this.props.history.push("/products");
            }}
          >
            Close
          </button>
        ),
      });
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  resetError = () => {
    let productErr = {
      productname: null,
      categoryid: "",
      unitcost: "",
      quantity: "",
    };
    this.setState({ productErr: productErr });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const history = this.props.history;
    const productname = this.state.product.productname;
    const categoryid = this.state.product.categoryid;
    const unitcost = this.state.product.unitcost;
    const quantity = this.state.product.quantity;
    const description = this.state.product.description;

    const regex = /^\d+(\.\d{1,2})?$/;
    let productErr = {
      productname: "",
      categoryid: "",
      unitcost: "",
      quantity: "",
      description: "",
    };

    if (!productname.trim()) {
      productErr.productname = "Product Name is required.";
    }
    if (!categoryid) {
      productErr.categoryid = "Category is required.";
    }
    if (!regex.test(unitcost)) {
      productErr.unitcost = "Unit cost is required.";
    } else if (!regex.test(unitcost)) {
      productErr.unitcost = "Please enter valid unitcost.";
    }

    if (!regex.test(quantity)) {
      productErr.quantity = "Quantity is required.";
    }

    if (!description.trim()) {
      productErr.description = "Description is required.";
    }

    this.setState({ productErr: productErr });

    if (
      productname.trim() &&
      categoryid &&
      regex.test(unitcost) &&
      regex.test(quantity) &&
      description.trim()
    ) {
      const data = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        product: this.state.product,
        actiontype: this.state.actiontype,
        edit: this.state.actiontype === "CREATE" ? false : true,
        productmaster: false,
        previousquantity: this.state.previousquantity
      };

      this.props.productEditWebService(data, history);
    }
  };

  categoryChange = (e) => {
    this.setState({
      product: { ...this.state.product, categoryid: e.target.value },
    });
  };
  productChange = (e) => {
    var a = e.target.value;

    if (a) {
      this.setState(
        {
          product: {
            ...this.state.product,
            productid: "",

            productreferenceid: e.target.value,
            productname: e.target[e.target.selectedIndex].getAttribute(
              "data-productname"
            ),
          },
        },
        () => {
          var productindex = this.props.productmasterlist.findIndex(
            (x) => x.productid === a
          );

          this.setProductDetails(productindex);
        }
      );
    } else {
      this.setState(
        {
          product: {
            ...this.state.product,
            productid: "",

            productreferenceid: "",
            productname: "",
          },
        },
        () => {
          var productindex = this.props.productmasterlist.findIndex(
            (x) => x.productid === a
          );

          this.setProductDetails(productindex);
        }
      );
    }
  };

  setProductDetails = (productindex) => {
    if (productindex > -1) {
      this.setState({
        product: {
          ...this.state.product,
          productid:
            this.state.actiontype === "UPDATE"
              ? this.state.product.productid
              : "",
          categoryid:
            this.state.actiontype === "UPDATE"
              ? this.state.product.categoryid
              : this.props.productmasterlist[productindex]
                ? this.props.productmasterlist[productindex].categoryid
                : null,
          categoryname:
            this.state.actiontype === "UPDATE"
              ? this.state.product.categoryname
              : this.props.productmasterlist[productindex]
                ? this.props.productmasterlist[productindex].categoryname
                : null,
          unitcost:
            this.state.actiontype === "UPDATE"
              ? this.state.product.unitcost
              : this.props.productmasterlist[productindex]
                ? this.props.productmasterlist[productindex].unitcost
                : null,
          description:
            this.state.actiontype === "UPDATE"
              ? this.state.product.description
              : this.props.productmasterlist[productindex]
                ? this.props.productmasterlist[productindex].description
                : null,
          taxrate:
            this.state.actiontype === "UPDATE"
              ? this.state.product.taxrate
                ? this.state.product.taxrate
                : "0"
              : this.props.productmasterlist[productindex].taxexempt === "Y"
                ? "0"
                : this.props.logindetails.userdefault.taxrate,
          productreferenceid:
            this.state.actiontype === "UPDATE"
              ? this.state.product.productreferenceid
                ? this.state.product.productreferenceid
                : ""
              : this.props.productmasterlist[productindex]
                ? this.props.productmasterlist[productindex].productid
                : null,
          status:
            this.state.actiontype === "UPDATE"
              ? this.state.product.status
              : "A",
        },
      });
    } else {
      if (this.props.edit === true) {
        this.setState({
          ...this.state.product,
          productid: this.state.product.productid,
          categoryid: this.state.product.categoryid,
          categoryname: this.state.product.categoryname,
          unitcost: this.state.product.unitcost,
          description: this.state.product.description,
          productreferenceid: this.state.product.productreferenceid,
          status: this.state.product.status,
        });
      } else {
        this.setState({
          product: {
            ...this.state.product,
            productid: "",
            categoryid: "",
            categoryname: "",
            unitcost: "",
            description: "",
            productreferenceid: "",
            taxrate: 0,
            status: "A",
          },
        });
      }
    }
  };

  statusChange = (value) => {
    this.setState({
      product: {
        ...this.state.product,
        status: value,
      },
    });
  };

  render() {

    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/products")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className="card-header custom-card-header">
                    <h4>
                      {" "}
                      {this.state.actiontype === "CREATE"
                        ? "Add Product"
                        : "Edit Product"}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"productname"}
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Product Name
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id={"productname"}
                              className="form-control"
                              onChange={this.productChange}
                              onFocus={this.resetError}
                              autoFocus={true}
                              value={
                                this.state.actiontype === "CREATE"
                                  ? this.state.product.productreferenceid
                                  : this.state.product.productid
                              }
                              disabled={
                                this.state.actiontype === "CREATE"
                                  ? false
                                  : true
                              }
                            >
                              <option value="">--Select Product--</option>
                              {this.state.actiontype === "CREATE"
                                ? this.props.productmasterlist.map(
                                  (product) => (
                                    <option
                                      key={product.productid}
                                      value={product.productid}
                                      data-productname={product.productname}
                                    >
                                      {product.productname}
                                    </option>
                                  )
                                )
                                : this.props.products.map((product) => (
                                  <option
                                    key={product.productid}
                                    value={product.productid}
                                    data-productname={product.productname}
                                  >
                                    {product.productname}
                                  </option>
                                ))}
                            </select>
                            {this.state.productErr.productname && (
                              <small className="text-danger">
                                {this.state.productErr.productname}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"category"}
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Category
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="category"
                              className="form-control"
                              onChange={this.categoryChange}
                              onFocus={this.resetError}
                              value={this.state.product.categoryid}
                              disabled={
                                this.state.actiontype === "CREATE" ? true : true
                              }
                            >
                              <option value="">--Select Category--</option>
                              {this.props.categories.map((category) => (
                                <option
                                  key={category.categoryid}
                                  value={category.categoryid}
                                >
                                  {category.description}
                                </option>
                              ))}
                            </select>
                            {this.state.productErr.categoryid && (
                              <small className="text-danger">
                                {this.state.productErr.categoryid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"quantity"}
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Quantity
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="quantity"
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    quantity: e.target.value.replace(
                                      /[^0-9]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              value={this.state.product.quantity}
                              className="form-control"
                              placeholder="Enter Quantity"
                            />
                            {this.state.productErr.quantity && (
                              <small className="text-danger">
                                {this.state.productErr.quantity}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"unitcost"}
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Unit Cost
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="unitcost"
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    unitcost: e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              value={this.state.product.unitcost}
                              className="form-control"
                              placeholder="Enter Unit cost"
                            />
                            {this.state.productErr.unitcost && (
                              <small className="text-danger">
                                {this.state.productErr.unitcost}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              for={"taxrate"}
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Tax Rate
                            </label>
                          </div>
                          <div className="col-md-8">
                            <input
                              id="taxrate"
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    taxrate: e.target.value.replace(
                                      /[^0-9.]/g,
                                      ""
                                    ),
                                  },
                                })
                              }
                              disabled
                              onFocus={this.resetError}
                              type="text"
                              value={
                                this.state.product.taxrate
                                  ? this.state.product.taxrate * 100 + "%"
                                  : 0.1 * 100 + "%"
                              }
                              className="form-control"
                              placeholder="Enter Tax Rate"
                            />
                            {this.state.productErr.taxrate && (
                              <small className="text-danger">
                                {this.state.productErr.taxrate}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Status
                            </label>
                          </div>
                          <div className="col-md-8">
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                checked={
                                  this.state.product.status === "A"
                                    ? true
                                    : false
                                }
                                onClick={() => this.statusChange("A")}
                              />{" "}
                              Active
                            </label>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <label className={"control-label"}>
                              <input
                                type="radio"
                                checked={
                                  this.state.product.status === "I"
                                    ? true
                                    : false
                                }
                                onClick={() => this.statusChange("I")}
                              />{" "}
                              Inactive
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group row">
                          <div className="control-label col-md-2">
                            <label
                              for={"description"}
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Description
                            </label>
                          </div>
                          <div className="col-md-10">
                            <textarea
                              id="description"
                              onChange={(e) =>
                                this.setState({
                                  product: {
                                    ...this.state.product,
                                    description: e.target.value,
                                  },
                                })
                              }
                              value={this.state.product.description}
                              className="form-control"
                              rows="3"
                              placeholder="Enter Description"
                              disabled={true}
                            ></textarea>
                            {this.state.productErr.description && (
                              <small className="text-danger">
                                {this.state.productErr.description}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <button
                        type="submit"
                        className="btn pull-right submit-btn"
                        style={styles.confirmButtonStyle}
                      >
                        {this.state.actiontype === "CREATE" ? "Save" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  products: state.VendorProductReducer.products,
  productmasterlist: state.VendorProductReducer.productmasterlist,
  categories: state.VendorProductReducer.category,
  updateProductError: state.VendorProductReducer.updateProductError,
  updateProductSuccess: state.VendorProductReducer.updateProductSuccess,
});
export default connect(mapStateToProps, {
  updateProductService,
  callProductMasterWebServiceForUpdate,
  productEditWebService,
  setPreviousQuantity
})(AddProduct);
