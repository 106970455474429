import React, { Component } from 'react';
import { Modal } from "react-bootstrap";
import { styles } from "../../styles/commontStyles";

class DialogModal extends Component {
    render() {
        const { visible, title, body, footer } = this.props;
        return (
            <Modal show={visible} centered>
                {title &&
                    <Modal.Header style={{ backgroundColor:'#F2F2F2' }}>
                        <Modal.Title style={styles.dialogHeaderStyle}>{title}</Modal.Title>
                    </Modal.Header>
                }
                <Modal.Body>
                    <div style={styles.dialogBodyTextStyle}>{body}</div>
                </Modal.Body>
                <Modal.Footer>
                    {footer}
                </Modal.Footer>
            </Modal>
        )
    }
}

export default DialogModal;