import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./navbar";
import Loader from "./loader";
import Footer from "./footer";
import DatePicker from "react-datepicker";
import {
  getFlightSchedule,
  updateFlightForm,
} from "../../actions/public/flightInfoAction";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "../../styles/commontStyles";
import { callUpdateOrderWebService } from "../../actions/public/updateOrderAction";
import DialogModal from "../common/dialogModal";
import moment from "moment";
import { Container, Col, Row } from "react-bootstrap";
import Select from "react-select";
import { dateFormat } from "../../actions/common/dateTime";
import { validateScheduleId } from "../../actions/public/validateScheduleId";

const formatOptionLabel = ({ value, label, scheduleId, targetDate }) => (
  <div style={{ display: "flex", borderRadius: 0, padding: 0, fontSize: 12 }}>
    <div style={{ width: "70%" }}>{label}</div>
    <div
      className={"flight-time-option"}
      style={{
        width: "30%",
        marginLeft: "10px",
        fontWeight: "bold",
        textAlign: "right",
      }}
    >
      {targetDate.substr(11, 8)}
    </div>
  </div>
);

class UpdateFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      traveldate: "",
      airline: { value: "", error: "" },
      flightdate: { value: "", error: "" },
      flighttime: "",
      startDate: new Date(),
      fromscheduleid: "",
      toscheduleid: "",
    };
  }
  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (!this.props.location.state.order) {
      this.props.history.push("/");
    }
    this.resetFlightForm();
  };

  resetError = () => {
    this.setState({
      airline: { ...this.state.airline, error: "" },
      flightdate: { ...this.state.flightdate, error: "" },
    });
  };

  resetFlightForm = () => {
    let olddate = new Date(
      this.props.location.state.order.flight.flighttime.substr(0, 11)
    );
    let now = new Date();

    const year = now.getFullYear();
    const month = now.getMonth();
    const date = now.getDate() + 1;
    this.setState({
      now: now,
      maximumdate: new Date(year, month, date),
    });
    const flightdetail = this.props.location.state.order.flight;

    this._onDateChange(olddate, flightdetail);
  };

  _onDateChange = (value, flightdetail = null) => {
    this.setState({
      startDate: value,
    });

    const dateString = `${value.getFullYear()}${value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
      }${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;

    const flight = this.props.location.state.order.flight;
    let flightData = {
      airportid: flight.airportid,
      direction: flight.direction,
      traveldate: dateString,
    };
    this.props.getFlightSchedule(flightData);
    if (flightdetail !== null) {
      this.setState({
        flighttime: flightdetail.flighttime.replace(" @ ", " "),
        airline: { ...this.state.airline, value: flightdetail.airlineid },
        flightid: flightdetail.flightid,
        flightdate: { ...this.state.flightdate, value: flightdetail.flightid },
      });
    } else {
      this.setState({
        flighttime: "",
        airline: { ...this.state.airline, value: null },
        flightid: null,
      });
    }
  };

  componentWillReceiveProps = (nextProps) => {

    if (nextProps.data.flightschedule !== null);
    {
      const schedules = nextProps.data.flightschedule;
      const flightdetail = this.props.location.state.order.flight;

      const filterschedule = schedules.filter(
        (schedule) => schedule.flightId === flightdetail.flightid
      );
      if (filterschedule?.length) {
        this.setState({
          fromscheduleid: filterschedule[0].scheduleId,
        });
      } else {
        this.setState({
          fromscheduleid: null
        })
      }

    }

    if (nextProps.status === "error") {
      if (nextProps.status !== this.props.status && nextProps.status !== null) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.error,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  onChangeAirline = (e) => {
    // return false;
    this.setState({
      airline: { ...this.state.airline, value: e.value },
      flighttime: "",
    });
  };
  onChangeFlight = (event) => {
    if (event.value === "") {
      this.setState({
        flighttime: "",
      });
    } else {
      let now = moment();
      let flightdatetime = moment(event.targetDate, "YYYY-MM-DD hh:mm A");
      let timediff = moment(flightdatetime).diff(now, "hours");

      let isFlightBeforeNow = moment(flightdatetime).isBefore(now);

      if (this.props.userDetail.userdefault.subscriber === "Y") {
        validateScheduleId({
          direction: this.props.location.state.order.flight.direction,
          scheduleid: event.scheduleId,
        }).then(
          function (value) {
            if (value.valid === "N") {
              this.setState({
                displayDialog: true,
                dialogTitle: "Oops!",
                dialogBody: value.statusMessage,
                dialogFooter: (
                  <button
                    className="btn"
                    style={styles.dialogCancelButtonStyle}
                    onClick={() => {
                      this.setState({ displayDialog: false });
                    }}
                  >
                    OK
                  </button>
                ),
              });
            } else {
              this.setState({
                flightdate: { ...this.state.flightdate, value: event.value },
                flighttime: event.targetDate,
                toscheduleid: event.scheduleId,
                flightid: event.value,
              });

              this.props.updateFlightForm([
                {
                  key: "flightId",
                  value: event.value,
                },
                {
                  key: "flighttime",
                  value: event.targetDate,
                },
                {
                  key: "scheduleId",
                  value: event.scheduleId,
                },
              ]);
            }
          }.bind(this)
        );
      }
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.airline.value === "") {
      this.setState({
        airline: { ...this.state.airline, error: "Please select an airline." },
      });
    }

    if (this.state.flightdate.value === "") {
      this.setState({
        flightdate: {
          ...this.state.flightdate,
          error: "Please select flight.",
        },
      });
    }

    if (this.state.airline.value && this.state.flightdate.value) {
      const order = this.props.location.state.order;
      let toscheduleids =
        this.state.toscheduleid != ""
          ? this.state.toscheduleid
          : this.state.fromscheduleid;

      const data = {
        actiontype: "UPDATESCHEDULE",
        orderid: order.orderid,
        fromscheduleid: this.state.fromscheduleid,
        toscheduleid: toscheduleids,
      };

      this.props.callUpdateOrderWebService(data, this.props.history);
    }
  };
  addDays = () => {
    let newDate = new Date();
    // let date = newDate.getDate() + 1;

    let date = "";
    if (this.props.userDetail.userdefault.subscriber === "Y") {
      if (this.props.formData.direction === "A") {
        let allowedarrivaldays =
          this.props.userDetail.userdefault.allowedarrivaldays;
        date = newDate.getDate() + (allowedarrivaldays - 1);
      } else {
        let alloweddeparturedays =
          this.props.userDetail.userdefault.alloweddeparturedays;
        date = newDate.getDate() + (alloweddeparturedays - 1);
      }
    }

    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    return new Date(year, month, date);
  };

  flightsFilter = () => {
    let flights = [];
    if ("flightschedule" in this.props.data) {
      this.props.data.flightschedule.map((flight, index) => {
        if (flight.airline === this.state.airline.value) {
          flights.push({
            value: flight.flightId,
            label: flight.flightId,

            scheduleId: flight.scheduleId,
            targetDate: flight.targetDate,
          });
        }
      });
    }
    return flights;
  };

  airlineFilter = () => {
    let airlines = [];

    if (Object.keys(this.props.data).length) {
      this.props.data.airlines.map((airline, index) => {
        airlines.push({
          value: airline.description,
          label: airline.description,
          airlineid: airline.airlineid,
        });
      });
    }

    return airlines;
  };

  render() {
    return (
      <>
        <NavBar />
        <Loader message="Retrieving Flights..." />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar categoryBar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
              <ul>
                <li>Flight Details</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div
          className="container-fluid"
          style={{ paddingTop: 30, paddingBottom: 100 }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={this.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="traveldate"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            Travel Date
                          </label>
                          <br></br>
                          <DatePicker
                            selected={this.state.startDate}
                            className="form-control full-width"
                            onChange={(value) => this._onDateChange(value)}
                            minDate={new Date()}
                            maxDate={this.addDays()}
                            dateFormat={dateFormat(
                              "datepicker",
                              this.props.userDetail.userdefault
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="airline"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            Airline
                          </label>
                          <Select
                            className="custom-form-control"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                            onChange={this.onChangeAirline}
                            onFocus={this.resetError}
                            options={this.airlineFilter()}
                            placeholder={"Select Airline"}
                            value={this.airlineFilter().filter(
                              (option) =>
                                option.value === this.state.airline.value
                            )}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                neutral50: "#5c6873", // Placeholder color
                              },
                            })}
                          />

                          {this.state.airline.error && (
                            <small className="text-danger">
                              {this.state.airline.error}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="flightdate"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            Flight
                          </label>
                          <Select
                            name="form-field-name"
                            formatOptionLabel={formatOptionLabel}
                            options={this.flightsFilter()}
                            onChange={this.onChangeFlight}
                            onFocus={this.resetError}
                            // value={this.state.flightId.value}
                            value={this.flightsFilter().filter(
                              (option) => option.value === this.state.flightid
                            )}
                            placeholder={"Select Flight"}
                          />
                          {this.state.flightdate.error && (
                            <small className="text-danger">
                              {this.state.flightdate.error}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label
                            htmlFor="flightime"
                            style={{ fontWeight: "bold", fontSize: 14 }}
                          >
                            Flight Time
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              this.state.flighttime === ""
                                ? ""
                                : moment(this.state.flighttime).format(
                                  dateFormat(
                                    "normaltime",
                                    this.props.userDetail.userdefault
                                  )
                                )
                            }
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                    <input
                      type="submit"
                      className="btn pull-right"
                      value="UPDATE FLIGHT DETAILS"
                      style={styles.updateflightButtonStyle}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  cartTotal: state.CartReducer.cartTotal,
  formData: state.FlightInfoReducer.formData,
  data: state.FlightInfoReducer.data,
  userDetail: state.SignInReducer.logindetails,
  status: state.UpdateOrderReducer.status,
  error: state.UpdateOrderReducer.error,
});
export default connect(mapStateToProps, {
  getFlightSchedule,
  updateFlightForm,
  callUpdateOrderWebService,
})(UpdateFlight);
