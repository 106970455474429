import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./navbar";
import Loader from "./loader";
import Footer from "./footer";
import {
  getFlightSchedule,
  updateFlightForm,
} from "../../actions/public/flightInfoAction";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateGuestFormInfo } from "../../actions/public/paymentAction";
import { Container, Col, Row } from "react-bootstrap";
import "../../styles/style.css";
class Guest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHolderName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      checked: false,
      toggle: false,
      paymentMethod: "card",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      calculatedValue: "",
      isOpen: true,
      errorMessage: {
        cardHolderName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      },
      currency: "",
      exchangerate: "",
    };
  }
  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.cartItems.length === 0) {
      this.props.history.push("/");
    }

    const guestInfo = this.props.guestInfo;
    this.setState({
      firstName: guestInfo.firstname,
      lastName: guestInfo.lastname,
      email: guestInfo.email,
      phoneNumber: guestInfo.phone,
    });
  };

  isValidEmail = (email) => {
    var expr = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    return expr.test(email);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const firstname = this.state.firstName;
    const lastname = this.state.lastName;
    const email = this.state.email;
    const phoneNumber = this.state.phoneNumber;

    let errorMessage = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    };

    if (!firstname.trim()) {
      errorMessage.firstName = "First Name is Required.";
    }
    if (!lastname.trim()) {
      errorMessage.lastName = "Last Name is Required.";
    }
    if (!email.trim()) {
      errorMessage.email = "Email is required.";
    } else {
      if (!this.isValidEmail(email)) {
        errorMessage.email = "Please enter a valid email address.";
      }
    }
    if (!phoneNumber) {
      errorMessage.phoneNumber = "Phone Number is Required.";
    }

    this.setState({ errorMessage: errorMessage });
    if (
      firstname.trim() &&
      lastname.trim() &&
      email.trim() &&
      phoneNumber.trim() &&
      this.isValidEmail(email)
    ) {
      const data = {
        firstname: this.state.firstName,
        lastname: this.state.lastName,
        email: this.state.email,
        phone: this.state.phoneNumber,
        useDefaultGuestInfo: false,
      };

      this.props.updateGuestFormInfo(data);
      this.props.history.push("/cart/checkout", { skipguestvalidation: false });
    }
  };

  firstNameChange = (e) => {
    this.setState({
      firstName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
    });
  };
  lastNameChange = (e) => {
    this.setState({
      lastName: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
    });
  };
  emailChange = (e) => {
    this.setState({
      email: e.target.value.trim(),
    });
  };
  phoneNumberChange = (e) => {
    this.setState({
      phoneNumber: e.target.value.replace(/[^0-9]/g, ""),
    });
  };

  setUseDefaultInfo = () => {
    if (this.state.useDefaultGuestInfo === true) {
      this.setState({
        useDefaultGuestInfo: false,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
      });
    } else {
      this.setState({
        useDefaultGuestInfo: true,
        firstName: this.props.userDetail.userdefault.firstname,
        lastName: this.props.userDetail.userdefault.lastname,
        email: this.props.userDetail.userdefault.email,
        phoneNumber: this.props.userDetail.userdefault.phone,
      });
    }
  };

  handleSubmitWithoutInfo = () => {
    this.setState({
      useDefaultGuestInfo: false,
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    });

    const data = {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      useDefaultGuestInfo: false,
    };
    this.props.updateGuestFormInfo(data);
    this.props.history.push("/cart/checkout", {
      skipguestvalidation: true,
    });
  };

  render() {
    if (this.props.cartItems.length === 0) {
      this.props.history.push("/");
    }

    return (
      <>
        <NavBar />
        <Loader message="Retrieving Flights..." />

        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className={"stores-container"}>
          <form onSubmit={this.handleSubmit}>
            <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div
                    style={{
                      // padding: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className={"card-head"}>
                      <h3 className="summary-title">Guest Information</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    {this.props.userDetail.userdefault.subscriber === "Y" &&
                    this.props.userDetail.internal === "N" ? (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="dafualtvalue">
                              <input
                                onChange={(e) => this.setUseDefaultInfo()}
                                id="dafualtvalue"
                                type="checkbox"
                                value={this.state.useDefaultGuestInfo}
                                name="dafualtvalue"
                                checked={this.state.useDefaultGuestInfo}
                              />
                              &nbsp; Use Subscription Details
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="firstName">First Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.firstName}
                            onChange={this.firstNameChange}
                            onFocus={this.resetError}
                            autoFocus={true}
                            placeholder={'Enter First Name'}
                          />
                          {this.state.errorMessage.firstName && (
                            <small className="text-danger">
                              {this.state.errorMessage.firstName}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="lastName">Last Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            maxLength="16"
                            value={this.state.lastName}
                            onChange={this.lastNameChange}
                            onFocus={this.resetError}
                            placeholder={'Enter Last Name'}
                          />
                          {this.state.errorMessage.lastName && (
                            <small className="text-danger">
                              {this.state.errorMessage.lastName}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="email">Email:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.email}
                            onChange={this.emailChange}
                            onFocus={this.resetError}
                            placeholder={'Enter Email'}

                          />
                          {this.state.errorMessage.email && (
                            <small className="text-danger">
                              {this.state.errorMessage.email}
                            </small>
                          )}
                          {/* <InputMask mask="99/99" className="form-control" value={this.state.expiryDate} onChange={this.expirydateChange} required /> */}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="phoneNumber">Phone Number:</label>
                          <input
                            type="text"
                            maxLength={10}
                            className="form-control"
                            maxLength="10"
                            value={this.state.phoneNumber}
                            onChange={this.phoneNumberChange}
                            onFocus={this.resetError}
                            placeholder={'Enter Phone Number'}

                          />
                          {this.state.errorMessage.phoneNumber && (
                            <small className="text-danger">
                              {this.state.errorMessage.phoneNumber}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card">
                  <div
                    style={{
                      // padding: 15,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className={"card-head "}>
                      <h3 className="summary-title">Order Summary</h3>
                    </div>
                  </div>
                  <div className="card-body">
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginBottom: 10 }}
                    >
                      <div style={{ fontSize: 14, fontWeight: "bold" }}>
                        Sub Total
                      </div>
                      <div style={{ fontSize: 14 }}>
                        ${this.props.cartTotal.toFixed(2)}
                      </div>
                    </div>
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginBottom: 10 }}
                    >
                      <div style={{ fontSize: 14, fontWeight: "bold" }}>
                        Tax
                      </div>
                      <div style={{ fontSize: 14 }}>
                        ${this.props.cartTaxTotal.toFixed(2)}
                      </div>
                    </div>
                    <div className={"seperator"}></div>
                    <div
                      className="d-flex justify-content-between"
                      style={{ marginBottom: 10 }}
                    >
                      <div style={{ fontSize: 16, fontWeight: "bold" }}>
                        Grand Total
                      </div>
                      <div style={{ fontSize: 16, fontWeight: "bold" }}>
                        $
                        {(
                          this.props.cartTotal + this.props.cartTaxTotal
                        ).toFixed(2)}
                      </div>
                    </div>
                    {this.props.userDetail.userdefault.loungeoperator === "Y" &&
                    this.props.userDetail.internal === "N" ? (
                      <>
                        <button
                          onClick={() => this.handleSubmitWithoutInfo()}
                          style={{
                          
                              backgroundColor: "#FED23D",
                              marginTop: 20,
                              fontSize: 14,
                              fontWeight: "bold",
                              color: "#333",
                              width: "45%",
                              marginRight: 15,
                            }}
                            className="btn  btn-lg"
                        >
                          SKIP
                        </button>

                        <button
                          type="submit"
                          style={{
                            backgroundColor: "#FED23D",
                            marginTop: 20,
                            fontSize: 14,
                            fontWeight: "bold",
                            color: "#333",
                            width: "50%",
                          }}
                          className="btn  btn-lg"

                        >
                          Flight Information
                        </button>
                      </>
                    ) : (
                      <button
                        type="submit"
                        style={{
                          backgroundColor: "#FED23D",
                          marginTop: 20,
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#333",
                        }}
                        className="btn btn-block btn-lg"
                      >
                        Flight Information
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  cartTotal: state.CartReducer.cartTotal,
  cartTaxTotal: state.CartReducer.cartTaxTotal,
  formData: state.FlightInfoReducer.formData,
  data: state.FlightInfoReducer.data,
  userDetail: state.SignInReducer.logindetails,
  guestInfo: state.PaymentReducer.guestInfo,
});
export default connect(mapStateToProps, {
  getFlightSchedule,
  updateFlightForm,
  updateGuestFormInfo,
})(Guest);
