import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "./navbar";
import DialogModal from "./dialogModal";
import { styles } from "../../styles/commontStyles";
import {
  changeProductQuantity,
  removeItem,
} from "../../actions/public/cartAction";
import Footer from "./footer";
import { Container, Image, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { confirmCart } from "../../actions/public/paymentAction";
import Loader from "../common/loader";
import moment from "moment";
import Fac from "../../plugins/fac";
import { loaderStatus } from "../../actions/vendor/loaderAction";
import { processTransaction } from "../../actions/common/OrderAction";
import { processCard } from "../../actions/common/ProcessCardAction";
import { dateFormat } from "../../actions/common/dateTime";

class OrderReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  _changeProductQuantity = (productid, quantity, maxquantity) => {
    if (quantity > maxquantity) {
      alert(`Only ${maxquantity} item left in stock`);
    } else {
      if (quantity >= 1) {
        this.props.changeProductQuantity(productid, quantity, maxquantity);
      }
    }
  };
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  removeItemFromCart = (item) => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this item?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.closeDialog();
              this.props.removeItem(item);
            }}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  filterCartItems = (cartProducts) => {
    let filtercart = [];
    cartProducts.forEach((cartproduct) => {
      this.props.stores.forEach((store) => {
        if (store.storeid === cartproduct.vendorid) {
          store.categories.forEach((category) => {
            category.products.forEach((product) => {
              if (product.siblings) {
                product.siblings.forEach((sibling) => {
                  if (sibling.productid === cartproduct.productid) {
                    filtercart.push({
                      ...sibling,
                      quantityincart: cartproduct.quantityincart,
                      total: cartproduct.total,
                    });
                  }
                });
              } else {
                if (product.productid === cartproduct.productid) {
                  filtercart.push({
                    ...product,
                    quantityincart: cartproduct.quantityincart,
                    total: cartproduct.total,
                  });
                }
              }
            });
          });
        }
      });
    });
    return filtercart;
  };

  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  paymentSubmit = async () => {
    const PaymentGatewayDetail = JSON.parse(
      localStorage.getItem("PaymentGatewayDetail")
    );
    if (PaymentGatewayDetail.paymentgateway === "FAC") {
      const FAC_CONFIG = {
        merchantid: PaymentGatewayDetail.merchantid,
        password: PaymentGatewayDetail.password,
        endpoint: PaymentGatewayDetail.endpointpath,
        acquireid: PaymentGatewayDetail.acquirerid,
        currency_code: PaymentGatewayDetail.currencycode,
        transacton_code: PaymentGatewayDetail.transactioncode,
        redirecturl: PaymentGatewayDetail.redirecturl,
        endpointpath: PaymentGatewayDetail.endpointpath,
      };

      this.props.loaderStatus(true);

      let cartTotal = this.props.cartTotal + this.props.cartTaxTotal;

      //Request body for processtransaction api
      let processTransactionRequestBody = {
        transactiontype: "RESERVECART",
        source: "AIR",
        reservation: {
          direction: this.props.location.state.request.direction,
          scheduleid: this.props.location.state.request.scheduleId,
          cart: this.props.location.state.request.cart.map((item, index) => {
            var cartItem = { ...item };
            delete cartItem.vendorname;
            return cartItem;
          }),
          walkin: this.props.location.state.request.walkin ? "Y" : "N",
        },
        amount: cartTotal.toFixed(2),
        reset: "N"
      };

      let processTransactionResponse = await processTransaction(
        processTransactionRequestBody
      );


      if (!processTransactionResponse.success) {
        this.props.loaderStatus(false);
        this.setState({
          displayDialog: true,
          dialogTitle: "Alert!",
          dialogBody: processTransactionResponse.statusMessage,
          dialogFooter: (
            <>
              <button
                className="btn"
                style={styles.dialogConfirmButtonStyle}
                onClick={() => {
                  this.closeDialog();
                }}
              >
                Ok
              </button>
            </>
          ),
        });
      } else {
        const { cardInfo, cartTotal } = this.props.location.state.request;
        const fac = new Fac(FAC_CONFIG);

        const transactionStatusCheck = await processCard({
          orderid: processTransactionResponse.data.orderid,
          actiontype: "TRANSACTIONSTATUS",
        });


        if (transactionStatusCheck.data.reasoncode !== "1") {

          processTransactionRequestBody.reset = "Y";

          processTransactionResponse = await processTransaction(
            processTransactionRequestBody
          );

          if (!processTransactionResponse.success) {
            this.setState({
              displayDialog: true,
              dialogTitle: "Alert!",
              dialogBody: processTransactionResponse.statusMessage,
              dialogFooter: (
                <>
                  <button
                    className="btn"
                    style={styles.dialogConfirmButtonStyle}
                    onClick={() => {
                      this.closeDialog();
                    }}
                  >
                    Ok
                  </button>
                </>
              ),
            });
            return;
          }


          let processCardRequestBody = {
            orderid: processTransactionResponse.data.orderid,
            // orderid: "AIRS05",
            actiontype: "HOLDFUNDS",
            transactioncode: 0,
            creditcard: {
              cardtype: "VISA",
              cardnumber: cardInfo.cardnumber,
              cardholder: cardInfo.cardholder,
              expirydate: cardInfo.expirydate,
              cvv: cardInfo.cvv,
              amount: cartTotal.toFixed(2),
            },
          };

          const processCardResponse = await processCard(processCardRequestBody);
          if (processCardResponse.success) {
            const response = await fac.process(
              processCardResponse.data.html,
              processTransactionResponse.data.orderid
            );

            this.props.loaderStatus(false);

            if (response.data.reasoncode == 1) {
              let request = this.props.location.state.request;
              request = {
                ...request,
                transactionCode: 0,
                paymentResponse: response.data,
              };
              this.props.confirmCart(request, this.props.history);
            } else {
              this.props.loaderStatus(false);
              //FAC Failed so we call processtransaction with 'transactiontype = CANCELCART'
              let cancelCartResponse = await processTransaction({
                transactiontype: "CANCELCART",
              });

              let errorMessage = response.data.reasondescription
                ? response.data.reasondescription
                : response.statusMessage;

              if (errorMessage !== "Window is closed") {
                this.props.history.push("/order/payment/failed", {
                  walkin: "N",
                  amount: cartTotal.toFixed(2),
                });

                this.setState({
                  displayDialog: true,
                  dialogTitle: "Alert!",
                  dialogBody: errorMessage,
                  dialogFooter: (
                    <>
                      <button
                        className="btn"
                        style={styles.dialogConfirmButtonStyle}
                        onClick={() => {
                          this.closeDialog();
                        }}
                      >
                        Ok
                      </button>
                    </>
                  ),
                });
              }

              if (!cancelCartResponse.success) {
                this.setState({
                  displayDialog: true,
                  dialogTitle: "Alert!",
                  dialogBody: cancelCartResponse.statusMessage,
                  dialogFooter: (
                    <>
                      <button
                        className="btn"
                        style={styles.dialogConfirmButtonStyle}
                        onClick={() => {
                          this.closeDialog();
                        }}
                      >
                        Ok
                      </button>
                    </>
                  ),
                });
              }
            }
          } else {
            this.props.loaderStatus(false);
            //FAC Failed so we call processtransaction with 'transactiontype = CANCELCART'

            if (!processCardResponse.success) {
              this.setState({
                displayDialog: true,
                dialogTitle: "Alert!",
                dialogBody: processCardResponse.statusMessage,
                dialogFooter: (
                  <>
                    <button
                      className="btn"
                      style={styles.dialogConfirmButtonStyle}
                      onClick={() => {
                        this.closeDialog();
                      }}
                    >
                      Ok
                    </button>
                  </>
                ),
              });
            }

            let cancelCartResponse = await processTransaction({
              transactiontype: "CANCELCART",
            });
          }
        } else {



          if (processTransactionResponse.data.amount === cartTotal) {
            if (transactionStatusCheck.data.reasoncode == 1) {
              let request = this.props.location.state.request;
              request = {
                ...request,
                transactionCode: 0,
                paymentResponse: transactionStatusCheck.data,
              };
              this.props.confirmCart(request, this.props.history);
            }
          }
          else {
            this.props.loaderStatus(false);
            this.setState({
              displayDialog: true,
              dialogTitle: "Alert!",
              dialogBody: "The amount authorized does not match with the amount, please contact support.",
              dialogFooter: (
                <>
                  <button
                    className="btn"
                    style={styles.dialogConfirmButtonStyle}
                    onClick={() => {
                      this.closeDialog();
                    }}
                  >
                    Ok
                  </button>
                </>
              ),
            });

          }




          // else {
          //   this.props.loaderStatus(false);
          //   //FAC Failed so we call processtransaction with 'transactiontype = CANCELCART'
          //   let cancelCartResponse = await processTransaction({
          //     transactiontype: "CANCELCART",
          //   });

          //   let errorMessage = transactionStatusCheck.data.reasondescription
          //     ? transactionStatusCheck.data.reasondescription
          //     : transactionStatusCheck.statusMessage;

          //   if (errorMessage !== "Window is closed") {
          //     this.props.history.push("/order/payment/failed", {
          //       walkin: "N",
          //       amount: cartTotal.toFixed(2),
          //     });

          //     this.setState({
          //       displayDialog: true,
          //       dialogTitle: "Alert!",
          //       dialogBody: errorMessage,
          //       dialogFooter: (
          //         <>
          //           <button
          //             className="btn"
          //             style={styles.dialogConfirmButtonStyle}
          //             onClick={() => {
          //               this.closeDialog();
          //             }}
          //           >
          //             Ok
          //           </button>
          //         </>
          //       ),
          //     });
          //   }

          //   if (!cancelCartResponse.success) {
          //     this.setState({
          //       displayDialog: true,
          //       dialogTitle: "Alert!",
          //       dialogBody: cancelCartResponse.statusMessage,
          //       dialogFooter: (
          //         <>
          //           <button
          //             className="btn"
          //             style={styles.dialogConfirmButtonStyle}
          //             onClick={() => {
          //               this.closeDialog();
          //             }}
          //           >
          //             Ok
          //           </button>
          //         </>
          //       ),
          //     });
          //   }
          // }
        }
      }
    } else {
      const request = this.props.location.state.request;
      this.props.confirmCart(request, this.props.history);
    }
  };

  render() {
    let request = this.props.location.state.request;

    return (
      <>
        <NavBar />
        <Loader message="Processing..." />
        <Container className="back-bar-container" fluid>
          <Row className={" categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>{/* <a className="categoryMenu">Stores</a> */}</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <div className="container-fluid" className="stores-container">
          <div className="row justify-content-center">
            {this.props.cartItems.length > 0 ? (
              <>
                <div className="col-md-8">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="Review_Your_Order_text">
                        <h3 className="summary-title">Review Your Order</h3>
                      </div>
                      <div className="row" style={{ padding: 15 }}>
                        <div className="col-md-6">
                          <div>
                            <h5 className={"order-review-h5"}>
                              Flight Information:
                            </h5>
                          </div>
                          <table width="100%">
                            <thead>
                              <tr>
                                <th>
                                  <span className="FS14">Name</span>
                                </th>
                                <td>
                                  <span className="FS14 TTC">
                                    {this.props.guestInfo.firstname +
                                      " " +
                                      this.props.guestInfo.lastname}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>
                                  <span className="FS14">Email</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    {this.props.guestInfo.email}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="FS14">Contact No</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    {this.props.guestInfo.phone}
                                  </span>
                                </td>
                              </tr>

                              <tr>
                                <th>
                                  <span className="FS14">Airline</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    {this.props.formData.airline}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="FS14">Flight Id</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    {this.props.formData.flightId}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="FS14">Flight Time</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    {moment(
                                      this.props.formData.flighttime
                                    ).format(
                                      dateFormat(
                                        "normaldatetime",
                                        this.props.userDetail.userdefault
                                      )
                                    )}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-6">
                          <div>
                            <h5 className={"order-review-h5"}>
                              Payment Details:
                            </h5>
                          </div>
                          <table width="100%">
                            <thead>
                              <tr>
                                <th>
                                  <span className="FS14">Card Holder Name</span>
                                </th>
                                <td>
                                  <span className="FS14 TTC">
                                    {request.cardInfo.cardholder}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="FS14">Card Type</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    {request.cardInfo.cardtype.toUpperCase()}
                                  </span>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>
                                  <span className="FS14">Card Number</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    ************
                                    {request.cardInfo.cardnumber.substring(
                                      request.cardInfo.cardnumber.length - 4
                                    )}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  <span className="FS14">Expiry Date</span>
                                </th>
                                <td>
                                  <span className="FS14">
                                    {request.cardInfo.expirydate.substr(0, 2)}/
                                    {request.cardInfo.expirydate.substr(2, 2)}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12 FL MT20">
                    <div className="card">
                      <div className="order_items_text">
                        <h3 className="summary-title">Order Items</h3>
                      </div>
                      <div className="card-body">
                        {this.filterCartItems(this.props.cartItems).map(
                          (item, index) => (
                            <Container
                              key={index}
                              className="card-body-container"
                              style={{ marginBottom: 20 }}
                            >
                              <div style={{ flex: 0.3, fontSize: 14 }}>
                                <Image
                                  style={{ height: 130, width: "auto" }}
                                  resizemode="contain"
                                  src={
                                    APPLICATION_CONST.productImageUrl +
                                    item.productreferenceid +
                                    ".png" +
                                    "?" +
                                    new Date()
                                  }
                                />
                              </div>
                              <div style={{ flex: 0.3, fontSize: 14 }}>
                                <div className="FS14 FWB">
                                  {item.productname}
                                </div>
                                <div style={{ fontSize: 13 }}>
                                  ${parseFloat(item.unitcost).toFixed(2)}
                                </div>
                                <div style={{ marginTop: 10 }}>
                                  <span style={{ fontWeight: "bold" }}>
                                    Quantity
                                  </span>
                                  <span
                                    style={{ marginLeft: 5, marginRight: 5 }}
                                  >
                                    {" "}
                                    {item.quantityincart}{" "}
                                  </span>
                                </div>
                              </div>

                              <div
                                style={{
                                  flex: 0.2,
                                  fontSize: 14,
                                  textAlign: "right",
                                  marginRight: 20,
                                }}
                              >
                                <div
                                  style={{
                                    flex: 0.2,
                                    marginBottom: 20,
                                    fontSize: 16,
                                  }}
                                >
                                  $
                                  {parseFloat(
                                    item.unitcost * item.quantityincart
                                  ).toFixed(2)}
                                </div>
                              </div>
                            </Container>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="card">
                    <div
                      style={{
                        padding: 15,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: 18,
                          color: "#555",
                        }}
                      >
                        <h3 className="summary-title">Order Summary</h3>
                      </div>
                    </div>
                    <div className="card-body">
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginBottom: 10 }}
                      >
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>
                          Sub Total
                        </div>
                        <div style={{ fontSize: 14 }}>
                          ${this.props.cartTotal.toFixed(2)}
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginBottom: 10 }}
                      >
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>
                          Tax
                        </div>
                        <div style={{ fontSize: 14 }}>
                          ${this.props.cartTaxTotal.toFixed(2)}
                        </div>
                      </div>
                      <div className="seperator"></div>
                      <div
                        className="d-flex justify-content-between"
                        style={{ marginBottom: 10 }}
                      >
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          Grand Total
                        </div>
                        <div style={{ fontSize: 16, fontWeight: "bold" }}>
                          $
                          {(
                            this.props.cartTotal + this.props.cartTaxTotal
                          ).toFixed(2)}
                        </div>
                      </div>
                      <button
                        style={{
                          backgroundColor: "#FED23D",
                          marginTop: 20,
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#333",
                          width: "45%",
                          marginRight: 15,
                        }}
                        className="btn  btn-lg"
                        onClick={() => this.props.history.push("/cart/")}
                      >
                        Edit
                      </button>
                      <button
                        style={{
                          backgroundColor: "#FED23D",
                          marginTop: 20,
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "#333",
                          width: "50%",
                        }}
                        className="btn   btn-lg"
                        onClick={() => this.paymentSubmit()}
                      >
                        Pay Now
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center">
                <img
                  src={APPLICATION_CONST.emptyCartImage}
                  height="200"
                  alt="empty-cart"
                />
                <h3>Your cart is empty!</h3>
                <p>Looks like you haven't made your choice yet</p>
              </div>
            )}
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.CartReducer.cart,
  cartTotal: state.CartReducer.cartTotal,
  cartTaxTotal: state.CartReducer.cartTaxTotal,
  stores: state.StoreReducer.stores,
  userDetail: state.SignInReducer.logindetails,
  formData: state.FlightInfoReducer.formData,
  guestInfo: state.PaymentReducer.guestInfo,
});

export default connect(mapStateToProps, {
  changeProductQuantity,
  removeItem,
  confirmCart,
  loaderStatus,
})(OrderReview);
