import React from "react";
import { Nav, OverlayTrigger } from "react-bootstrap";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { Popover, Table } from "react-bootstrap";
import { VIPER_CONST } from "../../Config/CustomConfig";
import { styles } from "../../styles/commontStyles";
import axios from 'axios';

class BookingPriceList extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      Params: []
    }
  }

  componentWillMount() {
    let body = {
      username: VIPER_CONST.alwaysOnUsername,
      sessionid: VIPER_CONST.alwaysOnSessionid,
      request: {
      },
    };

    axios({
      method: "post",
      url: `${VIPER_CONST.url}getmarkets`,
      data: body,
    })
      .then((response) => {
        const params = response.data.data.markets?.filter(m => m.marketid === "JAM").map(e => e.pricelist);
        this.setState({ Params: params[0] })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Nav style={styles.navLinkStyle}>
        <OverlayTrigger
          key={"bottom"}
          placement={"bottom"}
          overlay={<Popover id="popover-basic" className={"booking-price-list-popover"}>
            <Popover.Title
              as="h3"
              style={{ fontWeight: "bold", color: "blue", padding: 15 }}
            >
              Price Details (USD)
            </Popover.Title>
            <Popover.Content>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Departures</th>
                    <th style={{ borderRight: 0 }}>Adult</th>
                    <th style={{ borderLeft: 0 }}>Child</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.Params?.filter(item => item.productid === 'DEPARTURELOUNGE').map(item => <tr key={item.lounge}>
                      <td>{item.lounge}</td>
                      <td style={{ borderRight: 0 }}>US ${item.adultrate}</td>
                      <td style={{ borderLeft: 0 }}>US ${item.childrate}</td>
                    </tr>)
                  }
                  <tr>
                    <th colSpan="3">Arrivals</th>
                  </tr>
                  {
                    this.state.Params?.filter(item => item.productid === 'ARRIVALONLY').map(item => <tr key={item.lounge}>
                      <td>{item.lounge}</td>
                      <td style={{ borderRight: 0 }}>US ${item.adultrate}</td>
                      <td style={{ borderLeft: 0 }}>US ${item.childrate}</td>
                    </tr>)
                  }
                  <tr>
                    <th colSpan="3">Arrivals &amp; Departures</th>
                  </tr>
                  {
                    this.state.Params?.filter(item => item.productid === 'ARRIVALBUNDLE').map(item => <tr key={item.lounge}>
                      <td>{item.lounge}</td>
                      <td style={{ borderRight: 0 }}>US ${item.adultrate}</td>
                      <td style={{ borderLeft: 0 }}>US ${item.childrate}</td>
                    </tr>)
                  }
                </tbody>
              </Table>
            </Popover.Content>
          </Popover>}
          trigger={['hover', 'focus']}
        // delay={{ show: 10, hide: 5000 }}
        >
          <a
            href={`https://booking.vacationpriorities.com/affiliate/${APPLICATION_CONST.affiliatedId}`}
            style={
              {
                // color:"#FFF",
                // backgroundColor:"red",
                // textAlign:"center",
                // textDecoration:"none",
                // padding:"5px",
                // paddingTop:"9px",
                // borderRadius:"2px",
                // borderWidth:"1px solid #efefef"
              }
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt=""
              src={`${APPLICATION_CONST.vendorButtonImageUrl}booknow.png`}
            />
          </a>
        </OverlayTrigger>
      </Nav>
    );
  }
}

export default BookingPriceList;

