import { SET_IS_AUTHENTICATED } from "./actionTypes";
import { emptyCart } from "../public/cartAction";

export const signOut = (history) => {
  localStorage.clear();
  return (dispatch) => {
    dispatch(emptyCart());
    dispatch({ type: SET_IS_AUTHENTICATED, payload: false });
   };
};
