import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import { orderService } from "../../actions/vendor/orderAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { orderEdit } from "../../actions/vendor/orderProcessAction";
import QrReader from "react-qr-reader";
import Modal from "react-bootstrap/Modal";
import DialogModal from "../common/dialogModal";
import { Col, Row, Container } from "react-bootstrap";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { dateFormat } from "../../actions/common/dateTime";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      isOpen: false,
      result: null,
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.props.location.state) {
      const data = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        actiontype: this.props.location.state.actiontype,
      };
      this.props.orderService(data);
    } else {
      this.props.history.push("/");
    }
  };

  componentDidUpdate(prevProps, prevState) {
    const orderType = this.props.location.state.actiontype;

    if (prevState.result !== this.state.result) {
      if (this.state.result !== null) {
        let filterOrder;

        if (orderType === "CHECKIN") {
          let scanArr = this.state.result.split("|");
          filterOrder = this.props.orders.filter(
            (d) => d.orderid === scanArr[0] && d.assignedto === scanArr[1]
          );
        } else {
          if (this.state.result.includes("|") === true) {
            let scanArr = this.state.result.split("|");
            filterOrder = this.props.orders.filter(
              (d) => d.orderid === scanArr[0]
            );
          } else {
            filterOrder = this.props.orders.filter(
              (d) => d.orderid === this.state.result
            );
          }
        }
        if (filterOrder.length > 0) {
          this._gotoOrderDetail(filterOrder[0]);
        } else {
          this.setState({
            displayDialog: true,
            dialogTitle: "Oops!",
            dialogBody: "Invalid QR Code Scan.",
            dialogFooter: (
              <>
                <button
                  className="btn"
                  style={styles.dialogCancelButtonStyle}
                  onClick={() => {
                    this.setState({ displayDialog: false });
                  }}
                >
                  OK
                </button>
              </>
            ),
          });
        }
      }
    }
  }

  pageTitle = () => {
    const orderType = this.props.location.state.actiontype;
    const cancelrequest = this.props.location.state.cancelrequest;
    const subaction = this.props.location.state.subaction;

    let userdetails = this.props.logindetails;

    if (orderType === "FULFILLED" && !cancelrequest) {
      return "Assign Orders";
    } else if (orderType === "MYASSIGNEDFULFILLMENT" && !cancelrequest) {
      return "Confirm Assignment";
    } else if (
      orderType === "ASSIGNEDRETURNS" &&
      subaction !== "cancelrefund"
    ) {
      return "Confirm Return";
    } else if (orderType === "CONFIRMEDRETURNS") {
      return "Check In Return";
    } else if (orderType === "MYCONFIRMEDFULFILLMENT") {
      return "My Confirmed Orders";
    } else if (orderType === "UNPROCESSED") {
      return "Fulfill Orders";
    } else if (orderType === "CHECKIN" && !cancelrequest) {
      return "Check In";
    } else if (orderType === "CHECKEDINORDERS") {
      return "Check Out";
    } else if (orderType === "CHECKEDOUTORDERS") {
      return "Return";
    } else if (
      orderType === "CANCELFULFILLED" &&
      cancelrequest &&
      userdetails.userdefault.express === "Y" &&
      userdetails.userdefault.fulfillmentallowed === "Y"
    ) {
      return "Cancel Fulfillment";
    } else if (
      orderType === "CANCELFULFILLED" &&
      cancelrequest &&
      userdetails.userdefault.fulfillmentallowed === "Y"
    ) {
      return "Cancel Fulfillment";
    } else if (
      orderType === "CANCELASSIGNED" &&
      cancelrequest &&
      userdetails.userdefault.assignmentallowed === "Y"
    ) {
      return "Cancel Assignment";
    } else if (orderType === "CANCELCONFIRMED" && cancelrequest) {
      return "Cancel Confirmation";
    } else if (orderType === "REFUNDABLEORDERS") {
      return "Refund Orders";
    } else if (orderType === "REPRINTABLERECEIPTS") {
      return "Reprint Receipts";
    } else if (
      orderType === "ASSIGNEDRETURNS" &&
      subaction === "cancelrefund"
    ) {
      return "Cancel Return";
    } else if (orderType === "CANCELRETURNS") {
      return "Cancel Return";
    } else if (
      orderType === "CANCELFULFILLED" &&
      subaction === "cancelfulfillment"
    ) {
      return "Cancel Fulfillment";
    } else if (orderType === "CANCELCHECKIN") {
      return "Cancel Check In";
    }
  };

  _gotoOrderDetail = (item) => {
    let result = item;
    let processAction = this.props.location.state.actiontype;

 
    if (processAction === "REFUNDABLEORDERS") {
      result.orderitems.forEach((item) => {
        item.appliedamount = 0;
      });
    } else {
      result.orderitems.forEach((item) => {
        item.display_appliedamount = item.appliedamount;
        if (item.minamount > 0) {
          if (processAction === "UNPROCESSED") {
            item.appliedamount = 0;
          } else {
            item.appliedamount = item.minamount;
          }
        }
        if (item.minamount === 0 && item.maxamount === 0) {
          item.appliedamount = 0;
        }
        if (item.appliedamount > item.maxamount) {
          item.appliedamount = item.maxamount;
        }

        if (item.minamount === 0) {
          item.appliedamount = 0;
        }
      });
    }
    console.log('result',result);
    
     this.props.orderEdit({
      order: result,
    });

    this.props.history.push(`/order/detail`, {
      actiontype: this.props.location.state.actiontype,
      order: result,
      cancelrequest: this.props.location.state.cancelrequest
        ? this.props.location.state.cancelrequest
        : false,
      subaction: this.props.location.state.subaction,
    });
  };

  calcTotal = (orderItems) => {
    let total = 0;
    orderItems.forEach((item) => {
      let amount = item.orderedamount * item.unitcost;
      let tax = amount * item.taxrate;
      total += amount + tax;
    });
    return total;
  };

  scanQRCode = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  hideModal = () => {
    // setIsOpen(true);
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  handleScan = (data) => {
    if (data) {
      this.setState({
        result: data,
      });
    }
  };

  handleError = (err) => {
    console.error(err);
  };

  goBack = () => {
    const orderType = this.props.location.state.actiontype;
    const cancelrequest = this.props.location.state.cancelrequest;
    const subaction = this.props.location.state.subaction;

    let userdetails = this.props.logindetails;

    if (orderType === "FULFILLED" && !cancelrequest) {
      this.props.history.push("/");
    } else if (orderType === "MYASSIGNEDFULFILLMENT" && !cancelrequest) {
      this.props.history.push("/");

    } else if (
      orderType === "ASSIGNEDRETURNS" &&
      subaction !== "cancelrefund"
    ) {
      this.props.history.push("/");

    } else if (orderType === "CONFIRMEDRETURNS") {
      this.props.history.push("/");

    } else if (orderType === "MYCONFIRMEDFULFILLMENT") {
      this.props.history.push("/");

    } else if (orderType === "UNPROCESSED") {
      this.props.history.push("/");

    } else if (orderType === "CHECKIN" && !cancelrequest) {
      this.props.history.push("/");

    } else if (orderType === "CHECKEDINORDERS") {
      this.props.history.push("/");

    } else if (orderType === "CHECKEDOUTORDERS") {
      this.props.history.push("/");

    } else if (
      orderType === "CANCELFULFILLED" &&
      cancelrequest &&
      userdetails.userdefault.express === "Y" &&
      userdetails.userdefault.fulfillmentallowed === "Y"
    ) {
      this.props.history.push("/CancelRequest");

    } else if (
      orderType === "CANCELFULFILLED" &&
      cancelrequest &&
      userdetails.userdefault.fulfillmentallowed === "Y"
    ) {
      this.props.history.push("/CancelRequest");

    } else if (
      orderType === "CANCELASSIGNED" &&
      cancelrequest &&
      userdetails.userdefault.assignmentallowed === "Y"
    ) {
      this.props.history.push("/CancelRequest");

    } else if (orderType === "CANCELCONFIRMED" && cancelrequest) {
      this.props.history.push("/CancelRequest");

    } else if (orderType === "REFUNDABLEORDERS") {
      this.props.history.push("/admin");

    } else if (orderType === "REPRINTABLERECEIPTS") {
      this.props.history.push("/admin");

    } else if (
      orderType === "ASSIGNEDRETURNS" &&
      subaction === "cancelrefund"
    ) {
      this.props.history.push("/CancelRequest");

    } else if (orderType === "CANCELRETURNS") {
      this.props.history.push("/admin");

    } else if (
      orderType === "CANCELFULFILLED" &&
      subaction === "cancelfulfillment"
    ) {
      this.props.history.push("/admin");

    } else if (orderType === "CANCELCHECKIN") {
      this.props.history.push("/admin");

    }
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.orderFilter();
      }
    );
  };

  orderFilter() {
    let product = this.props.orders;
    let products = [...product];
    let slice = products.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    let data = {
      slice: slice,
      products: products,
    };

    return data;
  }

  render() {
    let actiontype = this.props.location.state.actiontype;

    console.log('ORDERS',JSON.stringify(this.props.orders));
    if (!this.props.location.state) return null;
    return (
      <>
        <NavBar />
        <Loader message="Retrieving Orders..." />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar categoryBar"}>
            <Col lg="12" md="12">
              <a className={"back-link"} onClick={() => this.goBack()}>
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
              <ul>
                <li>{this.pageTitle()}</li>
              </ul>
              {(actiontype === "CHECKIN" ||
                actiontype === "CHECKEDINORDERS" ||
                actiontype === "REFUNDABLEORDERS" ||
                actiontype === "CONFIRMEDRETURNS" ||
                actiontype === "ASSIGNEDRETURNS" ||
                actiontype === "CHECKEDOUTORDERS" ||
                actiontype === "UNPROCESSED" ||
                actiontype === "CANCELRETURNS" ||
                actiontype === "REPRINTABLERECEIPTS" ||
                actiontype === "CANCELCONFIRMED" ||
                actiontype === "CANCELFULFILLED" ||
                actiontype === "CANCELCHECKIN") &&
                this.props.orders.length > 0 && (
                  <a
                    className="btn btn-success qr-code-scan-btn"
                    onClick={() => this.scanQRCode()}
                  >
                    <FontAwesomeIcon
                      icon="barcode"
                      color="#FFF"
                      style={{ marginRight: 5 }}
                    />
                    Scan Order
                  </a>
                )}
            </Col>
          </Row>
        </Container>

        {this.props.loadingState === false ? (
          this.props.orders.length > 0 ? (
            <div className="container-fluid stores-container">
              {this.orderFilter().slice.map((order, index) => (
                <div
                  key={index}
                  style={{
                    backgroundColor: "#FFF",
                    marginBottom: 10,
                    border: "1px solid #E1E1E1",
                    minHeight: 150,
                    borderRadius: 5,
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      backgroundColor: "#EEE",
                      padding: 15,
                    }}
                  >
                    {/* <div>
                      <div
                        style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                      >
                        ORDER PLACED
                      </div>
                      <div style={{ fontSize: 13, fontFamily: "Poppins-Light" }}>
                        March 10, 2020
                      </div>
                    </div> */}
                    <div>
                      <div
                        style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                      >
                        ORDER NUMBER
                      </div>
                      <div
                        style={{
                          fontSize: 16,
                          fontFamily: "Poppins-Light",
                          textAlign: "left",
                        }}
                      >
                        {order.orderid}
                      </div>
                    </div>
                    <div>
                      <div
                        style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                      >
                        CUSTOMER
                      </div>
                      <div
                        style={{ fontSize: 13, fontFamily: "Poppins-Light" }}
                      >
                        {order.guest.firstname} {order.guest.lastname}
                      </div>
                    </div>

                    <div>
                      <div
                        style={{
                          fontSize: 12,
                          fontFamily: "Poppins-Light",
                          textAlign: "right",
                          color: "#FFF",
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: "#39304A",
                            padding: 5,
                            borderRadius: 2,
                          }}
                        >
                          {order.status}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: 20,
                    }}
                  >
                    <div>
                      <div>
                        <div
                          style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                        >
                          FLIGHT INFO
                        </div>
                        <div
                          style={{ fontSize: 14, fontFamily: "Poppins-Light" }}
                        >
                          {moment(order.flight.flighttime).format(
                            dateFormat(
                              "normaldatetime",
                              this.props.logindetails.userdefault
                            )
                          )}
                        </div>
                        <div
                          style={{ fontSize: 13, fontFamily: "Poppins-Light" }}
                        >
                          {order.flight.airlineid}
                          <span
                            style={{
                              fontFamily: "Poppins-Light",
                              marginLeft: 5,
                            }}
                          >
                            {order.flight.flightid}
                          </span>
                        </div>
                      </div>
                    </div>

                    {actiontype === "CONFIRMEDRETURNS" ||
                    actiontype === "CHECKIN" ||
                    actiontype === "ASSIGNEDFULFILLMENT" ||
                    actiontype === "ASSIGNEDRETURNS" ||
                    actiontype === "CANCELRETURNS" ||
                    actiontype === "REPRINTABLERECEIPTS" ||
                    actiontype === "CANCELCHECKIN" ||
                    actiontype === "CONFIRMEDFULFILLMENT" ? (
                      <div>
                        <div>
                          <div
                            style={{
                              fontSize: 11,
                              fontFamily: "Poppins-Light",
                            }}
                          >
                            Assigned To:
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: "Poppins-Light",
                              textAlign: "right",
                            }}
                          >
                            {order.assignedname}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {actiontype === "CONFIRMEDFULFILLMENT" ? (
                      <div>
                        <div>
                          <div
                            style={{
                              fontSize: 11,
                              fontFamily: "Poppins-Light",
                            }}
                          >
                            Vendor
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: "Poppins-Light",
                              textAlign: "right",
                            }}
                          >
                            {order.orderitems.length}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <div>
                        <div
                          style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                        >
                          ITEMS
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            fontFamily: "Poppins-Light",
                            textAlign: "right",
                          }}
                        >
                          {order.orderitems.length}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <div
                          style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                        >
                          TOTAL
                        </div>
                        <div
                          style={{
                            fontSize: 14,
                            fontFamily: "Poppins-Light",
                            textAlign: "right",
                          }}
                        >
                          $
                          {parseFloat(this.calcTotal(order.orderitems)).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{ fontSize: 13, fontFamily: "Poppins-Light" }}
                      >
                        <button
                          className="btn btn-sm"
                          style={{
                            fontSize: 10,
                            backgroundColor: "#455A64",
                            color: "#FFF",
                          }}
                          onClick={
                            () => this._gotoOrderDetail(order)
                            // this.props.history.push(`/order/detail`, {
                            //   actiontype: this.props.location.state.actiontype,
                            //   order: order,
                            //   cancelrequest: this.props.location.state
                            //     .cancelrequest,
                            // })
                          }
                        >
                          VIEW DETAILS
                          <FontAwesomeIcon
                            icon="chevron-right"
                            color="#FFF"
                            style={{ marginLeft: 20 }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="col-md-12">
                <ReactPaginate
                  previousLabel={"Prev"}
                  nextLabel={"Next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={Math.ceil(
                    this.props.orders.length / this.state.perPage
                  )}
                  marginPagesDisplayed={5}
                  pageRangeDisplayed={10}
                  onPageChange={this.handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
              <div className="spacer"></div>
            </div>
          ) : (
            <div
              className="container-fluid stores-container"
              style={{
                fontWeight: "bold",
                fontSize: 30,
                color: "#999",
                textAlign: "center",
                paddingTop: 150,
                paddingBottom: 150,
              }}
            >
              No Orders Currently available for Processing
              <div className="spacer"></div>
            </div>
          )
        ) : null}

        {/* ******** Categories Modal */}
        <Modal
          centered
          show={this.state.isOpen}
          onHide={this.hideModal}
          size="xs"
        >
          <Modal.Header closeButton style={{ backgroundColor: "#F2F2F2" }}>
            <Modal.Title style={styles.dialogHeaderStyle}>
              Scan Order
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              "max-height": "calc(100vh - 210px)",
              "overflow-y": "auto",
            }}
          >
            <div className="row">
              <div className="col-md-12">
                <QrReader
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: "100%" }}
                />
                <p>{this.state.result}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.hideModal}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {/* ******** Categories Modal End*/}

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.VendorOrderReducer.orders,
  loadingState: state.VendorOrderReducer.loading,
  logindetails: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps, { orderService, orderEdit })(Orders);
