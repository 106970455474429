import {
    USER_SERVICE_SUCCESS,
    USER_SERVICE_PENDING,
    USER_SERVICE_ERROR,
    EDIT_USER_SERVICE_ERROR,
    EDIT_USER_SERVICE_PENDING,
    EDIT_USER_SERVICE_SUCCESS,
} from '../../actions/vendor/actionTypes';

const initialState = {
    users: [],
    userServiceError: null,
    userServiceSuccess: null
}

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        /*****************get user reducer****************/
        case USER_SERVICE_PENDING:
            return {
                ...state,
                userServiceError: null,
                userServiceSuccess: null
            }
        case USER_SERVICE_SUCCESS:
            return {
                ...state,
                users: action.payload.users,
            }
        case USER_SERVICE_ERROR:
            return {
                ...state,
                userServiceError: action.payload,
                userServiceSuccess: null
            }
        case EDIT_USER_SERVICE_PENDING:
            return {
                ...state,
                userServiceError: null,
                userServiceSuccess: null
            }
        case EDIT_USER_SERVICE_SUCCESS:
            return {
                ...state,
                userServiceError: null,
                userServiceSuccess: action.payload
            }
        case EDIT_USER_SERVICE_ERROR:
            return {
                ...state,
                userServiceError: action.payload,
                userServiceSuccess: null
            }
        default:
            return state;
    }
}

export default UserReducer;