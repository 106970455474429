import axios from "axios";
import { APPLICATION_CONST, TEST_ORDER_ID } from "../../Config/CustomConfig";

//***********  Obsolete  *************
export const getOrderId = (data) => {
  return new Promise((resolve, reject) => {
    const body = {
      username: data.username,
      sessionid: data.sessionid,
      failstatus: 0,
      request: {
        source: data.source,
      },
    };

    console.log("orderid reques:", JSON.stringify(body));
    axios({
      method: "post",
      url: `${APPLICATION_CONST.url}getorderid`,
      data: body,
    })
      .then((response) => {
        console.log("response", response);
        if (response.data) {
          console.log("orderid response:", JSON.stringify(response.data));
          let OrderId = response.data.data.orderid;
          resolve({
            success: true,
            statusMessage: "Success",
            orderId: OrderId,
          });
        } else {
          resolve({
            success: true,
            statusMessage: "Success",
            orderId: TEST_ORDER_ID,
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
          statusMessage: "Error",

          orderId: null,
        });
      });
  });
};

export const processTransaction = (data) => {
  return new Promise((resolve, reject) => {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

    const body = {
      username: loginDetail.username,
      sessionid: loginDetail.sessionid,
      failstatus: 0,
      request: data,
    };

    console.log("body", JSON.stringify(body));

    axios({
      method: "post",
      url: `${APPLICATION_CONST.url}processtransaction`,
      data: body,
    })
      .then((response) => {
        console.log("response", JSON.stringify(response));
        if (response.data.status === 0) {
          resolve({
            success: true,
            statusMessage: response.data.statusMessage,
            data: response.data.data,
          });
        } else if (response.data.status > 0) {
          resolve({
            success: false,
            statusMessage: response.data.statusMessage,
             orderId: null,
          });
        } else {
          resolve({
            success: false,
            statusMessage: "Something went wrong",
            orderId: null,
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
          statusMessage: "Error",
          orderId: null,
        });
      });
  });
};
