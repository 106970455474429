import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import {
  updateProductService,
  getClassType,
  callUpdateClassification,
} from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class AddClassification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classification: {
        classid: "",
        classtypeid: "",
        classtype: "",
        description: "",
      },
      productErr: {
        classid: "",
        classtypeid: "",
        classtype: "",
        description: "",
      },
      actiontype: "CREATE",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.location.state) {
      const classification = this.props.location.state.classification;
      if (classification) {
        this.setState({
          classification: classification,
          actiontype: "UPDATE",
        });
      }
    } else if (this.props.location.pathname === "/classification/edit") {
      this.props.history.push("/");
    }

    this.props.getClassType();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.classificationerror !== this.props.classificationerror) {
      if (this.props.classificationerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: this.props.classificationerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  resetError = () => {
    let productErr = {
      classid: "",
      classtypeid: "",
      classtype: "",
      description: "",
    };
    this.setState({ productErr: productErr });
  };

  handleSubmit = (e) => {
    e.preventDefault();
     const classtypeid = this.state.classification.classtypeid;
    const description = this.state.classification.description;

    let productErr = {
      classtypeid: "",
      description: "",
    };

    if (!classtypeid.trim()) {
      productErr.classtypeid = "Class Type required.";
    }
    if (!description) {
      productErr.description = "Description is required.";
    }

    this.setState({ productErr: productErr });

    if (classtypeid.trim() && description.trim()) {
      const actiontype = this.state.actiontype;

      if (actiontype === "UPDATE") {
        this.props.callUpdateClassification(
          {
            actiontype: "UPDATE",
            classid: this.state.classification.classid,
            classificationdescription: this.state.classification.description,
            classtypeid: this.state.classification.classtypeid,
          },
          this.props.history
        );
      } else {
        this.props.callUpdateClassification(
          {
            actiontype: "CREATE",
            classid: this.state.classification.classid,
            classificationdescription: this.state.classification.description,
            classtypeid: this.state.classification.classtypeid,
          },
          this.props.history
        );
      }
    }
  };

  classChange = (e) => {
    this.setState({
      classification: {
        ...this.state.classification,
        classtypeid: e.target.value,
      },
    });
  };
  render() {
    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/classifications")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className="card-header custom-card-header">
                    <h4>
                      {this.state.actiontype === "CREATE"
                        ? "Add Classification"
                        : "Edit Classification"}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="classtype"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Class Type
                            </label>
                          </div>
                          <div className="col-md-8">
                            <select
                              id="classtype"
                              className="form-control"
                              onChange={this.classChange}
                              onFocus={this.resetError}
                              value={this.state.classification.classtypeid}
                              autoFocus={true}
                              disabled={
                                this.state.actiontype === "UPDATE"
                                  ? true
                                  : false
                              }
                            >
                              <option value="">--Select Class--</option>
                              {this.props.classtypeslist.map((item) => (
                                <option
                                  key={item.classtypeid}
                                  value={item.classtypeid}
                                >
                                  {item.description}
                                </option>
                              ))}
                            </select>
                            {this.state.productErr.classtypeid && (
                              <small className="text-danger">
                                {this.state.productErr.classtypeid}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <div className="control-label col-md-4">
                            <label
                              htmlFor="description"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Description
                            </label>
                          </div>
                          <div className="col-md-8">
                            <textarea
                            id="description"
                              onChange={(e) =>
                                this.setState({
                                  classification: {
                                    ...this.state.classification,
                                    description: e.target.value,
                                  },
                                })
                              }
                              value={this.state.classification.description}
                              className="form-control"
                              rows="3"
                            ></textarea>
                            {this.state.productErr.description && (
                              <small className="text-danger">
                                {this.state.productErr.description}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={"card-footer"}>
                    <div className="col-md-12" style={{ padding: 0 }}>
                      <button
                        type="submit"
                        className="btn pull-right submit-btn"
                        style={styles.confirmButtonStyle}
                      >
                        {this.state.actiontype === "CREATE" ? "Save" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  productmaster: state.VendorProductReducer.productmaster,
  classtypeslist: state.VendorProductReducer.classtypeslist,
  classificationerror: state.VendorProductReducer.classificationerror,
  classificationerrormessage:
    state.VendorProductReducer.classificationerrormessage,
});
export default connect(mapStateToProps, {
  updateProductService,
  getClassType,
  callUpdateClassification,
})(AddClassification);
