export const styles = {
  /********* TABLE STYLES **********/
  tableHeadingStyle: {
    fontSize: 12,
    fontWeight: "bold",
    color: "#222",
  },

  tableHeadingStyleNumerical: {
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "right",
    color: "#222",
  },

  tableCellStyle: {
    fontSize: 12,
    marginTop: 3,
    marginBottom: 3,
    verticalAlign: "middle",
  },

  tableCellStyleNumerical: {
    fontSize: 12,
    textAlign: "right",
  },
  /********************************/

  /* CARD STYLE ON VENDOR HOME SCREEN */
  vendorCardStyle: {
    backgroundColor: "#FFF",
    borderWidth: 0,
    border: "1px solid #EEE",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingTop: 20,
    margin: 10,
    borderRadius: 5,
  },

  /* BUTTON STYLE ON VENDOR BUTTONS */
  vendorCardButtonStyle: {
    fontSize: 12,
    fontWeight: "bold",
    backgroundColor: "#4f4b4a",
    border: "none",
    border: "1px solid #eee",
    padding: 10,
    color: "#fff",
    width: "100%",
  },

  inputLabelStyle: {
    fontWeight: "bold",
    fontSize: 14,
    display: "flex",
    alignItems: "center",
  },

  confirmButtonStyle: {
    backgroundColor: "#FED23D",
    fontSize: 14,
    fontWeight: "bold",
    color: "#333",
    paddingRight: 40,
    paddingLeft: 40,
  },

  orderDetailsCaption: {
    fontWeight: 600,
    fontSize: 14,
  },

  dialogHeaderStyle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#555",
  },

  dialogCancelButtonStyle: {
    border: "none",
    backgroundColor: "#212121",
    color: "#FFF",
    fontWeight: "bold",
    fontSize: 12,
    paddingRight: 25,
    paddingLeft: 25,
  },

  dialogConfirmButtonStyle: {
    border: "none",
    backgroundColor: "#FED23D",
    color: "#000",
    fontWeight: "bold",
    fontSize: 12,
    paddingRight: 25,
    paddingLeft: 25,
  },

  dialogBodyTextStyle: {
    fontSize: 12,
  },

  navLinkStyle: {
    color: "#585B56",
    fontSize: 12,
    // textTransform: "uppercase",
    // fontFamily: "Poppins-Light",
    // marginRight: 20,
    // marginLeft: 20,
    fontWeight:"Bold",
    paddingLeft: 20,
    paddingRight: 20,
  },

  pageHeadingStyle: {
    color: "#EDAE49",
    fontSize: 40,
    fontFamily: "Signatra",
    textShadow: "0px 1px 1px #222",
    display: "inline-block",
  },

  pageSubHeadingStyle: {
    color: "#EDAE49",
    fontSize: 13,
    fontFamily: "Poppins-Light",
    // textShadow:"0px 1px 1px #222"
  },

  productCardStyle: {
    border: "1px solid #EEE",
    padding: 10,
    alignItems: "center",
  },

  pageHeadingFlightLeg: {
    fontFamily: "Poppins-Light",
    color: "#555",
    fontSize: 20,
  },

  pageHeadingAirport: {
    color: "#222",
    fontSize: 14,
    fontFamily: "Poppins-Light",
  },

  pageHeadingSubText: {
    color: "#555",
    fontSize: 16,
    fontFamily: "Poppins-Light",
  },

  loginPageHeader: {
    color: "#EDAE49",
    fontSize: 40,
    fontFamily: "Signatra",
    textShadow: "0px 1px 1px #222",
    textAlign: "center",
  },

  numeric_right: {
    textAlign: "right",
  },

  modal_90w: {
    width: 900,
  },

  viewCategoryButton: {
    backgroundColor: "#fed23d",
    padding: 10,
    color: "#000",
    fontSize: 12,
    fontWeight: "bold",
    border: "1px solid #CCC",
    marginTop: 10,
  },

  editflightButtonStyle: {
    fontSize: 14,
    fontWeight: "bold",
    color: "#FFF",
    paddingRight: 40,
    paddingLeft: 40,
  },
  updateflightButtonStyle: {
    backgroundColor: "#FED23D",
    fontSize: 14,
    fontWeight: "bold",
    color: "#333",
    paddingRight: 40,
    paddingLeft: 40,
  },
  /* STYLE FOR FOOTER */
  footer_nav: {
    backgroundColor: "#EEE",
    marginTop: 0,
    minHeight: 200,
    height: "auto",
    zIndex: 0,
  },
  footer_nav_Container: {
    flexDirection: "column",
    paddingTop: 40,
  },
  footer_title: {
    fontWeight: "bold",
    fontSize: 12,
    marginBottom: 10,
  },
  footer_menu: {
    color: "#000",
    fontSize: 11,
  },
  footer_logo_div: {
    textAlign: "center",
    margin: 20,
    fontFamily: "Signatra",
    fontSize: 25,
    color: "#4C3B4D",
  },
  footer_logo: {
    height: 100,
    width: "auto",
    margin: 3,
    alignSelf: "center",
  },
  footer_border: {
    height: 1,
    backgroundColor: "#CCC",
    width: "100%",
    marginBottom: 20,
  },
  footer_txt: {
    textAlign: "center",
    fontSize: 11,
    margin: 10,
  },
  footer_txt_pip: {
    textAlign: "center",
    fontSize: 11,
    margin: 10,
    fontWeight: "bold",
  },
  mainBannerImage: {
    width: "100%",
  },
  storeBannerImage: {
    width: "100%",
    cursor: "pointer",
  },
  errorContainer: {
    justifyContent: "center",
    textAlign: "center",
    color: "red",
  },
};
