import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class OrderSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: null,
    };
  }

  componentDidMount() {
    const processAction = this.props.location.state.actiontype;

    if (processAction === "FULFILLORDER") {
      this.setState({ successMessage: "Order FulFilled" });
    }
    if (processAction === "ASSIGNFULFILLMENT") {
      this.setState({ successMessage: "Order Assigned" });
    }
    if (processAction === "CONFIRMFULFILLMENT") {
      this.setState({ successMessage: "Assignment Confirmed" });
    }
    if (processAction === "CONFIRMRETURN") {
      let message =
        this.props.userDetail.userdefault.express === "Y"
          ? "Order Returned"
          : "Checked In";
      this.setState({ successMessage: message });
    }
    if (processAction === "CHECKINRETURN") {
      this.setState({ successMessage: "Checked In" });
    }
    if (processAction === "CHECKINORDER") {
      this.setState({ successMessage: "Checked In" });
    }
    if (processAction === "CHECKEDINORDERS") {
      this.setState({ successMessage: "Check Out Successfull" });
    }

    if (processAction === "CANCELFULFILLED") {
      this.setState({ successMessage: "Fulfillment Canceled" });
    }

    if (processAction === "CANCELASSIGNEDFULFILLMENT") {
      this.setState({ successMessage: "Assignment Cancelled" });
    }

    if (processAction === "CANCELCONFIRMEDFULFILLMENT") {
      this.setState({ successMessage: "Confirmation Cancelled" });
    }

    if (processAction === "ASSIGNRETURN") {
      this.setState({ successMessage: "Order Returned" });
    }

    if (processAction === "REFUNDORDER") {
      this.setState({ successMessage: "Refund Success" });
    }

    if (processAction === "CANCELASSIGNEDRETURN") {
      this.setState({ successMessage: "Return Canceled Success" });
    }

    if (processAction === "CANCELORDER") {
      this.setState({ successMessage: "Order Canceled" });
    }

    if (processAction === "UPDATESCHEDULE") {
      this.setState({ successMessage: "Flight Updated" });
    }

    if (processAction === "CANCELCHECKIN") {
      this.setState({ successMessage: "Check In Cancelled" });
    }

    if (processAction === "CHECKOUTORDER") {
      this.setState({ successMessage: "Check Out Success" });
    }
  }

  backToHome = () => {
    let location = "/";

    const processAction = this.props.location.state.actiontype;
    const userdetails = this.props.userDetail;

    if (userdetails.userdefault.loungeoperator === "Y") {
      if (
        processAction === "CANCELFULFILLED" ||
        processAction === "CANCELCHECKIN" ||
        processAction === "CANCELASSIGNEDRETURN" ||
        processAction === "REFUNDORDER"
      ) {
        location = "/admin";
        this.props.history.push(location);
        return;
      }
    } else if (
      userdetails.userdefault.vendor === "Y" &&
      userdetails.userdefault.subscriber === "N"
    ) {
      // if (
      //   processAction === "CANCELRETURNS" ||
      //   // processAction === "CANCELFULFILLED" 
      //   // processAction === "CANCELASSIGNEDFULFILLMENT" ||
      //   // processAction === "CANCELCONFIRMEDFULFILLMENT"
      // ) {
      //   location = "/CancelRequest";
      //   this.props.history.push(location);
      // }
    }

    else if (
      userdetails.userdefault.vendor === "Y" &&
      userdetails.userdefault.subscriber === "Y"
    ) {
      if (
        processAction === "UPDATESCHEDULE" ||
        processAction === "CANCELORDER"
      ) {
        location = "/orders";
      }
      this.props.history.push(location);
    }

    if (processAction === "FULFILLORDER") {
      this.props.history.push("/orders", { actiontype: "UNPROCESSED" });
    }

    if (processAction === "ASSIGNFULFILLMENT") {
      this.props.history.push("/orders", { actiontype: "FULFILLED" });
    }

    if (processAction === "CONFIRMFULFILLMENT") {
      this.props.history.push("/orders", {
        actiontype: "MYASSIGNEDFULFILLMENT",
      });
    }

    if (processAction === "CHECKINORDER") {
      this.props.history.push("/orders", { actiontype: "CHECKIN" });
    }

    if (processAction === "CHECKOUTORDER") {
      this.props.history.push("/orders", { actiontype: "CHECKEDINORDERS" });
    }

    if (processAction === "ASSIGNRETURN") {
      this.props.history.push("/orders", { actiontype: "CHECKEDOUTORDERS" });
    }

    if (processAction === "CANCELASSIGNEDRETURN") {
      this.props.history.push("/orders", { actiontype: "CANCELRETURNS" });
    }

    if (processAction === "CANCELASSIGNEDRETURN") {
      this.props.history.push("/orders", { actiontype: "CANCELRETURNS" });
    }

    if (processAction === "CANCELFULFILLED") {
      this.props.history.push("/orders", {
        actiontype: "CANCELFULFILLED",
        //subaction: "cancelfulfillment",
        cancelrequest: true
      });
    }

    if (processAction === "CANCELCHECKIN") {
      this.props.history.push("/orders", {
        actiontype: "CANCELCHECKIN",
      });
    }

    if (processAction === "CONFIRMRETURN") {
      this.props.history.push("/orders", { actiontype: "ASSIGNEDRETURNS" });
    }

    if (processAction === "CHECKINRETURN") {
      this.props.history.push("/orders", { actiontype: "CONFIRMEDRETURNS" });
    }

    if (processAction === "CANCELASSIGNEDFULFILLMENT") {
      this.props.history.push("/orders", {
        actiontype: "CANCELASSIGNED",
        cancelrequest: true,
      });
    }

    if (processAction === "CANCELCONFIRMEDFULFILLMENT") {
      this.props.history.push("/orders", {
        actiontype: "CANCELCONFIRMED",
        cancelrequest: true,
      });
    }
  };
  render() {
    return (
      <>
        <NavBar />
        <div
          className="container-fluid"
          style={{ paddingBottom: 50, paddingTop: 50 }}
        >
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <FontAwesomeIcon
                icon="check-circle"
                color="#4CAF50"
                style={{ fontSize: 140 }}
              />

              <div
                style={{
                  marginTop: 30,
                  fontSize: 30,
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {this.state.successMessage}
              </div>

              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                <button
                  className="btn btn-success btn-primary"
                  style={{ fontSize: 13, fontWeight: "bold" }}
                  onClick={() => this.backToHome()}
                >
                  <FontAwesomeIcon
                    icon="reply"
                    color="#FFF"
                    style={{ fontSize: 15, marginRight: 5 }}
                  />
                  Back To Home
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

//  export default Success;
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps)(OrderSuccess);
