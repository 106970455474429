import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";

import ChangePasswordReducer from "../reducers/authentication/changePasswordRedcuer";
/*******************authentication reducer **************************/
import ResetPasswordReducer from "../reducers/authentication/resetPasswordReducer";
import SignUpReducer from "../reducers/authentication/signUpReducer";
import SignInReducer from "../reducers/authentication/sinInReducer";
import CartReducer from "../reducers/public/cartReducer";
import FlightInfoReducer from "../reducers/public/flightInfoReducer";
import OrderReducer from "../reducers/public/orderReducer";
import PaymentReducer from "../reducers/public/paymentReducer";
/************************** public reducers ************************/
import StoreReducer from "../reducers/public/storeReducer";
/***************** order reducers ***********************************/
/***************** error simulate reducers **************************/
import LoaderReducer from "../reducers/vendor/loaderReducer";
import OrderProcessReducer from "../reducers/vendor/orderProcessReducer";
/*******************authentication reducer **************************/
/****************** vendor reducers *********************************/
import UserReducer from "../reducers/vendor/userReducer";
/****************** vendor reducers *********************************/
/***************** order reducers ***********************************/
import VendorOrderReducer from "../reducers/vendor/vendorOrderReducer";
import VendorProductReducer from "../reducers/vendor/vendorProductReducer";
import VendorReducer from "../reducers/vendor/vendorReducer";

import UpdateOrderReducer from "../reducers/public/updateOrderReducer";
import ReportsReducer from "../reducers/vendor/reportsReducer";
import ReceiptReducer from "../reducers/vendor/receiptReducer";
import SendReceiptReducer from "../reducers/vendor/sendReceiptReducer";

/***************** error simulate reducers **************************/

const saveToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
};

const loadFromLocalStorage = () => {
  try {
    const serializedState = sessionStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
  }
};

const AppReducers = combineReducers({
  StoreReducer,
  FlightInfoReducer,
  PaymentReducer,
  CartReducer,
  OrderReducer,
  UserReducer,
  SignInReducer,
  VendorOrderReducer,
  VendorReducer,
  LoaderReducer,
  OrderProcessReducer,
  VendorProductReducer,
  ResetPasswordReducer,
  SignUpReducer,
  ChangePasswordReducer,
  UpdateOrderReducer,
  ReportsReducer,
  ReceiptReducer,
  SendReceiptReducer,
});

const rootReducer = (state, action) => {
  return AppReducers(state, action);
};

const persistedStore = loadFromLocalStorage();
let store = createStore(
  rootReducer,
  persistedStore,
  compose(applyMiddleware(thunk, createLogger({})))
);
// let store = createStore(rootReducer, persistedStore, compose(applyMiddleware(thunk)));

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
