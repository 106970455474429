import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../styles/commontStyles";
import "../../styles/style.css";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { signupService } from "../../actions/authentication/signUpAction";
import { Container, Image } from "react-bootstrap";
import { APPLICATION_CONST } from "../../Config/CustomConfig";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: { value: "", error: "" },
      firstname: { value: "", error: "" },
      lastname: { value: "", error: "" },
      email: { value: "", error: "" },
      phone: { value: "", error: "" },
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.error,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (nextProps.success && nextProps.success !== this.props.success) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: "User added successfully!",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.closeDialog();
              this.props.history.push("/login");
            }}
          >
            Close
          </button>
        ),
      });
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  resetError = () => {
    this.setState({
      username: { ...this.state.username, error: "" },
      firstname: { ...this.state.firstname, error: "" },
      lastname: { ...this.state.lastname, error: "" },
      phone: { ...this.state.phone, error: "" },
      email: { ...this.state.email, error: "" },
    });
  };

  ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  };

  handelSubmit = (e) => {
    e.preventDefault();
    let username = this.state.username;
    let firstname = this.state.firstname;
    let lastname = this.state.lastname;
    let email = this.state.email;
    let phone = this.state.phone;

    if (username.value === "") {
      this.setState({
        username: { ...username, error: "Username is required." },
      });
    }
    if (firstname.value === "") {
      this.setState({
        firstname: { ...firstname, error: "First Name is required." },
      });
    }
    if (lastname.value === "") {
      this.setState({
        lastname: { ...lastname, error: "Last Name is required." },
      });
    }
    if (email.value === "") {
      this.setState({ email: { ...email, error: "Email is required." } });
    }
    if (phone.value === "") {
      this.setState({
        phone: { ...phone, error: "Phone number is required." },
      });
    }
    if (!this.ValidateEmail(email.value) && email.value !== "") {
      this.setState({
        email: { ...email, error: "Please enter a valid email address." },
      });
      return false;
    }

    if (
      username.value !== "" &&
      firstname.value !== "" &&
      lastname.value !== "" &&
      email.value !== "" &&
      phone.value !== ""
    ) {
      const data = {
        username: this.state.username.value,
        firstname: this.state.firstname.value,
        lastname: this.state.lastname.value,
        email: this.state.email.value,
        phone: this.state.phone.value,
      };
      this.props.signupService(data, this.props.history);
    }
  };

  render() {
    return (
      <>
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"auth-container"} fluid>
          <div className="auth-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <a href={"/"}>
                <Image
                  type="button"
                  style={{
                    height: 60,
                    width: "auto",
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={APPLICATION_CONST.applicationImages + "logo.png"}
                />
              </a>
            </div>
            <div>
              <h5 style={{ textAlign: "center", fontFamily: "Poppins-Light" }}>
                Create a New Account
              </h5>
              <form className="form-signin" onSubmit={this.handelSubmit}>
                <div className="row">
                  <div className="col-md-12 myInput-continer">
                    <div className="form-group">
                      <input
                        value={this.state.username.value}
                        type="text"
                        className="form-control myInput"
                        placeholder="Enter username"
                        onFocus={this.resetError}
                        autoFocus={true}
                        onChange={(e) =>
                          this.setState({
                            username: {
                              ...this.state.username,
                              value: e.target.value.replace(/[^a-z0-9]/g, ""),
                            },
                          })
                        }
                      />
                      {this.state.username.error && (
                        <small className="text-danger">
                          {this.state.username.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group myInput-continer">
                      <input
                        value={this.state.firstname.value}
                        type="text"
                        className="form-control myInput"
                        placeholder="Enter first name"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            firstname: {
                              ...this.state.firstname,
                              value: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
                            },
                          })
                        }
                      />
                      {this.state.firstname.error && (
                        <small className="text-danger">
                          {this.state.firstname.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group myInput-continer">
                      <input
                        value={this.state.lastname.value}
                        type="text"
                        className="form-control myInput"
                        placeholder="Enter last name"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            lastname: {
                              ...this.state.lastname,
                              value: e.target.value.replace(/[^a-zA-Z\s]/g, ""),
                            },
                          })
                        }
                      />
                      {this.state.lastname.error && (
                        <small className="text-danger">
                          {this.state.lastname.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group myInput-continer">
                      <input
                        value={this.state.phone.value}
                        type="text"
                        className="form-control myInput"
                        placeholder="Enter phone number"
                        maxLength={10}
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            phone: {
                              ...this.state.phone,
                              value: e.target.value.replace(/[^0-9]/g, ""),
                            },
                          })
                        }
                      />
                      {this.state.phone.error && (
                        <small className="text-danger">
                          {this.state.phone.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group myInput-continer">
                      <input
                        value={this.state.email.value}
                        type="email"
                        className="form-control myInput"
                        placeholder="Enter email address"
                        onFocus={this.resetError}
                        style={{ fontSize: 12, fontFamily: "Poppins-Light" }}
                        onChange={(e) =>
                          this.setState({
                            email: {
                              ...this.state.email,
                              value: e.target.value,
                            },
                          })
                        }
                      />
                      {this.state.email.error && (
                        <small className="text-danger">
                          {this.state.email.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <div style={{ marginTop: 10 }}></div>
                      <button
                        className="btn btn-lg btn-block"
                        type="submit"
                        style={styles.confirmButtonStyle}
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  fontSize: 12,
                  fontFamily: "Poppins-Light",
                }}
              >
                ALREADY HAVE AN ACCOUNT? &nbsp;
                <Link
                  to="/login"
                  style={{
                    fontSize: 12,
                    fontFamily: "Poppins-Light",
                    fontWeight: "bold",
                  }}
                >
                  SIGN IN
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  success: state.SignUpReducer.success,
  error: state.SignUpReducer.error,
});
export default connect(mapStateToProps, { signupService })(Register);
