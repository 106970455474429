import axios from "axios";
import { RECEIPT_SUCCESS, RECEIPT_ERROR, RECEIPT_PENDING } from "./actionTypes";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { loaderStatus } from "./loaderAction";

export const callReceiptWebService = (request, history, guestemail) => (
  dispatch
) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: request,
  };

   dispatch(serviceActionPending());
  dispatch(loaderStatus(true));

  axios({
    method: "post",
    url: APPLICATION_CONST.url + "printreceipt",
    timeout: 10000,
    data: JSON.stringify(body),
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        history.push("/order/receipt", {
          reportFilename: response.data.data.filename,
          orderid: request.orderid,
          actionype: "RECEIPTREPRINT",
          receiptdata: { guestemail: guestemail },
        });
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
        alert(response.data.statusMessage);
      } else {
        dispatch(serviceActionError("Something went wrong!"));
        alert("Something went wrong. Please try again later!");
      }
    })
    .catch((error) => {
      dispatch(loaderStatus(false));
      dispatch(serviceActionError(error.message));
      alert(error.message);
    });
};

export const serviceActionPending = () => ({
  type: RECEIPT_PENDING,
});

export const serviceActionError = (error) => ({
  type: RECEIPT_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: RECEIPT_SUCCESS,
  payload: data,
});
