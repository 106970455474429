import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../styles/commontStyles";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { resetPasswordSubmit } from "../../actions/authentication/resetPasswordAction";
import "../../styles/style.css";
import { Container, Image } from "react-bootstrap";
import { APPLICATION_CONST } from "../../Config/CustomConfig";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: { value: "", error: "" },
      robots: { value: false, error: "" },
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.error,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (nextProps.success && nextProps.success !== this.props.success) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: "Password reset completed",
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
  }
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  resetError = () => {
    this.setState({
      username: { ...this.state.username, error: "" },
      robots: { ...this.state.robots, error: "" },
    });
  };
  handelSubmit = (e) => {
    e.preventDefault();
    let username = this.state.username;
    let robots = this.state.robots;
    const history = this.props.history;

    if (username.value === "") {
      this.setState({
        username: { ...username, error: "Username is required." },
      });
    }
    if (robots.value === false) {
      this.setState({
        robots: { ...robots, error: "Please select you are not a robot" },
      });
    }
    if (username.value !== "" && robots.value === true) {
      // if (username.value !== '') {
      const data = {
        username: this.state.username.value,
      };
      this.props.resetPasswordSubmit(data, history);
    }
  };
  render() {
    return (
      <>
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"auth-container"} fluid>
          <div className="auth-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <a href={"/"}>
                <Image
                  type="button"
                  style={{
                    height: 60,
                    width: "auto",
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={APPLICATION_CONST.applicationImages + "logo.png"}
                />
              </a>
            </div>

            <div>
              <h5 style={{ textAlign: "center", fontFamily: "Poppins-Light" }}>
                Reset Password
              </h5>

              <form className="form-signin" onSubmit={this.handelSubmit}>
                <div className="row">
                  <div className="col-md-12 myInput-continer">
                    <div className="form-group">
                      <input
                        value={this.state.username.value}
                        type="text"
                        autoFocus={true}
                        className="form-control myInput"
                        placeholder="Username"
                        id="username"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            username: {
                              ...this.state.username,
                              value: e.target.value.replace(" ", ""),
                            },
                          })
                        }
                      />

                      {this.state.username.error && (
                        <small className="text-danger">
                          {this.state.username.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-md-12 myInput-continer"
                    style={{ marginBottom: 10 }}
                  >
                    <div className="form-group ">
                      <label
                        className="checkbox pull-left myInput"
                        style={{
                          fontSize: 12,
                          fontWeight: "bold",
                          width: "100%",
                        }}
                      >
                        <input
                          type="checkbox"
                          // onChange={(e) => this.setState({ robots: !this.state.robots })}/>
                          onFocus={this.resetError}
                          style={{ marginRight: 5 }}
                          onChange={(e) =>
                            this.setState({
                              robots: {
                                ...this.state.robots,
                                value: !this.state.robots.value,
                              },
                            })
                          }
                        />
                        I am not a Robot
                      </label>

                      {this.state.robots.error && (
                        <small
                          className="text-danger pull-left"
                          style={{ marginBottom: 5 }}
                        >
                          {this.state.robots.error}
                        </small>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-block"
                        type="submit"
                        style={styles.confirmButtonStyle}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontSize: 12,
                fontFamily: "Poppins-Light",
              }}
            >
              BACK TO &nbsp;
              <Link
                to="/login"
                style={{
                  fontSize: 12,
                  fontFamily: "Poppins-Light",
                  fontWeight: "bold",
                }}
              >
                SIGN IN
              </Link>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  success: state.ResetPasswordReducer.success,
  error: state.ResetPasswordReducer.error,
});

export default connect(mapStateToProps, { resetPasswordSubmit })(ResetPassword);
