import {
  CHANGE_PAYMENT_METHOD,
  RESET_CREDIT_CARD_INFO,
  UPDATE_CREDIT_CARD_INFO,
  CONFIRM_CART_SERVICE_SUCCESS,
  UPDATE_GUEST_FROM_INFO,
  CONFIRM_CART_SERVICE_ERROR,
  CONFIRM_CART_SERVICE_PENDING,
  CONFIRM_CART_SIGNATURE_ADD,
} from "../../actions/public/actionTypes";

const initialState = {
  paymentMethod: "Credit Card",
  guestInfo: {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  },
  creditCardInfo: {
    value: {
      cardtype: "",
      cardholder: "",
      cvv: "",
      cardnumber: "",
      expirydate: "",
    },
    status: {
      cardholder: "invalid",
      cardnumber: "invalid",
      expirydate: "invalid",
      code: "invalid",
    },
  },
  paymentStatus: null,
  paymentError: null,
  signature: null,
};

const PaymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CREDIT_CARD_INFO:
      const tempCreditCartInfo = { ...state.creditCardInfo };
      tempCreditCartInfo.value = action.payload.value;
      tempCreditCartInfo.status = action.payload.status;
      tempCreditCartInfo.valid = action.payload.valid;
      return {
        ...state,
        creditCardInfo: tempCreditCartInfo,
      };
    case RESET_CREDIT_CARD_INFO:
      const resetCreditCardInfo = { ...state.creditCardInfo };
      for (let key in resetCreditCardInfo.value) {
        resetCreditCardInfo.value[key] = "";
      }
      for (let key in resetCreditCardInfo.status) {
        resetCreditCardInfo.status[key] = "invalid";
      }
      return {
        ...state,
        creditCardInfo: resetCreditCardInfo,
      };
    case CONFIRM_CART_SERVICE_SUCCESS:
      return {
        ...state,
        paymentStatus: "success",
        signature: null,
        guestInfo: {
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
        },
      };
    case CONFIRM_CART_SERVICE_PENDING:
      return {
        ...state,
        paymentStatus: "pending",
      };
    case CONFIRM_CART_SERVICE_ERROR:
      return {
        ...state,
        paymentStatus: "failed",
        paymentError: action.payload,
      };
    case UPDATE_GUEST_FROM_INFO:
      return {
        ...state,
        guestInfo: action.payload,
      };
    case CHANGE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case CONFIRM_CART_SIGNATURE_ADD:
      return {
        ...state,
        signature: action.payload,
      };
    default:
      return state;
  }
};

export default PaymentReducer;
