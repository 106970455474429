import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../styles/commontStyles";
import NavBar from "../common/navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../common/footer";
import { getReportService } from "../../actions/vendor/reportAction";
import DialogModal from "../common/dialogModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

import { dateFormat } from "../../actions/common/dateTime";

class Runreport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      from: "",
      to: "",
      toggle: false,
      startOrderId: null,
      endOrderId: null,
    };
  }
  handleFromChange = (value) => {
    this.setState({
      fromDate: value,
    });
    const dateString = `${value.getFullYear()}/${
      value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
    }/${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
    this.setState({
      from: dateString,
    });
  };
  handleToChange = (value) => {
    this.setState({
      toDate: value,
    });
    const dateString = `${value.getFullYear()}/${
      value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
    }/${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
    this.setState({
      to: dateString,
    });
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.status === "error") {
      if (nextProps.status !== this.props.status && nextProps.status !== null) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.errorMessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  };

  showReport = (e) => {
    e.preventDefault();
    let toDate = this.state.toDate;
    let fromDate = this.state.fromDate;

    let startOrderId = this.state.startOrderId;
    let endOrderId = this.state.endOrderId;
    const orderidparameters = this.props.location.state.orderidparameters;
    const dateparameters = this.props.location.state.dateparameters;
    if (orderidparameters === "Y") {
      if (!startOrderId || !endOrderId) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: "Enter valid Order Id",
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
        return false;
      }
    }
    const startDate = `${fromDate.getFullYear()}${
      fromDate.getMonth() < 9
        ? "0" + (fromDate.getMonth() + 1)
        : fromDate.getMonth() + 1
    }${
      fromDate.getDate() < 10 ? "0" + fromDate.getDate() : fromDate.getDate()
    }`;
    const endDate = `${toDate.getFullYear()}${
      toDate.getMonth() < 9
        ? "0" + (toDate.getMonth() + 1)
        : toDate.getMonth() + 1
    }${toDate.getDate() < 10 ? "0" + toDate.getDate() : toDate.getDate()}`;
    const headerTitle = this.props.location.state.headerTitle;
    const reportid = this.props.location.state.reportid;
    if (orderidparameters === "Y") {
      const data = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        headerTitle: headerTitle,
        request: {
          reportid: reportid,
          startorderid: startOrderId,
          endorderid: endOrderId,
        },
      };
      this.props.getReportService(data, this.props.history);
      this.setState((prevState) => ({
        toggle: !prevState.toggle,
      }));
    } else if (dateparameters === "Y") {
      const data = {
        username: this.props.logindetails.username,
        sessionid: this.props.logindetails.sessionid,
        headerTitle: headerTitle,
        request: {
          reportid: reportid,
          startdate: startDate,
          enddate: endDate,
        },
      };
      this.props.getReportService(data, this.props.history);
      this.setState((prevState) => ({
        toggle: !prevState.toggle,
      }));
    }
  };
  render() {
    const headerTitle = this.props.location.state.headerTitle;
    const orderidparameters = this.props.location.state.orderidparameters;
    const dateparameters = this.props.location.state.dateparameters;

    console.log("state", this.props.location.state);
    return (
      <>
        <NavBar />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>{headerTitle}</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid stores-container">
          <div className="row">
            <div className="col-md-12">
              <form className="form-inline">
                {orderidparameters == "Y" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins-Light",
                          fontSize: 12,
                          marginRight: 10,
                        }}
                      >
                        Start Order ID
                      </div>

                      <input
                        required
                        value={this.state.startOrderId}
                        autoFocus={true}
                        type="text"
                        className="form-control myInput"
                        placeholder="Enter Start Order ID"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            startOrderId: e.target.value,
                          })
                        }
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins-Light",
                          fontSize: 12,
                          marginRight: 10,
                        }}
                      >
                        End Order ID
                      </div>
                      <input
                        required
                        value={this.state.endOrderId}
                        // autoFocus={true}
                        type="text"
                        className="form-control myInput"
                        placeholder="Enter End Order ID"
                        onChange={(e) =>
                          this.setState({
                            endOrderId: e.target.value,
                          })
                        }
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <button
                        onClick={this.showReport}
                        className="btn"
                        style={styles.confirmButtonStyle}
                      >
                        Run Report
                      </button>
                    </div>
                  </>
                ) : null}

                {dateparameters == "Y" ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins-Light",
                          fontSize: 12,
                          marginRight: 10,
                        }}
                      >
                        FROM
                      </div>
                      <DatePicker
                        selected={this.state.fromDate}
                        className="form-control"
                        onChange={this.handleFromChange}
                        maxDate={new Date()}
                        dateFormat={dateFormat(
                          "datepicker",
                          this.props.logindetails.userdefault
                        )}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Poppins-Light",
                          fontSize: 12,
                          marginRight: 10,
                        }}
                      >
                        TO
                      </div>
                      <DatePicker
                        selected={this.state.toDate}
                        className="form-control"
                        onChange={this.handleToChange}
                        minDate={this.state.fromDate}
                        maxDate={new Date()}
                        dateFormat={dateFormat(
                          "datepicker",
                          this.props.logindetails.userdefault
                        )}
                      />
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                      onClick={this.showReport}
                      className="btn"
                      style={styles.confirmButtonStyle}
                    >
                      Run Report
                    </button>
                  </>
                ) : null}
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  reportFileName: state.ReportsReducer.reportFileName,
  status: state.ReportsReducer.status,
  errorMessage: state.ReportsReducer.errorMessage,
});

export default connect(mapStateToProps, { getReportService })(Runreport);
