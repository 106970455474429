import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { callUpdateClassType } from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class AddClasstype extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classtype: {
        classtypeid: "",
        description: "",
      },
      productErr: {
        classtypeid: "",
        description: "",
      },
      actiontype: "CREATE",
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (this.props.location.state) {
      const classtype = this.props.location.state.classtype;
      if (classtype) {
        this.setState({
          classtype: classtype,
          actiontype: "UPDATE",
        });
      }
    } else if (this.props.location.pathname === "/classtypes/edit") {
      this.props.history.push("/");
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.classtypeerror !== this.props.classtypeerror) {
      if (this.props.classtypeerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: this.props.classtypeerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  resetError = () => {
    let productErr = {
      classid: "",
      classtypeid: "",
      classtype: "",
      description: "",
    };
    this.setState({ productErr: productErr });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const history = this.props.history;
    const description = this.state.classtype.description;

    let productErr = {
      classtypeid: "",
      description: "",
    };

    if (!description.trim()) {
      productErr.description = "Description is required.";
    }

    this.setState({ productErr: productErr });

    if (description.trim()) {
      const actiontype = this.state.actiontype;

      if (actiontype === "UPDATE") {
        this.props.callUpdateClassType(
          {
            actiontype: "UPDATE",
            classtypeid: this.state.classtype.classtypeid,
            classtypedescription: this.state.classtype.description.trim(),
          },
          this.props.history
        );
      } else {
        this.props.callUpdateClassType(
          {
            actiontype: "CREATE",
            classtypeid: this.state.classtype.classtypeid,
            classtypedescription: this.state.classtype.description.trim(),
          },
          this.props.history
        );
      }
    }
  };

  classChange = (e) => {
    this.setState({
      classtype: {
        ...this.state.classtype,
        classtypeid: e.target.value,
      },
    });
  };
  render() {
    return (
      <>
        <NavBar />
        <Loader message="Processing" />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />
        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/classtypes")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>

            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <form onSubmit={this.handleSubmit}>
                <div className="card">
                  <div className={"card-header custom-card-header"}>
                    <h4>
                    {this.state.actiontype === "CREATE"
                    ? "Add Class Type"
                    : "Edit Class Type"}
                    </h4>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <div className="control-label col-md-2">
                            <label
                              htmlFor="description"
                              style={styles.inputLabelStyle}
                              className="required"
                            >
                              Description
                            </label>
                          </div>
                          <div className="col-md-10">
                            <input
                            id="description"
                              onChange={(e) =>
                                this.setState({
                                  classtype: {
                                    ...this.state.classtype,
                                    description: e.target.value,
                                  },
                                })
                              }
                              onFocus={this.resetError}
                              type="text"
                              autoFocus={true}
                              value={this.state.classtype.description}
                              className="form-control"
                              placeholder="Enter  Description"
                            />

                            <small className="text-danger">
                              {this.state.productErr.description}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div class="col-md-12" style={{padding: 0}}>
                      <button
                          type="submit"
                          className="btn pull-right submit-btn"
                          style={styles.confirmButtonStyle}
                      >
                        {this.state.actiontype === "CREATE" ? "Save" : "Update"}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  productmaster: state.VendorProductReducer.productmaster,
  classtypeslist: state.VendorProductReducer.classtypeslist,
  classtypeerror: state.VendorProductReducer.classtypeerror,
  classtypeerrormessage: state.VendorProductReducer.classtypeerrormessage,
});
export default connect(mapStateToProps, {
  callUpdateClassType,
})(AddClasstype);
