import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import {
  getVendorService,
  callUpdateVendor,
} from "../../actions/vendor/vendorAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST, AIRPORTS } from "../../Config/CustomConfig";
import { Image, Badge, Container, Col, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";

class Vendors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      loaderMessage: "Retrieving Vendors...",
      dataTable: {
        columns: [
          {
            label: "#",
            field: "index",
            width: 10,
          },
          {
            label: "Image",
            field: "image",
            sort: "asc",
            width: 150,
          },
          {
            label: "Name",
            field: "name",
            sort: "asc",
            width: 270,
          },
          {
            label: "Airport",
            field: "airport",
            sort: "asc",
            width: 200,
          },
          {
            label: "Contact Mail",
            field: "contactno",
            sort: "asc",
            width: 100,
          },
          {
            label: "Description",
            field: "description",
            sort: "asc",
            width: 100,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
          {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: [
          ...this.props.vendorslist.map((vendor, index) => ({
            index: index + 1,
            image: (
              <Image
                style={{
                  height: 50,
                  width: 50,
                  margin: 3,
                  alignSelf: "center",
                }}
                resizemode="contain"
                src={
                  APPLICATION_CONST.storeImageUrl +
                  vendor.vendorreferenceid +
                  ".jpg" +
                  "?" +
                  new Date()
                }
              />
            ),
            name: vendor.vendorname,
            airport: vendor.airportid === "NMIA" ? AIRPORTS.NMIA : AIRPORTS.SIA,
            contactno: vendor.contactemail,
            description: vendor.description.replace(
              /(?:\r\n|\r|\n)/g,
              "<br />"
            ),
            status:
              vendor.status === "A" ? (
                <Badge variant="success">Active</Badge>
              ) : (
                <Badge variant="danger">Inactive</Badge>
              ),
            action: (
              <span>
                <button
                  onClick={() => this.onEditBtnClick(vendor)}
                  className="btn btn-sm btn-info btn-warning"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <i className="fa fa-pencil"></i>
                </button>
                <button
                  className="btn btn-sm btn-info btn-danger"
                  onClick={() => this.showDeleteCategoryDialog(vendor)}
                >
                  <FontAwesomeIcon
                    icon="trash"
                    style={{ widht: 15, height: 15 }}
                  />
                </button>
              </span>
            ),
          })),
        ],
      },
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    this.props.getVendorService();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.vendorerror !== this.props.vendorerror) {
      if (this.props.vendorerror === true) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: this.props.vendorerrormessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  onEditBtnClick = (vendor) => {
    this.props.history.push("/vendors/edit", {
      vendor: vendor,
    });
  };
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  showDeleteCategoryDialog = (item) => {
    this.setState({
      loaderMessage: "Processing...",
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this Vendor?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.deleteVendor(item);
            }}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  deleteVendor = (item) => {
    this.closeDialog();
    this.props.callUpdateVendor(
      {
        actiontype: "DELETE",
        vendorid: item.vendorid,
      },
      this.props.history
    );
  };

  uploadProductImage = (item) => {
    this.props.history.push("imageupload", {
      item: item,
      assettype: "VENDOR",
    });
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.vendorslist !== this.props.vendorslist) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.vendorslist.map((vendor, index) => ({
              index: index + 1,
              image: (
                <Image
                  style={{
                    height: 50,
                    width: 50,
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={
                    APPLICATION_CONST.storeImageUrl +
                    vendor.vendorreferenceid +
                    ".jpg" +
                    "?" +
                    new Date()
                  }
                />
              ),
              name: vendor.vendorname,
              airport:
                vendor.airportid === "NMIA" ? AIRPORTS.NMIA : AIRPORTS.SIA,
              contactno: vendor.contactemail,
              description: vendor.description,
              status:
                vendor.status === "A" ? (
                  <Badge variant="success">Active</Badge>
                ) : (
                  <Badge variant="danger">Inactive</Badge>
                ),
              action: (
                <span>
                  <button
                    onClick={() => this.onEditBtnClick(vendor)}
                    className="btn btn-sm btn-info btn-warning"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>
                  <button
                    className="btn btn-sm btn-info btn-danger"
                    onClick={() => this.showDeleteCategoryDialog(vendor)}
                  >
                    <FontAwesomeIcon
                      icon="trash"
                      style={{ widht: 15, height: 15 }}
                    />
                  </button>
                </span>
              ),
            })),
          ],
        },
      });
    }
  }

  render() {
    return (
      <>
        <NavBar />
        <Loader message={this.state.loaderMessage} />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Vendors</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="stores-container" fluid>
          <div style={{ padding: 15 }} className="custom_datatable ">
            <MDBDataTable
              striped
              bordered
              hover
              small
              data={this.state.dataTable}
            />
            <button
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                position: "fixed",
                backgroundColor: "#4CAF50",
                bottom: 0,
                right: 0,
                zIndex: 100,
                margin: 30,
              }}
              className="btn"
              onClick={() => this.props.history.push("/vendors/add")}
            >
              <FontAwesomeIcon icon="plus" color="#FFF" />
            </button>
          </div>
          <div className="spacer"></div>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  vendorslist: state.VendorReducer.vendorslist,
  vendorerror: state.VendorReducer.vendorerror,
  vendorerrormessage: state.VendorReducer.vendorerrormessage,
});
export default connect(mapStateToProps, {
  getVendorService,
  callUpdateVendor,
})(Vendors);
