import React, { Component } from "react";
import { QRCode } from "react-qr-svg";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { airportDisplayName } from "../common/helperFunctions";
import { Container, Col, Row } from "react-bootstrap";
import { styles } from "../../styles/commontStyles";
import DialogModal from "../common/dialogModal";
import Modal from "react-bootstrap/Modal";
import Loader from "../common/loader";
import { callUpdateOrderWebService } from "../../actions/public/updateOrderAction";
import { connect } from "react-redux";
import moment from "moment";
import { loaderStatus } from "../../actions/vendor/loaderAction";
import Fac from "../../plugins/fac";
import { TEST_ORDER_ID, TEST_MODE } from "../../Config/CustomConfig";
import { processTransaction } from "../../actions/common/OrderAction";
import { processCard } from "../../actions/common/ProcessCardAction";
import { dateFormat } from "../../actions/common/dateTime";

class OrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: null,
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      products: [],
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (!this.props.location.state) {
      this.props.history.push("/");
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.status !== this.props.status && nextProps.status !== null) {
      if (nextProps.status === "error") {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.error,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  }

  calcTotal = (orderItems) => {
    let total = 0;
    orderItems.forEach((item) => {
      total += item.orderedamount * item.unitcost;
    });
    return total;
  };

  calcTax = (orderItems) => {
    let charge = 0;
    let taxTotal = 0;
    orderItems.forEach((item) => {
      charge = item.unitcost * item.orderedamount;
      taxTotal += parseFloat(charge) * parseFloat(item.taxrate);
    });
    return taxTotal.toFixed(2);
  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  cancelOrder = () => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Alert!",
      dialogBody: "Are you want to cancel this order?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={this._cancelOrderSubmit}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  _cancelOrderSubmit = async () => {
    const orderid = this.props.location.state.order.orderid;
    const deposit = this.props.location.state.order.deposit;
    this.closeDialog();
    const PaymentGatewayDetail = JSON.parse(
      localStorage.getItem("PaymentGatewayDetail")
    );
    if (PaymentGatewayDetail.paymentgateway === "FAC") {
      this.props.loaderStatus(true);
      const FAC_CONFIG = {
        merchantid: PaymentGatewayDetail.merchantid,
        password: PaymentGatewayDetail.password,
        endpoint: PaymentGatewayDetail.endpointpath,
        acquireid: PaymentGatewayDetail.acquirerid,
        currency_code: PaymentGatewayDetail.currencycode,
        transacton_code: PaymentGatewayDetail.transactoncode,
        redirecturl: PaymentGatewayDetail.redirecturl,
        endpointpath: PaymentGatewayDetail.endpointpath,
      };

      const fac = new Fac(FAC_CONFIG);

      /*let orderitems = this.props.location.state.order.orderitems;
        let totalAmt =
        parseFloat(this.calcTotal(orderitems)) +
        parseFloat(this.calcTax(orderitems));
      */

      // Calling Process Transaction API
      let processTransactionResponse = await processTransaction({
        orderid: orderid,
        transactiontype: "STARTTRANSACTION",
        actiontype: "CANCELORDER",
      });

      if (processTransactionResponse.success) {
        let processCardRequestBody = {};
        console.log('deposit',deposit);
        processCardRequestBody = {
          amount: deposit.amount,
          modificationtype: 3,
          actiontype: "MODIFYTRANSACTION",
          orderid: deposit.orderid,
        };

        
        const response = await processCard(processCardRequestBody);
        console.log("responses", response);

        // let response = await fac.TransactionModification(requestBody);

        this.props.loaderStatus(false);

        console.log("FAC Reponse Return", JSON.stringify(response));
        if (response.success) {
          const data = {
            actiontype: "CANCELORDER",
            orderid: orderid,
          };

          this.props.callUpdateOrderWebService(data, this.props.history);
        } else {
          //FAC failed and call processTransaction API
          this.props.loaderStatus(false);

          await processTransaction({
            transactiontype: "ENDTRANSACTION",
            actiontype: "CANCELORDER",
            orderid: deposit.orderid,
          });

          this.setState({
            displayDialog: true,
            dialogTitle: "Alert!",
            dialogBody: response.statusMessage,
            dialogFooter: (
              <>
                <button
                  className="btn"
                  style={styles.dialogConfirmButtonStyle}
                  onClick={() => {
                    this.closeDialog();
                  }}
                >
                  Ok
                </button>
              </>
            ),
          });
        }
      } else {
        this.props.loaderStatus(false);

        this.setState({
          displayDialog: true,
          dialogTitle: "Alert!",
          dialogBody: processTransactionResponse.statusMessage,
          dialogFooter: (
            <>
              <button
                className="btn"
                style={styles.dialogConfirmButtonStyle}
                onClick={() => {
                  this.closeDialog();
                }}
              >
                Ok
              </button>
            </>
          ),
        });
      }
    } else {
      const data = {
        actiontype: "CANCELORDER",
        orderid: orderid,
      };

      this.props.callUpdateOrderWebService(data, this.props.history);
    }
  };

  render() {
    if (!this.props.location.state) return null;
    return (
      <>
        <NavBar />

        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <div
          className="container-fluid"
          style={{ padding: 0, paddingBottom: 100 }}
        >
          <Container className="back-bar-container" fluid>
            <Row className={"categoryBar back-bar"}>
              <Col lg="12" md="12">
                <a
                  className={"back-link"}
                  onClick={() => this.props.history.goBack()}
                >
                  <FontAwesomeIcon
                    icon="chevron-left"
                    color="#777"
                    className={"back-icon "}
                  />
                  Back
                </a>
                <ul>
                  <li>Order {this.props.location.state.order.orderid}</li>
                </ul>
              </Col>
            </Row>
          </Container>

          <Container fluid className="stores-container">
            <div className="row">
              <div className="col-md-5">
                <div className="card" style={{ marginBottom: 20 }}>
                  <div className="card-header custom-card-header">
                    <h5 style={{ fontFamily: "Poppins-Light" }}>
                      <span style={styles.orderDetailsCaption}>Order #: </span>{" "}
                      {this.props.location.state.order.orderid}
                    </h5>

                    <span
                      className="pull-right"
                      style={{
                        fontWeight: "600",
                        fontSize: 14,
                        position: "absolute",
                        right: "10px",
                        backgroundColor: "#39304A",
                        padding: "0px 10px",
                        borderRadius: 2,
                        color: "#FFF",
                      }}
                    >
                      {this.props.location.state.order.status}
                    </span>
                  </div>
                  <div className="card-body">
                    <div>
                      <div></div>

                      {this.props.location.state.actiontype ===
                      "CONFIRMEDRETURNS" ? (
                        <div>
                          <span style={styles.orderDetailsCaption}>
                            Assigned:{" "}
                          </span>{" "}
                          {this.props.location.state.order.assignedname}
                        </div>
                      ) : null}
                      <QRCode
                        bgColor="#FFFFFF"
                        fgColor="#000000"
                        level="Q"
                        value={`${this.props.location.state.order.orderid}`}
                        style={{ width: 140, height: 140, alignSelf: "center" }}
                      />
                    </div>

                    {this.props.location.state.actiontype === "CHECKIN" ? (
                      <>
                        <div>
                          <span style={styles.orderDetailsCaption}>
                            Vendor:{" "}
                          </span>{" "}
                          {this.props.location.state.order.vendorname}
                        </div>
                        <div>
                          <span style={styles.orderDetailsCaption}>
                            Assigned:{" "}
                          </span>{" "}
                          {this.props.location.state.order.assignedname}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="card" style={{ marginBottom: 20 }}>
                  <div className="card-header custom-card-header">
                    <h5 style={{ fontFamily: "Poppins-Light" }}>
                      <span style={styles.orderDetailsCaption}>
                        Customer Detail
                      </span>{" "}
                    </h5>
                  </div>

                  <div
                    className="card-body"
                    style={{ fontSize: 12, fontFamily: "Poppins-Light" }}
                  >
                    <div>
                      <span style={styles.orderDetailsCaption}>Name: </span>{" "}
                      {this.props.location.state.order.guest.firstname}{" "}
                      {this.props.location.state.order.guest.lastname}
                    </div>
                    <div>
                      <span style={styles.orderDetailsCaption}>Email: </span>{" "}
                      {this.props.location.state.order.guest.email}
                    </div>
                    <div>
                      <span style={styles.orderDetailsCaption}>Phone: </span>{" "}
                      {this.props.location.state.order.guest.phone}
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header custom-card-header">
                    <h5 style={{ fontFamily: "Poppins-Light" }}>
                      <span style={styles.orderDetailsCaption}>
                        Flight Detail
                      </span>{" "}
                    </h5>
                  </div>

                  <div
                    className="card-body"
                    style={{ fontSize: 12, fontFamily: "Poppins-Light" }}
                  >
                    <div>
                      <span style={styles.orderDetailsCaption}>Airport: </span>{" "}
                      {this.props.location.state.order.flight.airportid ===
                      "SIA"
                        ? "Sangster International"
                        : "Norman Manley International"}
                    </div>
                    <div>
                      <span style={styles.orderDetailsCaption}>Airline: </span>{" "}
                      {this.props.location.state.order.flight.airlineid}
                    </div>
                    <div>
                      <span style={styles.orderDetailsCaption}>Flight: </span>{" "}
                      {this.props.location.state.order.flight.flightid}
                    </div>
                    <div>
                      <span style={styles.orderDetailsCaption}>
                        Travel Date:{" "}
                      </span>{" "}
                      {moment(
                        this.props.location.state.order.flight.flighttime
                      ).format(dateFormat(
                        "normaldate",
                        this.props.logindetails.userdefault
                      ))}
                    </div>
                    <div>
                      <span style={styles.orderDetailsCaption}>
                        Flight Time:{" "}
                      </span>{" "}
                      {moment(
                        this.props.location.state.order.flight.flighttime
                      ).format(dateFormat(
                        "normaltime",
                        this.props.logindetails.userdefault
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <div className="card" style={{ marginBottom: 20 }}>
                  <div className="card-header custom-card-header">
                    <h5 style={{ fontFamily: "Poppins-Light" }}>
                      <span style={styles.orderDetailsCaption}>
                        Order Items
                      </span>{" "}
                    </h5>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <table width="100%">
                          <tr>
                            <th
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                                textAlign: "left",
                              }}
                            >
                              Product
                            </th>
                            <th
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                                textAlign: "center",
                              }}
                            >
                              Cost
                            </th>
                            <th
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                                textAlign: "center",
                              }}
                            >
                              Quantity
                            </th>
                            <th
                              style={{
                                fontSize: 12,
                                fontFamily: "Poppins-Light",
                                textAlign: "right",
                              }}
                            >
                              Total
                            </th>
                          </tr>
                          {this.props.location.state.order.orderitems.map(
                            (item) => (
                              <tr>
                                <td
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "left",
                                  }}
                                >
                                  {item.productname}
                                </td>

                                <td
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "center",
                                  }}
                                >
                                  ${parseFloat(item.unitcost).toFixed(2)}
                                </td>
                                <td
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.orderedamount}
                                </td>
                                <td
                                  style={{
                                    fontSize: 12,
                                    fontFamily: "Poppins-Light",
                                    textAlign: "right",
                                  }}
                                >
                                  $
                                  {(item.unitcost * item.orderedamount).toFixed(
                                    2
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.location.state.order.status === "CANCELLED" ||
                this.props.location.state.order.status === "CHECKED OUT" ||
                this.props.location.state.order.status === "REFUNDED" ? null : (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                    }}
                  >
                    <div className="col-md-12">
                      <button
                        onClick={() =>
                          this.props.history.push("/order/update-flight", {
                            order: this.props.location.state.order,
                          })
                        }
                        className="btn btn-info btn-primary"
                        style={styles.editflightButtonStyle}
                      >
                        Edit Flight
                      </button>

                      <button
                        onClick={this.cancelOrder}
                        className="btn pull-right"
                        style={styles.confirmButtonStyle}
                      >
                        Cancel Order
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* <div className="col-md-7"> */}
            {/* </div> */}
            {/* <div className="row">
              <div
                className="col-md-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  value={`${this.props.location.state.order.orderid}`}
                  style={{ width: 140, height: 140, alignSelf: "center" }}
                />
                <div
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 11,
                    marginTop: 10,
                  }}
                >
                  Order Number: {this.props.location.state.order.orderid}
                </div>
              </div>

              <div className="offset-1 col-md-8">
                <div className="row">
                  <table className="table">
                    <tr>
                      <th style={styles.tableHeadingStyle}>Product</th>
                      <th style={styles.tableHeadingStyleNumerical}>Cost</th>
                      <th style={styles.tableHeadingStyleNumerical}>
                        Quantity
                      </th>
                      <th style={styles.tableHeadingStyleNumerical}>Total</th>
                    </tr>
                  </table>
                </div>
           

                <div
                  style={{
                    height: 1,
                    backgroundColor: "#EEE",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                ></div>

                {this.props.location.state.order.orderitems.map((item) => (
                  <div className="row">
                    <div className="col-md-3" style={styles.tableCellStyle}>
                      {item.productname}
                    </div>
                    <div
                      className="col-md-3"
                      style={styles.tableCellStyleNumerical}
                    >
                      ${parseFloat(item.unitcost).toFixed(2)}
                    </div>
                    <div
                      className="col-md-3"
                      style={styles.tableCellStyleNumerical}
                    >
                      {item.orderedamount}
                    </div>
                    <div
                      className="col-md-3"
                      style={styles.tableCellStyleNumerical}
                    >
                      ${(item.unitcost * item.orderedamount).toFixed(2)}
                    </div>
                  </div>
                ))}

                <div className="row " style={{ marginTop: 50 }}>
                  <table className={"table"}>
                    <tr>
                      <td
                        width="85%"
                        style={{
                          borderTop: 0,
                          fontWeight: "bold",
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        Sub Total
                      </td>
                      <td
                        width="30%"
                        style={{
                          borderTop: 0,
                          textAlign: "right",
                          fontWeight: "bold",
                          fontSize: 18,
                        }}
                      >
                        $
                        {this.calcTotal(
                          this.props.location.state.order.orderitems
                        ).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="85%"
                        style={{
                          borderTop: 0,
                          fontWeight: "bold",
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        Tax
                      </td>
                      <td
                        width="30%"
                        style={{
                          borderTop: 0,
                          textAlign: "right",
                          fontWeight: "bold",
                          fontSize: 18,
                        }}
                      >
                        $
                        {this.calcTax(
                          this.props.location.state.order.orderitems
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="85%"
                        style={{
                          borderTop: 0,
                          fontWeight: "bold",
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        Grand Total
                      </td>
                      <td
                        width="30%"
                        style={{
                          borderTop: 0,
                          textAlign: "right",
                          fontWeight: "bold",
                          fontSize: 18,
                        }}
                      >
                        $
                        {(
                          parseFloat(
                            this.calcTotal(
                              this.props.location.state.order.orderitems
                            )
                          ) +
                          parseFloat(
                            this.calcTax(
                              this.props.location.state.order.orderitems
                            )
                          )
                        ).toFixed(2)}
                      </td>
                    </tr>
                  </table>
                 
                </div>
                {this.props.location.state.order.status === "CANCELLED" ||
                this.props.location.state.order.status === "CHECKED OUT" ||
                this.props.location.state.order.status === "REFUNDED" ? null : (
                  <div
                    className="row"
                    style={{
                      display: "flex",
                    }}
                  >
                    <button
                      onClick={() =>
                        this.props.history.push("/order/update-flight", {
                          order: this.props.location.state.order,
                        })
                      }
                      className="btn btn-info btn-primary"
                      style={{ marginLeft: "auto", marginRight: "15px" }}
                    >
                      Edit Flight
                    </button>

                    <button
                      onClick={this.cancelOrder}
                      className="btn pull-right"
                      style={styles.confirmButtonStyle}
                    >
                      Cancel Order
                    </button>
                  </div>
                )}
              </div>
            </div>
           */}
            <div className="spacer"></div>
          </Container>
        </div>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.UpdateOrderReducer.status,
  error: state.UpdateOrderReducer.error,
  logindetails: state.SignInReducer.logindetails,

});

export default connect(mapStateToProps, {
  callUpdateOrderWebService,
  loaderStatus,
})(OrderDetail);
