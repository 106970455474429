import sha1 from "crypto-js/sha1";
import Base64 from "crypto-js/enc-base64";
import {
  FAC_SIMULATION,
  SIMULATE_FAC,
  FAC_MERCHANT_URL,
} from "../Config/CustomConfig";
import { processCard } from "../actions/common/ProcessCardAction";

class Fac {
  xmlResponse = "";
  constructor(config) {
    this.merchantid = config.merchantid;
    this.password = config.password;
    this.endpoint = config.endpoint;
    this.acquireid = config.acquireid;
    this.currency_code = config.currency_code;
    this.transacton_code = config.transacton_code;
    this.redirecturl = config.redirecturl;
    this.endpointpath = config.endpointpath;
  }

  formatAmount(amount) {
    amount = amount.toString().replace(".", "");

    amount = amount.padStart(12, "0");
    return amount;
  }

  callWebService = async (requestBody, action) => {
    let EndPoint = this.endpointpath + "/" + action;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/xml");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: requestBody,
      redirect: "follow",
    };

    let response = await fetch(EndPoint, requestOptions);

    if (response.status === 200) {
      let data = await response.text();
      return data;
    }
  };

  generateRequest(PaymentDetails = {}, type) {
    let requestBody;
    switch (type) {
      case "Authorize3DS":
        requestBody = this.Authorize3DSRequest(PaymentDetails);
        break;
      case "Authorize":
        requestBody = this.AuthorizeRequest(PaymentDetails);
        break;
      case "TransactionModification":
        requestBody = this.TransactionModificationRequest(PaymentDetails);
        break;
    }
    return requestBody;
  }

  TransactionModificationRequest(PaymentDetails = {}) {
    let requestBody = '<?xml version="1.0" encoding="utf-8"?>';
    requestBody += "<TransactionModificationRequest";
    requestBody +=
      ' xmlns="http://schemas.firstatlanticcommerce.com/gateway/data" >';
    requestBody += "<AcquirerId>" + PaymentDetails.AcqID + "</AcquirerId>";
    requestBody += "<Amount>" + PaymentDetails.FormatAmount + "</Amount>";
    requestBody += "<CurrencyExponent>2</CurrencyExponent>";
    requestBody += "<MerchantId>" + PaymentDetails.MerID + "</MerchantId>";
    requestBody +=
      "<ModificationType>" +
      PaymentDetails.ModificationType +
      "</ModificationType>";
    requestBody +=
      "<OrderNumber>" + PaymentDetails.Transaction_id + "</OrderNumber>";
    requestBody += "<Password>" + PaymentDetails.MerPWD + "</Password>";
    requestBody += "</TransactionModificationRequest>";

    console.log("TransactionModificationRequest", requestBody);
    return requestBody;
  }

  Authorize3DSRequest(PaymentDetails = {}) {
    let requestBody = '<?xml version="1.0" encoding="utf-8"?>';
    requestBody += "<Authorize3DSRequest";
    requestBody += ' xmlns:i="http://www.w3.org/2001/XMLSchema-instance"';
    requestBody +=
      ' xmlns="http://schemas.firstatlanticcommerce.com/gateway/data">';
    requestBody += "<CardDetails>";
    requestBody += "<CardCVV2>" + PaymentDetails.CardCVV + "</CardCVV2>";
    requestBody +=
      "<CardExpiryDate>" + PaymentDetails.CardExp + "</CardExpiryDate>";
    requestBody += "<CardNumber>" + PaymentDetails.CardNumber + "</CardNumber>";
    requestBody += "<Installments>0</Installments>";
    requestBody += "</CardDetails>";
    requestBody +=
      "<MerchantResponseURL>" + this.redirecturl + "</MerchantResponseURL>";
    requestBody += "<TransactionDetails>";
    requestBody += "<AcquirerId>" + PaymentDetails.AcqID + "</AcquirerId>";
    requestBody += "<Amount>" + PaymentDetails.FormatAmount + "</Amount>";
    requestBody +=
      "<Currency>" + PaymentDetails.CurrencyNumeric + "</Currency>";
    requestBody += "<CurrencyExponent>2</CurrencyExponent>";
    requestBody += "<MerchantId>" + PaymentDetails.MerID + "</MerchantId>";
    requestBody +=
      "<OrderNumber>" + PaymentDetails.Transaction_id + "</OrderNumber>";
    requestBody +=
      "<Signature>" + PaymentDetails.SignatureEncrypted + "</Signature>";
    requestBody += "<SignatureMethod>SHA1</SignatureMethod>";
    requestBody +=
      "<TransactionCode>" +
      PaymentDetails.TransactionCode +
      "</TransactionCode>";
    requestBody +=
      "<CustomerReference>" +
      PaymentDetails.CustomerReference +
      "</CustomerReference>";
    requestBody += "</TransactionDetails>";
    requestBody += "</Authorize3DSRequest>";

    console.log("requestBody", requestBody);
    return requestBody;
  }

  AuthorizeRequest(PaymentDetails = {}) {
    let requestBody = '<?xml version="1.0"?>';
    requestBody += "<AuthorizeRequest";
    requestBody += ' xmlns:i="http://www.w3.org/2001/XMLSchema-instance"';
    requestBody +=
      ' xmlns="http://schemas.firstatlanticcommerce.com/gateway/data">';
    requestBody += "<TransactionDetails>";
    requestBody += "<AcquirerId>" + PaymentDetails.AcqID + "</AcquirerId>";
    requestBody += "<Amount>" + PaymentDetails.FormatAmount + "</Amount>";
    requestBody += "<Currency>840</Currency>";
    requestBody += "<CurrencyExponent>2</CurrencyExponent>";
    requestBody += "<MerchantId>" + PaymentDetails.MerID + "</MerchantId>";
    requestBody +=
      "<OrderNumber>" + PaymentDetails.Transaction_id + "</OrderNumber>";
    requestBody +=
      "<Signature>" + PaymentDetails.SignatureEncrypted + "</Signature>";
    requestBody += "<SignatureMethod>SHA1</SignatureMethod>";
    requestBody += "<TransactionCode>0</TransactionCode>";
    requestBody += "</TransactionDetails>";
    requestBody += "<CardDetails>";
    requestBody += "<CardCVV2>" + PaymentDetails.CardCVV + "</CardCVV2>";
    requestBody +=
      "<CardExpiryDate>" + PaymentDetails.CardExp + "</CardExpiryDate>";
    requestBody += "<CardNumber>" + PaymentDetails.CardNumber + "</CardNumber>";
    requestBody += "</CardDetails>";
    requestBody += "<BillingDetails>";
    requestBody += "<BillToZipPostCode/>";
    requestBody += "</BillingDetails>";
    requestBody += "</AuthorizeRequest>";

    console.log("requestBody", requestBody);
    return requestBody;
  }

  generateSignature(CardDetails = {}) {
    let MerID = CardDetails.MerID;
    let MerPWD = CardDetails.MerPWD;
    let AcqID = CardDetails.AcqID;
    let Transaction_id = CardDetails.Transaction_id;
    let FormatAmount = CardDetails.FormatAmount;
    let CurrencyNumeric = CardDetails.CurrencyNumeric;

    let signature = MerPWD;
    signature += MerID;
    signature += AcqID;
    signature += Transaction_id;
    signature += FormatAmount;
    signature += CurrencyNumeric;

    let SignatureEncrypted = signature;

    SignatureEncrypted = sha1(SignatureEncrypted);
    SignatureEncrypted = Base64.stringify(SignatureEncrypted);
    return SignatureEncrypted;
  }

  purchase = async (data, callableFunction) => {
    return new Promise((resolve, reject) => {
      let MerID = this.merchantid;
      let MerPWD = this.password;
      let AcqID = this.acquireid;
      let CurrencyNumeric = this.currency_code;
      let TransactionCode = this.transacton_code;

      let CustomerReference = data.card_name;
      let CardNumber = data.card_number;
      let CardCVV = data.card_cvv;
      let CardExp = data.card_exp;

      let Transaction_id = data.order_id;
      let amount = data.card_amount.toFixed(2);
      let FormatAmount = this.formatAmount(amount);
      // let CurrencyNumeric = $CurrencyNumeric;
      // console.clear();

      let PaymentDetails = {
        MerID: MerID,
        MerPWD: MerPWD,
        AcqID: AcqID,
        CardNumber: CardNumber,
        CardCVV: CardCVV,
        CardExp: CardExp,
        CurrencyNumeric: CurrencyNumeric,
        Transaction_id: Transaction_id,
        TransactionCode: TransactionCode,
        FormatAmount: FormatAmount,
        CustomerReference: CustomerReference,
      };

      let SignatureEncrypted = this.generateSignature(PaymentDetails);

      if (SignatureEncrypted) {
        PaymentDetails = {
          ...PaymentDetails,
          SignatureEncrypted: SignatureEncrypted,
        };
      }

      let requestBody = this.generateRequest(PaymentDetails, "Authorize3DS");
      this.callWebService(requestBody, "Authorize3DS").then(function (value) {
        let response = value;
        let parser = new DOMParser();
        let xmlDoc = parser.parseFromString(response, "text/xml");

        console.log("xmlDoc", xmlDoc);
        let HtmlData = xmlDoc.getElementsByTagName("HTMLFormData")[0]
          .childNodes[0].nodeValue;

        let paymentWindow = window.open("", "_blank", "width=900,height=600");
        paymentWindow.document.write(HtmlData);

        let paymentInterval = setInterval(function () {
          if (paymentWindow.closed) {
            clearInterval(paymentInterval);
            resolve({
              success: false,
              statusMessage: "Window is closed",
              data: {},
            });
          }
        }, 1000);
        window.addEventListener(
          "message",
          function (ev) {
            let postData = ev.data;
            console.log("postData", postData);

            if (postData.OrderID) {
              clearInterval(paymentInterval);
              paymentWindow.close();
              resolve({
                success: true,
                statusMessage: "Success",
                data: postData,
              });
              // return "Deepak Suthar";
            }
          },
          false
        );
      });
    });
  };

  authorize = async (data, callableFunction) => {
    return new Promise((resolve, reject) => {
      let MerID = this.merchantid;
      let MerPWD = this.password;
      let AcqID = this.acquireid;
      let CurrencyNumeric = this.currency_code;
      let TransactionCode = 64;

      let CustomerReference = data.card_name;
      let CardNumber = data.card_number;
      let CardCVV = data.card_cvv;
      let CardExp = data.card_exp;

      let Transaction_id = data.order_id;
      let amount = data.card_amount.toFixed(2);
      let FormatAmount = this.formatAmount(amount);
      // let CurrencyNumeric = $CurrencyNumeric;
      // console.clear();

      let PaymentDetails = {
        MerID: MerID,
        MerPWD: MerPWD,
        AcqID: AcqID,
        CardNumber: CardNumber,
        CardCVV: CardCVV,
        CardExp: CardExp,
        CurrencyNumeric: CurrencyNumeric,
        Transaction_id: Transaction_id,
        TransactionCode: TransactionCode,
        FormatAmount: FormatAmount,
        CustomerReference: CustomerReference,
      };

      let SignatureEncrypted = this.generateSignature(PaymentDetails);

      if (SignatureEncrypted) {
        PaymentDetails = {
          ...PaymentDetails,
          SignatureEncrypted: SignatureEncrypted,
        };
      }

      let requestBody = this.generateRequest(PaymentDetails, "Authorize3DS");
      let instance = this;
      this.callWebService(requestBody, "Authorize3DS").then(
        function (value) {
          let response = value;

          console.log("response", response);
          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(response, "text/xml");

          let HtmlData = xmlDoc.getElementsByTagName("HTMLFormData")[0]
            .childNodes[0].nodeValue;

          let paymentWindow = window.open("", "_blank", "width=900,height=600");
          paymentWindow.document.write(HtmlData);

          let paymentInterval = setInterval(function () {
            if (paymentWindow.closed) {
              clearInterval(paymentInterval);
              resolve({
                success: false,
                statusMessage: "Window is closed",
                data: {},
              });
            }
          }, 1000);

          window.addEventListener(
            "message",
            function (ev) {
              let postData = ev.data;
              console.log("postData", postData);

              if (postData.originUrl === FAC_MERCHANT_URL) {
                if (postData.ResponseCode === 1) {
                  if (postData.OrderID) {
                    let requestBody = instance.generateRequest(
                      PaymentDetails,
                      "Authorize"
                    );
                    instance
                      .callWebService(requestBody, "Authorize")
                      .then(function (value) {
                        let response = value;
                        console.log("response here", response);
                        let xmlDoc = parser.parseFromString(
                          response,
                          "text/xml"
                        );

                        console.log("xmlDoc", xmlDoc);
                        let ResponseCode = xmlDoc.getElementsByTagName(
                          "ResponseCode"
                        )[0].childNodes[0].nodeValue;
                        console.log(ResponseCode);
                        let ReasonCodeDescription = xmlDoc.getElementsByTagName(
                          "ReasonCodeDescription"
                        )[0].childNodes[0].nodeValue;

                        let AuthCode = xmlDoc.getElementsByTagName(
                          "AuthCode"
                        )[0].childNodes[0].nodeValue;
                        let reponseData = {
                          ...postData,
                          ResponseCode: ResponseCode,
                          ReasonCodeDescription: ReasonCodeDescription,
                          AuthCode: AuthCode,
                        };

                        console.log("postData", postData);
                        console.log("reponseData", reponseData);

                        if (reponseData.OrderID) {
                          clearInterval(paymentInterval);
                          paymentWindow.close();
                          resolve({
                            success: true,
                            statusMessage: "Success",
                            data: reponseData,
                          });
                        }
                      });
                  }
                } else {
                  clearInterval(paymentInterval);
                  paymentWindow.close();
                  resolve({
                    success: false,
                    statusMessage: postData.ReasonCodeDesc,
                    data: postData,
                  });
                }
              }
            },
            false
          );
        }.bind(this)
      );
    });
  };

  TransactionModification = async (data, callableFunction) => {
    return new Promise((resolve, reject) => {
      let MerID = this.merchantid;
      let MerPWD = this.password;
      let AcqID = this.acquireid;
      let Transaction_id = data.order_id;
      let amount = data.card_amount.toFixed(2);
      let FormatAmount = this.formatAmount(amount);
      let ModificationType = data.modification_type;

      let PaymentDetails = {
        MerID: MerID,
        MerPWD: MerPWD,
        AcqID: AcqID,
        Transaction_id: Transaction_id,
        FormatAmount: FormatAmount,
        ModificationType: ModificationType,
      };

      let requestBody = this.generateRequest(
        PaymentDetails,
        "TransactionModification"
      );

      let instance = this;
      this.callWebService(requestBody, "TransactionModification").then(
        function (value) {
          let response = value;
          console.log("response", response);
          let parser = new DOMParser();
          let xmlDoc = parser.parseFromString(response, "text/xml");

          let AcquirerId = xmlDoc.getElementsByTagName("AcquirerId")[0]
            .childNodes[0].nodeValue;
          let MerchantId = xmlDoc.getElementsByTagName("MerchantId")[0]
            .childNodes[0].nodeValue;
          let OrderNumber = xmlDoc.getElementsByTagName("OrderNumber")[0]
            .childNodes[0].nodeValue;

          let ReasonCode = xmlDoc.getElementsByTagName("ReasonCode")[0]
            .childNodes[0].nodeValue;
          let ReasonCodeDescription = xmlDoc.getElementsByTagName(
            "ReasonCodeDescription"
          )[0].childNodes[0].nodeValue;
          let ResponseCode = xmlDoc.getElementsByTagName("ResponseCode")[0]
            .childNodes[0].nodeValue;

          let responseData = {
            AcquirerId: AcquirerId,
            MerchantId: MerchantId,
            OrderNumber: OrderNumber,
            ReasonCode: ReasonCode,
            ReasonCodeDesc: ReasonCodeDescription,
            ResponseCode: ResponseCode,
          };

          if (responseData.ResponseCode === 1) {
            resolve({
              success: true,
              statusMessage: responseData.ReasonCodeDescription,
              data: responseData,
            });
          } else {
            resolve({
              success: false,
              statusMessage: responseData.ReasonCodeDescription,
              data: responseData,
            });
          }
        },
        false
      );
    });
  };

  xmlToJson(xml) {
    // Create the return object
    var obj = {};

    if (xml.nodeType === 1) {
      // element
      // do attributes
      if (xml.attributes.length > 0) {
        obj["@attributes"] = {};
        for (var j = 0; j < xml.attributes.length; j++) {
          var attribute = xml.attributes.item(j);
          obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType === 3) {
      // text
      obj = xml.nodeValue;
    }

    // do children
    if (xml.hasChildNodes()) {
      for (var i = 0; i < xml.childNodes.length; i++) {
        var item = xml.childNodes.item(i);
        var nodeName = item.nodeName;
        if (typeof obj[nodeName] === "undefined") {
          obj[nodeName] = this.xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push === "undefined") {
            var old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJson(item));
        }
      }
    }
    return obj;
  }

  process = async (HtmlData, orderid) => {
    return new Promise((resolve, reject) => {
      let paymentWindow = window.open("", "_blank", "width=900,height=600");

      if (!paymentWindow) {
        resolve({
          success: false,
          statusMessage:
            "Browser prevented this site from opening pop-up windows please re-enable and try again.",
          data: {},
        });
      }

      paymentWindow.document.write(
        '<div style="margin: auto; width: 50%; border: 1px solid #EEE; padding: 10px; border-radius: 10px;right: 50%; bottom: 50%;transform: translate(50%,50%);position: absolute;"><p style="text-align: center;"><span style="font-weight: bold; font-size: 1.2em;">Please Wait </span><br/>We are processing your payment, do not try to click away from this page. This process can take up to 30 seconds</p>'
      );
      paymentWindow.document.write(HtmlData);

      let requestCount = 0;

      let transactionStatus = setInterval(function () {
        requestCount++;

        if (requestCount >= 12) {
          clearInterval(paymentInterval);
          clearInterval(transactionStatus);

          paymentWindow.close();

          resolve({
            success: false,
            statusMessage: "Timed Out",
            data: {
              authorizationnumber: "",
              originalresponsecode: "",
              cardnumber: "",
              referencenumber: "",
              reasoncode: "",
              reasondescription: "Timed Out",
            },
          });
        }

        const processCardResponse = processCard({
          orderid: orderid,
          actiontype: "TRANSACTIONSTATUS",
        }).then(function (value) {
          console.log("value", value);

          if (value.data.reasoncode != 999) {
            //***************Need To UnComment this code when Richard API giving proper data*/
            let responseData = {
              authorizationnumber: value.data.authorizationnumber,
              originalresponsecode: value.data.originalresponsecode,
              cardnumber: value.data.cardnumber,
              referencenumber: value.data.referencenumber,
              reasoncode: value.data.reasoncode,
              // reasoncode: 3,

              reasondescription: value.data.reasondescription,
            };

            //***************Need To UnComment this code when Richard API giving proper data*/

            clearInterval(paymentInterval);
            clearInterval(transactionStatus);

            paymentWindow.close();

            if (value.data.reasoncode == 1) {
              resolve({
                success: true,
                statusMessage: responseData.reasondescription,
                data: responseData,
              });
            } else {
              resolve({
                success: false,
                statusMessage: responseData.reasondescription,
                data: responseData,
              });
            }
          }
        });
      }, 5000);

      let paymentInterval = setInterval(function () {
        if (paymentWindow.closed) {
          clearInterval(paymentInterval);
          clearInterval(transactionStatus);

          resolve({
            success: false,
            statusMessage: "Window is closed",
            data: {},
          });
        }
      }, 10000);
      window.addEventListener(
        "message",
        function (ev) {
          let postData = ev.data;
          console.log("postData", postData);
          console.log("postDataString", JSON.stringify(postData));

          if (postData.originUrl === FAC_MERCHANT_URL) {
            if (postData.ResponseCode === 1) {
              clearInterval(paymentInterval);
              clearInterval(transactionStatus);

              let responseData = {
                authorizationnumber: postData.AuthCode,
                originalresponsecode: postData.OriginalResponseCode,
                cardnumber: postData.PaddedCardNo,
                referencenumber: postData.ReferenceNo,
                reasoncode: postData.ReasonCode,
                reasondescription: postData.ReasonCodeDesc,
              };
              paymentWindow.close();

              window.removeEventListener("message", function (ev) {});
              resolve({
                success: true,
                statusMessage: "Success",
                data: responseData,
              });
            } else {
              clearInterval(paymentInterval);
              clearInterval(transactionStatus);
              window.removeEventListener("message", function (ev) {});

              paymentWindow.close();

              let responseData = {
                authorizationnumber: postData.AuthCode,
                originalresponsecode: postData.OriginalResponseCode,
                cardnumber: postData.PaddedCardNo,
                referencenumber: postData.ReferenceNo,
                reasoncode: postData.ReasonCode,
                reasondescription: postData.ReasonCodeDesc,
              };
              resolve({
                success: false,
                statusMessage: postData.reasondescription,
                data: responseData,
              });
            }
          }
        },
        false
      );
    });
  };
}

//**************************************************************************/
//***************************FAC Simulation Class***************************/
//**************************************************************************/
class FacSIM {
  constructor(config) {}

  //************** Fake Methods  ****************/

  purchase = async (data, callableFunction) => {
    return new Promise((resolve, reject) => {
      let paymentWindow = window.open("", "_blank", "width=900,height=600");
      paymentWindow.document.write(`
        <html><body><center><h1>Processing transaction</h1></center></body></html>
        `);
      setTimeout(() => {
        paymentWindow.close();
      }, 5000);

      let paymentInterval = setInterval(function () {
        if (paymentWindow.closed) {
          clearInterval(paymentInterval);

          let dummyData = JSON.parse(
            `{"success":true,"statusMessage":"Success","data":{"MerID":"88802358","AcqID":"464748","OrderID":"AIR200073","ResponseCode":"1","ReasonCode":"33","ReasonCodeDesc":"Authentication successful with attempt.","AuthenticationResult":"A","CAVVValue":"hk+FrO0OV2SmYwASX3/ICEYAAAA=","ECIIndicator":"01","TransactionStain":"AgACAQADAQgBBAECAQIGAgIg7lw=","Signature":"4uDn17CA8oO5XfCulIUwkkWmPfw=","SignatureMethod":"SHA1","ReasonCodeDescription":"Transaction is approved.","AuthCode":"123456"}}`
          );

          resolve({
            success: true,
            statusMessage: "success",
            data: dummyData.data,
          });
        }
      }, 1000);
    });
  };

  TransactionModification = async (data, callableFunction) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let dummyData = JSON.parse(
          `{"success":true,"statusMessage":"Success","data":{"MerID":"88802358","AcqID":"464748","OrderID":"AIR200073","ResponseCode":"1","ReasonCode":"33","ReasonCodeDesc":"Authentication successful with attempt.","AuthenticationResult":"A","CAVVValue":"hk+FrO0OV2SmYwASX3/ICEYAAAA=","ECIIndicator":"01","TransactionStain":"AgACAQADAQgBBAECAQIGAgIg7lw=","Signature":"4uDn17CA8oO5XfCulIUwkkWmPfw=","SignatureMethod":"SHA1","ReasonCodeDescription":"Transaction is approved.","AuthCode":"123456"}}`
        );

        resolve({
          success: true,
          statusMessage: "success",
          data: dummyData.data,
        });
      }, 3000);
    });
  };

  authorize = async (data, callableFunction) => {
    return new Promise((resolve, reject) => {
      let paymentWindow = window.open("", "_blank", "width=900,height=600");
      paymentWindow.document.write(`
          <html><body><center><h1>Processing transaction</h1></center></body></html>
          `);
      setTimeout(() => {
        paymentWindow.close();
      }, 5000);

      let paymentInterval = setInterval(function () {
        if (paymentWindow.closed) {
          clearInterval(paymentInterval);

          let dummyData = JSON.parse(
            `{"success":true,"statusMessage":"Success","data":{"MerID":"88802358","AcqID":"464748","OrderID":"AIR200073","ResponseCode":"1","ReasonCode":"33","ReasonCodeDesc":"Authentication successful with attempt.","AuthenticationResult":"A","CAVVValue":"hk+FrO0OV2SmYwASX3/ICEYAAAA=","ECIIndicator":"01","TransactionStain":"AgACAQADAQgBBAECAQIGAgIg7lw=","Signature":"4uDn17CA8oO5XfCulIUwkkWmPfw=","SignatureMethod":"SHA1","ReasonCodeDescription":"Transaction is approved.","AuthCode":"123456"}}`
          );

          resolve({
            success: true,
            statusMessage: "success",
            data: dummyData.data,
          });
        }
      }, 1000);
    });
  };
}

export default SIMULATE_FAC ? FacSIM : Fac;
