import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { callProductMasterWebService } from "../../actions/vendor/productAction";
import {
  updateProductService,
  productDeleteWebService,
  uploadProductImage,
} from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container, Col, Row } from "react-bootstrap";

import { APPLICATION_CONST } from "../../Config/CustomConfig";

class ProductMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      productImage: null,
      imageExists: false,
      loaderMessage: "",
      dimensions: {},
    };
    this.reader = new FileReader();

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {}
  _onSubmit = () => {
    var item = this.props.location.state.item;
    var assettype = this.props.location.state.assettype;

    let assetid;

    if (assettype === "PRODUCT") {
      assetid = item.productid;
    } else if (assettype === "CATEGORY") {
      assetid = item.categoryid;
    } else if (assettype === "VENDOR") {
      assetid = item.vendorid;
    }

    const body = {
      username: this.props.userdetails.username,
      sessionid: this.props.userdetails.sessionid,
      assettype: assettype,
      assetid: assetid,
      file: this.state.imageData,
      actiontype: "UPLOAD",
    };
    this.props.uploadProductImage(body, this.props.history);
  };
  _deleteConfirm = () => {
    this.setState({
      displayDialog: false,
    });
    var item = this.props.location.state.item;

    var assettype = this.props.location.state.assettype;

    let assetid;

    if (assettype === "PRODUCT") {
      assetid = item.productid;
    } else if (assettype === "CATEGORY") {
      assetid = item.categoryid;
    } else if (assettype === "VENDOR") {
      assetid = item.vendorid;
    }

    const body = {
      username: this.props.userdetails.username,
      sessionid: this.props.userdetails.sessionid,
      assettype: assettype,
      assetid: assetid,
      delete: "Y",
      actiontype: "DELETE",
    };

    this.props.uploadProductImage(body, this.props.history);
  };
  deleteImage = () => {
    this.setState({
      displayDialog: true,
      dialogTitle: "Alert!",
      dialogBody: "Are You sure you want to delete this image ?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={this._deleteConfirm}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  showImageError = () => {};

  onImageLoaded = () => {
    const item = this.props.location.state.item;

    var assettype = this.props.location.state.assettype;

    let productImageFromUrl;

    if (assettype === "PRODUCT") {
      productImageFromUrl =
        APPLICATION_CONST.productImageUrl +
        item.productid +
        ".png" +
        "?" +
        new Date();
    } else if (assettype === "CATEGORY") {
      productImageFromUrl =
        APPLICATION_CONST.categoryImageUrl +
        item.categoryid +
        ".png" +
        "?" +
        new Date();
    } else if (assettype === "VENDOR") {
      productImageFromUrl =
        APPLICATION_CONST.storeImageUrl +
        item.vendorid +
        ".jpg" +
        "?" +
        new Date();
    }

    this.setState({
      imageExists: true,
      productImageFromUrl: productImageFromUrl,
    });
  };

  onImageLoadError = () => {
    this.setState({
      imageExists: false,
    });
  };

  onImageLoadEnded = () => {};

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.deleteProductError !== this.props.deleteProductError &&
      nextProps.deleteProductError
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.deleteProductError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.uploadproductimageerror !==
        this.props.uploadproductimageerror &&
      nextProps.uploadproductimageerror
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.uploadproductimageerrormessage,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  onChange = (imageList) => {};
  onError = (errors, files) => {
    console.log(errors, files);
  };

  addImageProcess = (src) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve([img.height, img.width]);
      img.onerror = reject;
      img.src = src;
    });
  };

  handleChange = (e) => {
    let assets = this.fileTypeAllowed();
    if (e.target.files[0]) {
      let imageExtension = e.target.files[0].type;
      let imageFile = e.target.files[0];
      let imageUrl = URL.createObjectURL(imageFile);
      let imageSize = e.target.files[0].size;
      let errorMsg = "";
      var assettype = this.props.location.state.assettype;

     

      this.addImageProcess(imageUrl).then((dimension) => {
        let height = dimension[0];
        let width = dimension[1];

        let allowedFileType =
          assets.filetype === "JPG" ? "JPEG" : assets.filetype;
        if (imageExtension !== "image/" + allowedFileType.toLowerCase()) {
          if (assettype === "PRODUCT") {
            errorMsg = "Product image should be a";
          } else if (assettype === "CATEGORY") {
            errorMsg = "Category image should be a";
          } else if (assettype === "VENDOR") {
            errorMsg = "Vendor image should be a";
          }

          this.setState({
            displayDialog: true,
            dialogTitle: "Oops!",
            dialogBody: `${errorMsg} ${assets.filetype}`,
            dialogFooter: (
              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.closeDialog}
              >
                Close
              </button>
            ),
          });
          return false;
        } else if (assets.maxsize < imageSize / (1024 * 1024)) {
          const filesize = (assets.maxsize / 1024).toString() + "MB";
          if (assettype === "PRODUCT") {
            errorMsg = "Product image should be under";
          } else if (assettype === "CATEGORY") {
            errorMsg = "Category image should be under";
          } else if (assettype === "VENDOR") {
            errorMsg = "Vendor image should be under ";
          }
          this.setState({
            displayDialog: true,
            dialogTitle: "Oops!",
            dialogBody: `${errorMsg} ${filesize}`,
            dialogFooter: (
              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.closeDialog}
              >
                Close
              </button>
            ),
          });
          return false;
        } else if (assets.height !== height || assets.width !== width) {
          if (assettype === "PRODUCT") {
            errorMsg = "Product image should";
          } else if (assettype === "CATEGORY") {
            errorMsg = "Category image should";
          } else if (assettype === "VENDOR") {
            errorMsg = "Vendor image should";
          }
          this.setState({
            displayDialog: true,
            dialogTitle: "Oops!",
            dialogBody: `${errorMsg} ${assets.width} x ${assets.height} px.`,
            dialogFooter: (
              <button
                className="btn"
                style={styles.dialogCancelButtonStyle}
                onClick={this.closeDialog}
              >
                Close
              </button>
            ),
          });

          return false;
        } else {
          this.setState({
            productImage: URL.createObjectURL(imageFile),
            imageData: imageFile,
          });
        }
      });
    }
  };

  handleClick(e) {
    this.refs.fileUploader.click();
  }

  fileTypeAllowed = () => {
    let assettype = this.props.location.state.assettype;
    let assets = this.props.userdetails.assets.find(
      (x) => x.assettype === assettype
    );

    return assets;
  };

  goBack = () => {
    var assettype = this.props.location.state.assettype;

    if (assettype === "PRODUCT") {
      this.props.history.push("/productmaster");
    } else if (assettype === "CATEGORY") {
      this.props.history.push("/categorys");
    } else if (assettype === "VENDOR") {
      this.props.history.push("/vendormaster");
    }
  };

  render() {
    this.fileTypeAllowed();
    const item = this.props.location.state.item;
    var assettype = this.props.location.state.assettype;

    let assetid;
    let imageurlcheck;

    const { width, height } = this.state.dimensions;

    if (assettype === "PRODUCT") {
      assetid = item.productid;
      imageurlcheck =
        APPLICATION_CONST.productImageUrl +
        item.productid +
        ".png" +
        "?" +
        new Date();
    } else if (assettype === "CATEGORY") {
      assetid = item.categoryid;
      imageurlcheck =
        APPLICATION_CONST.categoryImageUrl +
        item.categoryid +
        ".png" +
        "?" +
        new Date();
    } else if (assettype === "VENDOR") {
      assetid = item.vendorid;
      imageurlcheck =
        APPLICATION_CONST.storeImageUrl +
        item.vendorid +
        ".jpg" +
        "?" +
        new Date();
    }

    return (
      <>
        <NavBar />
        <Loader message={this.state.loaderMessage} />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a className={"back-link"} onClick={() => this.goBack()}>
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>
                  {" "}
                  {assettype === "PRODUCT"
                    ? " Product "
                    : assettype === "CATEGORY"
                    ? " Category "
                    : assettype === "VENDOR"
                    ? " Vendor "
                    : null}{" "}
                  Image Upload
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div className="container-fluid stores-container">
          {/* dimensions width: {width}, height: {height} */}

          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            onChange={this.handleChange}
            onClick={(e) => (e.target.value = null)}
            ref="fileUploader"
            accept={`image/${
              this.fileTypeAllowed().filetype === "JPG"
                ? "JPEG"
                : this.fileTypeAllowed().filetype
            }
            `}
          ></input>
          <img
            alt=""
            onError={() => this.onImageLoadError()}
            onLoad={() => this.onImageLoaded()}
            onLoadEnd={(e) => this.onImageLoadEnded()}
            style={{ height: 1, width: 1, opacity: 0 }}
            src={imageurlcheck}
          />
          {this.state.productImage === null &&
          this.state.imageExists === false ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    height: "250px",
                    width: "250px",
                    border: "1px dashed black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => this.handleClick()}
                >
                  <span>
                    Tap to Select
                    {assettype === "PRODUCT"
                      ? " Product "
                      : assettype === "CATEGORY"
                      ? " Category "
                      : assettype === "VENDOR"
                      ? " Vendor "
                      : null}
                    Image
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div className="row">
              <div className="col-md-12">
                {this.state.productImage !== null ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "250px",
                        width: "250px",
                        border: "1px dashed black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => this.handleClick()}
                    >
                      <img
                        alt=""
                        src={this.state.productImage}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        height: "250px",
                        width: "250px",
                        border: "1px dashed black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => this.handleClick()}
                    >
                      <img
                        alt=""
                        src={this.state.productImageFromUrl}
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-12" style={{ marginTop: 10 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {this.state.imageExists === true ? (
                    <div
                      style={{
                        width: "250px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          marginBottom: 10,
                          width: "100%",
                          ...styles.dialogConfirmButtonStyle,
                        }}
                        // for="upload-button"
                        onClick={this.handleClick}
                      >
                        Change{" "}
                        {assettype === "PRODUCT"
                          ? " Product "
                          : assettype === "CATEGORY"
                          ? " Category "
                          : assettype === "VENDOR"
                          ? " Vendor "
                          : null}{" "}
                        Image
                      </Button>
                    </div>
                  ) : null}

                  {this.state.imageExists === true ? (
                    <div
                      style={{
                        width: "250px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        style={{
                          marginBottom: 10,
                          width: "100%",
                          ...styles.dialogCancelButtonStyle,
                        }}
                        onClick={() => this.deleteImage()}
                      >
                        Delete
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
          {this.state.productImage !== null ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "250px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => this._onSubmit()}
                  style={{
                    marginBottom: 10,
                    width: "100%",
                    ...styles.dialogConfirmButtonStyle,
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          ) : null}
          <div className="spacer"></div>
        </div>

        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  userdetails: state.SignInReducer.logindetails,
  products: state.VendorProductReducer.products,
  deleteProductError: state.VendorProductReducer.deleteerrormessage,
  deleteProductSuccess: state.VendorProductReducer.deletesuccessmessage,
  uploadproductimageerrormessage:
    state.VendorProductReducer.uploadproductimageerrormessage,
  uploadproductimageerror: state.VendorProductReducer.uploadproductimageerror,
});
export default connect(mapStateToProps, {
  callProductMasterWebService,
  updateProductService,
  productDeleteWebService,
  uploadProductImage,
})(ProductMaster);
