import axios from "axios";
import { APPLICATION_CONST,FAC_MERCHANT_URL } from "../../Config/CustomConfig";

export const getPaymentGateway = (data) => {
  return new Promise((resolve, reject) => {
    const body = {
      username: data.username,
      sessionid: data.sessionid,
      failstatus: 0,
      request: {},
    };

    console.log("request", JSON.stringify(body));

    axios({
      method: "post",
      url: `${APPLICATION_CONST.url}getpaymentgateway`,
      data: body,
    })
      .then((response) => {
       
        console.log("response", JSON.stringify(response.data));
        // ******** save login detail into local storage *********//
        let setPaymentData = {
          ...response.data.data,
          redirecturl:  FAC_MERCHANT_URL,
        };

        console.log("setPaymentData", setPaymentData);
        localStorage.setItem(
          "PaymentGatewayDetail",
          JSON.stringify(setPaymentData)
        );
      })
      .catch((error) => {
        console.log("error", error);
      });
  });
};
