import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "../../styles/commontStyles";
import DialogModal from "../common/dialogModal";
import Loader from "../common/loader";
import { emptyCart, confirmCart } from "../../actions/public/paymentAction";

class OrderSuccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: null,
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  cancelPaymentProcess = () => {
    this.props.emptyCart();
    this.props.history.push("/");
  };

  _cancelCheckOutOrder = () => {
    const logindetails = JSON.parse(localStorage.getItem("loginDetail"));

    let orderErrorType = this.props.orderErrorType;
    let errorMessage = this.props.location.state.errorMessage;
    let resubmitData = this.props.location.state.resubmitData;
    if (orderErrorType === "PNP_HELD_PAYMENT_FAIL") {
      this.props.history.push("/orders", {
        actiontype: "CHECKEDINORDERS",
      });
    } else {
      let resubmitData = this.props.location.state.resubmitData;
      let data = {
        username: resubmitData.username,
        sessionid: resubmitData.sessionid,
        ordereditems: resubmitData.ordereditems,
        processaction: "CANCELCHECKOUTORDER",
        logindata: {
          username: logindetails.username,
          password: logindetails.password,
          internal: logindetails.internal,
        },
      };
      this.props.confirmCart(data, this.props.history);
    }
  };

  makeCheckOutPayment = () => {
    let walkin = this.props.location.state.walkin;
    if (walkin === "Y") {
      this.props.history.goBack();
    } else {
      this.props.history.goBack();
    }
    return false;
  };

  resubmitOrder = () => {
    let resubmitData = this.props.location.state.resubmitData;
    this.props.confirmCart(resubmitData, this.props.history);
  };

  render() {
    let errorMessage = this.props.location.state.errorMessage;
    let resubmitData = this.props.location.state.resubmitData;
    return (
      <>
        <NavBar />
        <Loader message="Processing..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <div
          className="container-fluid"
          style={{ paddingBottom: 50, paddingTop: 50 }}
        >
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <FontAwesomeIcon
                icon="times-circle"
                color="#FF0000"
                style={{ fontSize: 140 }}
              />
              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                Payment Failed
              </div>

              <div style={{ marginTop: 30, fontSize: 15, fontWeight: "bold" }}>
                {errorMessage}!
              </div>

              <div
                style={{
                  marginTop: 30,
                  fontSize: 30,
                  fontWeight: "bold",
                  justifyContent: "space-between",
                }}
              >
                <button
                  onClick={this.makeCheckOutPayment}
                  className="btn "
                  style={{
                    ...styles.confirmButtonStyle,
                    ...{ marginRight: 15 },
                  }}
                >
                  MAKE PAYMENT
                </button>

                <button
                  onClick={this.resubmitOrder}
                  className="btn "
                  style={{
                    ...styles.confirmButtonStyle,
                    ...{},
                  }}
                >
                  RESUBMIT
                </button>

                <button
                  onClick={this.cancelPaymentProcess}
                  className="btn "
                  style={{
                    ...styles.confirmButtonStyle,
                    ...{ marginLeft: 15 },
                  }}
                >
                  CANCEL PROCESS
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

//  export default Success;
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
  orderErrorType: state.OrderProcessReducer.orderErrorType,
});

export default connect(mapStateToProps, { confirmCart, emptyCart })(
  OrderSuccess
);
