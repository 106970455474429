import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../styles/commontStyles";
import NavBar from "../common/navbar";
import "react-datepicker/dist/react-datepicker.css";
import Footer from "../common/footer";
import { getReportService } from "../../actions/vendor/reportAction";
import DialogModal from "../common/dialogModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

class Runreport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      fromDate: new Date(),
      toDate: new Date(),
      from: "",
      to: "",
      toggle: false,
    };
  }
  handleFromChange = (value) => {
    this.setState({
      fromDate: value,
    });
    const dateString = `${value.getFullYear()}/${
      value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
    }/${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
    this.setState({
      from: dateString,
    });
  };
  handleToChange = (value) => {
    this.setState({
      toDate: value,
    });
    const dateString = `${value.getFullYear()}/${
      value.getMonth() < 9 ? "0" + (value.getMonth() + 1) : value.getMonth() + 1
    }/${value.getDate() < 10 ? "0" + value.getDate() : value.getDate()}`;
    this.setState({
      to: dateString,
    });
  };
  componentDidMount = () => {
    window.scrollTo(0,0);

  };

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.status === "error") {
      if (nextProps.status !== this.props.status && nextProps.status !== null) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.errorMessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  };

  showReport = (e) => {
    e.preventDefault();
    let toDate = this.state.toDate;
    let fromDate = this.state.fromDate;

    const startDate = `${fromDate.getFullYear()}${
      fromDate.getMonth() < 9
        ? "0" + (fromDate.getMonth() + 1)
        : fromDate.getMonth() + 1
    }${
      fromDate.getDate() < 10 ? "0" + fromDate.getDate() : fromDate.getDate()
    }`;
    const endDate = `${toDate.getFullYear()}${
      toDate.getMonth() < 9
        ? "0" + (toDate.getMonth() + 1)
        : toDate.getMonth() + 1
    }${toDate.getDate() < 10 ? "0" + toDate.getDate() : toDate.getDate()}`;

    const data = {
      username: this.props.logindetails.username,
      sessionid: this.props.logindetails.sessionid,
      request: {
        extractid: 1,
        startdate: startDate,
        enddate: endDate,
      },
    };
    this.props.getReportService(data);
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };
  render() {
    const reportFileName = this.props.reportFileName;
    const source = reportFileName;
    const headerTitle = this.props.location.state.headerTitle;
    return (
      <>
        <NavBar />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        {/* <div
          style={{
            backgroundColor: "#F1F1F1",
            borderTop: "1px solid #DDD",
            borderBottom: "1px solid #DDD",
            marginBottom: 20,
          }}
        >
          <div style={{ padding: 20, textAlign: "center" }}>
            <button
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "#777",
                marginLeft: 20,
                marginTop: 20,
                float: "left",
                display: "inline-block",
              }}
              onClick={() => this.props.history.goBack()}
            >
              <FontAwesomeIcon
                icon="chevron-left"
                color="#777"
                className={"back-icon"}
              />
              Back
            </button>
            <div style={styles.pageHeadingStyle}>{headerTitle}</div>
          </div>
        </div> */}
        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>{headerTitle}</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid stores-container">
          <center>
            <iframe
              id="receipt"
              src={
                // "https://db1.reliablesoftjm.com/reports/vipaFastTrackReceipt_21252.pdf"
                source
              }
              style={{ display: "block", height: 800, width: "80%" }}
              title="Report"
            />
          </center>
          <div className="spacer"> </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  reportFileName: state.ReportsReducer.reportFileName,
  status: state.ReportsReducer.status,
  errorMessage: state.ReportsReducer.errorMessage,
});

export default connect(mapStateToProps, { getReportService })(Runreport);
