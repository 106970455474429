import axios from "axios";
import { APPLICATION_CONST } from "../../Config/CustomConfig";

export const validateScheduleId = (data) => {
  return new Promise((resolve, reject) => {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

    const body = {
      username: loginDetail.username,
      sessionid: loginDetail.sessionid,
      failstatus: 0,
      request: data,
    };

    console.log("end point", `${APPLICATION_CONST.url}validatescheduleid`);
    console.log("validatescheduleid request:", JSON.stringify(body));
    axios({
      method: "post",
      url: `${APPLICATION_CONST.url}validatescheduleid`,
      data: body,
    })
      .then((response) => {
        console.log("rrrr", response);
        let testMode = false;
        let resoponseData;
        if (testMode) {
          resoponseData = JSON.parse(
            '{"status":0,"statusMessage":"Success","severity":0,"username":"rsadhi","sessionid":"00000005333012744915811032","data":{"valid":"Y"}}'
          );
        } else {
          resoponseData = response.data;
        }

        console.log("response", resoponseData);
        if (resoponseData.status === 0) {
          console.log("orderid response:", JSON.stringify(resoponseData));
          resolve({
            success: true,
            statusMessage: resoponseData.statusMessage,
            valid: "Y",
          });
        } else if (resoponseData.status > 0) {
          resolve({
            success: false,
            statusMessage: resoponseData.statusMessage,
            valid: "N",
          });
        } else {
          resolve({
            success: false,
            statusMessage: "Something went wrong",
            valid: "N",
          });
        }
      })
      .catch((error) => {
        resolve({
          success: false,
          statusMessage: error.message,
          valid: "N",
        });
      });
  });
};
