import React, { Component } from "react";
import { connect } from "react-redux";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import { addToCart } from "../../actions/public/cartAction";
import { Image, Card, Toast, Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  APPLICATION_CONST,
  MAX_BRAND_SHOW,
  PRODUCT_SHOW,
} from "../../Config/CustomConfig";
import { styles } from "../../styles/commontStyles";

import InputRange from "react-input-range";
import SortBy from "../common/sortBy";
import ReactPaginate from "react-paginate";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayToast: false,
      toastMessage: "",
      selectedProduct: [],
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
      filterProducts: this.props.location.state.category.products,
      value: { min: null, max: null },
      searchTag: "",
      sortBy: "product_asc",
      products: this.filterProducts(),
      max_brand_show: MAX_BRAND_SHOW,
    };
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    if (!this.props.location.state) return this.props.history.push("/");
  };

  _goToProductDetail = (product) => {
    const storeid = this.props.location.state.storeid;
    const storename = product.vendorname;
    const category = this.props.location.state.category;

    this.props.history.push("/product/detail", {
      product: product,
      storeid: storeid,
      storename: storename,
      category: category,
    });
  };

  _addToCart = (product) => {
    if (product.siblings) {
      const storeid = this.props.location.state.storeid;
      const storename = product.vendorname;
      const category = this.props.location.state.category;

      this.props.history.push("/product/detail", {
        product: product,
        storeid: storeid,
        storename: storename,
        category: category,
      });
    } else {
      const storeid = this.props.location.state.storeid;
      const storename = this.props.location.state.storename;

      product.vendorid = storeid;
      product.vendorname = storename;

      this.props.addToCart({ ...product, quantity: 1 });
    }
    this.setState({
      displayToast: true,
      toastMessage: `${product.productname} added to your cart.`,
    });
    this.setState({ flag: 1 });
  };

  onBtnClick = (product) => {
    const storeid = this.props.location.state.storeid;
    const storename = this.props.location.state.storename;
    this.props.history.push("/product/detail", {
      product: product,
      storeid: storeid,
      storename: storename,
      category: this.props.location.state.category,
    });
  };

  goToCartCheck = (productid) => {
    const cart = this.props.cartItems.filter(
      (product) => product.productid === productid
    ).length;
    return cart;
  };

  getMinPrice = () => {
    const products = this.props.location.state.category.products;

    if (products) {
      if (products.length > 0) {
        return products.reduce(
          (min, product) => (product.unitcost < min ? product.unitcost : min),
          products[0].unitcost
        );
      } else {
        return 0;
      }
    }
  };

  getMaxPrice = () => {
    const products = this.props.location.state.category.products;
    if (products) {
      if (products.length > 0) {
        return products.reduce(
          (max, product) => (product.unitcost > max ? product.unitcost : max),
          products[0].unitcost
        );
      } else {
        return 1000;
      }
    }
  };

  productFilter() {
    let product = this.props.location.state.category.products;
    let products = product ? [...product] : [];

    let sortBy = this.state.sortBy;

    if (sortBy === "product_asc") {
      products.sort(function (a, b) {
        if (a.productname < b.productname) {
          return -1;
        }
        if (a.productname > b.productname) {
          return 1;
        }
      });
    }

    if (sortBy === "product_desc") {
      products.sort(function (a, b) {
        if (a.productname > b.productname) {
          return -1;
        }
        if (a.productname < b.productname) {
          return 1;
        }
      });
    }

    if (sortBy === "price_asc") {
      products.sort(function (a, b) {
        if (a.unitcost < b.unitcost) {
          return -1;
        }
        if (a.unitcost > b.unitcost) {
          return 1;
        }
      });
    }

    if (sortBy === "price_desc") {
      products.sort(function (a, b) {
        if (a.unitcost > b.unitcost) {
          return -1;
        }
        if (a.unitcost < b.unitcost) {
          return 1;
        }
      });
    }

    if (this.state.selectedProduct.length > 0) {
      products = products.filter(
        (product) =>
          this.state.selectedProduct.indexOf(product.productname) !== -1
      );
    }

    if (this.state.value.min != null && this.state.value.max != null) {
      products = products.filter(
        (product) =>
          this.state.value.min <= product.unitcost &&
          product.unitcost <= this.state.value.max
      );
    }

    if (this.state.searchTag != "") {
      products = products.filter(
        (product) =>
          product.productname
            .toLowerCase()
            .indexOf(this.state.searchTag.toLowerCase()) !== -1
      );
    }

    let slice = products.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    let data = {
      slice: slice,
      products: products,
    };

    return data;
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.productFilter();
      }
    );
  };

  brandRender = (product,index) => {
    return (
      // <label className={"brand-tag"}>
      //   <input
      //     type="checkbox"
      //     value={product.productname}
      //     checked={
      //       this.state.selectedProduct.indexOf(product.productname) !== -1
      //     }
      //     onChange={(e) => this.changeProductFilter(e)}
      //   />{" "}
      //   &nbsp;{product.productname}
      // </label>


<>
<input
  type="checkbox"
  value={product.productname}
  checked={
    this.state.selectedProduct.indexOf(product.productname) !== -1
  }
  id={"chk_" + index}
  className={"sidebar-filter-chk"}
  onChange={(e) => this.changeProductFilter(e)}
/>
<label for={"chk_" + index} className={"brand-tag"} key={index}>
  {product.productname}
</label>
</>
    );
  };

  changeProductFilter = ({ target }) => {
    let productname = target.value;

    if (target.checked) {
      this.setState({
        selectedProduct: [...this.state.selectedProduct, productname],
        offset: 0,
        perPage: 10,
        currentPage: 0,
      });
    } else {
      let products = this.state.selectedProduct.filter(
        (product) => product !== productname
      );

      this.setState({
        selectedProduct: products,
        offset: 0,
        perPage: 10,
        currentPage: 0,
      });
    }
  };

  filterProducts = () => {
    let products = this.props.location.state.category.products
      ? this.props.location.state.category.products
      : [];

    if (products) {
      products.sort(function (a, b) {
        if (a.productname < b.productname) {
          return -1;
        }
        if (a.productname > b.productname) {
          return 1;
        }
      });
    }

    return products;
  };

  resetSelection = () => {
    this.setState({
      selectedProduct: [],
    });
  };

  render() {
    if (!this.props.location.state) return null;
    return (
      <>
        <NavBar />
        <Toast
          onClose={() => this.setState({ displayToast: false })}
          show={this.state.displayToast}
          delay={2000}
          autohide
          className={"toast"}
        >
          <Toast.Body>
            <span className={"toast-message"}> {this.state.toastMessage}</span>
          </Toast.Body>
        </Toast>
        <Container className={"back-bar-container"} fluid>
          <Row className={"back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => {
                  this.props.history.push("/store/categories", {
                    storeid: this.props.location.state.storeid,
                  });
                }}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>
        <div className="container-fluid stores-container">
          {this.filterProducts().length > 0 ? (
            <Container fluid className={"main-container-products"}>
              <div className="row">
                <section className="col-md-2 products-filter-sidebar">
                  <section>
                    <h5>PRICE</h5>
                    <div className={"price-range-container"}>
                      <InputRange
                        maxValue={this.getMaxPrice()}
                        minValue={this.getMinPrice()}
                        value={
                          this.state.value.min != null &&
                          this.state.value.min != null
                            ? {
                                min: this.state.value.min,
                                max: this.state.value.max,
                              }
                            : {
                                min: this.getMinPrice(),
                                max: this.getMaxPrice(),
                              }
                        }
                        onChange={(value) =>
                          this.setState({
                            value: value,
                            offset: 0,
                            perPage: 10,
                            currentPage: 0,
                          })
                        }
                        formatLabel={(value) => "$" + value}
                      />
                    </div>
                  </section>
                  <section>
                    <h5>BRAND</h5>
                    {this.state.selectedProduct.length > 0 ? (
                      <a
                        href="javascript:void(0);"
                        onClick={() => this.resetSelection()}
                        className={"clear-selecion "}
                      >
                        Clear Selection
                      </a>
                    ) : null}
                    <div style={{ marginTop: 15 }}>
                      <input
                        onChange={(e) =>
                          this.setState({
                            searchTag: e.target.value,
                            offset: 0,
                            perPage: 10,
                            currentPage: 0,
                          })
                        }
                        type="text"
                        value={this.state.searchTag}
                        className="form-control"
                        placeholder="Search (Lindt, Rum..)"
                        disabled={this.state.edit}
                      />
                    </div>
                    <div style={{ marginTop: 15 }}>
                      {this.filterProducts().map((product, index) =>
                        index <= this.state.max_brand_show
                          ? this.brandRender(product)
                          : null
                      )}
                    </div>

                    {this.state.max_brand_show === MAX_BRAND_SHOW ? (
                      this.state.max_brand_show <
                      this.filterProducts().length ? (
                        <a
                          href="javascript:void(0);"
                          onClick={() => {
                            this.setState({ max_brand_show: 50000 });
                          }}
                        >
                          Show More >>
                        </a>
                      ) : null
                    ) : null}

                    {this.state.max_brand_show > MAX_BRAND_SHOW &&
                    this.state.max_brand_show > this.filterProducts().length ? (
                      <a
                        href="javascript:void(0);"
                        onClick={() => {
                          this.setState({ max_brand_show: MAX_BRAND_SHOW });
                        }}
                      >
                        Show Less >>
                      </a>
                    ) : null}
                  </section>
                </section>

                {this.filterProducts().length > 0 ? (
                  <section className="col-md-10">
                    <div className="row">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div className=" pull-right">
                          <label style={{ fontWeight: "bold" }}>
                            Show&nbsp;
                          </label>
                          <select
                            className=""
                            value={this.state.perPage}
                            onChange={(e) =>
                              this.setState({
                                perPage: e.target.value,
                                offset: 0,
                                currentPage: 0,
                              })
                            }
                          >
                            {PRODUCT_SHOW.map((show, index) => (
                              <option value={show.count}>{show.count}</option>
                            ))}
                          </select>
                        </div>
                        <div
                          className=" pull-right"
                          style={{ marginRight: 15 }}
                        >
                          <label style={{ fontWeight: "bold" }}>
                            Sort By&nbsp;
                          </label>
                          <SortBy
                            handleChange={(value) => {
                              this.setState({
                                sortBy: value,
                                offset: 0,
                                perPage: 10,
                                currentPage: 0,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row" style={{ marginTop: 30 }}>
                      {this.productFilter().slice.map((product, index) => (
                        <div
                          className="col-md-3 col-sm-3 col-xs-6 product-card-outer"
                          key={index}
                          title={product.description}
                        >
                          {" "}
                          {product.stickerid != "" ? (
                            <img alt=""
                              src={
                                APPLICATION_CONST.stickerImages +
                                product.stickerid
                              }
                              className={"product-sticker"}
                            />
                          ) : null}
                          <Card style={{ ...styles.productCardStyle }}>
                            <Card.Img
                              onClick={() => this._goToProductDetail(product)}
                              variant="top"
                              className={"product-card-image"}
                              src={
                                APPLICATION_CONST.productImageUrl +
                                product.productreferenceid +
                                ".png"+"?" +
                                        new Date()
                              }
                            />
                            <Card.Body className={"product-card-body"}>
                              <Card.Title
                                onClick={() => this._goToProductDetail(product)}
                                className="productNameCaption"
                              >
                                {product.productname}
                              </Card.Title>
                              <Card.Text>
                                {product.msrp > product.unitcost ? (
                                  <>
                                    <div className="productCostText">
                                      <strike>USD $ {product.msrp}</strike>
                                    </div>
                                    <div className="productCostText">
                                      USD $ {product.unitcost}
                                    </div>
                                  </>
                                ) : (
                                  <div className="productCostText">
                                    USD ${product.unitcost}
                                  </div>
                                )}
                              </Card.Text>
                            </Card.Body>
                            {this.goToCartCheck(product.productid) > 0 ? (
                              <button
                                onClick={() => this.props.history.push("/cart")}
                                className={"goToCartButton"}
                              >
                                Go to Cart
                              </button>
                            ) : (
                              <button
                                onClick={() => this._addToCart(product)}
                                className={"addToCartButton"}
                              >
                                Add to Cart&nbsp;&nbsp;
                                <FontAwesomeIcon
                                  icon="shopping-cart"
                                  color="#fff"
                                  size="lg"
                                />
                              </button>
                            )}
                          </Card>
                        </div>
                      ))}
                    </div>
                    <div className="row products-pagination-container">
                      <div className="col-md-12">
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.ceil(
                            this.productFilter().products.length /
                              this.state.perPage
                          )}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          forcePage={this.state.currentPage}
                        />
                      </div>
                    </div>
                  </section>
                ) : null}
              </div>
            </Container>
          ) : (
            <Container fluid className={"main-container-products"}>
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ justifyContent: "center", textAlign: "center" }}
                >
                  <h5 style={{ color: "red" }}>No Products Found</h5>
                </div>
              </div>
            </Container>
          )}
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  stores: state.StoreReducer.stores,
  flightData: state.FlightInfoReducer.formData,
  products: state.VendorProductReducer.products,
  logindetails: state.SignInReducer.logindetails,
  cartItems: state.CartReducer.cart,
});
export default connect(mapStateToProps, { addToCart })(Products);
