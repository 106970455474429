import {
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_ERROR,
  UPDATE_CHANGE_PASSWORD_FORM,
  SHOW_CHANGE_PASSWORD_DIALOG,
  HIDE_CHANGE_PASSWORD_DIALOG,
  DISABLE_FORCE_RESET_PASSWORD,
} from "./actionTypes";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import axios from "axios";
import md5 from "md5";
import { loaderStatus } from "../vendor/loaderAction";

export const updateChangePasswordForm = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_CHANGE_PASSWORD_FORM,
    payload: data,
  });
};
export const showChangePasswordDialog = () => (dispatch) => {
  dispatch({ type: SHOW_CHANGE_PASSWORD_DIALOG });
};

export const hideChangePasswordDialog = () => (dispatch) => {
  dispatch({ type: HIDE_CHANGE_PASSWORD_DIALOG });
};

export const changePasswordServicePending = () => ({
  type: CHANGE_PASSWORD_PENDING,
});

export const changePasswordServiceSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordServiceError = (error) => ({
  type: CHANGE_PASSWORD_ERROR,
  payload: error,
});

export const callChangePasswordService = (data, history) => {
  const body = {
    username: data.username,
    sessionid: data.sessionid,
    failstatus: 0,
    request: {
      oldpassword: md5(data.oldPassword),
      newpassword: md5(data.newPassword),
    },
  };

  return (dispatch) => {
    dispatch(changePasswordServicePending());
    dispatch(loaderStatus(true));

    axios({
      method: "post",
      url: APPLICATION_CONST.url + "changepassword",
      data: body,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(changePasswordServiceSuccess());
          dispatch(loaderStatus(false));

          // ******** update the newpassword insessionStorage *********//
          const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
          loginDetail.password = body.request.newpassword;
          localStorage.setItem("loginDetail", JSON.stringify(loginDetail));
          dispatch({ type: DISABLE_FORCE_RESET_PASSWORD });
          history.push("/");
        } else if (response.data.status > 0) {
          dispatch(changePasswordServiceError(response.data.statusMessage));
          dispatch(loaderStatus(false));
        } else {
          dispatch(
            changePasswordServiceError(
              "Something went wrong.Please try again later!"
            )
          );
          dispatch(loaderStatus(false));
        }
      })
      .catch((error) => {
        dispatch(loaderStatus(false));

        dispatch(changePasswordServiceError(error.message));
      });
  };
};
