import React, { Component } from "react";
import { connect } from "react-redux";
import md5 from "md5";
import { Link } from "react-router-dom";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import "bootstrap/dist/css/bootstrap.min.css";
import { styles } from "../../styles/commontStyles";
import { callLoginService } from "../../actions/authentication/signInAction";
import "../../styles/style.css";
import { Container, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { SUBSCRIBER_LOGIN } from "../../Config/CustomConfig";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: { value: "", error: "" },
      password: { value: "", error: "" },
      terms: false,
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      displayPassword: false,
    };
  }
  componentDidMount() {
    const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

    if (!loginDetail) {
      const data = {
        failstatus: 0,
        username: SUBSCRIBER_LOGIN.username,
        password: SUBSCRIBER_LOGIN.password,
        terms: SUBSCRIBER_LOGIN.terms,
        silentLogin: true,
        internal: "Y",
      };
      this.props.callLoginService(data, this.props.history);
    } else if (loginDetail.terms && !loginDetail.internal) {
      const data = {
        username: loginDetail.username,
        password: loginDetail.password,
        terms: loginDetail.terms,
        internal: "N",
      };
      this.props.callLoginService(data, this.props.history);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.error !== this.props.error && nextProps.error !== null) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.error,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  togglePassword = () => {
    this.setState({
      displayPassword: !this.state.displayPassword,
    });
  };
  resetError = () => {
    this.setState({
      username: { ...this.state.username, error: "" },
      password: { ...this.state.password, error: "" },
    });
  };
  handelSubmit = (e) => {
    e.preventDefault();
    const username = this.state.username;
    const password = this.state.password;
    if (username.value === "") {
      this.setState({
        username: { ...username, error: "Username is required." },
      });
    }
    if (password.value === "") {
      this.setState({
        password: { ...password, error: "Password is required." },
      });
    }

    if (username.value !== "" && password.value !== "") {
      const data = {
        username: this.state.username.value,
        password: md5(this.state.password.value),
        terms: this.state.terms,
        internal: "N",
      };

      this.props.callLoginService(data, this.props.history);
    }
  };

  render() {
    return (
      <>
        <Loader message="Signing In..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"auth-container"} fluid>
          <div className="auth-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <a href={"/"}>
                <Image
                  type="button"
                  style={{
                    height: 60,
                    width: "auto",
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={APPLICATION_CONST.applicationImages + "logo.png"}
                />
              </a>
            </div>

            <div>
              <h5 style={{ textAlign: "center", fontFamily: "Poppins-Light" }}>
                Sign In
              </h5>

              <form className="form-signin" onSubmit={this.handelSubmit}>
                <div className="row">
                  <div className="col-md-12 myInput-continer">
                    <div className="form-group">
                      <input
                        value={this.state.username.value}
                        autoFocus={true}
                        type="text"
                        className="form-control myInput"
                        placeholder="Username"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            username: {
                              ...this.state.username,
                              value: e.target.value,
                            },
                          })
                        }
                      />
                      {this.state.username.error && (
                        <small className="text-danger">
                          {this.state.username.error}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group myInput-continer">
                      <input
                        value={this.state.password.value}
                        type={this.state.displayPassword ? "text" : "password"}
                        className="form-control myInput"
                        placeholder="Password"
                        onFocus={this.resetError}
                        onChange={(e) =>
                          this.setState({
                            password: {
                              ...this.state.password,
                              value: e.target.value,
                            },
                          })
                        }
                      />
                      <FontAwesomeIcon
                        icon={this.state.displayPassword ? "eye" : "eye-slash"}
                        color="#495057"
                        style={{
                          position: "absolute",
                          top: 15,
                          right: 25,
                          cursor: "pointer",
                          zIndex: "5",
                        }}
                        size=""
                        onClick={() => this.togglePassword()}
                      />
                      {this.state.password.error && (
                        <small className="text-danger">
                          {this.state.password.error}
                        </small>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <div style={{ marginTop: 10 }}></div>
                      <button
                        className="btn btn-lg btn-block"
                        type="submit"
                        style={styles.confirmButtonStyle}
                      >
                        Sign in
                      </button>
                    </div>
                  </div>

                  <label
                    className="checkbox col-md-6"
                    style={{ fontSize: 12, marginTop: 15 }}
                  >
                    <input
                      type="checkbox"
                      onChange={(e) =>
                        this.setState({ terms: !this.state.terms })
                      }
                    />
                    &nbsp; Keep me Signed In
                  </label>
                  <label
                    className="col-md-6"
                    style={{ fontSize: 12, textAlign: "right", marginTop: 15 }}
                  >
                    <Link to="/reset-password" className="need-help">
                      Forgot Password?
                    </Link>
                  </label>
                </div>
              </form>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                fontSize: 12,
                fontFamily: "Poppins-Light",
              }}
            >
              DON'T HAVE AN ACCOUNT? &nbsp;
              <Link
                to="/register"
                style={{
                  fontSize: 12,
                  fontFamily: "Poppins-Light",
                  fontWeight: "bold",
                }}
              >
                SIGN UP NOW
              </Link>
            </div>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authed: state.SignInReducer.authed,
  error: state.SignInReducer.error,
});

export default connect(mapStateToProps, { callLoginService })(Login);
