import {
  ORDER_PROCESS_SUCCESS,
  ORDER_PROCESS_PENDING,
  ORDER_PROCESS_ERROR,
  UPDATE_EXTRA_ORDER_QUANTITY,
  ADD_TO_ORDER,
  EMPTY_EXTRA_ORDER,
  REMOVE_FROM_ORDER,
  ORDER_EDIT,
  ORDER_QTY_UPDATE,
  ADD_SIGNATURE,
  REMOVE_SIGNATURE,
  ORDER_UPDATE,
  UPDATE_CARD_DETAILS,
  RESET_CARD_DETAILS,
} from "../../actions/vendor/actionTypes";

const initialState = {
  extraOrder: [],
  extraOrderTotal: 0,
  loungeProducts: null,
  orderProcessError: null,
  orderProcessSuccess: null,
  order: {},
  signature: null,
  orderErrorType: null,
  orderEditSuccess: false,
  cardedetails: {
    cardHolderName: null,
    cardNumber: null,
    expiryDate: null,
    cvv: null,
  },
};

const _calcExtraOrderTotal = (extraOrders) => {
  let total = 0;
  extraOrders.forEach((item) => {
    total += item.unitcost * item.quantity;
  });
  return total;
};

const OrderProcessReducer = (state = initialState, action) => {
  let tempCart;
  let tempOrder;
  switch (action.type) {
    case ORDER_PROCESS_SUCCESS:
      return {
        ...state,
        orderProcessError: null,
        orderErrorType: null,
        orderProcessSuccess: action.payload,
        order: {},
        signature: null,
        extraOrder: [],
      };
    case ORDER_PROCESS_PENDING:
      return {
        ...state,
        orderProcessError: null,
        orderErrorType: null,
        orderProcessSuccess: null,
      };
    case ORDER_PROCESS_ERROR:
      return {
        ...state,
        orderProcessError: action.payload.error,
        orderErrorType: action.payload.errorType,
        orderProcessSuccess: null,
      };
    case ADD_TO_ORDER:
      const productExistInExtraOrder = state.extraOrder.find(
        (p) =>
          p.productid === action.payload.productid &&
          p.storeid === action.payload.storeid
      );

      if (productExistInExtraOrder) {
        return {
          ...state,
        };
      } else {
        const product = {
          vendorid: action.payload.vendorid,
          productid: action.payload.productid,
          productname: action.payload.productname,
          unitcost: action.payload.unitcost,
          quantity: 1,
          maxqty: action.payload.quantity,
          total: action.payload.unitcost,
          taxrate: action.payload.taxrate,
        };
        return {
          ...state,
          extraOrder: [product, ...state.extraOrder],
          extraOrderTotal: _calcExtraOrderTotal([product, ...state.extraOrder]),
        };
      }
    case REMOVE_FROM_ORDER:
      tempCart = state.extraOrder.filter(
        (item) => item.productid !== action.payload
      );
      return {
        ...state,
        extraOrder: tempCart,
        extraOrderTotal: _calcExtraOrderTotal(tempCart),
      };

    case EMPTY_EXTRA_ORDER:
      return {
        ...state,
        extraOrder: [],
        extraOrderTotal: 0,
      };
    case UPDATE_EXTRA_ORDER_QUANTITY:
      tempCart = [...state.extraOrder];
      tempCart.forEach((product) => {
        if (product.productid === action.payload.productid) {
          product.quantity = action.payload.qty;
          product.total = product.quantity * product.unitcost;
          return;
        }
      });
      return {
        ...state,
        extraOrder: tempCart,
        extraOrderTotal: _calcExtraOrderTotal(tempCart),
      };
    case ORDER_EDIT:
      return {
        ...state,
        order: action.payload.order,
        orderProcessError: null,
        orderErrorType: null,
        signature: null,
        extraOrder: [],
      };
    case ORDER_UPDATE:
      return {
        ...state,
        order: action.payload,
      };
    case ORDER_QTY_UPDATE:
      console.clear();
      tempOrder = { ...state.order };
      console.log('TEMP ORDER BEFORE:',tempOrder);

      tempOrder.orderitems.forEach((item) => {
        if (item.orderitemid === action.payload.orderitemid) {
          item.applyamount = action.payload.qty;
          return;
        }
      });

      console.log('TEMP ORDER AFTER:',tempOrder);

      return {
        ...state,
        order: tempOrder,
      };
    case ADD_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
      };
    case REMOVE_SIGNATURE:
      return {
        ...state,
        signature: null,
      };
    case UPDATE_CARD_DETAILS:
      return {
        ...state,
        cardedetails: action.payload,
      };
    case RESET_CARD_DETAILS:
      return {
        ...state,
        cardedetails: {
          cardHolderName: null,
          cardNumber: null,
          expiryDate: null,
          cvv: null,
        },
      };
    default:
      return state;
  }
};

export default OrderProcessReducer;
