import axios from "axios";
import {
  PRODUCT_SERVICE_ERROR,
  PRODUCT_SERVICE_PENDING,
  PRODUCT_SERVICE_SUCCESS,
  EDIT_PRODUCT_SERVICE_PENDING,
  EDIT_PRODUCT_SERVICE_SUCCESS,
  EDIT_PRODUCT_SERVICE_ERROR,
  PRODUCT_CATEGORY,
  GET_CLASSIFICATION_FAILED,
  GET_CLASSIFICATION_SUCCESS,
  UPDATE_CLASSIFICATION_FAILED,
  UPDATE_CLASSIFICATION_SUCCESS,
  GET_CLASS_TYPE_SUCCESS,
  GET_CLASS_TYPE_FAILED,
  UPDATE_CLASS_TYPE_FAILED,
  UPDATE_CLASS_TYPE_SUCCESS,
  DELETE_PRODUCT_ERROR,
  DELETE_PRODUCT_PENDING,
  DELETE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_MASTER_LIST_SUCCESS,
  UPDATE_PRODUCT_MASTER_LIST_FAILED,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAILED,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILED,
  UPLOAD_PRODUCT_IMAGE_SUCCESS,
  UPLOAD_PRODUCT_IMAGE_FAILED,
  SET_PRODUCT_PREVIOUS_QUANTITY
} from "./actionTypes";
import { loaderStatus } from "../vendor/loaderAction";
import { APPLICATION_CONST } from "../../Config/CustomConfig";

export const getProductService = (data) => (dispatch) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: !data ? {} : data,

  };

  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + "getproducts",
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(serviceActionSuccess(response.data.data));
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
      } else {
        dispatch(serviceActionError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(serviceActionError(error.message));
      dispatch(loaderStatus(false));
    });
};

export const serviceActionPending = () => ({
  type: PRODUCT_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: PRODUCT_SERVICE_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: PRODUCT_SERVICE_SUCCESS,
  payload: data,
});

export const updateProductService = (data) => (dispatch) => {
  const body = {
    username: data.username,
    sessionid: data.sessionid,
    failstatus: 0,
    request: {
      actiontype: data.actiontype,
      ...data.product,
    },
  };

  dispatch(editProductServicePending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + "updateproduct",
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(editProductServiceSuccess(response.data.statusMessage));
      } else if (response.data.status > 0) {
        dispatch(editProductServiceError(response.data.statusMessage));
      } else {
        dispatch(editProductServiceError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(loaderStatus(false));
      dispatch(editProductServiceError(error.message));
    });
};

export const editProductServicePending = () => (dispatch) => {
  dispatch({ type: EDIT_PRODUCT_SERVICE_PENDING });
};

export const editProductServiceSuccess = (value) => (dispatch) => {
  dispatch({
    type: EDIT_PRODUCT_SERVICE_SUCCESS,
    payload: value,
  });
};

export const editProductServiceError = (value) => (dispatch) => {
  dispatch({
    type: EDIT_PRODUCT_SERVICE_ERROR,
    payload: value,
  });
};

export const productCategory = (category) => ({
  type: PRODUCT_CATEGORY,
  payload: category,
});

export const getClassification = (data) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {},
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));

    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "getclassification",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(getClassificationSuccess(response.data.data));
        } else if (response.data.status > 0) {
          dispatch(getClassificationFailed(response.data.statusMessage));
        } else {
          dispatch(getClassificationFailed(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(getClassificationFailed("No Internet Connection", false));

        dispatch(loaderStatus(false));
      });
  };
};

export const getClassificationSuccess = (data) => ({
  type: GET_CLASSIFICATION_SUCCESS,
  payload: data,
});

export const getClassificationFailed = (error) => ({
  type: GET_CLASSIFICATION_FAILED,
  payload: error,
});

export const callUpdateClassification = (data, history) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    // failstatus:99,
    request: {
      actiontype: data.actiontype,
      classid: data.classid,
      classtypeid: data.classtypeid,
      description: data.classificationdescription,
    },
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));
    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "updateclassification",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(updateClassificationSuccess(response.data.data));
          history.push("/classifications/success", {
            type: "classification",
            actiontype: data.actiontype,
          });
        } else if (response.data.status > 0) {
          dispatch(updateClassificationFailed(response.data.statusMessage));
        } else {
          dispatch(updateClassificationFailed(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(updateClassificationFailed(error.message, false));
        dispatch(loaderStatus(false));
      });
  };
};

export const updateClassificationSuccess = (data) => ({
  type: UPDATE_CLASSIFICATION_SUCCESS,
  payload: data,
});

export const updateClassificationFailed = (error) => ({
  type: UPDATE_CLASSIFICATION_FAILED,
  payload: error,
});

export const getClassType = (data) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {},
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));
    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "getclasstype",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(getClassTypeSuccess(response.data.data));
        } else if (response.data.status > 0) {
          dispatch(getClassTypeFailed(response.data.statusMessage));
        } else {
          dispatch(getClassTypeFailed(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(getClassTypeFailed(error.message));
        dispatch(loaderStatus(false));
      });
  };
};

export const getClassTypeSuccess = (data) => ({
  type: GET_CLASS_TYPE_SUCCESS,
  payload: data,
});

export const getClassTypeFailed = (error) => ({
  type: GET_CLASS_TYPE_FAILED,
  payload: error,
});

export const callUpdateClassType = (data, history) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {
      actiontype: data.actiontype,
      classtypeid: data.classtypeid,
      description: data.classtypedescription,
    },
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));

    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "updateclasstype",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(updateClassTypeSuccess(response.data.data));
          history.push("/classtypes/success", {
            type: "classtype",
            actiontype: data.actiontype,
          });
        } else if (response.data.status > 0) {
          dispatch(updateClassTypeFailed(response.data.statusMessage));
        } else {
          dispatch(updateClassTypeFailed(APPLICATION_CONST.message));
        }

        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(updateClassTypeFailed(error.message));
        dispatch(loaderStatus(false));
      });
  };
};

export const updateClassTypeSuccess = (data) => ({
  type: UPDATE_CLASS_TYPE_SUCCESS,
  payload: data,
});

export const updateClassTypeFailed = (error) => ({
  type: UPDATE_CLASS_TYPE_FAILED,
  payload: error,
});

export const callProductMasterWebService = () => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {},
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));
    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "getproductmaster",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(serviceActionSuccess(response.data.data));
        } else if (response.data.status > 0) {
          dispatch(serviceActionError(response.data.statusMessage));
        } else {
          dispatch(serviceActionError(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(serviceActionError(error.message));
        dispatch(loaderStatus(false));
      });
  };
};

export const productDeleteWebService = (props) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  return (dispatch) => {
    var request = {};
    if (props.productmaster === false) {
      request = {
        username: loginDetail.username,
        sessionid: loginDetail.sessionid,
        request: {
          actiontype: "DELETE",
          productid: props.product.productid,
          productname: props.product.productname,
          description:
            props.product.description === undefined
              ? ""
              : props.product.description,
          categoryid: props.product.categoryid,
          unitcost: parseFloat(props.product.unitcost),
          quantity: Number(props.product.quantity),
          previousquantity: Number(props.previousquantity),
          status: props.product.status,
        },
      };
    } else if (props.productmaster === true) {
      request = {
        username: loginDetail.username,
        sessionid: loginDetail.sessionid,
        request: {
          actiontype: "DELETE",
          productid: props.product.productid,
          productname: props.product.productname,
          description:
            props.product.description === undefined
              ? ""
              : props.product.description,
          categoryid: props.product.categoryid,
          unitcost: parseFloat(props.product.unitcost),
          noticequantity: Number(props.product.noticequantity),
          taxexempt: props.product.taxexempt,
          status: props.product.status,
        },
      };
    }

    dispatch(deleteProductServicePending());
    dispatch(loaderStatus(true));

    axios({
      method: "post",
      url:
        APPLICATION_CONST.url +
        (props.productmaster === true
          ? "updateproductmaster"
          : "updateproduct"),
      data: request,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          // if (props.productmaster === false) {
          //   dispatch(callProductMasterWebService({}));
          // } else if (props.productmaster === true) {
          //   dispatch(callProductMasterWebService({}));
          // }

          dispatch(
            deleteProductServiceSuccess("Product Deleted Successfully.")
          );
        } else if (response.data.status > 0) {
          dispatch(deleteProductServiceError(response.data.statusMessage));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(deleteProductServiceError(error.message));
        dispatch(loaderStatus(false));
      });
  };
};

export const deleteProductServicePending = () => ({
  type: DELETE_PRODUCT_PENDING,
});

export const deleteProductServiceSuccess = (message) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: message,
});

export const deleteProductServiceError = (message) => ({
  type: DELETE_PRODUCT_ERROR,
  payload: message,
});

export const productEditWebService = (props, history) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  return (dispatch) => {
    let request = null;

    if (props.productmaster === false) {
      if (props.edit === true) {
        request = {
          username: loginDetail.username,
          sessionid: loginDetail.sessionid,
          request: {
            actiontype: "UPDATE",
            productid: props.product.productid,
            description: props.product.description,

            productreferenceid: props.product.productreferenceid,
            unitcost: parseFloat(props.product.unitcost),
            taxrate: Number(props.product.taxrate),
            quantity: Number(props.product.quantity),
            previousquantity: Number(props.previousquantity),
            status: props.product.status,
          },
        };
      } else {
        request = {
          username: loginDetail.username,
          sessionid: loginDetail.sessionid,
          request: {
            actiontype: "CREATE",
            productid: props.product.productid,
            productreferenceid: props.product.productreferenceid,
            unitcost: parseFloat(props.product.unitcost),
            taxrate: Number(props.product.taxrate),
            quantity: Number(props.product.quantity),
            previousquantity: Number(props.previousquantity),
            status: props.product.status,
            description: props.product.description,
          },
        };
      }
    } else if (props.productmaster === true) {
      if (props.edit === true) {
        request = {
          username: loginDetail.username,
          sessionid: loginDetail.sessionid,
          request: {
            actiontype: "UPDATE",
            productid: props.product.productid,
            productname: props.product.productname,
            categoryid: props.product.categoryid,
            unitcost: parseFloat(props.product.unitcost),
            taxexempt: props.product.taxexempt,
            noticequantity: Number(props.product.noticequantity),
            description: props.product.description,
            parent: props.product.parent,
            parentid: props.product.parentid,
            classtype1: props.product.classtype1,
            classtype2: props.product.classtype2,
            classid1: props.product.classid1,
            classid2: props.product.classid2,
            recommended: props.product.recommended,
            status: props.product.status,
          },
        };
      } else {
        request = {
          username: loginDetail.username,
          sessionid: loginDetail.sessionid,
          request: {
            actiontype: "CREATE",
            productname: props.product.productname,
            categoryid: props.product.categoryid,
            unitcost: parseFloat(props.product.unitcost),
            taxexempt: props.product.taxexempt,
            noticequantity: Number(props.product.noticequantity),
            description: props.product.description,
            parent: props.product.parent,
            parentid: props.product.parentid,
            classtype1: props.product.classtype1,
            classtype2: props.product.classtype2,
            classid1: props.product.classid1,
            classid2: props.product.classid2,
            recommended: props.product.recommended,

            status: props.product.status,
          },
        };
      }

      if (props.product.vendors && props.product.vendors.length > 0) {
        request.request.vendors = props.product.vendors;
      }
    }
    dispatch(editProductServicePending());
    dispatch(loaderStatus(true));

    console.log("REQUEST", JSON.stringify(request));
    // return false;
    axios({
      method: "post",
      url:
        APPLICATION_CONST.url +
        (props.productmaster === true
          ? "updateproductmaster"
          : "updateproduct"),
      data: request,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          let success =
            props.edit === false
              ? "Product Saved Successfully."
              : "Product Updated Successfully.";
          dispatch(editProductServiceSuccess(success));

          history.push("productsuccess", {
            actiontype: props.edit === false ? "ADD" : "EDIT",
            type: props.productmaster ? "masterproduct" : "products",
          });
        } else if (response.data.status > 0) {
          dispatch(editProductServiceError(response.data.statusMessage));
        } else {
          dispatch(editProductServiceError(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(editProductServiceError(error.message));
        dispatch(loaderStatus(false));
      });
  };
};

export const updateProductMasterListSuccess = (data) => ({
  type: UPDATE_PRODUCT_MASTER_LIST_SUCCESS,
  payload: data,
});

export const updateProductMasterListFailed = (error) => ({
  type: UPDATE_PRODUCT_MASTER_LIST_FAILED,
  payload: error,
});

export const callProductMasterWebServiceForUpdate = (props) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {},
  };

  return (dispatch) => {
    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "getproductmaster",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(updateProductMasterListSuccess(response.data.data));
        } else if (response.data.status > 0) {
          dispatch(updateProductMasterListFailed(response.data.statusMessage));
        } else {
          dispatch(updateProductMasterListFailed(APPLICATION_CONST.message));
        }
      })
      .catch((error) => {
        dispatch(updateProductMasterListFailed(error.message));
      });
  };
};

export const getCategorys = (data) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {},
  };
  return (dispatch) => {
    dispatch(loaderStatus(true));

    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "getcategory",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(getCategorySuccess(response.data.data));
        } else if (response.data.status > 0) {
          dispatch(getCategoryFailed(response.data.statusMessage));
        } else {
          dispatch(getCategoryFailed(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(getCategoryFailed("No Internet Connection", false));
        dispatch(loaderStatus(false));
      });
  };
};

export const getCategorySuccess = (data) => ({
  type: GET_CATEGORY_SUCCESS,
  payload: data,
});

export const getCategoryFailed = (error) => ({
  type: GET_CATEGORY_FAILED,
  payload: error,
});

export const callUpdateCategory = (data, history) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {
      actiontype: data.actiontype,
      categoryid: data.categoryid,
      description: data.description,
    },
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));
    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "updatecategory",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(updateCategorySuccess(response.data.data));
          history.push("/categorys/success", {
            type: "category",
            actiontype: data.actiontype,
          });
        } else if (response.data.status > 0) {
          dispatch(updateCategoryFailed(response.data.statusMessage));
        } else {
          dispatch(updateCategoryFailed(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(updateCategoryFailed(error.message, false));
        dispatch(loaderStatus(false));
      });
  };
};

export const updateCategorySuccess = (data) => ({
  type: UPDATE_CATEGORY_SUCCESS,
  payload: data,
});

export const updateCategoryFailed = (error) => ({
  type: UPDATE_CATEGORY_FAILED,
  payload: error,
});

export const uploadProductImage = (data, history) => {
  var body = new FormData();
  body.append("username", data.username);
  body.append("sessionid", data.sessionid);
  body.append("assettype", data.assettype);
  body.append("assetid", data.assetid);

  if (data.actiontype === "UPLOAD") {
    body.append("file", data.file);
  }

  if (data.actiontype === "DELETE") {
    body.append("delete", data.delete);
  }

  return (dispatch) => {
    dispatch(loaderStatus(true));

    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "uploadfile",
      data: body,
      headers: {
        accept: "application/json",
        "Content-Type": `multipart/form-data`,
      },
      timeout: 10000,
      onUploadProgress: (ev: ProgressEvent) => {
        const progress = (ev.loaded / ev.total) * 100;
      },
    })
      .then((response) => {
        const items = {
          assettype: data.assettype,
          actiontype: data.actiontype,
        };
        if (response.data.status === 0) {
          dispatch(uploadProductImageSuccess(response.data.data));
          history.push("/imageupload/success", items);
        } else if (response.data.status > 0) {
          dispatch(uploadProductImageFailed(response.data.statusMessage));
        } else {
          dispatch(uploadProductImageFailed(APPLICATION_CONST.message));
        }

        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(uploadProductImageFailed(error.message));

        dispatch(loaderStatus(false));
      });
  };
};

export const uploadProductImageSuccess = (data) => ({
  type: UPLOAD_PRODUCT_IMAGE_SUCCESS,
  payload: data,
});

export const uploadProductImageFailed = (error) => ({
  type: UPLOAD_PRODUCT_IMAGE_FAILED,
  payload: error,
});

export const setPreviousQuantity = (data) => ({
  type: SET_PRODUCT_PREVIOUS_QUANTITY,
  payload: data
})