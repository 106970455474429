import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";

import { Carousel, Image, Container } from "react-bootstrap";

import { APPLICATION_CONST } from "../../../Config/CustomConfig";
import { callStoresWebService } from "../../../actions/public/storesAction";

import "./MainBannerComponent.css";
import { styles } from "../../../styles/commontStyles";
import { updateFlightForm } from "../../../actions/public/flightInfoAction";

class MainBannerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      storeid: null,
      instance: this,
    };
  }
  static getDerivedStateFromProps(props, state) {
    //  walkin page redirect after store success
    if (
      props.storeStatus !== state.instance.props.storeStatus &&
      state.instance.props.storeStatus !== null
    ) {
      // if (props.storeStatus === "success") {
      //   // this.props.history.push("/categories");
      //   state.instance.props.history.push("/store/categories", {
      //     storeid: state.storeid,
      //   });
      // }
    }

    // walkin page redirect after store success

    if (
      props.storeError !== state.instance.props.storeError &&
      state.instance.props.storeError !== null
    ) {
      return {
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: props.storeError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={state.instance.closeDialog}
          >
            Close
          </button>
        ),
      };
    }

    if (props.productsStatus === "success") {
      let returnData = {
        filterProducts: props.products,
      };

      if (state.instance.state.sortBy === state.sortBy) {
        returnData = { ...returnData, products: props.products };
      }

      return returnData;
    }
  }

  bannerFilter = () => {
    let banners = this.props.mainBanners;

    // banners = banners.filter((o) => {
    //   if (o["bannertype"]) {
    //     console.log("hhh", o["bannertype"].includes("MAIN-"));
    //     return o["bannertype"].includes("MAIN-");
    //   } else {
    //     return false;
    //   }
    // });

    banners.sort(function (a, b) {
      if (a.sequenceid < b.sequenceid) {
        return -1;
      }
      if (a.sequenceid > b.sequenceid) {
        return 1;
      }
    });

    return banners;
  };

  goToProduuct = (banner) => {
    let productid = banner.linkid;
    let findProductDetails = {};

    // return false;
    this.props.stores.forEach((store, index) => {
      store.categories.forEach((category, index) => {
        category.products.forEach((product, index) => {
          if (product.productid === productid) {
            findProductDetails = {
              store: store,
              product: product,
              category: category,
            };
            return false;
          }
        });
      });
    });

    if (Object.keys(findProductDetails).length != 0) {
      let storeid = findProductDetails.store.storeid;
      let storename = findProductDetails.store.name;
      let category = findProductDetails.category;
      let product = findProductDetails.product;

      this.props.history.push("/product/detail", {
        product: product,
        storeid: storeid,
        storename: storename,
        category: category,
      });
    }
  };

  goToUrllink = (banner) => {
    let url = banner.linkid;

    this.props.history.push("/BannerPage", {
      url: url,
    });
  };

  onBtnClick = (store) => {
    const param = [
      { key: "airportid", value: store.airportid },
      { key: "direction", value: store.direction },
    ];

    if (this.props.cartItems.length > 0) {
      const airportid = this.props.airportInfo.airportid;
      const direction = this.props.airportInfo.direction;
      if (store.direction === direction && store.airportid === airportid) {
        this.props.callStoresWebService();
        this.setState({
          storeid: store.linkid,
        });
      } else {
        this.confirmationDialog(param);
      }
    } else {
      this.props.updateFlightForm(param);
      this.props.callStoresWebService();
      this.setState({
        storeid: store.linkid,
      });
    }
    //  this.props.callStoresWebService();
    // this.setState({
    //   storeid: store.storeid,
    // });
  };
  bannerRender = (banner, index) => {
    if (banner.bannertype === "MAIN-VENDOR") {
      return (
        <Carousel.Item key={index} onClick={() => this.onBtnClick(banner)}>
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            fluid
            className={"carousel-item-image"}
          />
        </Carousel.Item>
      );
    } else if (banner.bannertype === "MAIN-PRODUCT") {
      return (
        <Carousel.Item key={index} onClick={() => this.goToProduuct(banner)}>
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            fluid
            className={"carousel-item-image"}
          />
        </Carousel.Item>
      );
    } else if (banner.bannertype === "MAIN-URL") {
      return (
        <Carousel.Item key={index} onClick={() => this.goToUrllink(banner)}>
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            fluid
            className={"carousel-item-image"}
          />
        </Carousel.Item>
      );
    } else {
      return (
        <Carousel.Item key={index}>
          <Image
            src={APPLICATION_CONST.bannerImages + banner.bannerid}
            alt="First slide"
            fluid
            className={"carousel-item-image"}
          />
        </Carousel.Item>
      );
    }

    // return (
    //   <Carousel.Item key={index}>
    //     <Image
    //       src={APPLICATION_CONST.bannerImages + banner.bannerid}
    //       alt="First slide"
    //       fluid
    //       className={"carousel-item-image"}
    //     />
    //   </Carousel.Item>
    // );
  };

  render() {
    return (
      <Container fluid className={"carousel-container"}>
        <Carousel>
          {this.bannerFilter().map((banner, index) =>
            this.bannerRender(banner, index)
          )}
        </Carousel>
        {/* <Loader message="Processing..." /> */}
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  mainBanners: state.SignInReducer.logindetails.banners.main,

  stores: state.StoreReducer.stores,
  logindetails: state.SignInReducer.logindetails,
  storeError: state.StoreReducer.error,
  storeStatus: state.StoreReducer.status,
  products: state.VendorProductReducer.products,
  productsStatus: state.VendorProductReducer.success,
  cartItems: state.CartReducer.cart,
  detailsBanners: state.SignInReducer.logindetails.banners.details,
  productError: state.VendorProductReducer.error,
});

export default connect(mapStateToProps, {
  callStoresWebService,
  updateFlightForm,
})(MainBannerComponent);
