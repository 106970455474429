import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  backToHome = () => {
    let location = "/";
    return (
      <button
        className="btn btn-success btn-primary"
        style={{ fontSize: 13, fontWeight: "bold" }}
        onClick={() => this.props.history.push(location)}
      >
        <FontAwesomeIcon
          icon="reply"
          color="#FFF"
          style={{ fontSize: 15, marginRight: 5 }}
        />
        Back To Home
      </button>
    );
  };

  render() {
    return (
      <>
        <NavBar />
        <div
          className="container-fluid"
          style={{ paddingBottom: 50, paddingTop: 50 }}
        >
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <FontAwesomeIcon
                icon="check-circle"
                color="#4CAF50"
                style={{ fontSize: 140 }}
              />

              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                Order #{this.props.location.state.orderid} Confirmed!
              </div>
              <div style={{ fontSize: 12 }}>
                Order has been placed successfully
              </div>
              {this.props.userDetail.userdefault.subscriber === "Y" &&
              this.props.userDetail.internal === "N" ? (
                <div style={{ fontSize: 12 }}>
                  You may view your orders anytime by choosing My Orders from
                  the menu.
                </div>
              ) : null}

              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                {this.backToHome()}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

//  export default Success;
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps)(Success);
