export const USER_SERVICE_ERROR = "user_service_error";
export const USER_SERVICE_PENDING = "user_service_pending";
export const USER_SERVICE_SUCCESS = "user_service_success";
export const EDIT_USER_SERVICE_ERROR = "edit_user_service_error";
export const EDIT_USER_SERVICE_PENDING = "edit_user_service_pending";
export const EDIT_USER_SERVICE_SUCCESS = "edit_user_service_success";
export const PRODUCT_SERVICE_ERROR = "product_service_error";
export const PRODUCT_SERVICE_PENDING = "product_service_pending";
export const PRODUCT_SERVICE_SUCCESS = "product_service_success";
export const EDIT_PRODUCT_SERVICE_ERROR = "edit_product_service_error";
export const EDIT_PRODUCT_SERVICE_SUCCESS = "edit_product_service_success";
export const EDIT_PRODUCT_SERVICE_PENDING = "edit_product_service_pending";
export const PRODUCT_CATEGORY = "product_category";

export const ORDER_ERROR = "order_error";
export const ORDER_PENDING = "order_pending";
export const ORDER_SUCCESS = "order_success";
export const ORDER_EDIT = "order_edit";
export const ORDER_QTY_UPDATE = "order_qty_update";
export const ORDER_ASSIGN_TO_AGENT = "order_assign_to_agent";
/***************order actions********************** */

/***************vendor actions***********************/
export const VENDOR_SERVICE_PENDING = "vendor_service_pending";

export const VENDOR_SERVICE_ERROR = "vendor_service_error";
export const VENDOR_SERVICE_SUCCESS = "vendor_service_success";
export const VENDOR_UPDATE_SERVICE_SUCCESS = "vendor_update_service_success";
export const VENDOR_UPDATE_SERVICE_ERROR = "vendor_update_service_error";

export const VENDOR_MASTER_SERVICE_ERROR = "vendor_master_service_error";
export const VENDOR_MASTER_SERVICE_SUCCESS = "vendor_master_service_success";
export const VENDOR_MASTER_UPDATE_SERVICE_SUCCESS =
  "vendor_master_update_service_success";
export const VENDOR_MASTER_UPDATE_SERVICE_ERROR =
  "vendor_master_update_service_error";

/***************loader actions*******************I***/
export const LOADER_STATUS = "loader_status";

/***************order process actions*****************/
export const ORDER_PROCESS_ERROR = "order_process_error";
export const ORDER_PROCESS_PENDING = "order_process_pending";
export const ORDER_PROCESS_SUCCESS = "order_process_success";
export const ORDER_ERROR_CLEAR = "order_error_clear";
/***************order process actions*****************/

/***************edit products actions*****************/

export const PRODUCT_DELETE = "product_delete";

/***************order process actions*****************/

export const SHOW_QRCODE_SCANNER = "show_qrcode_scanner";
export const HIDE_QRCODE_SCANNER = "hide_qrcode_scanner";
export const QRCODE_SCANNER_ERROR = "qrcode_scanner_error";
export const QRCODE_SCANNER_SUCCESS = "qrcode_scanner_success";

export const SHOW_PRODUCTFULFILL_MODAL = "show_productfulfill_modal";
export const HIDE_PRODUCTFULFILL_MODAL = "hide_productfulfill_modal";

export const ADD_TO_ORDER = "add_to_order";
export const REMOVE_FROM_ORDER = "remove_from_order";
export const EMPTY_EXTRA_ORDER = "empty_extra_order";
export const UPDATE_EXTRA_ORDER_QUANTITY = "update_extra_order_quantity";
export const SHOW_EXTRA_PRODUCT_CATEGORY_MODAL =
  "show_extra_product_category_modal";
export const HIDE_EXTRA_PRODUCT_CATEGORY_MODAL =
  "hide_extra_product_category_modal";
export const SHOW_FINAL_PRICE_MODAL = "show_final_price_modal";
export const HIDE_FINAL_PRICE_MODAL = "hide_final_price_modal";

export const REPORTS_PENDING = "reports_pending";
export const REPORTS_SUCCESS = "reports_success";
export const REPORTS_ERROR = "reports_error";

export const UPDATE_ERROR_REDUCER = "update_error_reducer";
export const SHOW_SIGNATURE_MODAL = "show_signature_modal";
export const HIDE_SIGNATURE_MODAL = "hide_signature_modal";

export const ADD_SIGNATURE = "add_signature";
export const REMOVE_SIGNATURE = "remove_signature";

export const ORDER_UPDATE = "order_update";

export const RECEIPT_PENDING = "receipt_pending";
export const RECEIPT_SUCCESS = "receipt_success";
export const RECEIPT_ERROR = "receipt_error";

export const SEND_RECEIPT_ERROR = "send_receipt_error";
export const SEND_RECEIPT_PENDING = "send_receipt_pending";
export const SEND_RECEIPT_SUCCESS = "send_receipt_success";

export const GET_CLASSIFICATION_SUCCESS = "get_classification_success";
export const GET_CLASSIFICATION_FAILED = "get_classification_failed";

export const UPDATE_CLASSIFICATION_SUCCESS = "update_classification_success";
export const UPDATE_CLASSIFICATION_FAILED = "update_classification_failed";

export const GET_CLASS_TYPE_SUCCESS = "get_class_type_success";
export const GET_CLASS_TYPE_FAILED = "get_class_type_failed";

export const UPDATE_CLASS_TYPE_SUCCESS = "update_class_type_success";
export const UPDATE_CLASS_TYPE_FAILED = "update_class_type_failed";

export const SET_PRODUCT_MASTER_BOOLEAN = "set_product_master_boolean";
export const UPDATE_PRODUCT_MASTER_LIST_SUCCESS =
  "update_product_master_list_success";
export const UPDATE_PRODUCT_MASTER_LIST_FAILED =
  "update_product_master_list_failed";

export const DELETE_PRODUCT_PENDING = "delete_product_pending";
export const DELETE_PRODUCT_SUCCESS = "delete_product_success";
export const DELETE_PRODUCT_ERROR = "delete_product_error";

export const UPDATE_CARD_DETAILS = "update_card_details";
export const RESET_CARD_DETAILS = "reset_card_details";

export const GET_CATEGORY_SUCCESS = "get_category_success";
export const GET_CATEGORY_FAILED = "get_category_failed";

export const UPDATE_CATEGORY_SUCCESS = "update_category_success";
export const UPDATE_CATEGORY_FAILED = "update_category_failed";

export const UPLOAD_PRODUCT_IMAGE_SUCCESS = "upload_product_image_success";
export const UPLOAD_PRODUCT_IMAGE_FAILED = "upload_product_image_failed";

export const SET_PRODUCT_PREVIOUS_QUANTITY = 'set_product_previous_quantity';

