export const dateFormat = (type,userdefault) => {
  let defaultFormat = 'MM/DD/YYYY';

  if(type == 'datepicker')
  {
    if(userdefault.subscriber === "Y")
    {
      defaultFormat = 'MM/dd/yyyy';
    }
    else
    {
      defaultFormat = 'dd/MM/yyyy';
    }
  }
  else if(type == 'normaldatetime')
  {
    if(userdefault.subscriber === "Y")
    {
      defaultFormat = 'MM/DD/YYYY hh:mm a';
    }
    else
    {
      defaultFormat = 'DD/MM/YYYY hh:mm a';
    }
  }
  else if(type == 'normaldate')
  {
    if(userdefault.subscriber === "Y")
    {
      defaultFormat = 'MM/DD/YYYY';
    }
    else
    {
      defaultFormat = 'DD/MM/YYYY';
    }
  }
  else if(type == 'normaltime')
  {
    if(userdefault.subscriber === "Y")
    {
      defaultFormat = 'hh:mm a';
    }
    else
    {
      defaultFormat = 'hh:mm a';
    }
  }

  return defaultFormat;
}