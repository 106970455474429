import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { getUserService } from "../../actions/vendor/userAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/style.css";
import { Badge, Container, Col, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";

class User extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      dataTable: {
        columns: [
          {
            label: "#",
            field: "index",
            width: 10
          },
          {
            label: "User Name",
            field: "username",
            sort: "asc",
            width: 150,
          },
          {
            label: "First Name",
            field: "firstname",
            sort: "asc",
            width: 150,
          },
          {
            label: "Last Name",
            field: "lastname",
            sort: "asc",
            width: 270,
          },
          {
            label: "Email",
            field: "email",
            sort: "asc",
            width: 200,
          },
          {
            label: "Phone",
            field: "phone",
            sort: "asc",
            width: 100,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
          {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: [
          ...this.props.users.map((user, index) => ({
            index: index + 1,
            username: user.username,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            status:
              user.status === "A" ? (
                <Badge variant="success">Active</Badge>
              ) : (
                <Badge variant="danger">Inactive</Badge>
              ),
            action: (
              <button
                onClick={() => this.onEditBtnClick(user.username)}
                className="btn btn-sm btn-info btn-warning"
              >
                <i className="fa fa-pencil"></i>
              </button>
            ),
          })),
        ],
      },
    };
  }
  componentDidMount = () => {
    window.scrollTo(0,0);

    const data = {
      username: this.props.logindetails.username,
      sessionid: this.props.logindetails.sessionid,
    };
    this.props.getUserService(data);
  };

  onEditBtnClick = (username) => {
    this.props.history.push("/users/edit", { username: username });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.userServiceError !== this.props.userServiceError &&
      nextProps.userServiceError !== null
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.userServiceError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (nextProps.users !== this.props.users) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.users.map((user, index) => ({
              index: index + 1,
              username: user.username,
              firstname: user.firstname,
              lastname: user.lastname,
              email: user.email,
              phone: user.phone,
              status:
                user.status === "A" ? (
                  <Badge variant="success">Active</Badge>
                ) : (
                  <Badge variant="danger">Inactive</Badge>
                ),
              action: (
                <button
                  onClick={() => this.onEditBtnClick(user.username)}
                  className="btn btn-sm btn-info btn-warning"
                >
                  <i className="fa fa-pencil"></i>
                </button>
              ),
            })),
          ],
        },
      });
    }
  }
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  render() {
    return (
      <>
        <NavBar />
        <Loader message="Retrieving Users..." />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        {/* <div
                    style={{
                        backgroundColor: "#F1F1F1",
                        borderTop: "1px solid #DDD",
                        borderBottom: "1px solid #DDD",
                    }}
                >
                    <div style={{ padding: 20, textAlign: "center" }}>
                        <button
                            style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#777",
                                marginLeft: 20,
                                marginTop: 20,
                                float: "left",
                                display: "inline-block",
                            }}
                            onClick={() => {
                                this.props.history.push("/");
                            }}
                        >
                            <FontAwesomeIcon
                                icon="chevron-left"
                                color="#777"
                                style={{ marginRight: 15 }}
                            />
                            Back
                        </button>
                        <div style={styles.pageHeadingStyle}>Users</div>
                    </div>
                </div> */}

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Users</li>
              </ul>
            </Col>
          </Row>
        </Container>
        <Container className="stores-container" fluid>
          <div style={{ padding: 15 }} className="custom_datatable ">
            <MDBDataTable
              striped
              bordered
              hover
              small
              data={this.state.dataTable}
            />

            <button
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                position: "fixed",
                backgroundColor: "#4CAF50",
                bottom: 0,
                right: 0,
                zIndex: 100,
                margin: 30,
              }}
              className="btn"
              onClick={() => this.props.history.push("/users/add")}
            >
              <FontAwesomeIcon icon="plus" color="#FFF" />
            </button>
          </div>
          <div className="spacer"></div>
        </Container>
        <Footer />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  users: state.UserReducer.users,
  userServiceError: state.UserReducer.userServiceError,
});
export default connect(mapStateToProps, { getUserService })(User);
