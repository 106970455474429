import {
  FETCH_STORES,
  STORES_SUCCESS,
  STORES_ERROR,
  STORES_PENDING,
  CLEAR_STORES_ERROR,
} from "../../actions/public/actionTypes";

const initialState = {
  stores: [],
  status: null,
  error: null,
};

const StoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_STORES:
      return {
        ...state,
        stores: action.payload,
        status: "success",
        error: null,
      };
    case STORES_PENDING:
      return {
        ...state,
        stores: [],
        status: "pending",
        error: null,
      };
    case STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
        status: "success",
        error: null,
      };
    case STORES_ERROR:
      return {
        ...state,
        stores: [],
        status: "error",
        error: action.payload,
      };
    case FETCH_STORES:
      return {
        ...state,
        stores: action.payload,
        status: "success",
        error: null,
      };
    case CLEAR_STORES_ERROR:
      return {
        ...state,
        stores: [],
        status: null,
        error: null,
      };
    default:
      return state;
  }
};

export default StoreReducer;
