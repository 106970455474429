import {
  REPORTS_PENDING,
  REPORTS_SUCCESS,
  REPORTS_ERROR,
} from "../../actions/vendor/actionTypes";

const initialState = {
  status: null,
  errorMessage: null,
  documentPath: null,
  reportFileName: null,
};

const ReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case REPORTS_PENDING:
      return {
        ...state,
        status: "pending",
        errorMessage: null,
        documentPath: null,
        reportFileName: null,
      };
    case REPORTS_SUCCESS:
      return {
        ...state,
        status: "success",
        errorMessage: null,
        documentPath: null,
        reportFileName: action.payload,
      };
    case REPORTS_ERROR:
      return {
        ...state,
        status: "error",
        errorMessage: action.payload,
        documentPath: null,
        reportFileName: null,
      };
    default:
      return state;
  }
};

export default ReportsReducer;
