import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from './navbar';
import Footer from './footer';
import { styles } from "../../styles/commontStyles";


class DeveloperContact extends Component {
    render() {
        return (
            <>
                <NavBar /> 
                
                <div style={{ backgroundColor:"#F1F1F1", borderTop:'1px solid #DDD', borderBottom:'1px solid #DDD' }}>

                    <div style={{ padding:20, textAlign:'center' }}>
                        <div style={styles.pageHeadingStyle}>Developer Contact</div>
                    </div>

                </div>

                <div className='container-fluid' style={{ paddingTop: 30, paddingBottom: 100 }}>
                    <div className='row justify-content-center'>
                        <div className="col-md-12">
                            <div className="card">                             
                                <div className="card-body">
                                 <p><b>Email : </b>info@reliablesoftjm.com</p>
                                 <p><b>Website : </b><a rel="noopener noreferrer" target="_blank" href="http://www.reliablesoftjm.com/">www.reliablesoftjm.com</a></p>
                                 <p><b>Phone : </b>1876-633-4400/1876-633-4401</p>
                                 <p><b>Address : </b>13B North Avenue, Kingston 5, Jamaica W.I.</p>                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </>

        )
    }
}

const mapStateToProps = (state) => ({
    userDetail: state.SignInReducer.logindetails,
})
export default connect(mapStateToProps)(DeveloperContact);