import {
  VENDOR_SERVICE_ERROR,
  VENDOR_SERVICE_PENDING,
  VENDOR_SERVICE_SUCCESS,
  VENDOR_UPDATE_SERVICE_ERROR,
  VENDOR_MASTER_SERVICE_ERROR,
  VENDOR_MASTER_SERVICE_SUCCESS,
  VENDOR_UPDATE_SERVICE_SUCCESS,
  VENDOR_MASTER_UPDATE_SERVICE_SUCCESS,
  VENDOR_MASTER_UPDATE_SERVICE_ERROR,
} from "../../actions/vendor/actionTypes";

const initialState = {
  vendorslist: [],
  vendorerror: false,
  vendorerrormessage: "",
  vendorsmasterlist: [],
  vendormastererror: false,
  vendormastererrormessage: "",
};

const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_SERVICE_SUCCESS:
      return {
        ...state,
        vendorslist: action.payload.vendors,
        vendorerror: false,
        vendormasterrerror: false,
        vendorerrormessage: "",
      };
    case VENDOR_SERVICE_PENDING:
      return {
        ...state,
        vendorerror: false,
        vendorerrormessage: "",
        vendormastererrormessage: "",
        vendormastererror: false,
      };
    case VENDOR_SERVICE_ERROR:
      return {
        ...state,
        vendorerror: true,
        vendorerrormessage: action.payload,
        vendorslist: [],
      };

    case VENDOR_UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        vendorerror: false,
        vendorerrormessage: "",
      };
    case VENDOR_UPDATE_SERVICE_ERROR:
      return {
        ...state,
        vendorerror: true,
        vendorerrormessage: action.payload,
      };
    // case VENDOR_SERVICE_SUCCESS:
    //   return {
    //     ...state,
    //     vendorslist: action.payload.vendors,
    //     vendorerror: false,
    //     vendorerrormessage: "",
    //   };
    // case VENDOR_SERVICE_PENDING:
    //   return {
    //     ...state,
    //     vendorerror: false,
    //     vendorerrormessage: "",
    //   };
    // case VENDOR_SERVICE_ERROR:
    //   return {
    //     ...state,
    //     vendorerror: true,
    //     vendorerrormessage: action.payload,
    //     vendorslist: [],
    //   };

    case VENDOR_MASTER_UPDATE_SERVICE_SUCCESS:
      return {
        ...state,
        vendormastererror: false,
        vendormastererrormessage: "",
      };
    case VENDOR_MASTER_UPDATE_SERVICE_ERROR:
      return {
        ...state,
        vendormastererror: true,
        vendormastererrormessage: action.payload,
      };
    case VENDOR_MASTER_SERVICE_ERROR:
      return {
        ...state,
        vendormastererror: true,
        vendormastererrormessage: action.payload,
      };
    case VENDOR_MASTER_SERVICE_SUCCESS:
      return {
        ...state,
        vendorerror: false,
        vendormastererror: false,
        vendorerrormessage: "",
        vendorsmasterlist: action.payload.vendormaster,
      };
    default:
      return state;
  }
};

export default VendorReducer;
