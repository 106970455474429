import Cart from "../components/common/cart";
import DeveloperContact from "../components/common/developerContact";
import PrivacyPolicy from "../components/common/privacyPolicy";
import Flight from "../components/common/flight";
import Guest from "../components/common/guest";

import UpdateFlight from "../components/common/updateFlight";

import Home from "../components/public/home";
import Category from "../components/public/category";
import Payment from "../components/public/payment";
import Order from "../components/public/order";
import OrderDetail from "../components/public/orderDetail";
import Store from "../components/public/store";
import Product from "../components/public/product";
import ProductDetail from "../components/common/productDetail";
import Success from "../components/public/success";

import VendorHome from "../components/vendor/home";
import User from "../components/vendor/user";
import AddUser from "../components/vendor/addUser";
import VendorProduct from "../components/vendor/product";
import Products from "../components/vendor/products";
import VendorCategory from "../components/vendor/category";
import AddVendorProduct from "../components/vendor/addProduct";
import VendorOrder from "../components/vendor/order";
import VendorOrderDetail from "../components/vendor/orderDetail";
import VendorPayment from "../components/vendor/payment";
import VendorReports from "../components/vendor/reports";
import VendorReportsRun from "../components/vendor/runreport";
import VendorOrderPayment from "../components/vendor/orderPayment";

import OrderSuccess from "../components/public/orderSuccess";
import CancelRequest from "../components/vendor/cancelRequest";
import PaymentCancel from "../components/common/paymentCancel";
import PaymentFailed from "../components/public/paymentCancel";
import ReportDetails from "../components/vendor/reportdetails";
import PaymentFailedScreen from "../components/common/PaymentFailed";


import Admin from "../components/vendor/Admin";
import Receipt from "../components/vendor/Receipt";
import VendorProductMaster from "../components/vendor/productMaster";

import OrderFailed from "../components/public/orderFailed";
import AddMasterProduct from "../components/vendor/addMasterProduct";
import Classifications from "../components/vendor/Classifications";
import AddClassification from "../components/vendor/addClassification";
import productClassSuccess from "../components/public/productClassSuccess";
import OrderReview from "../components/common/OrderReview";
import classtypes from "../components/vendor/classtypes";
import addClasstype from "../components/vendor/addClasstype";
import ProductSuccess from "../components/public/ProductSuccess";

import Categorys from "../components/vendor/Categorys";
import CategorySuccess from "../components/public/categorySuccess";
import AddCategory from "../components/vendor/addCategory";
import ProductImageUpload from "../components/vendor/productImageUpload";
import ImageUploadSuccess from "../components/vendor/imageUploadSuccess";

import Vendors from "../components/vendor/vendors";
import AddVendor from "../components/vendor/addVendor";
import VendorSuccess from "../components/vendor/vendorSuccess";

import VendorMaster from "../components/vendor/vendorMaster";
import AddVendorMaster from "../components/vendor/addVendorMaster";
import VendorMasterSuccess from "../components/vendor/vendorMasterSuccess";

import Faqs  from "../components/common/Faqs";
import Aboutus from "../components/common/Aboutus";
import Servicework from "../components/common/Servicework";
import Contactus from "../components/common/Contactus";
import Pickuplocations from "../components/common/Pickuplocations";
import CustomsAllowances from "../components/common/CustomsAllowances";
import AboutPayment from "../components/common/AboutPayment";
import CancellationPolicy from "../components/common/CancellationPolicy";
import TermsofUse from "../components/common/TermsofUse";
import BannerPage from "../components/common/BannerPage";

// import AddVendor from "../components/vendor/addVendor";
// import VendorSuccess from "../components/vendor/vendorSuccess";

const routes = [
  {
    path: "/",
    component: Home,
    exact: true,
    role: ["subscriber"],
  },
  // {
  //   path: "/index",
  //   component: Home,
  //   exact: true,
  //   role: ["subscriber"],
  // },
  {
    path: "/stores",
    component: Store,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/store/categories",
    component: Category,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/store/products",
    component: Product,
    exact: true,
    role: ["subscriber"],
  },

  {
    path: "/product/detail",
    component: ProductDetail,
    exact: true,
    role: ["subscriber", "loungeoperator"],
  },
  {
    path: "/cart",
    component: Cart,
    exact: true,
    role: ["subscriber", "loungeoperator"],
  },
  {
    path: "/cart/guest",
    component: Guest,
    exact: true,
    role: ["subscriber", "loungeoperator"],
  },
  {
    path: "/cart/checkout",
    component: Flight,
    exact: true,
    role: ["subscriber", "loungeoperator"],
  },
  {
    path: "/cart/payment",
    component: Payment,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/payment/success",
    component: Success,
    exact: true,
    role: ["subscriber", "loungeoperator"],
  },
  {
    path: "/payment/failed",
    component: PaymentFailed,
    exact: true,
    role: ["subscriber", "loungeoperator"],
  },
  {
    path: "/orders",
    component: Order,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/order/detail",
    component: OrderDetail,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/",
    component: VendorHome,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/products",
    component: VendorProduct,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/categories",
    component: VendorCategory,
    exact: true,
    role: ["loungeoperator"],
  },

  {
    path: "/products/add",
    component: AddVendorProduct,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/products/edit",
    component: AddVendorProduct,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/category/product",
    component: Products,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/users",
    component: User,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/users/add",
    component: AddUser,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/users/edit",
    component: AddUser,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/orders",
    component: VendorOrder,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/order/detail",
    component: VendorOrderDetail,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/cart/payment",
    component: VendorPayment,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/reports",
    component: VendorReports,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/reports/runreport",
    component: VendorReportsRun,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/reports/reportdetail",
    component: ReportDetails,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/order/payment",
    component: VendorOrderPayment,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/developer-contact",
    component: DeveloperContact,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/order/success",
    component: OrderSuccess,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/order/update-flight",
    component: UpdateFlight,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/CancelRequest",
    component: CancelRequest,
    exact: true,
    role: ["vendor"],
  },
  {
    path: "/order/PaymentCancel",
    component: PaymentCancel,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/admin",
    component: Admin,
    exact: true,
    role: [  "loungeoperator"],
  },
  {
    path: "/order/receipt",
    component: Receipt,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/productmaster",
    component: VendorProductMaster,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/order/failed",
    component: OrderFailed,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/masterproducts/add",
    component: AddMasterProduct,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/masterproducts/edit",
    component: AddMasterProduct,
    exact: true,
    role: ["vendor", "loungeoperator"],
  },
  {
    path: "/classifications",
    component: Classifications,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/classifications/add",
    component: AddClassification,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/classifications/edit",
    component: AddClassification,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/classifications/success",
    component: productClassSuccess,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/cart/review",
    component: OrderReview,
    exact: true,
    role: ["subscriber"],
  },
  {
    path: "/classtypes",
    component: classtypes,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/classtypes/add",
    component: addClasstype,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/classtypes/edit",
    component: addClasstype,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/classtypes/success",
    component: productClassSuccess,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/masterproducts/productsuccess",
    component: ProductSuccess,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/products/productsuccess",
    component: ProductSuccess,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/categorys",
    component: Categorys,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/categorys/add",
    component: AddCategory,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/categorys/edit",
    component: AddCategory,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/categorys/success",
    component: CategorySuccess,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/imageupload",
    component: ProductImageUpload,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/imageupload/success",
    component: ImageUploadSuccess,
    exact: true,
    role: ["loungeoperator", "vendor"],
  },
  {
    path: "/vendors",
    component: Vendors,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/vendors/add",
    component: AddVendor,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/vendors/edit",
    component: AddVendor,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/vendors/success",
    component: VendorSuccess,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/vendormaster",
    component: VendorMaster,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/vendormaster/add",
    component: AddVendorMaster,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/vendormaster/edit",
    component: AddVendorMaster,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/vendormaster/success",
    component: VendorMasterSuccess,
    exact: true,
    role: ["loungeoperator"],
  },
  {
    path: "/faq",
    component: Faqs,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/Aboutus",
    component: Aboutus,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/Servicework",
    component: Servicework,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/Contactus",
    component: Contactus,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/Pickuplocations",
    component: Pickuplocations,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/CustomsAllowances",
    component: CustomsAllowances,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/AboutPayment",
    component: AboutPayment,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/CancellationPolicy",
    component: CancellationPolicy,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/TermsofUse",
    component: TermsofUse,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/BannerPage",
    component: BannerPage,
    exact: true,
    role: ["subscriber", "vendor", "loungeoperator"],
  },
  {
    path: "/order/payment/failed",
    component: PaymentFailedScreen,
    exact: true,
    role: ["subscriber", "loungeoperator"],
  },
];

export default routes;
