import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { Image } from "react-bootstrap";
import { getReportService } from "../../actions/vendor/reportAction";
import { loaderStatus } from "../../actions/vendor/loaderAction";
import DialogModal from "../common/dialogModal";
import Loader from "../common/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Col, Row } from "react-bootstrap";

import { APPLICATION_CONST } from "../../Config/CustomConfig";
class Reports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
    };
  }

  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.status === "error") {
      if (nextProps.status !== this.props.status && nextProps.status !== null) {
        this.setState({
          displayDialog: true,
          dialogTitle: "Oops!",
          dialogBody: nextProps.errorMessage,
          dialogFooter: (
            <button
              className="btn"
              style={styles.dialogCancelButtonStyle}
              onClick={this.closeDialog}
            >
              Close
            </button>
          ),
        });
      }
    }
  };

  handleReportNavigation = async (reports) => {
    if (reports.dateparameters === "Y") {
      this.props.history.push("/reports/runreport", {
        headerTitle: reports.reportname,
        reportid: reports.reportid,
        dateparameters: reports.dateparameters,
        orderidparameters: reports.orderidparameters,
      });
    } else if (reports.orderidparameters === "Y") {
      this.props.history.push("/reports/runreport", {
        headerTitle: reports.reportname,
        reportid: reports.reportid,
        dateparameters: reports.dateparameters,
        orderidparameters: reports.orderidparameters,
      });
    } else {
      this.props.loaderStatus(true);
      var data = {
        fromDate: null,
        toDate: null,
        headerTitle: reports.reportname,
        request: {
          reportid: reports.reportid,
          startdate: null,
          enddate: null,
        },
      };
      this.props.getReportService(data, this.props.history);
    }
  };

  render() {
    return (
      <>
        <NavBar />

        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Loader message="Processing..." />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.goBack()}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Reports</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <div
          className="container-fluid stores-container"
          style={{ paddingTop: 30, paddingBottom: 100 }}
        >
          <div className="row">
            {this.props.logindetails.reports.map((reports) => (
              <div className="col-md-3">
                <div className="card" style={styles.vendorCardStyle}>
                  <Image
                    style={{
                      height: 100,
                      width: "auto",
                      margin: 3,
                      alignSelf: "center",
                    }}
                    resizemode="contain"
                    src={APPLICATION_CONST.reportImages + "button/chart.png"}
                  />
                  <div className="card-body">
                    <button
                      onClick={() => this.handleReportNavigation(reports)}
                      className="btn btn-block"
                      style={styles.vendorCardButtonStyle}
                    >
                      {reports.reportname}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="spacer"></div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  status: state.ReportsReducer.status,
  errorMessage: state.ReportsReducer.errorMessage,
});

export default connect(mapStateToProps, { getReportService, loaderStatus })(
  Reports
);
