import axios from "axios";
import {
  VENDOR_SERVICE_ERROR,
  VENDOR_SERVICE_PENDING,
  VENDOR_SERVICE_SUCCESS,
  VENDOR_UPDATE_SERVICE_ERROR,
  VENDOR_UPDATE_SERVICE_SUCCESS,
  VENDOR_MASTER_SERVICE_ERROR,
  VENDOR_MASTER_SERVICE_SUCCESS,
  VENDOR_MASTER_UPDATE_SERVICE_ERROR,
  VENDOR_MASTER_UPDATE_SERVICE_SUCCESS,
} from "./actionTypes";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { loaderStatus } from "../vendor/loaderAction";

/**********************************Vendors**************** */
export const getVendorService = (data) => (dispatch) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: {},
  };

  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + "getvendor",
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(serviceActionSuccess(response.data.data));
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
      } else {
        dispatch(serviceActionError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(serviceActionError(error.message));
      dispatch(loaderStatus(false));
    });
};

export const serviceActionPending = () => ({
  type: VENDOR_SERVICE_PENDING,
});

export const serviceActionError = (error) => ({
  type: VENDOR_SERVICE_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: VENDOR_SERVICE_SUCCESS,
  payload: data,
});

export const callUpdateVendor = (data, history) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    // failstatus:99,
    request: {
      ...data,
    },
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));
    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "updatevendor",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(updateServiceActionSuccess(response.data.data));
          history.push("/vendors/success", {
            type: "vendor",
            actiontype: data.actiontype,
          });
        } else if (response.data.status > 0) {
          dispatch(updateServiceActionError(response.data.statusMessage));
        } else {
          dispatch(updateServiceActionError(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(updateServiceActionError(error.message, false));
        dispatch(loaderStatus(false));
      });
  };
};

export const updateServiceActionError = (error) => ({
  type: VENDOR_UPDATE_SERVICE_ERROR,
  payload: error,
});

export const updateServiceActionSuccess = (data) => ({
  type: VENDOR_UPDATE_SERVICE_SUCCESS,
  payload: data,
});
/***********************Vendors***************************** */

/***********************Master Vendors********************** */
export const getVendorMasterService = (data) => (dispatch) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: {},
  };

  dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + "getvendormaster",
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
      if (response.data.status === 0) {
        dispatch(vendorMasterServiceActionSuccess(response.data.data));
      } else if (response.data.status > 0) {
        dispatch(vendorMasterServiceActionError(response.data.statusMessage));
      } else {
        dispatch(vendorMasterServiceActionError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(vendorMasterServiceActionError(error.message));
      dispatch(loaderStatus(false));
    });
};

export const vendorMasterServiceActionError = (error) => ({
  type: VENDOR_MASTER_SERVICE_ERROR,
  payload: error,
});

export const vendorMasterServiceActionSuccess = (data) => ({
  type: VENDOR_MASTER_SERVICE_SUCCESS,
  payload: data,
});

export const callUpdateVendorMaster = (data, history) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));

  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    request: {
      ...data,
    },
  };

  return (dispatch) => {
    dispatch(loaderStatus(true));
    dispatch(serviceActionPending());
    axios({
      method: "post",
      url: APPLICATION_CONST.url + "updatevendormaster",
      data: body,
      timeout: 10000,
    })
      .then((response) => {
        if (response.data.status === 0) {
          dispatch(updateMasterServiceActionSuccess(response.data.data));
          history.push("/vendormaster/success", {
            type: "vendor",
            actiontype: data.actiontype,
          });
        } else if (response.data.status > 0) {
          dispatch(updateMasterServiceActionError(response.data.statusMessage));
        } else {
          dispatch(updateMasterServiceActionError(APPLICATION_CONST.message));
        }
        dispatch(loaderStatus(false));
      })
      .catch((error) => {
        dispatch(updateMasterServiceActionError(error.message, false));
        dispatch(loaderStatus(false));
      });
  };
};

export const updateMasterServiceActionError = (error) => ({
  type: VENDOR_MASTER_UPDATE_SERVICE_ERROR,
  payload: error,
});

export const updateMasterServiceActionSuccess = (data) => ({
  type: VENDOR_MASTER_UPDATE_SERVICE_SUCCESS,
  payload: data,
});

/***********************Master Vendors********************** */
