import axios from "axios";
import { REPORTS_ERROR, REPORTS_PENDING, REPORTS_SUCCESS } from "./actionTypes";
import { loaderStatus } from "../vendor/loaderAction";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
export const getReportService = (data, history) => (dispatch) => {
  const loginDetail = JSON.parse(localStorage.getItem("loginDetail"));
  const body = {
    username: loginDetail.username,
    sessionid: loginDetail.sessionid,
    failstatus: 0,
    request: data.request,
  };

   dispatch(serviceActionPending());
  dispatch(loaderStatus(true));
  axios({
    method: "post",
    url: APPLICATION_CONST.url + "getreport",
    data: body,
  })
    .then((response) => {
      dispatch(loaderStatus(false));
        if (response.data.status === 0) {
        dispatch(
          serviceActionSuccess(
            APPLICATION_CONST.reportFilePath + response.data.data.filename
          )
        );

        history.push("/reports/reportdetail", {
          headerTitle: data.headerTitle,
        });
      } else if (response.data.status > 0) {
        dispatch(serviceActionError(response.data.statusMessage));
      } else {
        dispatch(serviceActionError(APPLICATION_CONST.message));
      }
    })
    .catch((error) => {
      dispatch(serviceActionError(error.message));
      dispatch(loaderStatus(false));
    });
};

export const serviceActionPending = () => ({
  type: REPORTS_PENDING,
});

export const serviceActionError = (error) => ({
  type: REPORTS_ERROR,
  payload: error,
});

export const serviceActionSuccess = (data) => ({
  type: REPORTS_SUCCESS,
  payload: data,
});
