import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class OrderFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: null,
    };
  }

  componentDidMount() {
    const actionType = this.props.location.state.actiontype;
    if (actionType === "FULFILLORDER") {
      this.setState({
        message: "Fulfillment Failed",
      });
    } else if (actionType === "ASSIGNFULFILLMENT") {
      this.setState({
        message: "Assignment Failed",
      });
    } else if (actionType === "CONFIRMFULFILLMENT") {
      this.setState({
        message: "Confirmation Failed",
      });
    } else if (actionType === "CONFIRMRETURN") {
      this.setState({
        message: "Return Failed",
      });
    } else if (actionType === "CHECKINRETURN") {
      this.setState({
        message: "Check In Failed",
      });
    } else if (actionType === "CHECKINORDER") {
      this.setState({
        message: "Check In Failed",
      });
    } else if (actionType === "CHECKOUTORDER") {
      this.setState({
        message: "Check Out Failed",
      });
    } else if (actionType === "ASSIGNRETURN") {
      this.setState({
        message: "Return Failed",
      });
    } else if (actionType === "CANCELFULFILLED") {
      this.setState({
        message: "Cancel Fulfillment",
        cancelrequest: true,
      });
    } else if (actionType === "CANCELCHECKIN") {
      this.setState({
        message: "Cancel Check In",
        cancelrequest: false,
      });
    } else if (actionType === "CANCELASSIGNEDFULFILLMENT") {
      this.setState({
        message: "Cancel Assignment",
        cancelrequest: true,
      });
    } else if (actionType === "CANCELCONFIRMEDFULFILLMENT") {
      this.setState({
        message: "Cancel Confirmation",
        cancelrequest: true,
      });
    } else if (actionType === "CANCELCHECKOUTORDER") {
      this.setState({
        message: "Cancel Check Out Order",
        cancelrequest: false,
      });
    } else if (actionType === "REFUNDORDER") {
      this.setState({
        message: "Refund Order",
        cancelrequest: false,
      });
    } else if (actionType === "CANCELASSIGNEDRETURN") {
      this.setState({
        message: "Return Canceled",
        cancelrequest: false,
      });
    }
  }

  backToHome = () => {
    let location = "/";

    const processAction = this.props.location.state.actiontype;
    if (
      processAction === "CANCELFULFILLED" ||
      processAction === "CANCELASSIGNEDFULFILLMENT" ||
      processAction === "CANCELCONFIRMEDFULFILLMENT"
    ) {
      location = "/";
    }
    return (
      <button
        className="btn btn-success btn-primary"
        style={{ fontSize: 13, fontWeight: "bold" }}
        onClick={() => this.props.history.push(location)}
      >
        <FontAwesomeIcon
          icon="reply"
          color="#FFF"
          style={{ fontSize: 15, marginRight: 5 }}
        />
        Back To Home
      </button>
    );
  };
  render() {
    return (
      <>
        <NavBar />
        <div
          className="container-fluid"
          style={{ paddingBottom: 50, paddingTop: 50 }}
        >
          <div className="row justify-content-center">
            <div className="col-md-6 text-center">
              <FontAwesomeIcon
                icon="times-circle"
                color="#FF0000"
                style={{ fontSize: 140 }}
              />

              <div
                style={{
                  marginTop: 30,
                  fontSize: 30,
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {this.state.message}
              </div>
              <div style={{ fontSize: 12 }}>{this.props.ordererror}</div>

              <div style={{ marginTop: 30, fontSize: 30, fontWeight: "bold" }}>
                {this.backToHome()}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

//  export default Success;
const mapStateToProps = (state) => ({
  userDetail: state.SignInReducer.logindetails,
  ordererror: state.OrderProcessReducer.orderProcessError,
});

export default connect(mapStateToProps)(OrderFailed);
