import {
  UPDATE_CHANGE_PASSWORD_FORM,
  FORCE_RESET_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_ERROR,
  SHOW_CHANGE_PASSWORD_DIALOG,
  HIDE_CHANGE_PASSWORD_DIALOG,
  DISABLE_FORCE_RESET_PASSWORD,
} from "../../actions/authentication/actionTypes";

const initialState = {
  formData: {
    oldPassword: {
      value: "",
      visible: false,
    },
    newPassword: {
      value: "",
      visible: false,
    },
    confirmPassword: {
      value: "",
      visible: false,
    },
  },
  displayDialog: false,
  forceReset: false,
  changePasswordStatus: null,
  changePasswordError: null,
};

const ChangePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CHANGE_PASSWORD_FORM:
      const tempFormData = { ...state.formData };
      const fieldname = action.payload.fieldname;
      const key = action.payload.key;
      const value = action.payload.value;
      tempFormData[fieldname][key] = value;
      return {
        ...state,
        formData: tempFormData,
      };
    case FORCE_RESET_PASSWORD:
      return {
        ...state,
        forceReset: true,
        displayDialog: true,
      };
    case DISABLE_FORCE_RESET_PASSWORD:
      return {
        ...state,
        forceReset: false,
        displayDialog: false,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordStatus: "success",
        changePasswordError: null,
      };
    case CHANGE_PASSWORD_PENDING:
      return {
        ...state,
        changePasswordStatus: "pending",
        changePasswordError: null,
      };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePasswordStatus: "failed",
        changePasswordError: action.payload,
      };
    case SHOW_CHANGE_PASSWORD_DIALOG:
      return {
        ...state,
        displayDialog: true,
      };
    case HIDE_CHANGE_PASSWORD_DIALOG:
      return {
        ...state,
        displayDialog: false,
      };
    default:
      return state;
  }
};

export default ChangePasswordReducer;
