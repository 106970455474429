import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Footer from "../common/footer";
import Loader from "../common/loader";
import { getMyOrders } from "../../actions/public/orderAction";
import { Container, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { dateFormat } from "../../actions/common/dateTime";

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      data: [],
      perPage: 10,
      currentPage: 0,
    };

    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0,0);

    this.props.getMyOrders();
  };
  calcTotal = (orderItems) => {
    let total = 0;
    orderItems.forEach((item) => {
      let orderedAmt = item.unitcost * item.orderedamount;
      let taxTotal = parseFloat(orderedAmt) * parseFloat(item.taxrate);
      total += orderedAmt + taxTotal;
    });
    return total;
  };

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.orderFilter();
      }
    );
  };

  orderFilter() {
    let product = this.props.orders;
    let products = [...product];
    let slice = products.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    let data = {
      slice: slice,
      products: products,
    };

    return data;
  }

  render() {
    return (
      <>
        <NavBar />
        <Loader message="Retrieving Orders.." />

        <Container className={"back-bar-container"} fluid>
          <Row className={" categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon"}
                />
                Back
              </a>
            </Col>
          </Row>
        </Container>

        <div
          className={"stores-container"}
          style={{ paddingTop: 30, paddingBottom: 100 }}
        >
          <div className="col">
            {this.props.orderStatus === "success" ? (
              this.props.orders.length > 0 ? (
                this.orderFilter().slice.map((order, index) => (
                  <div
                    key={index}
                    style={{
                      backgroundColor: "#FFF",
                      marginBottom: 10,
                      border: "1px solid #E1E1E1",
                      minHeight: 150,
                      borderRadius: 5,
                      overflow: "hidden",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        backgroundColor: "#EEE",
                        padding: 15,
                      }}
                    >
                      <div>
                        <div
                          style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                        >
                          ORDER PLACED
                        </div>
                        <div
                          style={{ fontSize: 13, fontFamily: "Poppins-Light" }}
                        >
                          {moment(order.flight.flighttime).format(dateFormat(
                          "normaldate",
                          this.props.userDetail.userdefault
                        ))}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                        >
                          CUSTOMER
                        </div>
                        <div
                          style={{ fontSize: 13, fontFamily: "Poppins-Light" }}
                        >
                          {order.guest.firstname} {order.guest.lastname}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{ fontSize: 11, fontFamily: "Poppins-Light" }}
                        >
                          ORDER NUMBER
                        </div>
                        <div
                          style={{
                            fontSize: 16,
                            fontFamily: "Poppins-Light",
                            textAlign: "right",
                          }}
                        >
                          {order.orderid}
                        </div>
                      </div>

                      <div>
                        <div
                          style={{
                            fontSize: 12,
                            fontFamily: "Poppins-Light",
                            textAlign: "right",
                            color: "#FFF",
                          }}
                        >
                          <span
                            style={{
                              backgroundColor: "#39304A",
                              padding: 5,
                              borderRadius: 2,
                            }}
                          >
                            {order.status}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: 20,
                      }}
                    >
                      <div>
                        <div>
                          <div
                            style={{
                              fontSize: 11,
                              fontFamily: "Poppins-Light",
                            }}
                          >
                            FLIGHT INFO
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: "Poppins-Light",
                            }}
                          >
                            {moment(order.flight.flighttime).format(
                              dateFormat(
                                "normaltime",
                                this.props.userDetail.userdefault
                              )
                            )}
                          </div>
                          <div
                            style={{
                              fontSize: 13,
                              fontFamily: "Poppins-Light",
                            }}
                          >
                            {order.flight.airlineid}
                            <span
                              style={{
                                fontFamily: "Poppins-Light",
                                marginLeft: 5,
                              }}
                            >
                              {order.flight.flightid}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div>
                          <div
                            style={{
                              fontSize: 11,
                              fontFamily: "Poppins-Light",
                            }}
                          >
                            ITEMS
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: "Poppins-Light",
                              textAlign: "right",
                            }}
                          >
                            {order.orderitems.length}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div>
                          <div
                            style={{
                              fontSize: 11,
                              fontFamily: "Poppins-Light",
                            }}
                          >
                            TOTAL
                          </div>
                          <div
                            style={{
                              fontSize: 14,
                              fontFamily: "Poppins-Light",
                              textAlign: "right",
                            }}
                          >
                            $
                            {parseFloat(
                              this.calcTotal(order.orderitems)
                            ).toFixed(2)}
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{ fontSize: 13, fontFamily: "Poppins-Light" }}
                        >
                          <button
                            className="btn btn-sm"
                            style={{
                              fontSize: 10,
                              backgroundColor: "#455A64",
                              color: "#FFF",
                            }}
                            onClick={() =>
                              this.props.history.push(`/order/detail`, {
                                order: order,
                              })
                            }
                          >
                            VIEW DETAILS
                            <FontAwesomeIcon
                              icon="chevron-right"
                              color="#FFF"
                              style={{ marginLeft: 20 }}
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Order Currently available for loading!</p>
              )
            ) : null}
          </div>

          <div className="col-md-12">
            <ReactPaginate
              previousLabel={"Prev"}
              nextLabel={"Next"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(
                this.props.orders.length / this.state.perPage
              )}
              marginPagesDisplayed={5}
              pageRangeDisplayed={10}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  orders: state.OrderReducer.orders,
  orderStatus: state.OrderReducer.orderStatus,
  //orderStatus: state.OrderReducer.orderStatus,
  orderError: state.OrderReducer.orderError,
  userDetail: state.SignInReducer.logindetails,
});

export default connect(mapStateToProps, { getMyOrders })(Orders);
