import React, { Component } from "react";
import { connect } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import NavBar from "../common/navbar";
import Loader from "../common/loader";
import DialogModal from "../common/dialogModal";
import { getProductService } from "../../actions/vendor/productAction";
import {
  updateProductService,
  callProductMasterWebService,
  productDeleteWebService,
} from "../../actions/vendor/productAction";
import Footer from "../common/footer";
import { styles } from "../../styles/commontStyles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "react-bootstrap";
import { APPLICATION_CONST } from "../../Config/CustomConfig";
import { Badge, Container, Col, Row } from "react-bootstrap";
import { MDBDataTable } from "mdbreact";

class Product extends Component {
  constructor(props) {
    super(props);

    if(this.props.logindetails.userdefault.supervisor === 'N'){
      this.props.history.push('/');
    }

    this.state = {
      displayDialog: false,
      dialogTitle: null,
      dialogBody: null,
      dialogFooter: null,
      loaderMessage: "Retrieving Products...",
      dataTable: {
        columns: [
          {
            label: "#",
            field: "index",
            width: 10,
          },
          {
            label: "Image",
            field: "image",
            sort: "asc",
            width: 150,
          },
          {
            label: "Product Name",
            field: "productname",
            sort: "asc",
            width: 270,
          },
          {
            label: "Unit Cost",
            field: "unitcost",
            sort: "asc",
            width: 200,
          },
          {
            label: "Quantity",
            field: "quantity",
            sort: "asc",
            width: 100,
          },
          {
            label: "Category",
            field: "categoryname",
            sort: "asc",
            width: 150,
          },
          {
            label: "Status",
            field: "status",
            sort: "asc",
            width: 150,
          },
          {
            label: "Action",
            field: "action",
            width: 100,
          },
        ],
        rows: [
          ...this.props.products.map((product, index) => ({
            index: index + 1,
            image: (
              <Image
                style={{
                  height: 50,
                  width: 50,
                  // width: "auto",
                  margin: 3,
                  alignSelf: "center",
                }}
                resizemode="contain"
                src={
                  APPLICATION_CONST.productImageUrl +
                  product.productreferenceid +
                  ".png"+"?" +
                  new Date()
                }
              />
            ),
            productname: product.productname,
            unitcost: product.unitcost.toFixed(2),
            quantity: product.quantity,
            categoryname: product.categoryname,
            status:
              product.status === "A" ? (
                <Badge variant="success">Active</Badge>
              ) : (
                <Badge variant="danger">Inactive</Badge>
              ),
            action: (
              <span>
                <button
                  onClick={() => this.onEditBtnClick(product.productid)}
                  className="btn btn-sm btn-info btn-warning"
                  style={{ marginRight: 10, marginLeft: 10 }}
                >
                  <i className="fa fa-pencil"></i>
                </button>

                <button
                  className="btn btn-sm btn-info btn-danger"
                  onClick={() => this.deleteProduct(product)}
                >
                  <FontAwesomeIcon
                    icon="trash"
                    color="#FFF"
                    style={{ widht: 15, height: 15 }}
                  />
                </button>
              </span>
            ),
          })),
        ],
      },
    };
  }

  componentDidMount = () => {
     window.scrollTo(0,0);

    this._getProductService();
  };
  _getProductService = () => {
    const data = {
      username: this.props.logindetails.username,
      sessionid: this.props.logindetails.sessionid,
    };
    this.props.getProductService(data);
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.deleteProductError &&
      nextProps.deleteProductError !== this.props.deleteProductError
    ) {
      this.setState({
        displayDialog: true,
        dialogTitle: "Oops!",
        dialogBody: nextProps.deleteProductError,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Close
          </button>
        ),
      });
    }

    if (
      nextProps.deleteProductSuccess &&
      nextProps.deleteProductSuccess !== this.props.deleteProductSuccess
    ) {
      this.setState({
        loaderMessage: "Retrieving Products...",
        displayDialog: true,
        dialogTitle: "Success!",
        dialogBody: `Product deleted successfully!`,
        dialogFooter: (
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={() => {
              this.closeDialog();
              this._getProductService();
            }}
          >
            Close
          </button>
        ),
      });
    }
    if (nextProps.products !== this.props.products) {
      this.setState({
        dataTable: {
          ...this.state.dataTable,
          rows: [
            ...nextProps.products.map((product, index) => ({
              index: index + 1,
              image: (
                <Image
                  style={{
                    height: 50,
                    width: 50,
                    // width: "auto",
                    margin: 3,
                    alignSelf: "center",
                  }}
                  resizemode="contain"
                  src={
                    APPLICATION_CONST.productImageUrl +
                    product.productreferenceid +
                    ".png"+"?" +
                    new Date()
                  }
                />
              ),
              productname: product.productname,
              unitcost: product.unitcost.toFixed(2),
              quantity: product.quantity,
              categoryname: product.categoryname,
              status:
                product.status === "A" ? (
                  <Badge variant="success">Active</Badge>
                ) : (
                  <Badge variant="danger">Inactive</Badge>
                ),
              action: (
                <span>
                  <button
                    onClick={() => this.onEditBtnClick(product.productid)}
                    className="btn btn-sm btn-info btn-warning"
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >
                    <i className="fa fa-pencil"></i>
                  </button>

                  <button
                    className="btn btn-sm btn-info btn-danger"
                    onClick={() => this.deleteProduct(product)}
                  >
                    <FontAwesomeIcon
                      icon="trash"
                      color="#FFF"
                      style={{ widht: 15, height: 15 }}
                    />
                  </button>
                </span>
              ),
            })),
          ],
        },
      });
    }
  }

  onEditBtnClick = (productid) => {
    this.props.history.push("/products/edit", { productid: productid });
  };
  closeDialog = () => {
    this.setState({
      displayDialog: false,
    });
  };
  deleteProduct = (product) => {
    this.setState({
      loaderMessage: "Processing...",
      displayDialog: true,
      dialogTitle: "Delete!",
      dialogBody: "Are you sure you want to delete this product?",
      dialogFooter: (
        <>
          <button
            className="btn"
            style={styles.dialogConfirmButtonStyle}
            onClick={() => {
              this.closeDialog();
              const history = this.props.history;
              const data = {
                username: this.props.logindetails.username,
                sessionid: this.props.logindetails.sessionid,
                product: product,
                actiontype: "DELETE",
                productmaster: false,
              };
              this.props.productDeleteWebService(data, history);
            }}
          >
            Ok
          </button>
          <button
            className="btn"
            style={styles.dialogCancelButtonStyle}
            onClick={this.closeDialog}
          >
            Cancel
          </button>
        </>
      ),
    });
  };

  render() {
    return (
      <>
        <NavBar />
        <Loader message={this.state.loaderMessage} />
        <DialogModal
          visible={this.state.displayDialog}
          title={this.state.dialogTitle}
          body={this.state.dialogBody}
          footer={this.state.dialogFooter}
        />

        <Container className={"back-bar-container"} fluid>
          <Row className={"categoryBar back-bar"}>
            <Col lg="12" md="12">
              <a
                className={"back-link"}
                onClick={() => this.props.history.push("/")}
              >
                <FontAwesomeIcon
                  icon="chevron-left"
                  color="#777"
                  className={"back-icon "}
                />
                Back
              </a>
              <ul>
                <li>Products</li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="stores-container" fluid>
          <div style={{ padding: 15 }} className="custom_datatable ">
            <MDBDataTable
              striped
              bordered
              hover
              small
              data={this.state.dataTable}
            />

            <button
              style={{
                width: 60,
                height: 60,
                borderRadius: 30,
                position: "fixed",
                backgroundColor: "#4CAF50",
                bottom: 0,
                right: 0,
                zIndex: 100,
                margin: 30,
              }}
              className="btn"
              onClick={() => this.props.history.push("/products/add")}
            >
              <FontAwesomeIcon icon="plus" color="#FFF" />
            </button>
          </div>
          <div className="spacer"></div>
        </Container>

        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  logindetails: state.SignInReducer.logindetails,
  products: state.VendorProductReducer.products,
  deleteProductError: state.VendorProductReducer.deleteerrormessage,
  deleteProductSuccess: state.VendorProductReducer.deletesuccessmessage,
});
export default connect(mapStateToProps, {
  getProductService,
  updateProductService,
  callProductMasterWebService,
  productDeleteWebService,
})(Product);
